import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

const CollectionLawFirmENoticeCondoEn = (props) => {
    const [data] = useState(props?.data)
    const [indexS] = useState(props?.indexS)
    const [setting] = useState(props?.setting)

    useEffect(() => {

    }, [props?.data])


    return (
        <div key={"fmcfCollectionLawFirm" + indexS} id={"colorUseDocEn" + indexS} className={"colorUseDoc colorUseDocEn print-page-a4 " + indexS} style={{ position: 'relative' }}>
            <div className="subpage pl-10 pr-10" id="style-document" style={{ overflow: 'hidden' }}>
                <div className="head fs-16 text-center" style={{ marginTop: -15 }}>
                    <img src={process.env.PUBLIC_URL + "/images/iconAction/E-notice/e-noticeIcon.jpeg"} width={100} />
                    <div style={{ position: 'relative' }}>
                        <span style={{ position: 'absolute', right: 269, top: -5 }}>{format(new Date(), "DD/MM/YYYY")}</span>
                        <p>Date..........................................</p>
                    </div>
                </div>

                <div className="detail fs-16">
                    <p style={{ fontSize: 13 }}>Subject: Request for Payment of Maintenance and Common Area Fees for Condominium Building Project's {props?.nameProjectEn})</p>
                    <p style={{ fontSize: 13 }}>To {data?.contactName}</p>
                    <p style={{ fontSize: 13 }}>Attached Document: Summary of Outstanding Debts for Unit Number {data?.contactRoom}</p>
                </div>

                <div className="invoice-body fs-16" style={{ position: 'relative' }}>

                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        I am writing to you as the legal representative of the juristic persons responsible for the management and maintenance of the property located at {data?.contactRoom} known as {props?.nameProjectEn} with a unit size of {data?.sizeRoom} square meters {data?.balconyRoom ? "and a balcony area of" : ""} {data?.balconyRoom ?? 0} {data?.balconyRoom ? "square meters" : ""}, totaling {data?.sizeRoom} square meters. This corporate entity is registered as a legal entity established in accordance with the Condominium Act of 1979, as per the law, with the objective of overseeing and maintaining common property for the collective benefit of co-owners within the condominium building, as well as being empowered to undertake any actions for the common good, as stated in the bylaws and resolutions passed during the general meetings.
                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        It has come to our attention that you, as a co-owner, have outstanding dues in the form of maintenance and common area fees, building insurance premiums, reserve fund contributions, and other expenses, including late payment fines, {data?.total.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} baht as indicated in the enclosed summary of outstanding debts.

                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        Therefore, in my capacity as the attorney-in-fact representing the corporate entity for the {props?.nameProjectEn} , I kindly request you to make the payment at the {props?.nameProjectEn} office within {setting?.dueDateDays} days from the date you receive this notice during the regular business hours of the corporate entity.
                    </p>
                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        Should you fail to comply with this request within the above time frame, it will be necessary for us to take further legal actions in accordance with the law.
                    </p>

                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        {
                            setting?.ruleStatus ?
                            `Furthermore, if a legal petition is filed to pursue the matter in court in accordance with the corporate entity's bylaws, you will be liable to pay a penalty of ${setting?.feeRateOther.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} baht to cover the expenses associated with legal proceedings. Additionally, any other costs incurred in the future in connection with the collection of outstanding debts will also become your responsibility.` : ``
                        }
                    </p>

                    <p style={{ textIndent: 60, textAlign: 'justify', fontSize: 13 }}>
                        We kindly urge you to take this matter seriously and make the necessary payments promptly to avoid any legal actions. We appreciate your immediate attention to this matter.
                    </p>


                    <div className='row'>
                        <div className='col-7'></div>
                        <div className='col-5 text-center'>
                            <p style={{ fontSize: 13 }}>Sincerely,</p>
                            <p>
                                {
                                    setting?.lawyerSignature ?
                                        <p>
                                            <img id={"signatureImage" + indexS} className='signature' src={setting?.lawyerSignature} />
                                        </p> : <label className='pt-5 pb-5'></label>
                                }

                            </p>
                            <p style={{ fontSize: 13 }}> ({setting?.lawyerName ?? "........................................................."}) </p>
                            <p style={{ fontSize: 13 }}>Representative Lawyer</p>
                        </div>
                    </div>


                    <p style={{ fontSize: 14, position: 'absolute', bottom: -25 }}>
                        Tel. {data?.telOffice}, {data?.telJuristic}
                    </p>


                </div>
            </div>
        </div>
    );

}


export default CollectionLawFirmENoticeCondoEn