/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type PaymentGatewayPaymentType = "CROSSBILL" | "DEEPLINK" | "ETC" | "KSHER" | "SLIP_VERIFICATION" | "TWOCTWOP" | "%future added value";
export type crossBillingPaymentCreateQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  ref_list?: ?$ReadOnlyArray<?string>,
|};
export type crossBillingPaymentCreateQueryResponse = {|
  +invoiceViewer: ?{|
    +id: string,
    +allInvoiceByStatus: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
        |}
      |}>,
    |},
  |},
  +paymentGatewaySettingList: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +paymentType: PaymentGatewayPaymentType,
        +fee: number,
        +bankAccount: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |},
      |}
    |}>
  |},
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +bankName: string,
          +status: boolean,
        |}
      |}>,
      +totalCount: ?number,
    |}
  |},
|};
export type crossBillingPaymentCreateQuery = {|
  variables: crossBillingPaymentCreateQueryVariables,
  response: crossBillingPaymentCreateQueryResponse,
|};
*/


/*
query crossBillingPaymentCreateQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $ref_list: [String]
) {
  invoiceViewer {
    id
    allInvoiceByStatus(search: $search, startDate: $start_date, endDate: $end_date, statusList: ["active", "overdue", "partial_payment"], ref_In: $ref_list) {
      totalCount
      edges {
        node {
          id
          docNumber
        }
      }
    }
  }
  paymentGatewaySettingList(paymentType: "crossbill") {
    edges {
      node {
        id
        paymentType
        fee
        bankAccount {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
    }
  }
  bankAccountViewer {
    allBankAccount(status: true, crossBilling: true) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountType
          receivePayment
          makePayment
          bankName
          status
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ref_list"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceViewer",
  "kind": "LinkedField",
  "name": "invoiceViewer",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "end_date"
        },
        {
          "kind": "Variable",
          "name": "ref_In",
          "variableName": "ref_list"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "start_date"
        },
        {
          "kind": "Literal",
          "name": "statusList",
          "value": [
            "active",
            "overdue",
            "partial_payment"
          ]
        }
      ],
      "concreteType": "InvoiceNodeConnection",
      "kind": "LinkedField",
      "name": "allInvoiceByStatus",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceNode",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountNumber",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accountType",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "receivePayment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "makePayment",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bankName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "paymentType",
      "value": "crossbill"
    }
  ],
  "concreteType": "PaymentGatewayNodeConnection",
  "kind": "LinkedField",
  "name": "paymentGatewaySettingList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentGatewayNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentGatewayNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fee",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "kind": "LinkedField",
              "name": "bankAccount",
              "plural": false,
              "selections": (v8/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "paymentGatewaySettingList(paymentType:\"crossbill\")"
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "crossBilling",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": "allBankAccount(crossBilling:true,status:true)"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "crossBillingPaymentCreateQuery",
    "selections": [
      (v7/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "crossBillingPaymentCreateQuery",
    "selections": [
      (v7/*: any*/),
      (v9/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2bd0bdd8c255c3919e2f64b00d07f395",
    "id": null,
    "metadata": {},
    "name": "crossBillingPaymentCreateQuery",
    "operationKind": "query",
    "text": "query crossBillingPaymentCreateQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $ref_list: [String]\n) {\n  invoiceViewer {\n    id\n    allInvoiceByStatus(search: $search, startDate: $start_date, endDate: $end_date, statusList: [\"active\", \"overdue\", \"partial_payment\"], ref_In: $ref_list) {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n        }\n      }\n    }\n  }\n  paymentGatewaySettingList(paymentType: \"crossbill\") {\n    edges {\n      node {\n        id\n        paymentType\n        fee\n        bankAccount {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n    }\n  }\n  bankAccountViewer {\n    allBankAccount(status: true, crossBilling: true) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountType\n          receivePayment\n          makePayment\n          bankName\n          status\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '466fd1e329ea623cf479f329190033ae';

module.exports = node;
