import '../../../report.scss';
import React from 'react';
import _ from "lodash";
import { Translation } from "react-i18next";
import Loading from "../../../../../libs/loading";
import { format } from 'date-fns';
import jwtDecode from 'jwt-decode'

const BankImpactReportTableTransectionPDF = ({ all_bank, page, loading, allPage }) => {

  let previous_balance = 0;
  let cash_in = 0;
  let cash_out = 0;
  let balance = 0;

  const jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
  const printBy = jwtDecodes.first_name + " " + jwtDecodes.last_name

  return (
    <React.Fragment>
      {loading ?
        <div id='bank-deposit-report-main colorUseDoc'>
          <div className="fade-up mt-5 tabel-main height">
            <div className="table-responsive">
              <table className="table table-hover colorUse notKanit">
                <thead className="thead-light">
                  <Translation>{(t) =>
                    <tr>
                      <th className="text-center" >วันที่</th>
                      <th className="text-center" >เลขที่</th>
                      <th className="text-center" >อ้างอิง</th>
                      <th className="text-center" >คำอธิบาย</th>
                      <th className="text-center" >ยอดยกมา</th>
                      <th className="text-center" >เงินรับ</th>
                      <th className="text-center" >เงินจ่าย</th>
                      <th className="text-center" >จำนวนเงินคงเหลือ</th>
                    </tr>
                  }</Translation>
                </thead>
                <tbody>
                  {_.map(all_bank, (item, index, array) => {
                    let lastItem = (index + 1) === array.length;

                    previous_balance += _.get(item, "previous_balance") ? parseFloat(_.get(item, "previous_balance")) : 0
                    cash_in += _.get(item, "cash_in") ? parseFloat(_.get(item, "cash_in")) : 0
                    cash_out += _.get(item, "cash_out") ? parseFloat(_.get(item, "cash_out")) : 0
                    balance += _.get(item, "balance") ? parseFloat(_.get(item, "balance")) : 0

                    return (
                      <React.Fragment key={"bankImpactReportTableTransectionPDF::" + index}>
                        {
                          _.get(item, "description") !== "รวม" &&
                          <tr className='fs-12' >
                            <td className="text-center">{_.get(item, "date")}</td>
                            <td className="text-center">{_.get(item, "ref_number")}</td>
                            <td className="text-center">{_.get(item, "ref_transaction")}</td>
                            <td className="text-left w370" style={{ verticalAlign: 'middle' }}>
                              <label className='tdDescriptionWrap mt-1 mb-1'><span className='tdDescriptionWrapSpan'>{_.get(item, "description")}</span></label>
                            </td>
                            <td className="text-right">{_.get(item, "previous_balance") !== "" ? parseFloat(_.get(item, "previous_balance")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{_.get(item, "cash_in") !== "" ? parseFloat(_.get(item, "cash_in")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{_.get(item, "cash_out") !== "" ? parseFloat(_.get(item, "cash_out")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{parseFloat(_.get(item, "balance")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                          </tr>
                        }

                        {/* {
                          lastItem &&
                          <tr className='td-sum fs-14' >
                            <td colSpan={4} className="text-right" ><b>รวม</b></td>
                            <td className="text-right">{parseFloat(previous_balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className="text-right">{parseFloat(cash_in).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className="text-right">{parseFloat(cash_out).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                            <td className="text-right">{parseFloat(balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                          </tr>
                        } */}
                        {
                          _.get(item, "description") === "รวม" &&
                          <tr className='td-sum fs-14' >
                            <td colSpan={4} className="text-right" ><b>รวมจำนวนเงิน</b></td>
                            <td className="text-right">{_.get(item, "previous_balance") !== "" ? parseFloat(_.get(item, "previous_balance")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{_.get(item, "cash_in") !== "" ? parseFloat(_.get(item, "cash_in")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{_.get(item, "cash_out") !== "" ? parseFloat(_.get(item, "cash_out")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : ""}</td>
                            <td className="text-right">{parseFloat(_.get(item, "balance")).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                          </tr>
                        }
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className={`footer`}>
            <div className='row'>
              <div className='col-5' />
              <div className='col-2 text-center'>
                <p className='f14 pb-0 mb-0'>{page}  /  {allPage}</p>
              </div>
              <div className='col-5 text-right'>
                <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
              </div>
            </div>
          </div>
        </div> : <Loading />}

    </React.Fragment>
  );
};

export default BankImpactReportTableTransectionPDF;