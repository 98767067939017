import {format} from "date-fns";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import {commitMutation} from "react-relay";
import Asset from '../../../../libs/autoAsset'
import _ from 'lodash'

const mutation = graphql`
    mutation otherExpenseMutationMutation($input: CreateOtherExpenseAndOtherExpenseTransactionInput!){
        createOtherExpenseAndOtherExpenseTransaction(input:$input){
            ok
            warningText
        }
    }`;

export function createOtherExpenseMutation(state) {
    let _this = {
        state
    };
    let other = {
        "id": _this.state.group_detail ? _this.state.group_detail.id : "",
        "issuedDate": format(_this.state.issuedDate, 'YYYY-MM-DD'),
        "taxType": _this.state.taxType.toLowerCase(),
        "remark": _this.state.remark,
        "creator": "",
        "approver": "",
        "updater": "",
        "total": _this.state.total < 0 ? 0 : _this.state.total,
        "tax_invoice_number": _this.state.tax_invoice_number,
        "tax_invoice_date": format(_this.state.tax_invoice_date, 'YYYY-MM-DD'),
        "tax_invoice_period": format(_this.state.tax_invoice_period, 'YYYY-MM-DD'),
        "ref_number": _this.state.ref_number,
    };

    let asset = []
    _.forEach(_this.state.transaction_list , transaction => {
        if(_.startsWith(transaction.productAndService.productCode,'1210') || _.startsWith(transaction.productAndService.productCode,'1230')){
            asset.push({
                type: Asset.getTypeAsset(transaction.productAndService.chartOfAccount.chartOfAccountCode),
                nounClassifier:'',
                historicalValue:0,
                purchaseDate:format(_this.state.issuedDate, 'YYYY-MM-DD'),
                costPrice: (parseFloat(transaction.preTaxAmount) + parseFloat(transaction.vatAmount)), //preTax = price - discount  
                calDepreciationDate: transaction.productAndService.productCode === '1210-01' ? null : format(_this.state.issuedDate, 'YYYY-MM-DD'),
                carcassPrice:1,
                historicalAmount:0,
                name: transaction.description,
                description:transaction.description,
                storage:'',
                remark:_this.state.remark,
                seller: _this.state.contact !== null  ? _this.state.contactList[0] : "other_select",
                sellerOther: _this.state.contact !== null ?  '' : _this.state.contactList[0] ,
                saleDate:null,
                saleStatus:'active',
                refNumber:''
            })
        }
    })
    // let mapDataOther = _this.state.transaction_list.map((ddaa )=> ({
    //     ...ddaa, description : ddaa.type === "EXPENSE" ? ddaa.descriptionOther : ddaa.description
    // }))
    let variables = {
        input: {
            contactId: _this.state.contactList,
            pay: JSON.stringify(other),
            payChannel: JSON.stringify(_this.state.payChannel),
            transactionList: JSON.stringify(_this.state.transaction_list),
            asset: asset,
            clientMutationId:"OE"+_this.state.signreq,
        }
    };

    return variables;

}

export function createOtherExpense(variables, callback, error_callback, uploadables) {
    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
export default {createOtherExpenseMutation, createOtherExpense};
