import React, { Component } from 'react'
import PrivateRoute from '../../libs/privateRoute'
import NoMatch from '../../components/noMatch'
import { Route, Switch } from "react-router-dom";
import StockManagementList from './stockMenagementList';
import StockManagementFormInput from './stockMenagementFormInput';
import CreateAndUpdateFromStock from './createAndUpdateFromStock';

export default class stockMenagement extends Component {
  render() {
    return (
      <Switch>
        <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={StockManagementList} />
        <PrivateRoute exact path={`${this.props.match.url}/form/input`} component={StockManagementFormInput} />
        <PrivateRoute exact path={`${this.props.match.url}/createUpdateForm/:action/:status`} component={CreateAndUpdateFromStock} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}