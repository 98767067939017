import React, { Component } from "react";
import { Link } from "react-router-dom";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";

import "./css/otherExpenseCreateUpdateForm.scss";
import {
  addDays,
  parse,
  differenceInCalendarDays,
  format,
  addMonths /*, addYears*/,
} from "date-fns";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import numberWithComma from "../../../../libs/numberWithComma";
import Swal from "sweetalert2";
import { Redirect } from "react-router";
import SearchSelect from "../../../../libs/searchSelect";
import getDocumentNumber from "../../../../libs/getDocumentNumber";
import _ from "lodash";
import getNameResidential from "../../../../libs/getNameResidential";

import OtherExpenseSummary from "./otherExpenseSummary";
import OtherExpenseDelete from "./otherExpenseDelete";
import OtherExpenseCreateDetailContact from "./otherExpenseCreateDetailContact";
import OtherExpensePeriodAdjustSetting from "./otherExpensePeriodAdjustSetting";
import OtherExpenseWaterMeterSelectDate from "./otherExpenseWaterMeterSelectDate";
import OtherExpenseIndividualAreaPriceSetting from "./otherExpenseIndividualAreaPriceSetting";
import OtherExpenseCarParkSelect from "./otherExpenseCarParkSelect";
import UploadFileInput from "../../../../libs/uploadFileInput";

import Other from "../mutations/otherExpenseMutation";
import PayPaymentChannelForm from "../../../pay/payPaymentChannelForm";
import { Translation } from "react-i18next";
import i18next from "i18next";




var timeout;

let transaction_object = {
  description: "",
  discount: 0,
  id: "",
  preTaxAmount: 0,
  price: "",
  productAndService: {},
  unitItems: 1,
  vat: 0,
  vatAmount: 0,
  whtRate: 0,
  whtRatePercent: 0,
  totalLeftInStock: "",
};

const query = graphql`
    query otherExpenseCreateUpdateFormQuery{
      allProjects {
            edges {
            node {
            periodCreate
        }
      }
    }
  }
`;
class OtherExpenseCreateUpdateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issuedDate: new Date(),
      dueDateCount: 7,
      dueDate: addDays(new Date(), 7),
      taxType:
        !props.project_have_vat &&
          (props.other_expense) &&
          props.contact &&
          props.contact.vat === 7
          ? "INCLUDE_TAX"
          : "NO_TAX",
      remark: "",
      total: 0,
      transaction_list: [],
      redirectToList: false,
      category: props.category,
      receiveDeposit: "0",
      chartOfAccountId: "",
      product_and_service: props.product_and_service,
      group_detail: props.group_detail,
      tax_invoice_number: "",
      ref_number: "",

      tax_invoice_date: new Date(),
      tax_invoice_period: new Date(),
      loading: false,
      payChannel: [],
      upload: {},
      contactList: props.contactList,
      totalPay: 0,
      total_discount: 0,
      total_wht_rate: 0,
      document_upload: "",
      status: "draft",
      vat: 0,
      contact:props.contact,
      signreq:Math.random().toString(36),
      case_return_vat:
        !props.project_have_vat && (props.other_expense) && props.contact && props.contact.vat === 7 
          ? "true"
          : false,
      temp_month_before : '',
      temp_month_after : '',
    };

    this.formRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitFrom = this.submitFrom.bind(this);
    this.appendNewRow = this.appendNewRow.bind(this);
    this.getPreTaxAmount = this.getPreTaxAmount.bind(this);
    this.setTransactionList = this.setTransactionList.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.updatePayChannel = this.updatePayChannel.bind(this);
    this.onSuccessOtherExpense = this.onSuccessOtherExpense.bind(this);
    this.handleInputDocument = this.handleInputDocument.bind(this);
    this.setServicePeriod = this.setServicePeriod.bind(this);
    this.setWaterMeterTransaction = this.setWaterMeterTransaction.bind(this);
    this.setElectricMeterTransaction = this.setElectricMeterTransaction.bind(this);
    this.setIndividualTransaction = this.setIndividualTransaction.bind(this);
    this.setCarParkTransaction = this.setCarParkTransaction.bind(this);
    this.getRemark = this.getRemark.bind(this);
  }

  removeTrailingZeros(description) {
    if (description.includes("ค่าน้ำประปา")) {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    } else {
      description = description.replace(/ *\[[^)]*\] */g, " ");
    }
    return description;
  }

  componentWillMount() {
    this.setState({ tax_invoice_date: "" });
    if (this.state.group_detail) {
      // update

      let issued_date = parse(this.state.group_detail.issuedDate);
      let due_date = parse(this.state.group_detail.dueDate);
      let due_date_count = differenceInCalendarDays(due_date, issued_date);

      let list = [...this.state.transaction_list];
      let transactions = [...this.props.transactions];

      transactions.forEach((transaction) => {
        transaction = _.cloneDeep(transaction.node);
        if (!_.get(transaction, "productAndService")) {
          _.set(
            transaction,
            "productAndService",
            _.get(transaction, "chartOfAccount")
          );
        }

        list = [...list, transaction];
      });

      this.setState(
        {
          transaction_list: list,
          issuedDate: issued_date,
          dueDate: due_date,
          dueDateCount: due_date_count,
          taxType: this.state.group_detail.taxType,
          remark: this.state.group_detail.remark,
        }
      );
    } else {
      // create new
      this.setState(
        {
          transaction_list: [...this.state.transaction_list,{ ...transaction_object },],
        },
        () => {
          if (this.state.case_return_vat) {
            let transaction_list = [...this.state.transaction_list];
            transaction_list.forEach((transaction) => {
              transaction["vat"] = 7;
              transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
              transaction["whtRate"] = this.getWhtRate(transaction);
            });
            this.setState({ transaction_list: [...transaction_list], vat: 7 });
          }
        }
      );

    }

    if (this.props.chart_of_account) {
      let product_and_services = [];
      product_and_services = [...this.props.product_and_service];
      let chart_of_accounts = [...this.props.chart_of_account];

      chart_of_accounts.forEach((chart_of_account) => {
        let list_object = {
          id: chart_of_account.node.id,
          name: chart_of_account.node.name,
          productCode: chart_of_account.node.chartOfAccountCode,
          totalLeftInStock: null,
          price: "",
          chartOfAccount: {
            id: chart_of_account.node.id,
            chartOfAccountCode: chart_of_account.node.chartOfAccountCode,
            name: chart_of_account.node.name,
          },
          type: "chart_of_account",
        };
        product_and_services = [...product_and_services, { node: list_object }];
      });

      this.setState(
        {
          product_and_service: product_and_services,
          taxType:
            !this.props.project_have_vat &&
              (this.props.purchase_form || this.props.other_expense) &&
              this.props.contact &&
              this.props.contact.vat === 7
              ? "INCLUDE_TAX"
              : "NO_TAX",
        }
      );
    }


  }

  appendNewRow() {
    transaction_object.vat = this.state.transaction_list[0].vat;
    this.setState({transaction_list: [...this.state.transaction_list,{ ...transaction_object },],});
  }

  getPreTaxAmount(transaction) {
    let pre_tax_amount = transaction.unitItems * transaction.price - transaction.discount;
    return parseFloat(pre_tax_amount.toFixed(2));
  }

  getVat(transaction) {
    const vatAmount = this.getPreTaxAmount(transaction) * ((transaction.vat || 0) / 100)
    return parseFloat(vatAmount.toFixed(2));
  }
  getWhtRate(transaction) {
    // return (this.getPreTaxAmount(transaction) *((transaction.whtRatePercent || 0) / 100));
    return Math.round( ( (this.getPreTaxAmount(transaction) *((transaction.whtRatePercent || 0) / 100)) + Number.EPSILON) * 100) / 100 
  }

  submitFrom(e) {
    e.preventDefault();

    this.state.payChannel.forEach((pay_channel) => {
      if (pay_channel.slug=='petty_cash'||pay_channel.slug=='cash'||pay_channel.slug=='rd_cash'){
        pay_channel.date = this.state.issuedDate
      }
    });

    this.setState({ loading: true });
    if (this.state.category === "other_expense") {
      if (parseFloat(this.state.total_wht_rate) === parseFloat(this.state.totalPay)) {
        let variables = Other.createOtherExpenseMutation(this.state);
        let upload = this.state.upload;
        Object.assign(upload, { document_upload: this.state.document_upload });
        if (variables) {
          Other.createOtherExpense(variables,this.onSuccessOtherExpense,this.onError,upload);
        }
      } else {
        Swal.fire("ยอดชำระไม่ถูกต้อง","กรุณากรอกจำนวนเงินที่ได้รับ ให้เท่ากับ " +numberWithComma(this.state.total_wht_rate) +" บาท","warning").then(() => this.setState({ loading: false }));
      }
    }
  }


  componentDidMount() {
    fetchQuery(environment, query).then((response_data) => {
        if(response_data){
            let data = _.cloneDeep(response_data);
            this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
        }
    })
}
  calPeriodMouth(val){
    let month_before = new Date();
    let month_after= new Date();

    month_before.setMonth(month_before.getMonth() -  parseInt(val));
    month_after.setMonth(month_after.getMonth() + parseInt(val));

    this.setState({
        temp_month_before : month_before,
        temp_month_after : month_after,
    })
}


  onSuccessOtherExpense(response) {
    this.setState({ loading: false });
    if (response.createOtherExpenseAndOtherExpenseTransaction.ok) {
      Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
        this.setState({ redirectToList: true });
      });
    } else {
      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response.createOtherExpenseAndOtherExpenseTransaction.warningText, "warning");
    }
  }

  onSuccess(response) {
    this.setState({ loading: false });
    if (response.updatePurchaseRecordGroupAndTransaction.ok) {
      Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => {
        if (!this.state.group_detail) {
          this.setState({ redirectToList: true });
        }
      });
    } else {
      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
    }
  }

  onError(err) {
    this.setState({ loading: false });
    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
  }

  setTransactionList(new_transaction) {
    this.setState({ transaction_list: new_transaction });
  }

  updatePayChannel(channel_list) {
    let totalPay = 0;
    let upload = {};
    channel_list.forEach((channel, index) => {
      if (channel.slug === "small-change" || channel.slug === "fee" || channel.slug === "other-expense") {
        if (this.state.total - this.state.total_discount - this.state.totalPay > 0 ) totalPay += parseFloat(channel.pay);
        else totalPay -= parseFloat(channel.pay);
      }
      else if(channel.slug === "petty_cash"){
        let petty_cash = parseFloat(0);
        if(parseFloat(channel.pay)) petty_cash = parseFloat(channel.pay);
        totalPay += petty_cash;
      }
      else if(channel.slug === "rd_cash"){
        let rd_cash = parseFloat(0);
        if(parseFloat(channel.pay)) rd_cash = parseFloat(channel.pay);
        totalPay += rd_cash;
      }
      else {
        totalPay += parseFloat(channel.pay);
      }
      if (channel.image) {
        upload[index] = channel.image;
      }
    });

    totalPay = Math.round(((totalPay) + Number.EPSILON) * 100) / 100;

    this.setState({
      payChannel: channel_list,
      totalPay: totalPay,
      upload: upload,
    });
  }

  handleInputDocument(e) {
    this.setState({document_upload: e.currentTarget.files[0],});
  }


  handleInputChange(e) {
    let total_discount = 0;
    // if (e.target.name === "vat") {
    //   let transaction_list = [...this.state.transaction_list];
    //   transaction_list.forEach((transaction) => {
    //     transaction.vat = e.target.value;
    //     transaction["preTaxAmount"] = this.getPreTaxAmount(transaction);
    //     transaction["whtRate"] = this.getWhtRate(transaction);
    //   });


    //   this.setState({ transaction_list, vat: e.target.value });
    //   // return;
    // }

    
    if (e.target.name === "dueDateCount") {
      this.setState({dueDateCount: e.currentTarget.value,dueDate: addDays(this.state.issuedDate, e.currentTarget.value),});
    } else if (e.target.dataset.key === "transaction_list") {
      let transaction_list = _.cloneDeep(this.state.transaction_list);
      if (e.target.name === "productAndService") {
        let product_service_list = _.cloneDeep(this.state.product_and_service);
        product_service_list.forEach((ps) => {
          if (ps.node.id === e.target.value) {
            if((_.startsWith(ps.node.productCode,'1210') || _.startsWith(ps.node.productCode,'1230')) && this.props.no_setting_asset){
              Swal.fire(i18next.t("pettyCashCreate:Can't choose this item."), i18next.t("clearAdvanceCreate:Because assets are not configured. Please complete the asset configuration before proceeding with the transaction"), "warning");
              transaction_list[e.target.dataset.id]["productAndService"] = {};
            }else{
              transaction_list[e.target.dataset.id]["productAndService"] =
                ps.node;
              if (ps.node.price == null) {
                transaction_list[e.target.dataset.id]["price"] = "";
              } else {
                transaction_list[e.target.dataset.id]["price"] = ps.node.price;
                transaction_list[e.target.dataset.id]["vatAmount"] = this.props.openVat ? this.getVat(transaction_list[e.target.dataset.id]) : 0;
              }
              transaction_list[e.target.dataset.id]["description"] = ps.node.name;
              transaction_list[e.target.dataset.id]["descriptionOther"] = ps.node.typeOfIncomeOther;
              transaction_list[e.target.dataset.id]["totalLeftInStock"] = ps.node.totalLeftInStock;
              transaction_list[e.target.dataset.id]["type"] = ps.node.type;
              transaction_list[e.target.dataset.id]["unitItems"] = 1;
              transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
              transaction_list[e.target.dataset.id]["vat"] = this.state.vat;
              transaction_list[e.target.dataset.id]["price_type"] = "";
              if (ps.node.type === "PRODUCT") {
                transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
                transaction_list[e.target.dataset.id]["whtRate"] = 0;
              }
              if (ps.node.type === "EXPENSE") {
                if (!this.props.contact) {
                  transaction_list[e.target.dataset.id]["whtRatePercent"] = 0;
                } else {
                  transaction_list[e.target.dataset.id]["whtRatePercent"] = ps.node.taxRate || 0;
                }
              }
            }
            
          }
        });
      } else {
        transaction_list[e.target.dataset.id][e.target.name] = e.target.value;
        if (e.target.name === "discount") {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            transaction_list.forEach((value) => {
              if (value.discount !== "" && value.discount !== null) {
                total_discount += parseFloat(value.discount);
              } else {
                total_discount += 0;
              }
            });
            this.setState({ total_discount: total_discount });
          }, 100);
        }
      }

      transaction_list[e.target.dataset.id]["preTaxAmount"] = this.getPreTaxAmount(transaction_list[e.target.dataset.id]);
      transaction_list[e.target.dataset.id]["whtRate"] = this.getWhtRate(transaction_list[e.target.dataset.id]);
      if (e.target.name === "price" || e.target.name === "unitItems" || e.target.name === "discount" || e.target.name === "vat") {
        transaction_list[e.target.dataset.id]["vatAmount"] = this.getVat(transaction_list[e.target.dataset.id]);
      }
      this.setState({ transaction_list: [...transaction_list] });
    } else {
      let name = e.target.name;
      this.setState({ [e.target.name]: e.target.value }, () => {
        if (name === "dueDate" || name === "issuedDate") {
          this.setState({dueDateCount: differenceInCalendarDays(this.state.dueDate,this.state.issuedDate),
          });
        }
      });
    }
  }

  setServicePeriod(transaction_index, period_state) {
    let service_name = this.state.transaction_list[transaction_index].productAndService.name;
    // let service_description = this.state.transaction_list[transaction_index].productAndService.description;
    let next_month = addMonths(new Date(period_state.startYear, period_state.startMonth, 1),period_state.periodMonth - 1).getMonth();
    let next_year = addMonths(new Date(period_state.startYear, period_state.startMonth, 1),period_state.periodMonth - 1).getFullYear();
    let description_text =" (" +format(new Date(period_state.startYear, period_state.startMonth, 1),"MM") +
    "/" +period_state.startYear +" - " +format(new Date(next_year, next_month, 1), "MM") +"/" +next_year +")";

    let transaction = this.state.transaction_list[transaction_index];
    //_.set(transaction, 'description', service_name + " [" + service_description + "] " + description_text);
    _.set(transaction, "description", service_name + description_text);
    _.set(transaction, "servicePeriod", period_state);
    _.set(transaction,"price",(period_state.totalPrice / period_state.periodMonth).toFixed(2));
    _.set(transaction, "preTaxAmount", period_state.totalPrice);
    _.set(transaction, "unitItems", period_state.periodMonth);
    _.set(transaction, "WaterMeterTransaction", []);
    _.set(transaction, "ElectricMeterTransaction", []);

    this.setState((prevState) => {
      return _.set(_.cloneDeep(prevState),"transaction_list." + transaction_index,transaction);
    });
  }

  setWaterMeterTransaction(transaction_index, water_state) {
    let service_name = this.state.transaction_list[transaction_index].productAndService.name;
    let description_text = service_name + " (" + (parseInt(water_state.MeterMonth) + 1) + "/" + water_state.MeterYear + ") หน่วยที่ " +
      water_state.description + " (" + water_state.unit + ")";

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", description_text);
    _.set(transaction, "servicePeriod", []);
    _.set(transaction, "WaterMeterTransaction", water_state);
    _.set(transaction, "price", water_state.unitPrice);
    _.set(transaction, "preTaxAmount", 0);
    _.set(transaction, "unitItems", water_state.unit);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setElectricMeterTransaction(transaction_index, electric_state) {
    let service_name = this.state.transaction_list[transaction_index].productAndService.name;
    let description_text = service_name + " (" + (parseInt(electric_state.MeterMonth) + 1) + "/" +
      electric_state.MeterYear + ") หน่วยที่ " + electric_state.description + " (" + electric_state.unit + ")";

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", description_text);
    _.set(transaction, "servicePeriod", []);
    _.set(transaction, "ElectricMeterTransaction", electric_state);
    _.set(transaction, "price", electric_state.unitPrice);
    _.set(transaction, "preTaxAmount", electric_state.totalPrice);
    _.set(transaction, "unitItems", electric_state.unit);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setIndividualTransaction(transaction_index, individual_state) {
    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "price", individual_state.price);
    _.set(transaction, "price_type", "individual");
    _.set(transaction, "unitItems", individual_state.unit);
    _.set(
      transaction,
      "preTaxAmount",
      parseFloat((individual_state.unit * individual_state.price).toFixed(2))
    );

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  setCarParkTransaction(transaction_index, data) {
    let service = this.state.transaction_list[transaction_index].productAndService;
    let licence_plate = [];
    let count = 0;

    data.allCars.edges.forEach((detail_park) => {
      licence_plate.push(detail_park.node.licencePlate);
      count += 1;
    });
    let service_name = service.name;
    if (licence_plate.length > 0) {
      service_name = service_name + " ทะเบียนรถ " + licence_plate.join();
    }

    let transaction = this.state.transaction_list[transaction_index];
    _.set(transaction, "description", service_name);
    _.set(transaction, "unitItems", count);
    _.set(transaction, "price", service.price);
    _.set(transaction, "preTaxAmount", service.price * count);

    this.setState((prevState) => {
      return _.set(
        _.cloneDeep(prevState),
        "transaction_list." + transaction_index,
        transaction
      );
    });
  }

  checkAndGetPrefixAddress(type, value) {
    if (this.props.contact?.registeredProvince.includes("กรุงเทพ") || this.props.contact?.registeredProvince.includes("กทม")) {
      if (value) {
        switch (type) {
          case "province":
            return "";
          case "district":
            return "แขวง";
          default:
            return "เขต";
        }
      }
      return "";
    } else {
      if (value) {
        switch (type) {
          case "province":
            return "จังหวัด";
          case "district":
            return "ตำบล";
          default:
            return "อำเภอ";
        }
      }
      return "";
    }
  }

  getRemark(remark) {
    this.setState({ remark: remark });
  }

  checkFormBeforeSubmit = (status) => {      
    let unitItems = Number(this.state.transaction_list[0]?.unitItems)     
    
    if (this.formRef.current.checkValidity()) {
      if (this.formRef.current) {
        this.setState({ status: status },()=>{
          this.formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
        });
      }

    } else if(!Number.isInteger(unitItems) && unitItems !== 0){
      Swal.fire({
        title: "ไม่สามารถใส่จุดทศนิยมในช่องจำนวนได้",
        type: "warning",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",              
      }).then((result) => {        
        if (result.value) {                    
          setTimeout(() => {
            this.formRef.current.reportValidity();
          }, 500);
        }
      });      
    }else {
      this.formRef.current.reportValidity();
    }
  };

  render() {
    if (this.state.redirectToList) {
      if (this.state.status === "active") {
        return <Redirect to={this.props.back_page} />;
      } else {
        return <Redirect to={this.props.next_page} />;
      }
    }

    return (
      
      <Translation>{(t)=>
      <form onSubmit={this.submitFrom} id="other-create-update"  ref={this.formRef}>
        <div className="row">
          {this.props.contactList.length > 0 && (
            <div className="col-3">
              <p>
                <strong>
                  {this.props.other_expense ? i18next.t("otherExpenseCreateUpdateForm:Creditor") : i18next.t("otherExpenseCreateUpdateForm:Customer")}
                </strong>
              </p>

              <div className="input-group">
                {this.props.contactList.length === 1 ? (
                  this.props.contact ? (
                    <input
                      type="text"
                      className="form-control"
                      value={
                        this.props.contact.typeOfContact === "RESIDENTIAL"
                          ? this.props.contact.refNumber +
                          " " + getNameResidential(this.props.contact.firstName, this.props.contact.lastName)
                          : this.props.contact.refNumber +
                          " " + this.props.contact.name
                      }
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      value={this.props.contactList[0] || "-"}
                      disabled
                    />
                  )
                ) : (
                  <OtherExpenseCreateDetailContact
                    Contact={this.props.contactList}
                  />
                )}
              </div>
            </div>
          )}
          <div className="col">
            <p>
              <strong>{i18next.t("otherExpenseCreateUpdateForm:Number")}</strong>
            </p>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={ this.state.group_detail &&
                    this.state.group_detail.status !== "DRAFT" &&
                    this.state.group_detail.status !== "WAITING_FIX"
                    ? this.state.group_detail.docNumber
                    : getDocumentNumber.get(this.state.category, "XXXX", this.state.issuedDate)
                }
                disabled
              />
            </div>
          </div>
          <div className="col">
            <p>
              <strong>{i18next.t("otherExpenseCreateUpdateForm:Invoice Date")}</strong>
            </p>
            <div className="input-group">
              {/* <DatePickerAdapter
                name="issuedDate"
                className="form-control"
                selected={this.state.issuedDate}
                // maxDate={this.state.dueDate}
                onChange={this.handleInputChange}
                required={true}
              /> */}
               {
                 _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                    <DatePickerAdapter
                    name="issuedDate" className="form-control" 
                    selected={this.state.issuedDate} 
                     minDate={this.state.temp_month_before}
                    //  maxDate={this.state.temp_month_after}
                    onChange={this.handleInputChange}
                     required={true}
                    />:
                    <DatePickerAdapter
                    name="issuedDate"
                    className="form-control"
                    selected={this.state.issuedDate}
                    // maxDate={this.state.dueDate}
                    onChange={this.handleInputChange}
                    required={true}
                  />
                                      
              }
            </div>
          </div>

          <div className="col">
            {this.props.project_have_vat && (
              <React.Fragment>
                <p>
                  <strong>{i18next.t("otherExpenseCreateUpdateForm:Price Type")}</strong>
                </p>
                <div className="input-group">
                  <select
                    className="form-control"
                    name="taxType"
                    value={this.state.taxType}
                    onChange={this.handleInputChange}
                  >
                    <option value="NO_TAX">{i18next.t("otherExpenseCreateUpdateForm:No Tax")}</option>
                    <option value="EXCLUDE_TAX">{i18next.t("otherExpenseCreateUpdateForm:Separate Tax")}</option>
                    <option value="INCLUDE_TAX">{i18next.t("otherExpenseCreateUpdateForm:Inclusive Tax")}</option>
                  </select>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        <hr />

        {(this.state.category === "other_expense") && (
          <React.Fragment>
            <div className="row form-group mt-3">
              <div className="col-3">
                <label htmlFor="tax_invoice_number" className="mr-5">
                  <strong>{i18next.t("otherExpenseCreateUpdateForm:Invoice/Receipt Number")}</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="tax_invoice_number"
                  value={this.state.tax_invoice_number}
                  onChange={this.handleInputChange}
                  name="tax_invoice_number"
                  required={false}
                  maxLength="30"
                />
                  <strong className="text-danger font-small">{t("PurchaseCreate:*Enter text no more than 30 characters")} {this.state.tax_invoice_number.length} / 30</strong>
              </div>
              {this.state.tax_invoice_number !== "" && (
                <div className="col-3">
                  <label htmlFor="taxDate" className="mr-5">
                    <strong>{i18next.t("otherExpenseCreateUpdateForm:On date")}</strong>
                  </label>
                  <div className="input-group">
                    <DatePickerAdapter
                      name="tax_invoice_date"
                      className="form-control"
                      // selected={this.state.tax_invoice_date}
                      selected={this.state.taxType.tax_invoice_number !== ""
                          ? this.state.tax_invoice_date
                            ? this.state.tax_invoice_date
                            : new Date()
                          : null
                      }
                      onChange={this.handleInputChange}
                      required={this.state.taxType.tax_invoice_number !== ""? "required": false}
                    />
                  </div>
                </div>
              )}
              <div className="col-3">
                <label htmlFor="ref_number" className="mr-5">
                  <strong>{i18next.t("otherExpenseCreateUpdateForm:Reference Document Number")}</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="ref_number"
                  value={this.state.ref_number}
                  onChange={this.handleInputChange}
                  name="ref_number"
                  maxLength="30"
                />
              </div>

              <div className="col-3">
                <UploadFileInput
                  handleInputDocument={this.handleInputDocument}
                  documentUpload={this.state.document_upload}
                />
              </div>
            </div>

            {this.props.project_have_vat && (
              <div className="row form-group mt-3">
                <div className="col form-inline">
                  <label htmlFor="tax_invoice_period" className="mr-5">
                    <strong>{i18next.t("otherExpenseCreateUpdateForm:Installment")}</strong>
                  </label>
                  <div className="input-group">
                    <DatePickerAdapter
                      name="tax_invoice_period"
                      className="form-control"
                      id="tax_invoice_period"
                      selected={this.state.tax_invoice_period}
                      onChange={this.handleInputChange}
                      showMonthYearPicker={true}
                      dateFormat="MM/yyyy"
                      required={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        <div className="fade-up">
          <div className="card mt-2">
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th width="200">{i18next.t("otherExpenseCreateUpdateForm:Item")}</th>
                  <th width="90">{i18next.t("otherExpenseCreateUpdateForm:Account")}</th>
                  <th width="200">{i18next.t("otherExpenseCreateUpdateForm:Description")} { this.props.contact && this.props.openWht ? 
                  <span className="text-danger">*{i18next.t("otherExpenseCreateUpdateForm:This text will appear in the withholding tax certificate")}</span> : null}</th>
                  <th width="60" className="text-right">
                    {i18next.t("otherExpenseCreateUpdateForm:Quantity")}
                  </th>

                  <th width="80" className="text-right">
                    {i18next.t("otherExpenseCreateUpdateForm:Price/Unit")}
                  </th>
                  <th width="60" className="text-right">
                      {i18next.t("otherExpenseCreateUpdateForm:Discount")}
                    </th>

                  {/* เจ้าหนี้ */}
                  {(!(this.state.taxType === "NO_TAX") || this.state.case_return_vat) && (
                    <th width="60" className="text-right">
                      {i18next.t("otherExpenseCreateUpdateForm:VAT")}
                    </th>
                  )}
                  
                  {(!(this.state.taxType === "NO_TAX") || this.state.case_return_vat) && (
                    <th width="60" className="text-right">
                      {i18next.t("otherExpenseCreateUpdateForm:VAT amount")}
                    </th>
                  )}
                  {/* เจ้าหนี้ */}

                  {/* บุคคลภายนอก */}
                  { this.props.contactWho && (
                    <th width="60" className="text-right">
                      {i18next.t("otherExpenseCreateUpdateForm:VAT")}
                    </th>
                  )}
                  
                  { this.props.contactWho && (
                    <th width="60" className="text-right">
                       {i18next.t("otherExpenseCreateUpdateForm:VAT amount")}
                    </th>
                  )}
                  {/* บุคคลภายนอก */}

                  {this.props.contact && this.props.openWht && (
                    <th width="100" className="text-right">
                      {i18next.t("otherExpenseCreateUpdateForm:Withholding Tax")}
                    </th>
                  )}

                  {this.state.transaction_list.length !== 1 && <th width={1} />}
                </tr>
              </thead>
              <tbody>
                {this.state.transaction_list.map((transaction, index) => {
                  let productAndServiceOrChartOfAccount = "";
                  let disableTransaction = false;
                  let invoice_cal_fine = false;
                  let showlimit = transaction.whtRatePercent != 0

                  if (transaction.chartOfAccount) {
                    disableTransaction = true;
                    productAndServiceOrChartOfAccount = transaction.chartOfAccount;
                  } else {
                    productAndServiceOrChartOfAccount = transaction.productAndService;
                  }
                  if (this.state.category === "invoice" && transaction.type === "FINE" && this.props.mode === "create"
                  ) {
                    invoice_cal_fine = true;
                  }

                  return (

                    <tr key={"transaction-" + index}>
                      <td className={showlimit ? "pt-6" : "" } >
                        {transaction.chartOfAccount ? (
                          <input type="text" className="form-control"
                            value={transaction.chartOfAccount.chartOfAccountCode + " " + transaction.chartOfAccount.name}
                            disabled
                          />
                        ) : (
                          <SearchSelect
                            onChange={this.handleInputChange}
                            value={productAndServiceOrChartOfAccount.id}
                            name="productAndService"
                            placeholder={i18next.t("otherExpenseCreateUpdateForm:Item")}
                            data-id={index}
                            data-key="transaction_list"
                            queryObject={this.state.product_and_service}
                            keyOfValue="id"
                            require={true}
                            keyOfLabel="productCode:name"
                            disabled={disableTransaction}
                          />
                        )}
                      </td>
                      <td className={showlimit ? "pt-6" : "" }>
                        {transaction.chartOfAccount ? (
                          <span>
                            {transaction.chartOfAccount.chartOfAccountCode}
                          </span>
                        ) : (
                          this.state.product_and_service.map((product) => product.node.id === productAndServiceOrChartOfAccount.id && (
                            <span key={product.node.id}>
                              {product.node.chartOfAccount.chartOfAccountCode}
                            </span>
                          )
                          )
                        )}
                      </td>
                      <td>
                        <div className="invoice-description-wrapper">
                          {/*for product and service no option*/}
                          {showlimit &&
                            <p className="text-danger" style={{ fontSize: '9px', marginBottom: '-1px' }}>
                              <Translation>{t => t('purchaseCreate:*Enter text not over 50 character characters')}</Translation>
                               {` ${ transaction.description?.length} `} / 50
                            </p>
                          }
                          {!_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment") &&
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "WATER_METER" &&
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "ELECTRIC_METER" ? (
                            <input
                              type="text"
                              min={1}
                              className="form-control float-left"
                              data-id={index}
                              data-key="transaction_list"
                              name="description"
                              value={transaction.description || ""}
                              onChange={this.handleInputChange}
                              required
                              disabled={disableTransaction || invoice_cal_fine}
                              maxLength={showlimit ? 50 : null}
                            />
                          ) : (
                            <span>
                              {this.removeTrailingZeros(transaction.description)}
                            </span>
                          )}

                          {/*for water meter*/}
                          {(_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER") &&
                            _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" && (
                              <OtherExpenseWaterMeterSelectDate
                                type_service={_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType")}
                                setWaterMeterTransaction={this.setWaterMeterTransaction}
                                setElectricMeterTransaction={this.setElectricMeterTransaction}
                                transaction={transaction}
                                contact={this.state.contactList}
                                transaction_index={index}
                                mode={this.props.mode}
                              />
                            )}

                          {/*for period service*/}
                          {_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment")
                            && _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" && (
                              <OtherExpensePeriodAdjustSetting
                                setServicePeriod={this.setServicePeriod}
                                transaction={transaction}
                                contact={this.state.contactList}
                                transaction_index={index}
                                mode={this.props.mode}
                              />
                            )}
                          {/* for area and individual no service */}
                          {!_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.periodAdjustment") &&
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "WATER_METER" &&
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") !== "ELECTRIC_METER" &&
                            _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" && (
                              <OtherExpenseIndividualAreaPriceSetting
                                setIndividualTransaction={this.setIndividualTransaction}
                                transaction={transaction}
                                contact={this.state.contactList}
                                transaction_index={index}
                                mode={this.props.mode}
                              />
                            )}

                          {(_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "CAR_PARK" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "MOTORCYCLE_PARK") &&
                            _.get(this.state.transaction_list, index + ".productAndService.type") === "SERVICE" && (
                              <OtherExpenseCarParkSelect
                                setCarParkTransaction={this.setCarParkTransaction}
                                contact={this.state.contactList}
                                transaction={transaction}
                                transaction_index={index}
                                type_fee={_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType")}
                              />
                            )}
                        </div>
                      </td>
                      <td className={showlimit ? "text-right pt-6" : "text-right" }>
                        <input
                          type="number"
                          min={
                            (_.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "CAR_PARK" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "MOTORCYCLE_PARK") &&
                              this.props.contactList.length > 1 ? 0 : 1
                          }
                          className="form-control text-right"
                          max={productAndServiceOrChartOfAccount.totalLeftInStock}
                          data-id={index}
                          data-key="transaction_list"
                          name="unitItems"
                          value={transaction?.totalLeftInStock !== 0 ? transaction.unitItems : 0}
                          onChange={this.handleInputChange}
                          disabled={
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                            disableTransaction || invoice_cal_fine || transaction?.totalLeftInStock === 0
                          }
                          required
                        />
                      </td>
                      <td className={showlimit ? "text-right pt-6" : "text-right" } >
                        <input
                          type="number"
                          min={0}
                          step="0.01"
                          className="form-control text-right"
                          data-id={index}
                          data-key="transaction_list"
                          name="price"
                          value={transaction.price}
                          onChange={this.handleInputChange}
                          disabled={
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                            _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "INDIVIDUAL" ||
                            disableTransaction || invoice_cal_fine
                          }
                          title={
                            this.state.case_return_vat
                              ?  i18next.t("otherExpenseCreateUpdateForm:Include Tax")
                              : ""
                          }
                          required
                        />
                      </td>
                      {this.props.category !== "purchase" && (
                        <td style={{ width: 60 }} className={showlimit ? "text-right pt-6" : "text-right" } >
                          <input
                            type="number"
                            min={0}
                            className="form-control text-right"
                            data-id={index}
                            data-key="transaction_list"
                            name="discount"
                            value={transaction.discount}
                            onChange={this.handleInputChange}
                            disabled={
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                              disableTransaction ||
                              invoice_cal_fine
                            }
                            step="0.01"
                            required
                          />
                        </td>
                      )}
                      {/* Vat เจ้าหนี้*/}
                      {(!(this.state.taxType === "NO_TAX") || this.state.case_return_vat) && this.props.openVat && (
                          <td className={showlimit ? "pt-6" : "" } >
                            <select
                              className="form-control"
                              value={transaction.vat}
                              data-id={index}
                              data-key="transaction_list"
                              name="vat"
                              disabled={this.state.case_return_vat ? "disabled" : false}
                              onChange={this.handleInputChange}
                            >
                              <option value="">{i18next.t("preFixName:None")}</option>
                              <option value="0">VAT 0%</option>
                              <option value="7">VAT 7%</option>
                            </select>
                          </td>
                        )}
                      {(!(this.state.taxType === "NO_TAX") || this.state.case_return_vat) && this.props.openVat && (
                          <td className={showlimit ? "text-right pt-6" : "text-right" } >
                            <input
                              type="number"
                              min={0}
                              step="0.01"
                              className="form-control text-right"
                              data-id={index}
                              data-key="transaction_list"
                              name="vatAmount"
                              value={transaction.vatAmount}
                              onChange={this.handleInputChange}
                              title={i18next.t("otherExpenseCreateUpdateForm:VAT amount")}
                              required
                            />
                          </td>
                        )}

                      {/* Vat บุคคลภายนอก*/}
                      { this.props.contactWho && (
                          <td className={showlimit ? "pt-6" : "" } >
                            <select
                              className="form-control"
                              value={transaction.vat}
                              data-id={index}
                              data-key="transaction_list"
                              name="vat"
                              disabled={this.state.case_return_vat ? "disabled" : false}
                              onChange={this.handleInputChange}
                            >
                              <option value="">{i18next.t("preFixName:None")}</option>
                              <option value="7">VAT 7%</option>
                            </select>
                          </td>
                        )}
                      { this.props.contactWho && (
                          <td className={showlimit ? "text-right pt-6" : "text-right" } >
                            <input
                              type="number"
                              min={0}
                              step="0.01"
                              className="form-control text-right"
                              data-id={index}
                              data-key="transaction_list"
                              name="vatAmount"
                              value={transaction.vatAmount}
                              onChange={this.handleInputChange}
                              title={i18next.t("otherExpenseCreateUpdateForm:VAT amount")}
                              required
                            />
                          </td>
                        )}

                      {this.props.contact && this.props.openWht && (
                        <td className={showlimit ? "pt-6" : "" } >
                          <select
                            className="form-control"
                            value={transaction.whtRatePercent || 0}
                            data-id={index}
                            data-key="transaction_list"
                            name="whtRatePercent"
                            disabled={
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "AREA" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "WATER_METER" ||
                              _.get(this.state.transaction_list, index + ".productAndService.servicePricing.edges.0.node.pricingType") === "ELECTRIC_METER" ||
                              disableTransaction || invoice_cal_fine
                            }
                            onChange={this.handleInputChange}
                          >
                            <option value={0}>{i18next.t("preFixName:None")}</option>
                            <option value={1}>1%</option>
                            <option value={1.5}>1.5%</option>
                            <option value={2}>2%</option>
                            <option value={3}>3%</option>
                            <option value={5}>5%</option>
                            <option value={10}>10%</option>
                            <option value={15}>15%</option>
                          </select>
                        </td>
                      )}
                      {this.state.transaction_list.length !== 1 && (
                        <td width={1} className={showlimit ? "pt-6" : "" } >
                          {
                            <OtherExpenseDelete
                              transaction={transaction}
                              transaction_list={this.state.transaction_list}
                              setTransactionList={this.setTransactionList}
                            />
                          }
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {!this.props.disableNewRow && (
            <button
              type="button"
              className="btn add-button add mt-3"
              onClick={this.appendNewRow}
            >
              +{t("settingAll:Add list")}
            </button>
          )}

          {(this.state.category === "other_expense") && (
            <PayPaymentChannelForm
              updatePayChannel={this.updatePayChannel}
              payment_type={"pay"}
              not_other_channel={this.props.not_other_channel}
              issued_date={this.state.issuedDate}
              auto_suggest={this.state.total}
              pay={this.state.totalPay}
              pay_form={true}
            />
          )}

        </div>
        <OtherExpenseSummary
          remark={this.state.remark}
          handleInputChange={this.handleInputChange}
          transaction_list={this.state.transaction_list}
          remark_edit={false}
          receiveDeposit={this.state.receiveDeposit}
          taxType={this.state.taxType}
          category={this.state.category}
          required={this.state.category === "other_expense" ? true : false}
          mode={this.props.mode}
          getRemarkCallback={this.getRemark}
          total_discount={this.state.total_discount}
        />

        <div className="row">
          <div className="col text-right mt-2 button-grp">
            <Link to={this.props.back_page}>
              <button type="button" className="btn btn-secondary add">
                {i18next.t("Allaction:cancel")}
              </button>
            </Link>
            <button
              type="button"
              onClick={() => this.checkFormBeforeSubmit("draft")}                      
              // onClick={() => this.setState({ status: "draft" })}
              className="btn btn-primary add"
              disabled={this.state.loading}
            >
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm align-middle mr-2" />
              )}
              {this.state.category === "other_expense" ? i18next.t("Allaction:save") : i18next.t("claimRequst:Draft")}
            </button>
          </div>
        </div>
      </form>
      }</Translation>
    );
  }
}

export default OtherExpenseCreateUpdateForm;
