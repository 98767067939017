import React from "react";
import ReactExport from "react-data-export";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import { Dropdown } from "react-bootstrap";
import numberWithCommas from '../../../libs/numberWithComma';
import i18next from "i18next";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const _ = require('lodash');

const style = {
    alignment: {horizontal: "center"} ,font: {bold: true}
};

const styleNumber = {
    border: {
        left: {style: "thin", color: "FF0000FF"},
        right: {style: "thin", color: "FF0000FF"},
        top: {style: "thin", color: "FF0000FF"},
        bottom: {style: "thin", color: "FF0000FF"}
    },
    alignment: {
        vertical: "bottom",
        horizontal: "right"
    }
};

class ClaimReportExcel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            startDate : '',
            endDate : '',
            head : [],
            multiDataSet : []
        };
        this.buttonElement = this.buttonElement.bind(this);
        this.setData = this.setData.bind(this)
    }

    componentDidMount(){
        this.setData()
        this.setState({
            startDate : this.props.state.startDate,
            endDate : this.props.state.endDate,
        }, () => this.setData())
    }

    componentDidUpdate(prevProps){
        if(prevProps.state!== this.props.state){
            this.setState({
                startDate : this.props.state.startDate,
                endDate : this.props.state.endDate,
            }, () => this.setData())
        }
    }

    getStatus(status){
        let valueResult = ""
    
        switch (status) {
            case "consider_fail": 
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "not_approve":
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "void":
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "finish": 
                valueResult = "ปิดเคลม"
                break;
        }
        return valueResult
    
    }

    setData(){
        let startDate = this.state.startDate !== '' ? format(this.state.startDate,'DD/MM/YYYY') : '-';
        let endDate = this.state.endDate !== '' ? format(this.state.endDate , 'DD/MM/YYYY') : '-'
        let column = []
        let head_column = []

        this.props.head_table.forEach((head,index) => {
            column.push({title: "", width: {wpx: index === 0 ? 50 : 150}})
            head_column.push({value: head.label, style: { alignment: {horizontal: "center"} , font: { bold: true}}})
        })

        let data = [
            [{value: "รายงานค่าสินไหม", style: {font: {sz: "24", bold: true}}}],
            [{value: "วันที่ " + startDate + " ถึง " + endDate }],
            [{value: ""}],
            [{value: ""}],
            head_column ,
        ]

        let dataList = []
        
        for (const [index,items] of this.props.state.claimReportList.entries()){
            dataList = []
            dataList.push(
                index+1,
                items.claim_detail.issued_date,
                items.claim_detail.header,
                items.claim_detail.claim_type === 'public' ? 'พื้นที่ส่วนกลาง' : items.claim_detail.residential && items.claim_detail.residential.name,
                items.claim_detail.phone_contact,
                items.claim_detail.claim_amount,
                items.claim_detail.approved_amount,
                this.getStatus(items.claim_detail.status)
                )
            data.push(dataList)
        }
        let summary = []
        summary.push(
            '',
            '',
            '',
            '',
            {value: 'รวม (บาท)' , style: {font: { bold: true} , alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.allClaimAmount) ,style: {font: { bold: true} , alignment: {horizontal: "right"}}},
            {value:numberWithCommas(this.props.state.allApprovedAmount) ,style: {font: { bold: true} ,alignment: {horizontal: "right"}}}
        )
        data.push(summary)
        let multiDataSet = [
            {
                columns: column,
                data: data,
            }
        ];
        this.setState({multiDataSet: multiDataSet});

    }
    buttonElement() {
        return (
            <Dropdown.Item>Excel</Dropdown.Item>
        );
    }

    render() {
        return (
            <ExcelFile
                element={this.buttonElement()}
                filename={"report_" + format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}>
                <ExcelSheet dataSet={this.state.multiDataSet}  name="Organization" />
            </ExcelFile>  
        )
    }



}
export default ClaimReportExcel ;