/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateChangeElectricMeterInput = {|
  residential?: ?string,
  year?: ?string,
  month?: ?string,
  unit?: ?number,
  remark?: ?string,
  editDate?: ?any,
  initialUnit?: ?number,
  clientMutationId?: ?string,
|};
export type electricMeterCreateHistoryMutationVariables = {|
  input: CreateChangeElectricMeterInput
|};
export type electricMeterCreateHistoryMutationResponse = {|
  +createChangeElectricMeter: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type electricMeterCreateHistoryMutation = {|
  variables: electricMeterCreateHistoryMutationVariables,
  response: electricMeterCreateHistoryMutationResponse,
|};
*/


/*
mutation electricMeterCreateHistoryMutation(
  $input: CreateChangeElectricMeterInput!
) {
  createChangeElectricMeter(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateChangeElectricMeterPayload",
    "kind": "LinkedField",
    "name": "createChangeElectricMeter",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "electricMeterCreateHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "electricMeterCreateHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a6017f3b1fa5e2596f4dce5a256aef37",
    "id": null,
    "metadata": {},
    "name": "electricMeterCreateHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation electricMeterCreateHistoryMutation(\n  $input: CreateChangeElectricMeterInput!\n) {\n  createChangeElectricMeter(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67df29b66f44167df69638dd5ce8b508';

module.exports = node;
