import React, {Component} from 'react';
import Header from '../../../components/header/index';
import Sidebar from '../../../components/sidebar/index';
import Wrapper from '../../../components/wrapper/index';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import PaymentGatewayListTable from "./paymentGatewayListTable";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import _ from "lodash";


const query = graphql`
    query paymentGatewayListAllQuery{
        allPaymentGatewayGroup{
            edges{
                node{
                    id
                    status
                    docNumber
                    depositDate
                    description
                    paymentGatewayTransaction{
                        edges{
                            node{
                                id
                                bank{
                                    bankName
                                    accountNumber
                                }
                            }
                        }
                    }
                    depositTotal
                    feeTotal
                }
            }
            totalCount
        }
        selfProject {
            timeZone
        }
    }
`;

class PaymentGatewayListAll extends Component {
    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <div id="wrapper-content">
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="payment-gateway-list">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                             alt="back" className="back"/>
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('paymentgateway:payment_gateway_report')+ " "+"(PG)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_credit_card_create'}) &&
                                <Link to="/accounting/finance/payment-gateway/create/select-receive">
                                    <button type="button" className="btn btn-primary add ml-1" style={{float: "right"}}>
                                        <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus"/>
                                        <Translation>
                                            {
                                                t => <span>{t('paymentgateway:add_payment_gateway')}</span>
                                            }
                                        </Translation>
                                    </button>
                                </Link>
                                }
                            </div>
                        </div>
                        <div className="content-inner">
                            <PaymentGatewayListTable query={query} />
                        </div>

                    </div>
                </div>

            </Wrapper>

        )
    }
}

export default PaymentGatewayListAll;