/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductAndServiceType = "EXPENSE" | "FINE" | "PRODUCT" | "SERVICE" | "%future added value";
export type waterAndElectricUtilityReceiveReportExportDataQueryVariables = {|
  atDate?: ?any,
  month?: ?number,
  year?: ?number,
  search?: ?string,
  contactId?: ?string,
  typeOfContact?: ?string,
  productAndService?: ?string,
|};
export type waterAndElectricUtilityReceiveReportExportDataQueryResponse = {|
  +allContactUnitTransaction: ?$ReadOnlyArray<?any>,
  +selfProject: ?{|
    +name: string
  |},
  +productViewer: ?{|
    +allProduct: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: string,
          +productCode: string,
          +type: ProductAndServiceType,
          +price: ?number,
          +chartOfAccount: ?{|
            +id: string,
            +chartOfAccountCode: string,
            +name: string,
          |},
          +description: ?string,
          +totalLeftInStock: ?number,
        |}
      |}>
    |}
  |},
  +contactViewer: ?{|
    +allContact: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +refNumber: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |}
      |}>
    |}
  |},
|};
export type waterAndElectricUtilityReceiveReportExportDataQuery = {|
  variables: waterAndElectricUtilityReceiveReportExportDataQueryVariables,
  response: waterAndElectricUtilityReceiveReportExportDataQueryResponse,
|};
*/


/*
query waterAndElectricUtilityReceiveReportExportDataQuery(
  $atDate: Date
  $month: Int
  $year: Int
  $search: String
  $contactId: ID
  $typeOfContact: String
  $productAndService: String
) {
  allContactUnitTransaction(atDate: $atDate, month: $month, year: $year, search: $search, contactId: $contactId, typeOfContact: $typeOfContact, productAndService: $productAndService)
  selfProject {
    name
    id
  }
  productViewer {
    allProduct(type_In: "product, service, fine", servicePricing_PricingType_In: "water_meter, electric_meter") {
      edges {
        node {
          id
          name
          productCode
          type
          price
          chartOfAccount {
            id
            chartOfAccountCode
            name
          }
          description
          totalLeftInStock
        }
      }
    }
    id
  }
  contactViewer {
    allContact(typeOfPayment: "receivable", typeOfContact: $typeOfContact, order: "ref_number") {
      edges {
        node {
          id
          refNumber
          name
          firstName
          lastName
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "atDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productAndService"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeOfContact"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v7 = {
  "kind": "Variable",
  "name": "typeOfContact",
  "variableName": "typeOfContact"
},
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "atDate",
      "variableName": "atDate"
    },
    {
      "kind": "Variable",
      "name": "contactId",
      "variableName": "contactId"
    },
    {
      "kind": "Variable",
      "name": "month",
      "variableName": "month"
    },
    {
      "kind": "Variable",
      "name": "productAndService",
      "variableName": "productAndService"
    },
    {
      "kind": "Variable",
      "name": "search",
      "variableName": "search"
    },
    (v7/*: any*/),
    {
      "kind": "Variable",
      "name": "year",
      "variableName": "year"
    }
  ],
  "kind": "ScalarField",
  "name": "allContactUnitTransaction",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "servicePricing_PricingType_In",
      "value": "water_meter, electric_meter"
    },
    {
      "kind": "Literal",
      "name": "type_In",
      "value": "product, service, fine"
    }
  ],
  "concreteType": "ProductAndServiceNodeConnection",
  "kind": "LinkedField",
  "name": "allProduct",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAndServiceNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductAndServiceNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "productCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChartOfAccountNode",
              "kind": "LinkedField",
              "name": "chartOfAccount",
              "plural": false,
              "selections": [
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chartOfAccountCode",
                  "storageKey": null
                },
                (v9/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalLeftInStock",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "allProduct(servicePricing_PricingType_In:\"water_meter, electric_meter\",type_In:\"product, service, fine\")"
},
v12 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": "ref_number"
    },
    (v7/*: any*/),
    {
      "kind": "Literal",
      "name": "typeOfPayment",
      "value": "receivable"
    }
  ],
  "concreteType": "ContactNodeConnection",
  "kind": "LinkedField",
  "name": "allContact",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refNumber",
              "storageKey": null
            },
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "waterAndElectricUtilityReceiveReportExportDataQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "waterAndElectricUtilityReceiveReportExportDataQuery",
    "selections": [
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactViewer",
        "kind": "LinkedField",
        "name": "contactViewer",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4606d67e95240797a5723532a0b2fa2",
    "id": null,
    "metadata": {},
    "name": "waterAndElectricUtilityReceiveReportExportDataQuery",
    "operationKind": "query",
    "text": "query waterAndElectricUtilityReceiveReportExportDataQuery(\n  $atDate: Date\n  $month: Int\n  $year: Int\n  $search: String\n  $contactId: ID\n  $typeOfContact: String\n  $productAndService: String\n) {\n  allContactUnitTransaction(atDate: $atDate, month: $month, year: $year, search: $search, contactId: $contactId, typeOfContact: $typeOfContact, productAndService: $productAndService)\n  selfProject {\n    name\n    id\n  }\n  productViewer {\n    allProduct(type_In: \"product, service, fine\", servicePricing_PricingType_In: \"water_meter, electric_meter\") {\n      edges {\n        node {\n          id\n          name\n          productCode\n          type\n          price\n          chartOfAccount {\n            id\n            chartOfAccountCode\n            name\n          }\n          description\n          totalLeftInStock\n        }\n      }\n    }\n    id\n  }\n  contactViewer {\n    allContact(typeOfPayment: \"receivable\", typeOfContact: $typeOfContact, order: \"ref_number\") {\n      edges {\n        node {\n          id\n          refNumber\n          name\n          firstName\n          lastName\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6a0417f12a6759615be9fb1de84d320';

module.exports = node;
