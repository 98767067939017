import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import OtherReceiveReportTable from "./otherReceiveReportTable";
import ExportOtherReceive from "./server_export";
import ContactReportList from "../contactReportList";
import Loading from '../../../../libs/loading';
import _ from "lodash";
import postApiUseing from '../../../../libs/postApiUseing';
import './otherReceiveReportPDF.scss';
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import ProductServiceOtherReceiveList from "./productServiceOtherReceiveList";
import numberWithComma from "../../../../libs/numberWithComma";
import i18next from "i18next";
import BackButtonIcon from '../../../../components/BackBtn/indexBack';


const query = graphql`
  query otherReceiveReportQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $status: String
    $order: String
    $customer_type: String
    $payment_channel: String
    $contact: String
    $productAndService: String
  ) {
    allOtherReceive(
      first: $first
      last: $last
      startDate: $start_date
      endDate: $end_date
      status: $status
      search: $search
      order: $order
      customerType: $customer_type
      paymentChannel: $payment_channel
      productAndService: $productAndService
      contact: $contact
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          docNumber
          issuedDate
          total
          status
          payGroup
          name
          firstName
          lastName
          contact {
            name
            refNumber
            firstName
            lastName
            typeOfContact
            residential {
              name
            }
          }
          unknownContact
          otherReceiveTransaction {
            totalCount
            edges {
              node {
                id
                description
                total
                accountRecord{
                  id
                }
                productAndService{
                  productCode
                }
                chartOfAccount{
                  chartOfAccountCode
                }
              }
            }
          }
          cashTransaction {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          bankAccountTransaction {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          chequeDeposit {
            edges {
              node {
                price
              }
            }
            totalCount
          }
          creditCardTransaction {
            edges {
              node {
                amount
              }
            }
            totalCount
          }
          receiptDeposit {
            edges {
              node {
                id
                docNumber
                total
                name
                receiptDepositTransaction {
                  edges {
                    node {
                      chartOfAccount {
                        chartOfAccountCode
                        name
                      }
                    }
                  }
                }
              }
            }

          }
          unknownReceive {
            edges {
              node {
                amount
              }
            }
            
          }

          feeAccounting {
            edges {
              node {
                amount
              }
            }
          }
          
        }
      }
    }
    allOtherReceiveReport{
      accountRecordGroupNode{
        edges {
            node {
              id
              refNumber
              refTransaction
              name
              added
              issuedDate
              category
              totalDebit
              totalCredit
              refTransaction
              creator
              contact {
                  id
                  name
                  firstName
                  lastName
              }
              accountRecord {
                edges {
                  node {
                    id
                    name
                    debit
                    credit
                    chartOfAccountCode {
                    id
                    name
                    chartOfAccountCode
                    }
                  }
                }
              }
            }
          }
      }
  }
    summaryOtherReceive(
      startDate: $start_date
      endDate: $end_date
      status: $status
      search: $search
      customerType: $customer_type
      paymentChannel: $payment_channel
      contact: $contact
      productAndService: $productAndService
      report: true
    )
  }
`;

class OtherReceiveReport extends ComponentPagination {
  constructor(props) {
    super(props);
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth(), 1);

    this.state.current_date = new Date();
    this.state.start_date = new Date();
    this.state.end_date = new Date();
    this.state.temp_start_date = new Date();
    this.state.temp_end_date = new Date();
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.customer_type = "";
    this.state.temp_customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.payment_channel = "";
    this.state.temp_payment_channel = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.checkFetchState = false;
    this.state.dataResult = "";
    this.state.loading = false;

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

  }

  componentWillMount() {
    let bodyFormData = new FormData();
    bodyFormData.append('title', "otherReceiveReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
      customer_type: this.state.temp_customer_type,
      product_and_service: this.state.temp_product_and_service,
      payment_channel: this.state.temp_payment_channel,
      contact: this.state.temp_contact,
    },
      () => {
        this.fetchDataQuery();
        this.goFirst();
      });
    // super.goFirst()
  }

  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/other_receipt/PDF`, { data: data })
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      search: this.state.search,
      status: "",
      order: "doc_number",
      customer_type: this.state.customer_type,
      productAndService: this.state.product_and_service,
      payment_channel: this.state.payment_channel,
      contact: this.state.contact,
    }).then((item) => {
      this.setState({ dataResult: item, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });

  }

  render() {
    const summaryOtherReceive = this.state.dataResult ? JSON.parse(this.state.dataResult?.summaryOtherReceive) : "";

    let sumNumber = 0;
    for (let data in summaryOtherReceive) {
      if (data.startsWith('num')) {
        sumNumber += parseFloat(summaryOtherReceive[data]);
      }
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-receivable"}
                  LinkText={i18next.t("receivable_dashboard:Other Payment Receipt Report")}
                  boxHtmlText={
                    <div className={`col`} id="otherReceiveReport">
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "report_receivable_print",
                      }) &&
                        <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                          {!this.state.checkFetchState ?
                            <React.Fragment>
                              <button type="button" className="btnPrint">
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                <Translation>{t => t("meter:Preparing information")}</Translation>
                              </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                                <Translation>{t => t("PageList:Print")}</Translation>
                              </button>
                            </React.Fragment>
                          }
                          <div className={`dropdown-menu`}>
                            <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                            <ExportOtherReceive state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                          </div>
                        </div>
                      }
                    </div>
                  }
                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control search-input input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          name="search"
                          value={this.state.search_input}
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          maxDate={this.state.temp_end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.temp_customer_type}
                            contact={this.state.temp_contact}
                            contact_other={true}
                          />
                          <ProductServiceOtherReceiveList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("receiveReport:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receiveReport:All")}</option>
                                <option value="cash">{i18next.t("receiveReport:Cash")}</option>
                                <option value="bank">{i18next.t("receiveReport:Transfer")}</option>
                                <option value="cheque">{i18next.t("receiveReport:Cheque")}</option>
                                <option value="credit_card">{i18next.t("receiveReport:Credit Card")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                    <Loading />
                    :
                    this.state.dataResult &&
                    <React.Fragment>
                      <div className="row mt-3">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <div className="card fade-up">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                <tr className="text-center">
                                  <th style={{ width: '20%' }}>{i18next.t("receiveReport:Payment Type")}</th>
                                  <th style={{ width: '11%' }}>{i18next.t("receiveReport:Cash")}</th>
                                  <th style={{ width: '11%' }} >{i18next.t("receiveReport:Transfer")}</th>
                                  <th style={{ width: '11%' }} >{i18next.t("receiveReport:Cheque")}</th>
                                  <th style={{ width: '11%' }} >{i18next.t("receiveReport:Credit Card")}</th>
                                  <th style={{ width: '11%' }} >{i18next.t("receiveReport:Pending Verification")}</th>
                                  <th style={{ width: '11%' }} >{i18next.t("receiveReport:Other")}</th>
                                  <th style={{ width: '14%' }}>{i18next.t("receiveReport:Total")}</th>
                                </tr>
                                <tr className="text-center">
                                  <th>{i18next.t("receiveReport:Items")}</th>
                                  <td>{summaryOtherReceive.num_pay_by_cash ? summaryOtherReceive.num_pay_by_cash : "-"}</td>
                                  <td>{summaryOtherReceive.num_pay_by_bank ? summaryOtherReceive.num_pay_by_bank : "-"}</td>
                                  <td>{summaryOtherReceive.num_pay_by_cheque ? summaryOtherReceive.num_pay_by_cheque : "-"}</td>
                                  <td>{summaryOtherReceive.num_pay_by_credit_card ? summaryOtherReceive.num_pay_by_credit_card : "-"}</td>
                                  <td>{summaryOtherReceive.num_unknown_receive ? summaryOtherReceive.num_unknown_receive : "-"}</td>
                                  <td>{summaryOtherReceive.num_pay_by_other ? summaryOtherReceive.num_pay_by_other : "-"}</td>
                                  <th>{sumNumber ? sumNumber : "-"}</th>
                                </tr>
                                <tr className="text-center">
                                  <th>{i18next.t("receiveReport:Total Amount")}</th>
                                  <td>{!summaryOtherReceive.sum_pay_by_cash || summaryOtherReceive.sum_pay_by_cash === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_cash)}</td>
                                  <td>{!summaryOtherReceive.sum_pay_by_bank || summaryOtherReceive.sum_pay_by_bank === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_bank)}</td>
                                  <td>{!summaryOtherReceive.sum_pay_by_cheque || summaryOtherReceive.sum_pay_by_cheque === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_cheque)}</td>
                                  <td>{!summaryOtherReceive.sum_pay_by_credit_card || summaryOtherReceive.sum_pay_by_credit_card === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_credit_card)}</td>
                                  <td>{!summaryOtherReceive.sum_unknown_receive || summaryOtherReceive.sum_unknown_receive === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_unknown_receive)}</td>
                                  <td>{!summaryOtherReceive.sum_pay_by_other || summaryOtherReceive.sum_pay_by_other === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_pay_by_other)}</td>
                                  <th>{!summaryOtherReceive.sum_other_receive_transaction || summaryOtherReceive.sum_other_receive_transaction === "0.00" ? "-" : numberWithComma(summaryOtherReceive.sum_other_receive_transaction)}</th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>


                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="card fade-up">
                            <div className="table-responsive">
                              <table className="table table-hover" style={{ fontSize: 12 }}>
                                <thead className="thead-light">
                                  <tr>
                                    <th rowSpan="2" className="text-center">
                                    {i18next.t("OtherReceiveReport:Order")}
                                    </th>
                                    <th
                                      rowSpan="2"
                                      className="text-center"
                                      width={130}
                                    >
                                      {i18next.t("OtherReceiveReport:Other income number")}
                                    </th>
                                    <th rowSpan="2" className="text-center">
                                    {i18next.t("OtherReceiveReport:Date")}
                                    </th>
                                    <th rowSpan="2">{i18next.t("OtherReceiveReport:เลขที่ห้อง/บ้านเลขที่")}</th>
                                    <th rowSpan="2">{i18next.t("OtherReceiveReport:Name")}</th>
                                    <th rowSpan="2">{i18next.t("OtherReceiveReport:Code")}</th>
                                    <th rowSpan="2">{i18next.t("OtherReceiveReport:Details")}</th>
                                    <th
                                      colSpan={6}
                                      className="text-center"
                                    >
                                      {i18next.t("OtherReceiveReport:Payment method")}
                                    </th>
                                    <th rowSpan="2" className="text-right">
                                    {i18next.t("OtherReceiveReport:Amount")}
                                    </th>
                                    <th rowSpan="2" className="text-right">
                                    {i18next.t("OtherReceiveReport:Total amount")}
                                    </th>

                                  </tr>
                                  <tr>
                                    <th className="text-center">
                                    {i18next.t("OtherReceiveReport:Cash")}
                                    </th>
                                    <th className="text-center">
                                    {i18next.t("OtherReceiveReport:Transfer")}
                                    </th>
                                    <th className="text-center">
                                    {i18next.t("OtherReceiveReport:Cheque")}
                                    </th>
                                    <th className="text-center">{i18next.t("OtherReceiveReport:Credit card")}</th>
                                    <th className="text-center">{i18next.t("OtherReceiveReport:Money pending verification")}</th>
                                    <th className="text-center">{i18next.t("OtherReceiveReport:Other")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <OtherReceiveReportTable
                                    props={this.state.dataResult}
                                    state={this.state}
                                    summary={JSON.parse(this.state.dataResult.summaryOtherReceive)}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={this.state.dataResult.allOtherReceive.totalCount}
                        />
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>

            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherReceiveReport;
