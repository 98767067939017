import React from 'react';
import {QueryRenderer , commitMutation} from 'react-relay';
import numberWithComma from "../../../libs/numberWithComma";
import environment from "../../../env/environment";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import {graphql} from "babel-plugin-relay/macro";
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import {Link} from "react-router-dom";
import Loading from '../../../libs/loading';
import Swal from "sweetalert2";
import i18next from 'i18next';

const allSetPettyCash = graphql`
    query pettyCashSetListTableQuery($search: String, $start_date: DateTime, $end_date: DateTime, $first: Int, $last: Int) {
        allSetPettyCash(search: $search, startDate: $start_date, endDate: $end_date, first: $first, last: $last){
            totalCount
            edges{
                node{
                    id
                    docNumber
                    added
                    withdrawer
                    description
                    withdrawAmount
                    issuedDate
                    status
                    voidRemark
                    pettyCashRecord(status:"wait"){
                        edges{
                          node{
                            id
                            docNumber
                            description
                            price
                            status
                          }
                        }
                    }
                    otherExpensePaymentChannel(status:"paid",slug:"petty_cash",statusSetPettyCash:"wait"){
                        edges{
                          node{
                            id
                            description
                            price
                            status
                          }
                        }
                    }
                    paymentChannel(status:"paid",slug:"petty_cash",statusSetPettyCash:"wait"){
                        edges{
                          node{
                            id
                            description
                            price
                            status
                          }
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation pettyCashSetListTableMutation($input: VoidSetPettyCashInput!){
        voidSetPettyCash(input: $input){
            ok
            warningText
      }
    }
`;
class PettyCashSetListTable extends ComponentPagination {

    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         loading: false,
    //         redirect: false,
    //         reQuery: false,
    //     };

    // }

    // handleChange() {
    //     this.setState({ reQuery: true });
    // }

    pettySetvoid(id) {
        // ยกเลิกจ่ายเงินสดย่อย
        Swal.fire({
            title: i18next.t("pettyCashList:Are you sure to cancel this petty cash?"),
            text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText:  i18next.t("pettyCashList:Confirm"),
            cancelButtonText:  i18next.t("pettyCashList:Cancel")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: i18next.t("pettyCashList:Note"),
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: i18next.t("pettyCashList:Cancel"),
                    confirmButtonText: i18next.t("pettyCashList:Confirm"),
                    inputValidator: (value) => {
                        if (!value) {
                            return i18next.t("pettyCashList:Please enter reason to cancel")
                        }
                    }
                });
                if (void_remark) {
                    let variables = {
                        input: {
                            id: id,
                            voidRemark: void_remark
                        }
                    };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {

                                if (response) {
                                    if (response.voidSetPettyCash.ok) {
                                        Swal.fire(
                                            i18next.t("Allaction:Canceled Successfully"),
                                            '',
                                            'success'
                                        )
                                        this.props.handleReQuery()
                                    }
                                    else {
                                        Swal.fire(
                                            i18next.t("Allaction:Canceled Unsuccessful"),
                                            response.voidSetPettyCash.warningText,
                                            'warning'
                                        )
                                    }
                                }
                            },
                            onError: (err) => {
                                Swal.fire(
                                    i18next.t("Allaction:Canceled Unsuccessful"),
                                    '',
                                    'warning'
                                );
                            },
                        },
                    )
                }
            } else {
                return;
            }
        })
    }

    render() {
        return (
            <QueryRenderer
                environment={environment}
                query={allSetPettyCash}
                variables={{
                    search: this.props.search,
                    first: this.state.first,
                    last: this.state.last,
                    status: this.props.status,
                    reQuery: this.props.reQuery
                }}
                render={({error, props}) => {
                    if (error) {
                        return <div>{error.message}</div>;
                    } else if (props) {
                        return <React.Fragment>
                            <div className="table-responsive fade-up card">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="text-center">{i18next.t("pettyCashList:No.")}</th>
                                        <th className="text-center">{i18next.t("pettyCashList:Date")}</th>
                                        <th>{i18next.t("pettyCashList:Hold by")}</th>
                                        <th>{i18next.t("pettyCashList:List")}</th>
                                        <th className="text-right">{i18next.t("pettyCashList:Amount withdrawal")}</th>
                                        <th className="text-right">{i18next.t("pettyCashList:Amount spent")}</th>
                                        <th className="text-right">{i18next.t("pettyCashList:Balance amount")}</th>
                                        <th className="text-right">{i18next.t("pettyCashList:Note")}</th>
                                        <th className="text-right"/>
                                        {/* <th className="text-center">ออกโดย</th> */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.allSetPettyCash.edges.map((set_petty_cash) => {
                                        let summary_petty_record = 0;
                                        set_petty_cash.node.otherExpensePaymentChannel.edges.forEach((other_Expense_PaymentChannel) => {
                                            summary_petty_record += other_Expense_PaymentChannel.node.price
                                        });
                                        set_petty_cash.node.paymentChannel.edges.forEach((paymentChannel) => {
                                            summary_petty_record += paymentChannel.node.price
                                        });
                                        set_petty_cash.node.pettyCashRecord.edges.forEach((petty_cash_record) => {
                                            summary_petty_record += petty_cash_record.node.price
                                        });

                                        return (
                                            <tr key={set_petty_cash.node.id}>
                                                <td className={set_petty_cash.node.status === "VOID" ? "text-center text-danger" : "text-center"}>
                                                    
                                                   {set_petty_cash.node.status === 'VOID' ?
                                                        set_petty_cash.node.docNumber
                                                    :
                                                   <Link
                                                        to={"/accounting/expense/petty_cash/set/from/" + set_petty_cash.node.id}
                                                        >{set_petty_cash.node.docNumber}
                                                    </Link>
                                                    }
                                                </td>
                                                <td className="text-center">{format(set_petty_cash.node.issuedDate ? set_petty_cash.node.issuedDate: set_petty_cash.node.added, 'DD/MM/YYYY', {locale: thLocale})}</td>
                                                <td>{set_petty_cash.node.withdrawer}</td>
                                                <td>{set_petty_cash.node.description}</td>
                                                <td className="text-right">{numberWithComma(set_petty_cash.node.withdrawAmount)}</td>
                                                <td className="text-right">{numberWithComma(summary_petty_record)}</td>
                                                <td className="text-right">{(set_petty_cash.node.withdrawAmount - summary_petty_record) > 0 ? numberWithComma(set_petty_cash.node.withdrawAmount - summary_petty_record) : '0.00'}</td>
                                                <td className="text-right">{set_petty_cash.node.voidRemark}</td>
                                                <td className="text-right">
                                                    {set_petty_cash.node.status !== 'VOID' &&
                                                        <button className="btn" onClick={() => this.pettySetvoid(set_petty_cash.node.id)}>
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/delete.png'}
                                                                alt="print" className="print" />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <Pagination changePage={this.changePage} first={this.state.first}
                                            last={this.state.last}
                                            totalCount={props.allSetPettyCash.totalCount}/>
                            </div>
                        </React.Fragment>
                    }
                    return <Loading/>
                }}
            />
        )
    }
}

export default PettyCashSetListTable;
