import React from "react";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import numberWithComma from "../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import Pagination from "../../../libs/newPagination";
import ComponentPagination from "../../../libs/componentPagination";
import { format, differenceInCalendarMonths } from "date-fns";
import localStoragePage from "../../../libs/localstorage";
import { Link } from "react-router-dom";
import ExportFIne from "./serverExport.js";
import _ from "lodash";
import { Translation } from "react-i18next";

import Loading from "../../../libs/loading";

const query = graphql`
  query fineDetailQuery(
    $first: Int
    $last: Int
    $fineGroup: ID
    $search: String
  ) {
    allFine(
      first: $first
      last: $last
      group_Id: $fineGroup
      search: $search
      fineList: true
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          invoice {
            id
            docNumber
            issuedDate
            dueDate
            contact {
              id
              name
              firstName
              lastName
              refNumber
              typeOfContact
              residential {
                id
                name
              }
            }
            total
            transaction {
              edges {
                node {
                  total
                  paid
                  productAndService {
                    productCode
                  }
                }
              }
            }
          }
          group {
            id
            fineDate
            service {
              id
              productCode
              name
            }
          }
          rate
          fine
        }
      }
    }
    summaryFine(groupId: $fineGroup, search: $search)
    summaryFineInvoiceTransaction(groupId: $fineGroup, search: $search)
  }
`;

class FineDetail extends ComponentPagination {
  constructor(props) {
    super(props);
    this.state.search = "";
    this.state.group_id = this.props.match.params.id;
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  onChangeSearch(e) {
    this.setState({ search: e.target.value });
  }

  render() {
    let product_code = this.props.match.params.product_code;
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {(t) => (
              <div className="container-fluid box" id="fine-detail">
                <div className="row justify-content-between">
                  <div className="col">
                    <h3 className="mb-4">
                      <Link to="/accounting/income/fine/list">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      {t("fineDetail:Detail of fine")}
                    </h3>
                  </div>
                  <div className="col text-right">
                    {_.some(
                      JSON.parse(localStorage.getItem("permission_list")),
                      { codename: "accounting_fine_print" }
                    ) && <ExportFIne state={this.state} />}
                  </div>
                </div>

                <div className="content-inner">
                  <div className="row">
                    <div className="col-sm-12 col-12">
                      <div className="form-inline float-right">
                        <div className="form-group mb-2">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("filterFunction:search")}
                              value={this.state.search}
                              onChange={this.onChangeSearch}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-12">
                      <QueryRenderer
                        environment={environment}
                        query={query}
                        variables={{
                          search: this.state.search,
                          fineGroup: this.props.match.params.id,
                          first: this.state.first,
                          last: this.state.last,
                        }}
                        render={({ error, props }) => {
                          if (error) {
                            return (
                              <tr>
                                <td>Error :(</td>
                              </tr>
                            );
                          } else if (props) {
                            let order_id =
                              this.state.first -
                              localStoragePage.getPageLimit();
                            let total_transaction = 0;
                            let total_fine = 0;
                            return (
                              <React.Fragment>
                                <div
                                  className="tab-pane fade show active table-responsive card mt-3"
                                  role="tabpanel"
                                  aria-labelledby="home-tab"
                                >
                                  <table className="table table-hover table-striped mt-2">
                                    <thead>
                                      <tr className="text-center">
                                        <th className="text-right">
                                          {t("fineDetail:No.")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Room No.")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Name")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Services")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Invoice")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:invoice date")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Date of payment")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Date of fine")}
                                        </th>
                                        <th className="text-left">
                                          {t("fineDetail:Over (month)")}
                                        </th>
                                        <th className="text-right">
                                          {t("fineDetail:Price")}
                                        </th>
                                        <th className="text-right">
                                          {t("fineDetail:Fine rate(%)/year")}
                                        </th>
                                        <th className="text-right">
                                          {t("fineDetail:Fine/month")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {props.allFine.edges.map(
                                        (fine, index) => {
                                          let row_number = order_id + index + 1;
                                          let sum_total_transaction =
                                            fine.node.invoice.transaction.edges.reduce(
                                              function (total, obj) {
                                                if (
                                                  obj.node.productAndService
                                                ) {
                                                  if (
                                                    product_code ===
                                                    obj.node.productAndService
                                                      .productCode
                                                  ) {
                                                    // return total + parseFloat(obj.node.total) - parseFloat(obj.node.paid)
                                                    return (
                                                      total +
                                                      parseFloat(obj.node.total)
                                                    );
                                                  }
                                                }
                                                return total;
                                              },
                                              0
                                            );
                                          total_transaction +=
                                            sum_total_transaction;
                                          total_fine += fine.node.fine;
                                          return (
                                            <tr
                                              className="text-left"
                                              key={fine.node.id}
                                            >
                                              <td className="text-right">
                                                {row_number}
                                              </td>
                                              <td>
                                                {fine.node.invoice.contact
                                                  .typeOfContact ===
                                                "RESIDENTIAL"
                                                  ? fine.node.invoice.contact
                                                      .residential.name
                                                  : "-"}
                                              </td>
                                              <td>
                                                {fine.node.invoice.contact
                                                  .typeOfContact ===
                                                  "RESIDENTIAL" &&
                                                  fine.node.invoice.contact
                                                    .firstName +
                                                    " " +
                                                    fine.node.invoice.contact
                                                      .lastName}

                                                {fine.node.invoice.contact
                                                  .typeOfContact ===
                                                  "SUPPLIER" &&
                                                  fine.node.invoice.contact
                                                    .name}
                                              </td>
                                              <td>
                                                (
                                                {
                                                  fine.node.group.service
                                                    .productCode
                                                }
                                                ) {fine.node.group.service.name}
                                              </td>
                                              <td>
                                                {fine.node.invoice.docNumber}
                                              </td>
                                              <td>
                                                {format(
                                                  fine.node.invoice.issuedDate,
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>
                                                {format(
                                                  fine.node.invoice.dueDate,
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td>
                                                {format(
                                                  fine.node.group.fineDate,
                                                  "DD/MM/YYYY"
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {differenceInCalendarMonths(
                                                  fine.node.group.fineDate,
                                                  fine.node.invoice.dueDate
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {numberWithComma(
                                                  sum_total_transaction
                                                )}
                                              </td>
                                              {/* <td className="text-right">{(fine.node.rate * 12).toFixed(2)}</td> */}
                                              <td className="text-right">
                                                {fine.node.rate.toFixed(2)}
                                              </td>
                                              {/* * คูณ 12 */}
                                              <td className="text-right">
                                                {numberWithComma(
                                                  fine.node.fine
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                      <tr>
                                        <td colSpan={9} className="text-right">
                                          <strong>
                                            {t("fineDetail:Price")}
                                          </strong>
                                        </td>
                                        <td className="text-right" colSpan={1}>
                                          <strong>
                                            {numberWithComma(total_transaction)}
                                          </strong>
                                        </td>
                                        <td colSpan={1} className="text-right">
                                          <strong>
                                            {t("fineDetail:Total of fines")}
                                          </strong>
                                        </td>
                                        <td colSpan={2} className="text-right">
                                          <strong>
                                            {numberWithComma(total_fine)}
                                          </strong>
                                        </td>
                                      </tr>
                                      {!props.allFine.pageInfo.hasNextPage && (
                                        <tr>
                                          <td
                                            colSpan={9}
                                            className="text-right"
                                          >
                                            <strong>
                                              {t("fineDetail:Total price")}
                                            </strong>
                                          </td>
                                          <td
                                            className="text-right"
                                            colSpan={1}
                                          >
                                            <strong>
                                              {numberWithComma(
                                                props.summaryFineInvoiceTransaction
                                              )}
                                            </strong>
                                          </td>
                                          <td
                                            colSpan={1}
                                            className="text-right"
                                          >
                                            <strong>
                                              {t("fineDetail:Total fines")}
                                            </strong>
                                          </td>
                                          <td
                                            className="text-right"
                                            colSpan={2}
                                          >
                                            <strong>
                                              {numberWithComma(
                                                props.summaryFine
                                              )}
                                            </strong>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="row">
                                  <Pagination
                                    changePage={this.changePage}
                                    first={this.state.first}
                                    last={this.state.last}
                                    totalCount={props.allFine.totalCount}
                                  />
                                </div>
                              </React.Fragment>
                            );
                          }
                          return <Loading />;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default FineDetail;
