import React, {Component} from 'react';
import './lottery.scss'

class ResultLotteryAwardTrue extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let myLang = this.props.match?.params?.lang
        return (
            <>
             <div className='text-center mt-6'>
                <img src={process.env.PUBLIC_URL + '/images/lottery/true.png'}
                  alt="revenue" className={this.props.mini && 'd-none'} />
             </div>
            <div className='row  p-4'>
                 <div className='col text-center'>
                         <span>{this.props.number}</span>
                         <p>{this.props.status_data[0].reward}</p>
                         <div className='alert-noti' style={{backgroundColor:'#D6FFFA'}}>
                            <p>{myLang === 'en' ? 'You were awarded' : 'ยินดีด้วย...คุณถูกรางวัล'} </p>
                         </div>
                </div>
            </div>
            </>
        );
    }
}

export default ResultLotteryAwardTrue;