/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceStatus = "ACTIVE" | "DRAFT" | "OVERDUE" | "PAID" | "PARTIAL_PAYMENT" | "VOID" | "WAITING_FIX" | "%future added value";
export type InvoiceTaxType = "EXCLUDE_TAX" | "INCLUDE_TAX" | "NO_TAX" | "%future added value";
export type creditNoteSelectTransactionQueryVariables = {|
  id: string
|};
export type creditNoteSelectTransactionQueryResponse = {|
  +invoiceViewer: ?{|
    +allInvoiceByStatus: ?{|
      +totalCount: ?number,
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +total: number,
          +issuedDate: any,
          +dueDate: any,
          +status: InvoiceStatus,
          +taxType: InvoiceTaxType,
          +transaction: ?{|
            +totalCount: ?number,
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +description: string,
                +unitItems: number,
                +discount: ?number,
                +price: number,
                +preTaxAmount: ?number,
                +vat: ?number,
                +paid: ?number,
                +whtRate: ?number,
                +whtRatePercent: ?number,
                +total: ?number,
                +productAndService: ?{|
                  +productCode: string,
                  +chartOfAccount: ?{|
                    +id: string,
                    +chartOfAccountCode: string,
                  |},
                |},
                +invoice: {|
                  +docNumber: string,
                  +issuedDate: any,
                |},
                +receiptDepositTransactionUsed: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +amount: ?number,
                    |}
                  |}>
                |},
                +creditNoteTransaction: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +price: number,
                    |}
                  |}>
                |},
              |}
            |}>,
          |},
        |}
      |}>,
    |}
  |}
|};
export type creditNoteSelectTransactionQuery = {|
  variables: creditNoteSelectTransactionQueryVariables,
  response: creditNoteSelectTransactionQueryResponse,
|};
*/


/*
query creditNoteSelectTransactionQuery(
  $id: ID!
) {
  invoiceViewer {
    allInvoiceByStatus(statusList: ["active", "overdue", "partial_payment"], contact_Id: $id, order: "doc_number") {
      totalCount
      edges {
        node {
          id
          docNumber
          total
          issuedDate
          dueDate
          status
          taxType
          transaction(status_In: "active, overdue, partial_payment") {
            totalCount
            edges {
              node {
                id
                description
                unitItems
                discount
                price
                preTaxAmount
                vat
                paid
                whtRate
                whtRatePercent
                total
                productAndService {
                  productCode
                  chartOfAccount {
                    id
                    chartOfAccountCode
                  }
                  id
                }
                invoice {
                  docNumber
                  issuedDate
                  id
                }
                receiptDepositTransactionUsed {
                  edges {
                    node {
                      id
                      amount
                    }
                  }
                }
                creditNoteTransaction {
                  edges {
                    node {
                      id
                      price
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "contact_Id",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": "doc_number"
  },
  {
    "kind": "Literal",
    "name": "statusList",
    "value": [
      "active",
      "overdue",
      "partial_payment"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxType",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "status_In",
    "value": "active, overdue, partial_payment"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitItems",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preTaxAmount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paid",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "whtRatePercent",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ChartOfAccountNode",
  "kind": "LinkedField",
  "name": "chartOfAccount",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chartOfAccountCode",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiptDepositTransactionUsedNodeConnection",
  "kind": "LinkedField",
  "name": "receiptDepositTransactionUsed",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReceiptDepositTransactionUsedNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ReceiptDepositTransactionUsedNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "CreditNoteNodeConnection",
  "kind": "LinkedField",
  "name": "creditNoteTransaction",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditNoteNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CreditNoteNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "creditNoteSelectTransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InvoiceNodeConnection",
            "kind": "LinkedField",
            "name": "allInvoiceByStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "TransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "transaction",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductAndServiceNode",
                                    "kind": "LinkedField",
                                    "name": "productAndService",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v21/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceNode",
                                    "kind": "LinkedField",
                                    "name": "invoice",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v22/*: any*/),
                                  (v23/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "transaction(status_In:\"active, overdue, partial_payment\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "creditNoteSelectTransactionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceViewer",
        "kind": "LinkedField",
        "name": "invoiceViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "InvoiceNodeConnection",
            "kind": "LinkedField",
            "name": "allInvoiceByStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "InvoiceNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InvoiceNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": (v10/*: any*/),
                        "concreteType": "TransactionNodeConnection",
                        "kind": "LinkedField",
                        "name": "transaction",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TransactionNodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TransactionNode",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v19/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProductAndServiceNode",
                                    "kind": "LinkedField",
                                    "name": "productAndService",
                                    "plural": false,
                                    "selections": [
                                      (v20/*: any*/),
                                      (v21/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "InvoiceNode",
                                    "kind": "LinkedField",
                                    "name": "invoice",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v6/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v22/*: any*/),
                                  (v23/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "transaction(status_In:\"active, overdue, partial_payment\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e30a95472239c4e02baf00101efde49a",
    "id": null,
    "metadata": {},
    "name": "creditNoteSelectTransactionQuery",
    "operationKind": "query",
    "text": "query creditNoteSelectTransactionQuery(\n  $id: ID!\n) {\n  invoiceViewer {\n    allInvoiceByStatus(statusList: [\"active\", \"overdue\", \"partial_payment\"], contact_Id: $id, order: \"doc_number\") {\n      totalCount\n      edges {\n        node {\n          id\n          docNumber\n          total\n          issuedDate\n          dueDate\n          status\n          taxType\n          transaction(status_In: \"active, overdue, partial_payment\") {\n            totalCount\n            edges {\n              node {\n                id\n                description\n                unitItems\n                discount\n                price\n                preTaxAmount\n                vat\n                paid\n                whtRate\n                whtRatePercent\n                total\n                productAndService {\n                  productCode\n                  chartOfAccount {\n                    id\n                    chartOfAccountCode\n                  }\n                  id\n                }\n                invoice {\n                  docNumber\n                  issuedDate\n                  id\n                }\n                receiptDepositTransactionUsed {\n                  edges {\n                    node {\n                      id\n                      amount\n                    }\n                  }\n                }\n                creditNoteTransaction {\n                  edges {\n                    node {\n                      id\n                      price\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b0a23e5b8369704acafcb05c72a7b4b';

module.exports = node;
