/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoomGroupStatus = "CANCLED" | "FINISHED" | "PENDING" | "%future added value";
export type chatListQueryVariables = {|
  status?: ?string,
  roomGroupId?: ?string,
  search?: ?string,
  tag?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  first?: ?number,
  last?: ?number,
|};
export type chatListQueryResponse = {|
  +allRoomGroup: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +totalCountRead: ?number,
        +id: string,
        +name: string,
        +detail: ?string,
        +status: RoomGroupStatus,
        +note: ?string,
        +updated: any,
        +added: any,
        +user: {|
          +id: string
        |},
        +roomGroupNumberRead: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +count: number,
              +user: {|
                +id: string,
                +juristic: ?{|
                  +id: string,
                  +firstName: string,
                  +lastName: string,
                |},
                +tenant: ?{|
                  +id: string,
                  +firstName: string,
                  +lastName: ?string,
                |},
              |},
            |}
          |}>
        |},
        +roomGroupTag: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +tag: string,
            |}
          |}>
        |},
        +roomGroupDocument: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
        +roomGroupImage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileName: string,
              +fileUpload: ?string,
            |}
          |}>
        |},
        +roomGroupMessage: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +message: ?string,
              +image: ?string,
              +added: any,
              +updated: any,
              +user: {|
                +id: string
              |},
            |}
          |}>
        |},
      |}
    |}>,
  |}
|};
export type chatListQuery = {|
  variables: chatListQueryVariables,
  response: chatListQueryResponse,
|};
*/


/*
query chatListQuery(
  $status: String
  $roomGroupId: String
  $search: String
  $tag: String
  $startDate: DateTime
  $endDate: DateTime
  $first: Int
  $last: Int
) {
  allRoomGroup(status: $status, roomGroupId: $roomGroupId, search: $search, tag: $tag, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {
    totalCount
    edges {
      node {
        totalCountRead
        id
        name
        detail
        status
        note
        updated
        added
        user {
          id
        }
        roomGroupNumberRead {
          edges {
            node {
              id
              count
              user {
                id
                juristic {
                  id
                  firstName
                  lastName
                }
                tenant {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        roomGroupTag {
          edges {
            node {
              id
              tag
            }
          }
        }
        roomGroupDocument {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
        roomGroupImage {
          edges {
            node {
              id
              fileName
              fileUpload
            }
          }
        }
        roomGroupMessage {
          edges {
            node {
              id
              message
              image
              added
              updated
              user {
                id
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "roomGroupId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tag"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
],
v13 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fileUpload",
    "storageKey": null
  }
],
v14 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "endDate"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      },
      {
        "kind": "Variable",
        "name": "roomGroupId",
        "variableName": "roomGroupId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "tag",
        "variableName": "tag"
      }
    ],
    "concreteType": "RoomGroupNodeConnection",
    "kind": "LinkedField",
    "name": "allRoomGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RoomGroupNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RoomGroupNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCountRead",
                "storageKey": null
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detail",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomGroupNumberReadNodeConnection",
                "kind": "LinkedField",
                "name": "roomGroupNumberRead",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomGroupNumberReadNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomGroupNumberReadNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "JuristicNode",
                                "kind": "LinkedField",
                                "name": "juristic",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TenantNode",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": (v12/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomGroupTagNodeConnection",
                "kind": "LinkedField",
                "name": "roomGroupTag",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomGroupTagNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomGroupTagNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tag",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomGroupDocumentNodeConnection",
                "kind": "LinkedField",
                "name": "roomGroupDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomGroupDocumentNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomGroupDocumentNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomGroupImageNodeConnection",
                "kind": "LinkedField",
                "name": "roomGroupImage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomGroupImageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomGroupImageNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoomGroupMessageNodeConnection",
                "kind": "LinkedField",
                "name": "roomGroupMessage",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoomGroupMessageNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoomGroupMessageNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "image",
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "chatListQuery",
    "selections": (v14/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "chatListQuery",
    "selections": (v14/*: any*/)
  },
  "params": {
    "cacheID": "34bd7a44a023645d8c45cbd1d2f51ace",
    "id": null,
    "metadata": {},
    "name": "chatListQuery",
    "operationKind": "query",
    "text": "query chatListQuery(\n  $status: String\n  $roomGroupId: String\n  $search: String\n  $tag: String\n  $startDate: DateTime\n  $endDate: DateTime\n  $first: Int\n  $last: Int\n) {\n  allRoomGroup(status: $status, roomGroupId: $roomGroupId, search: $search, tag: $tag, startDate: $startDate, endDate: $endDate, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        totalCountRead\n        id\n        name\n        detail\n        status\n        note\n        updated\n        added\n        user {\n          id\n        }\n        roomGroupNumberRead {\n          edges {\n            node {\n              id\n              count\n              user {\n                id\n                juristic {\n                  id\n                  firstName\n                  lastName\n                }\n                tenant {\n                  id\n                  firstName\n                  lastName\n                }\n              }\n            }\n          }\n        }\n        roomGroupTag {\n          edges {\n            node {\n              id\n              tag\n            }\n          }\n        }\n        roomGroupDocument {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n        roomGroupImage {\n          edges {\n            node {\n              id\n              fileName\n              fileUpload\n            }\n          }\n        }\n        roomGroupMessage {\n          edges {\n            node {\n              id\n              message\n              image\n              added\n              updated\n              user {\n                id\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '46915bcab851868c458b309d0962592e';

module.exports = node;
