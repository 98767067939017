import React, {Component} from "react";
import { Helmet } from "react-helmet";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import Loading from '../../../../libs/loading';
import { QueryRenderer } from "react-relay";
import ReceiptDepositReportPDFRenderTable from "./receiptDepositReportPDFRenderTable";
import './receiptDepositReportPDF.scss'

const query = graphql`
  query receiptDepositReportPDFQuery(
    $first: Int
    $last: Int
    $start_date: DateTime
    $end_date: DateTime
    $newEndDate: Date
    $search: String
    $payment_channel: String
    $status: String
    $contact: String
    $order: String
  ) {
    receiptDepositViewer {
      allReceiptDeposit(
        first: $first
        last: $last
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status
        paymentChannel: $payment_channel
        contact: $contact
        order: $order
        report:true
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            total
            status
            payGroup
            firstName
            lastName
            name
            contact {
              refNumber
              name
              residential {
                name
              }
              firstName
              lastName
              typeOfContact
            }
            receiptDepositTransaction {
              edges {
                node {
                  id
                  description
                  price
                  balance
                }
              }
            }
            receiptDepositTransactionUsed(useDate_Lte:$newEndDate){
              edges {
                node {
                  id
                  amount
                }
              }
            }
            bankAccountTransaction {
              totalCount
            }
            cashTransaction {
              totalCount
            }
            chequeDeposit {
              totalCount
            }
          }
        }
      }
      summaryReceiptDeposit(
        startDate: $start_date
        endDate: $end_date
        search: $search
        statusNew: $status 
        paymentChannel: $payment_channel
        contact: $contact
      )
      summaryUsePartialReceiptDeposit(
        search: $search
        startDate: $start_date
        endDate: $end_date 
        paymentChannel: $payment_channel 
        contact: $contact 
        statusNew: $status 
      )
    }
  }
`;

class ReceiptDepositReportPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    componentWillMount() {
        this.setState({
            data: this.props.location?.state?.data,
        })
    }

    render() {
        return (
            <React.Fragment>
                        <Helmet
                        style={[{
                            "cssText": `
                            body {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                                background-color: #FAFAFA;
                                }
                            
                                * {
                                box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                }
                            
                                .page {
                                width: 297mm;
                                max-hight:210mm;
                                min-height: 210mm;
                                padding: 10mm 5mm 5mm 5mm ;
                                border: 1px #000 solid;
                                border-radius: 2px;
                                background: white;
                                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                                position: relative;
                                }
                                .pageNumber{
                                position: fixed;
                                bottom: 0;
                                }
                            
                                @page {
                                size: a4 landscape;
                                margin: 0;
                                }
                                @media print {
                                html, body {
                                    width: 297mm;
                                    height: 210mm;
                                }

                                @page: last{
                                    .pageNumber {
                                        display: block;
                                    }
                                }
                                .bg-gray{
                                    background-color: #D3D3D3;
                                }
                                .page {
                                    margin: 0;
                                    border: initial;
                                    border-radius: initial;
                                    width: initial;
                                    min-height: initial;
                                    box-shadow: initial;
                                    background: initial;
                                    page-break-after: always;
                                    position: relative;
                                }
                                .pageNumber{
                                    position: fixed;
                                    bottom: 0;
                                }
                                }
                        `
                        }]}>
                        <meta charSet="utf-8" />
                        <title>Receipt Deposit</title>
                        <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                        </Helmet>
                        <div id="receiptDepositReportPDF">
                            <div className="print-top-menu">
                                    <div className="logo">
                                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                                    </div>
                                    <div className="print" onClick={() => window.print()}>
                                        PRINT
                                    </div>
                            </div>                           
                            <QueryRenderer
                                environment={environment}
                                query={query}
                                cacheConfig={{ use_cache: false }}
                                variables={{
                                    first: null,
                                    last: null,
                                    start_date: this.state.data.start_date,
                                    end_date: this.state.data.end_date,
                                    newEndDate: this.state.data.end_date.toISOString().slice(0,10),
                                    search: this.state.data.search,
                                    status: this.state.data.status,
                                    payment_channel: this.state.data.payment_channel,
                                    contact: this.state.data.contact,
                                    order: "doc_number",
                                }}
                                render={({ error, props }) => {
                                let summaryUsePartialReceiptDeposit = null
                                if (props) {
                                    if (props.receiptDepositViewer?.summaryUsePartialReceiptDeposit) {
                                        summaryUsePartialReceiptDeposit = JSON.parse(props.receiptDepositViewer?.summaryUsePartialReceiptDeposit)
                                      }
                                    let contactName = 'ทั้งหมด';
                                    let paymentChannel = 'ทั้งหมด';
                                    let status = 'ทั้งหมด';
                                    if(this.state.data.contact !== "") {
                                        if(props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.typeOfContact === "RESIDENTIAL"){
                                            contactName = props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.refNumber
                                            +" "+props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.firstName
                                            +" "+props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.lastName
                                        } 
                                        if(props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.typeOfContact === "SUPPLIER"){
                                            contactName = props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.refNumber
                                            +" "+props.receiptDepositViewer.allReceiptDeposit.edges[0].node.contact.name                                        
                                        }
                                    }
                                    switch (this.state.data.payment_channel) {
                                        case "cash":
                                          paymentChannel = "เงินสด";
                                          break;
                                        case "bank":
                                          paymentChannel = "เงินโอน";
                                          break;      
                                        case "cheque":
                                          paymentChannel = "เช็ค";
                                          break;      
                                        case "other":
                                          paymentChannel = "อื่นๆ";
                                          break;      

                                        default:
                                          break;
                                    }    
                                    switch (this.state.data.status) {
                                        case "active":
                                          status = "ยังไม่ตัดชำระ";
                                          break;
                                        case "use_all":
                                          status = "ตัดชำระแล้วทั้งจำนวน";
                                          break;      
                                        case "use_partial":
                                          status = "ตัดชำระแล้วบางส่วน";
                                          break;      
                                        case "void":
                                          status = "รายการที่ยกเลิก";
                                          break;      
                                          
                                        default:
                                          break;
                                    }    
                                    return (
                                        <ReceiptDepositReportPDFRenderTable 
                                            props={props}
                                            start_date={this.state.data.start_date}
                                            end_date={this.state.data.end_date}
                                            contactName={contactName}
                                            paymentChannel={paymentChannel}
                                            status={status}
                                            summaryUsePartialReceiptDeposit = {summaryUsePartialReceiptDeposit}
                                        /> 
                                    );
                                } else {
                                    return <Loading/>
                                }
                                }}
                            />
                        </div>    
            </React.Fragment>
        )
    }
}

export default ReceiptDepositReportPDF;