import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from "react-i18next";


class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab"  >
                <div className="col">
                    <ul>
                        <li>
                            <NavLink exact={true} to="/project/juristic-company/dashboard/general-information">
                                <Translation>
                                    {(t) => <span>{t("juristicCompany:General Information")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/project/juristic-company/dashboard/project-management">
                                <Translation>
                                    {(t) => <span>{t("juristicCompany:Project Management Team")}</span>}
                                </Translation>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/project/juristic-company/dashboard/support">
                                <Translation>
                                    {(t) => <span>{t("juristicCompany:Support Team")}</span>}
                                </Translation>
                            </NavLink>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

export default Navigation; 