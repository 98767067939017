import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
    mutation CreateAndUpdateProductMutation($input: CreateAndUpdateProductInput!){
        createAndUpdateProduct(input: $input){
            newProduct {
                id
                name
                productCode
                type
                collect
                chartOfAccount {
                    id
                    chartOfAccountCode
                    name
                    nameTh
                    nameEn
                }
            }
        }
    }
`;


export default function CreateAndUpdateProductMutation(input, callback, error_callback) {
    const variables = {
        input: input
    };
    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
