/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateReceiveInput = {|
  idList?: ?$ReadOnlyArray<?string>,
  status?: ?string,
  voidRemark?: ?string,
  remark?: ?string,
  clientMutationId?: ?string,
|};
export type receiveDetailMutationVariables = {|
  input: UpdateReceiveInput
|};
export type receiveDetailMutationResponse = {|
  +updateReceive: ?{|
    +warningText: ?string,
    +newReceive: ?{|
      +id: string
    |},
  |}
|};
export type receiveDetailMutation = {|
  variables: receiveDetailMutationVariables,
  response: receiveDetailMutationResponse,
|};
*/


/*
mutation receiveDetailMutation(
  $input: UpdateReceiveInput!
) {
  updateReceive(input: $input) {
    warningText
    newReceive {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateReceivePayload",
    "kind": "LinkedField",
    "name": "updateReceive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ReceiveNode",
        "kind": "LinkedField",
        "name": "newReceive",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "receiveDetailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "receiveDetailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fa751f06cb3bda305d2a1a05adacba92",
    "id": null,
    "metadata": {},
    "name": "receiveDetailMutation",
    "operationKind": "mutation",
    "text": "mutation receiveDetailMutation(\n  $input: UpdateReceiveInput!\n) {\n  updateReceive(input: $input) {\n    warningText\n    newReceive {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4e7579e6615f08eb404845f8b878b30';

module.exports = node;
