/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type JuristicNewsNewsType = "ALL" | "JURISTIC" | "TENANT" | "%future added value";
export type JuristicNewsStatus = "DRAFT" | "PUBLISH" | "VOID" | "%future added value";
export type announceDetailQueryVariables = {|
  id: string
|};
export type announceDetailQueryResponse = {|
  +juristicNews: ?{|
    +id: string,
    +title: ?string,
    +titleEn: ?string,
    +detail: ?string,
    +detailEn: ?string,
    +previewImage: string,
    +status: JuristicNewsStatus,
    +newsType: JuristicNewsNewsType,
    +creator: ?string,
    +added: any,
    +scheduleTime: ?any,
    +highlight: boolean,
    +group: ?{|
      +id: string,
      +name: string,
      +nameEn: ?string,
    |},
    +juristicNewsImages: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fileUrl: ?string,
          +fileUpload: string,
        |}
      |}>
    |},
    +juristicNewsFile: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +fileName: ?string,
          +fileUpload: string,
          +fileUrl: ?string,
        |}
      |}>
    |},
  |},
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +periodCreate: string
      |}
    |}>
  |},
|};
export type announceDetailQuery = {|
  variables: announceDetailQueryVariables,
  response: announceDetailQueryResponse,
|};
*/


/*
query announceDetailQuery(
  $id: ID!
) {
  juristicNews(id: $id) {
    id
    title
    titleEn
    detail
    detailEn
    previewImage
    status
    newsType
    creator
    added
    scheduleTime
    highlight
    group {
      id
      name
      nameEn
    }
    juristicNewsImages {
      edges {
        node {
          id
          fileUrl
          fileUpload
        }
      }
    }
    juristicNewsFile {
      edges {
        node {
          fileName
          fileUpload
          fileUrl
          id
        }
      }
    }
  }
  allProjects {
    edges {
      node {
        periodCreate
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "titleEn",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detail",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "detailEn",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "previewImage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "newsType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduleTime",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highlight",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNewsGroupNode",
  "kind": "LinkedField",
  "name": "group",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nameEn",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUrl",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileUpload",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "JuristicNewsImageNodeConnection",
  "kind": "LinkedField",
  "name": "juristicNewsImages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNewsImageNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JuristicNewsImageNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodCreate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "announceDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicNewsNode",
        "kind": "LinkedField",
        "name": "juristicNews",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNewsFileNodeConnection",
            "kind": "LinkedField",
            "name": "juristicNewsFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNewsFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JuristicNewsFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v16/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "announceDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "JuristicNewsNode",
        "kind": "LinkedField",
        "name": "juristicNews",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v17/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "JuristicNewsFileNodeConnection",
            "kind": "LinkedField",
            "name": "juristicNewsFile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "JuristicNewsFileNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "JuristicNewsFileNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v18/*: any*/),
                      (v16/*: any*/),
                      (v15/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeConnection",
        "kind": "LinkedField",
        "name": "allProjects",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1ec645b0ae5cc63fc6df64c116fb937",
    "id": null,
    "metadata": {},
    "name": "announceDetailQuery",
    "operationKind": "query",
    "text": "query announceDetailQuery(\n  $id: ID!\n) {\n  juristicNews(id: $id) {\n    id\n    title\n    titleEn\n    detail\n    detailEn\n    previewImage\n    status\n    newsType\n    creator\n    added\n    scheduleTime\n    highlight\n    group {\n      id\n      name\n      nameEn\n    }\n    juristicNewsImages {\n      edges {\n        node {\n          id\n          fileUrl\n          fileUpload\n        }\n      }\n    }\n    juristicNewsFile {\n      edges {\n        node {\n          fileName\n          fileUpload\n          fileUrl\n          id\n        }\n      }\n    }\n  }\n  allProjects {\n    edges {\n      node {\n        periodCreate\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82d29937736318250c27d2697d7d8d5a';

module.exports = node;
