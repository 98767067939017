import React from "react";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Wrapper from "../../../../components/wrapper";
import ComponentPagination from "../../../../libs/componentPagination";
import Pagination from "../../../../libs/newPagination";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import ReceiveReportTable from "./receiveReportTable";
import ExportReceive from "./server_export";
import ContactReportList from "../contactReportList";
import _ from "lodash";
import ProductServiceList from "../productServiceList";
import { default as letmeuse } from "../../../../libs/localstorage";
import Loading from "../../../../libs/loading"
import { fetchQuery } from "relay-runtime";
import postApiUseing from '../../../../libs/postApiUseing';
import Swal from "sweetalert2";
import './receiveReportPDF.scss'
import i18next from "i18next";

const query = graphql`
  query receiveReportQuery(
    $start_date: DateTime
    $end_date: DateTime
    $search: String
    $status: String
    $order: String
    $customer_type: String
    $payment_channel: String
    $payment_gateway_method_type: String
    $contact: String
    $productAndService: String
  ) {
    receiveViewer {
      allReceive(
        startDate: $start_date
        endDate: $end_date
        status: $status
        search: $search
        order: $order
        customerType: $customer_type
        paymentChannel: $payment_channel
        paymentGatewayMethodType: $payment_gateway_method_type
        contact: $contact
        productAndService: $productAndService
      ) {
        totalCount
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            id
            docNumber
            issuedDate
            total
            status
            payGroup
            firstName
            lastName
            contact {
              name
              refNumber
              firstName
              lastName
              typeOfContact
              residential {
                name
              }
            }
            receiveTransaction {
              totalCount
              edges {
                node {
                  id
                  amount
                  discount
                  receiptDepositTransactionUsed {
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                  transaction {
                    id
                    description
                    price
                    discount
                    total
                    paid
                    status
                    productAndService {
                      id
                      price
                      productCode
                    }
                    invoice {
                      docNumber
                      issuedDate
                    }
                  }
                }
              }
            }
            creditCardTransaction {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            cashDepositReceiveTransaction {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            bankAccountTransactionReceive {
              totalCount
              edges {
                node {
                  price
                  transferMethod
                }
              }
            }
            crossBank: bankAccountTransactionReceive(
              transferMethod: "cross_bank"
            ) {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            chequeDeposit {
              totalCount
              edges {
                node {
                  price
                }
              }
            }

            unknownReceive {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            receiptDeposit {
              totalCount
              edges {
                node {
                  docNumber
                  refNumber
                  status
                  remark
                  total
                  taxType
                  firstName
                  lastName
                  name
                  taxIdNumber
                  voidRemark
                  added
                  updated
                  issuedDate
                  receiptDepositTransaction {
                    totalCount
                    edges {
                      node {
                        price
                        description
                        chartOfAccount {
                          chartOfAccountCode
                          name
                        }
                      }
                    }
                  }
                  receiptDepositTransactionUsed {
                    totalCount
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                  crossBank: bankAccountTransaction(
                    transferMethod: "cross_bank"
                  ) {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  bankAccountTransaction {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  cashTransaction {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  chequeDeposit {
                    totalCount
                    edges {
                      node {
                        price
                      }
                    }
                  }
                  creditCardTransaction {
                    totalCount
                    edges {
                      node {
                        amount
                      }
                    }
                  }
                }
              }
            }
            creditNoteReceive {
              totalCount
              edges {
                node {
                  price
                }
              }
            }
            smallChange {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            feeAccounting {
              totalCount
              edges {
                node {
                  amount
                }
              }
            }
            withheldTaxReceive {
              totalCount
              edges {
                node {
                  total
                }
              }
            }
            paymentGatewayMethod{
              totalCount
              edges {
                node {
                    id
                    paymentType
                    paymentGateway{
                      id
                      paymentType
                      fee
                      typeCal
                    }
                    bankAccount{
                      id
                      docNumber
                      accountNumber
                      accountName
                      accountType
                      receivePayment
                      makePayment
                      bankName
                      status
                    }
                    paymentType
                    description
                    amount
                    feeAmount
                    total
                    depositDate
                    image
                }
              }
            }
          }
        }
      }
    }
    currentRecord: allAccountRecord(
      startDate: $start_date
      endDate: $end_date
      typeOfRecord: "current"
    ) {
      edges {
        node {
          id
          name
          debit
          credit
          added
          group {
            id
            name
            issuedDate
            refNumber
            totalDebit
            totalCredit
            refTransaction
            added
            category
          }
          chartOfAccountCode {
            id
            name
            chartOfAccountCode
          }
        }
      }
    }
    allPaymentGatewayMethodType{
      dataTable
    }
  }
`;

class ReceiveReport extends ComponentPagination {
  constructor(props) {
    super(props);

    letmeuse.removeCheckPage();
    letmeuse.setCheckPage("", "", "");
    let month_before = new Date();
    month_before.setMonth(month_before.getMonth() - 1);

    let min_date = new Date();
    min_date.setMonth(month_before.getMonth() - 12);

    this.state.current_date = new Date();
    // this.state.start_date = month_before;
    this.state.start_date = new Date();
    this.state.end_date = new Date();
    this.state.temp_start_date = new Date();
    this.state.temp_end_date = new Date();
    this.state.min_date = min_date;
    this.state.customer_type = "";
    this.state.search = "";
    this.state.search_input = "";
    this.state.payment_channel = "";
    this.state.payment_gateway_method_type = "";
    this.state.temp_payment_channel = "";
    this.state.temp_payment_gateway_method_type = "";
    this.state.contact = "";
    this.state.temp_contact = "";
    this.state.product_and_service = "";
    this.state.temp_product_and_service = "";
    this.state.loading = "false"

    // var summaryReceive_cash_total = 0
    // var summaryReceive_bank_account_total = 0
    // var summaryReceive_cross_bank_total = 0
    // var summaryReceive_credit_card_total = 0
    // var summaryReceive_cheque_deposit_total = 0
    // var summaryReceive_sum_total = 0

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchDataQuery = this.fetchDataQuery.bind(this);
    this.checkLoadingExcel = this.checkLoadingExcel.bind(this);

    this.state.dataResultReceive = {};
    this.state.allPaymentGatewayMethodType = {};
    this.state.checkFetchState = false;

    this.state.summaryReceive_cash_total = 0;
    this.state.summaryReceive_bank_account_total = 0;
    this.state.summaryReceive_cross_bank_total = 0;
    this.state.summaryReceive_credit_card_total = 0;
    this.state.summaryReceive_cheque_deposit_total = 0;
    this.state.summaryReceive_sum_total = 0;
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "receiveReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery();
  }

  fetchDataQuery() {
    this.setState({
      checkFetchState: false,
      loading: true,
    })
    fetchQuery(environment, query, {
      first: this.state.first,
      last: this.state.last,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      search: this.state.search,
      status: "paid,void",
      order: "doc_number",
      customer_type: this.state.customer_type,
      payment_channel: this.state.payment_channel,
      payment_gateway_method_type: this.state.payment_gateway_method_type,
      contact: this.state.contact,
      productAndService: this.state.product_and_service,
    }).then((item) => {
      this.setState({ dataResultReceive: item, allPaymentGatewayMethodType: item.allPaymentGatewayMethodType.dataTable, checkFetchState: true, loading: false });
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSearch(e);
    }
  }
  handleSearch(event) {
    this.setState(
      {
        start_date: this.state.temp_start_date,
        end_date: this.state.temp_end_date,
        search: event.target.value,
        checkFetchState: false,
        product_and_service: this.state.temp_product_and_service,
        contact: this.state.temp_contact,
        payment_channel: this.state.temp_payment_channel,
        payment_gateway_method_type: this.state.temp_payment_gateway_method_type,
      },
      () => {
        this.fetchDataQuery()
        this.goFirst()
      }
    );
  }

  CheckChange() {
    const check_page = letmeuse.getCheckPage().split(",");
    if (
      this.state.product_and_service === check_page[0] &&
      this.state.contact === check_page[1] &&
      this.state.payment_channel === check_page[2]
    ) {
      letmeuse.setCheckPage(
        this.state.product_and_service,
        this.state.contact,
        this.state.payment_channel
      );
    } else {
      letmeuse.setCheckPage(
        this.state.product_and_service,
        this.state.contact,
        this.state.payment_channel
      );
      let getPageLimit = letmeuse.getPageLimit();
      if (getPageLimit === 10) {
        this.state.first = 10;
        this.state.last = 10;
      } else if (getPageLimit === 20) {
        this.state.first = 20;
        this.state.last = 20;
      } else if (getPageLimit === 50) {
        this.state.first = 50;
        this.state.last = 50;
      }
    }
    // this.setState({checkFetchState: false}, () => this.fetchDataQuery())
  }
  onClickGetData(data) {
    this.props.history.push(`/accounting/report/account-receivable/receive/PDF`, { data: data })
  }
  checkLoadingExcel(status) {
    this.setState({ checkFetchState: status });
  }

  render() {
    // let col_span = 10;
    // let text_channel = "";
    // if (this.state.payment_channel !== "") {
    //   col_span = 10;
    //   if (this.state.payment_channel === "cash") {
    //     text_channel = "เงินสด";
    //   } else if (this.state.payment_channel === "bank") {
    //     text_channel = "เงินโอน";
    //   } else if (this.state.payment_channel === "credit_card") {
    //     text_channel = "บัตรเครดิต";
    //   } else if (this.state.payment_channel === "cross_bill") {
    //     text_channel = "Cross bill";
    //   } else if (this.state.payment_channel === "cheque") {
    //     text_channel = "cheque";
    //   }else if (this.state.payment_channel === "payment_gateway") {
    //     text_channel = "Payment Gateway"
    //   } else {
    //     text_channel = "อื่นๆ";
    //   }
    // }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <div className="container-fluid box">
            <div className="row justify-content-between" id="receiveReport">
              <div className="col">
                <h3 className="mb-4">
                  <Link to="/accounting/report/account-receivable">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/images/wrapperContent/back.png"
                      }
                      alt="back"
                      className="back"
                    />
                  </Link>
                  <Translation>
                    {(t) => <span>{i18next.t("receivable_dashboard:Payment Receipt Summary Report")}</span>}
                  </Translation>
                </h3>
              </div>
              <div className={`col`}>
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) &&
                  <div className={`${this.state.checkFetchState ? "dropdown" : ''} printReport`}>
                    {!this.state.checkFetchState ?
                      <React.Fragment>
                        <button type="button" className="btnPrint">
                          <span className="spinner-border spinner-border-sm align-middle mr-2" />
                          <Translation>{t => t("meter:Preparing information")}</Translation>
                        </button>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <button type="button" className="btnPrint dropdown-toggle" data-toggle="dropdown">
                          <Translation>{t => t("PageList:Print")}</Translation>
                        </button>
                      </React.Fragment>
                    }
                    <div className={`dropdown-menu`}>
                      <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                      <ExportReceive state={this.state} checkLoadingExcel={this.checkLoadingExcel} />
                    </div>
                  </div>
                }
              </div>
              {/* <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                  codename: "report_receivable_print",
                }) && <ExportReceive state={this.state} />}
              </div> */}
            </div>
            <Translation>
              {(t) =>
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                          onKeyDown={this.handleKeyDown}
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          // minDate={this.state.min_date}
                          maxDate={this.state.temp_end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">

                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.customer_type}
                            contact={this.state.temp_contact}
                          />

                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={
                              this.state.temp_product_and_service
                            }
                          />

                          {this.CheckChange()}

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("receiveReport:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receiveReport:All")}</option>
                                <option value="cash">{i18next.t("PaymentChannel:Cash")}</option>
                                <option value="bank">{i18next.t("PaymentChannel:Transfer")}</option>
                                <option value="cross_bill">Cross bill</option>
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                  <option value="credit_card">{i18next.t("PaymentChannel:Credit Card")}</option>
                                }
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                  <option value="payment_gateway">{i18next.t("PaymentChannel:Payment Gateway")}</option>
                                }
                                <option value="cheque">{i18next.t("PaymentChannel:Cheque")}</option>
                                <option value="receipt_deposit_transaction_used">{i18next.t("PaymentChannel:Advance Payment")}</option>
                                <option value="unknown_receive">{i18next.t("PaymentChannel:Pending Verification")}</option>

                                <option value="other">{i18next.t("PaymentChannel:Other")}</option>
                              </select>
                            </div>
                          </div>

                          {this.state.temp_payment_channel === "payment_gateway" &&
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{i18next.t("PaymentChannel:Payment Gateway Format")}</label>
                                <select
                                  name="temp_payment_gateway_method_type"
                                  onChange={this.handleChange}
                                  className="form-control dropdown-custom-arrow"
                                >
                                  <option key={"type-all"} value="">{i18next.t("receiveReport:All")}</option>
                                  {
                                    this.state.allPaymentGatewayMethodType &&
                                    this.state.allPaymentGatewayMethodType.map(element => {
                                      let type = JSON.parse(element)
                                      if (type.name !== "crossbill") {
                                        return (
                                          <option key={type.name} value={type.name}>{type.name}</option>
                                        )
                                      } else {
                                        return null
                                      }
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.loading ?
                    (
                      <Loading />
                    )
                    :
                    (<React.Fragment>
                      <ReceiveReportTable
                        props={this.state.dataResultReceive}
                        state={this.state}
                        sendData={this.getData}
                      />

                      <div className="row">
                        <Pagination
                          changePage={this.changePage}
                          first={this.state.first}
                          last={this.state.last}
                          totalCount={
                            this.state.dataResultReceive.receiveViewer.allReceive
                              .totalCount
                          }
                        />
                      </div>
                    </React.Fragment>
                    )}
                </div>
              }
            </Translation>
          </div>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default ReceiveReport;
