import React, {Component} from 'react';
import './lottery.scss'


class firstLotteryAward extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render(){
        let myLang = this.props.lang;
        return(
            <div style={{backgroundColor:'#FFFFFF'}}>
                    <div className='row'>
                        <div className='col text-center pt-3'>
                            <span>{myLang === 'en' ? 'Results' : 'รางวัลที่ 1'}</span>
                            {this.props.isShowDetail && <p style={{color:'#808080'}}>{myLang === 'en' ? 'Value (Baht)' : 'รางวัลละ'}{this.props.lotteryAward.first?.price} {myLang === 'en' ? 'Baht' : 'บาท'} </p> }
                           <p style={{color:'#1567B4'}}><h2>{this.props.lotteryAward && this.props.lotteryAward.first?.number[0]?.value}</h2></p>
                        </div>
                    </div>
                    <div className='row justify-content-center' style={{margin:'20px'}}>
                        <div className='col-4' style={{padding:'0'}}>
                            <span> {myLang === 'en' ? 'In first three digits' : 'เลขหน้า 3 ตัว'}</span>
                            {  this.props.isShowDetail && <p style={{color:'#808080',fontSize:'10px',margin:'0'}}>{myLang === 'en' ? 'Value (Baht)' : 'รางวัลละ'} {this.props.lotteryAward.last3f?.price} {myLang === 'en' ? 'Baht' : 'บาท'}</p>}
                            {this.props.lotteryAward && this.props.lotteryAward.last3f?.number.map((val) => {
                                return (
                                    <p style={{margin:0}} className='pt-2'><h3>{val.value}</h3></p>
                                )
                            })}
                        </div>
                        <div className='col-4' style={{padding:'0'}}>
                            <span> {myLang === 'en' ? 'In last three digits' : 'เลขท้าย 3 ตัว'} </span> 
                            {  this.props.isShowDetail &&  <p style={{color:'#808080',fontSize:'10px',margin:'0'}}>{myLang === 'en' ? 'Value (Baht)' : 'รางวัลละ'} {this.props.lotteryAward.last3b?.price} {myLang === 'en' ? 'Baht' : 'บาท'}</p> }
                            {this.props.lotteryAward && this.props.lotteryAward.last3b?.number.map((val) => {
                                return (
                                    <p style={{margin:0}} className='pt-2'><h3>{val.value}</h3></p>
                                )
                            })}
                        </div>
                        <div className='col-4 text-center' style={{padding:'0'}}>
                            <span> {myLang === 'en' ? 'In two digits' : 'เลขท้าย 2 ตัว'}  </span> 
                            {  this.props.isShowDetail &&  <p style={{color:'#808080',fontSize:'10px',margin:'0'}}>{myLang === 'en' ? 'Value (Baht)' : 'รางวัลละ'} {this.props.lotteryAward.last2?.price} {myLang === 'en' ? 'Baht' : 'บาท'}</p>}
                            {this.props.lotteryAward && this.props.lotteryAward.last2?.number.map((val) => {
                                return (
                                    <p style={{margin:0}} className='pt-2'><h2 style={{color:'#1567B4'}}>{val.value}</h2></p>
                                )
                            })}
                        </div>
                    </div>
            </div>
        )
    }
}

export default firstLotteryAward;