
import React, { Component } from 'react';

import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from 'react-relay'

import { Link } from "react-router-dom";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import SearchSelect from "../../../libs/searchSelect";
import { fetchQuery } from "relay-runtime";
import _ from "lodash";

import './css/contractServiceCreateForm.scss';
import environment from "../../../env/environment";


import Swal from "sweetalert2";
import { Redirect } from 'react-router';

import { Translation } from "react-i18next";
import i18n from "i18next"
import InvoiceDelete from "../invoice/invoiceDelete";
import { format, } from "date-fns";
import numberWithComma from "../../../libs/numberWithComma";
import FileInput from '../../../project/projectInfo/componentProjectList/fileInput';
import { dataFilterContractServiceObjects } from './dataFilterContractService'
import upload from '../../../libs/upload';
import i18next from 'i18next';

const contractServiceCreateForm = graphql`
    query contractServiceCreateFormQuery($id: String ,$issuedDate : Date){
        productViewer {
            allProduct(chartOfAccountId: $id, pricingType: "static"){
            totalCount
              edges {
                node {
                  id
                  name
                  productCode
                }
              }
            }
        }
        contractRevenueDocNumber (issuedDate : $issuedDate)
        allProjects {
            edges {
            node {
            periodCreate
            }
          }
        }
    }
`;
const mutation = graphql`
  mutation contractServiceCreateFormMutation($input: CreateContractRevenueInput!) {
    createContractRevenue(input: $input) {
      ok
      message
    }
  }
`;

let contractRevenueList_object = {
    list_id: "",
    description: "",
    price_per_period: 0,
    num_period: 0,
    total: 0,
};

class ContractServiceCreateForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            issuedDate: new Date(),
            productViewer: null,

            issued_date: new Date(),
            debtorId: '',
            docNumber: this.props.contractServiceQuery.contractRevenueDocNumber || '',
            contractForm: '',
            otherContractForm: '',
            contractNumber: '',
            remark: '',
            pricePerPeriod: 0,
            total: 0,
            startContractDate: new Date(),
            endContractDate: new Date(),
            contractRevenueList: [],
            chartOfAccountId: '',
            notiDay: 30,
            fileAttachment: '',
            fileName: '',

            redirectToList: false,

            disibleInput: false,
            document_upload: '',
            upload: {},
            signreq:Math.random().toString(36),

            temp_month_before : '',
            temp_month_after : '',
            period_create:''

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.appendNewRow = this.appendNewRow.bind(this);
        this.setTransactionList = this.setTransactionList.bind(this);
        this.sumPriceperPeriod = this.sumPriceperPeriod.bind(this);
        this.sumTotal = this.sumTotal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.chartOfAccountId !== this.state.chartOfAccountId) {
            this.queryproductViewer();
        }
        if (prevState.issued_date !== this.state.issued_date) {
            this.queryproductViewer();
        }
    }

    componentDidMount(){
        this.queryproductViewer()
    }

    queryproductViewer() {

        fetchQuery(environment, contractServiceCreateForm, {
            id: this.state.chartOfAccountId,
            issuedDate: format(this.state.issued_date, "YYYY-MM-DD")
        }).then(data => {
            if (data?.contractRevenueDocNumber) {
                this.setState({docNumber:data?.contractRevenueDocNumber})
            }
           
            if (_.isObject(data.productViewer.allProduct)) {

                this.setState({
                    contractRevenueList: [],
                    productViewer: data.productViewer,
                })

                data.productViewer.allProduct.totalCount > 0 && this.setState({
                    contractRevenueList: [...this.state.contractRevenueList, { ...contractRevenueList_object }]
                })
            }
            this.calPeriodMouth(data.allProjects.edges[0].node.periodCreate)
        })
    }

    calPeriodMouth(val){
        let month_before = new Date();
        let month_after= new Date();
    
        month_before.setMonth(month_before.getMonth() -  parseInt(val));
        month_after.setMonth(month_after.getMonth() + parseInt(val));
    
        this.setState({
            temp_month_before : month_before,
            temp_month_after : month_after,
        })
    }

    handleInputChange(e) {
        let transaction_list = _.cloneDeep(this.state.contractRevenueList);
        if (e.target.name === "chartOfAccount") {
            transaction_list[e.target.dataset.id].list_id = e.target.value
            let product = _.find(this.state.productViewer.allProduct.edges, {node :{ id: e.target.value } })
            transaction_list[e.target.dataset.id].description = product.node.name
        }
        else if (e.target.name === "description") {
            transaction_list[e.target.dataset.id].description = e.target.value
        }
        else if (e.target.name === "num_period") {
            transaction_list[e.target.dataset.id].num_period = e.target.value
        }
        else if (e.target.name === "price_per_period") {
            transaction_list[e.target.dataset.id].price_per_period = e.target.value
        }
        else {
            this.setState({ [e.target.name]: e.target.value });
            return;
        }
        if (transaction_list[e.target.dataset.id].price_per_period > 0 && transaction_list[e.target.dataset.id].num_period > 0) {
            const total = Math.round((transaction_list[e.target.dataset.id].price_per_period * transaction_list[e.target.dataset.id].num_period + Number.EPSILON) * 100) / 100;
            transaction_list[e.target.dataset.id].total = total
        }
        this.setState({
            contractRevenueList: transaction_list
        })
    }

    appendNewRow() {
        this.setState({ contractRevenueList: [...this.state.contractRevenueList, { ...contractRevenueList_object }] });
    }

    appendFileInputRow = (fileInput) => {
        this.setState({
            fileAttachment: fileInput.node.fileUpload,
            fileName: fileInput.node.nameFile
        })
    }
    sumPriceperPeriod() {
        let sum_price_per_period = 0;
        let transaction_list = this.state.contractRevenueList;
        transaction_list.forEach((transaction) => {
            sum_price_per_period = (sum_price_per_period + (parseFloat(transaction.price_per_period) || 0));
        });
        return sum_price_per_period
    }
    sumTotal() {
        let sum_price_per_period = 0;
        let transaction_list = this.state.contractRevenueList;
        transaction_list.forEach((transaction) => {
            sum_price_per_period = (sum_price_per_period + (parseFloat(transaction.total) || 0));
        });
        return sum_price_per_period
    }
    calPrice() {
        let sum_price = 0;
        let transaction_list = [...this.state.contractRevenueList];
        transaction_list.forEach((transaction) => {
            sum_price = (sum_price + (parseFloat(transaction.total) || 0));
        });
        this.setState({ total: sum_price });
    }

    setTransactionList(new_transaction) {
        this.setState({ contractRevenueList: new_transaction }, () => {
            this.calPrice();
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/contractService/fileAttachment" + path, file);
          resolve(res);
        });
      };

      
    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        if(this.state.contractRevenueList.length === 0){
            Swal.fire(i18n.t("contract_service:Save unsuccessfully! No TransactionList"), '', 'warning');
            this.setState({ loading: false });
            return;
        }

        let fileAttachment = ''
        if(this.state.fileAttachment !== ''){
            await this.callUpload('', this.state.fileAttachment).then((data) => {
                fileAttachment = data.key.replace("silverman-storage/private/", "");
            });
        }

        let variables = {
            input: {
                docNumber: this.state.docNumber,
                issuedDate: format(this.state.issued_date, "YYYY-MM-DD"),
                debtorId: this.state.debtorId,
                contractNumber: this.state.contractNumber,
                contractForm: this.state.contractForm,
                otherContractForm: this.state.otherContractForm,
                remark: this.state.remark,
                
                pricePerPeriod: this.sumPriceperPeriod(),
                total: this.sumTotal(),

                startContractDate: format(this.state.startContractDate, "YYYY-MM-DD"),
                endContractDate: format(this.state.endContractDate, "YYYY-MM-DD"),

                contractRevenueList: this.state.contractRevenueList,

                chartOfAccountId: this.state.chartOfAccountId,
                notiDay: this.state.notiDay,
                
                fileAttachment: fileAttachment, 
                fileName: this.state.fileName,
                clientMutationId:"CS"+this.state.signreq,
            }
        };

        if(this.state.contractRevenueList.length > 0){
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    // uploadables,
                    onCompleted: (response) => {
                        this.setState({ loading: false });
                        if (response.createContractRevenue.ok) {
                            Swal.fire(i18n.t("contract_service:Save successfully!"), '', 'success').then(() => {
                                this.setState({ redirectToList: true })
                            });
                        } else {
                            // console.log(response.createContractRevenue.message);
                            if(response.createContractRevenue.message === "have this doc_number"){
                                Swal.fire(i18n.t("contract_service:Save unsuccessfully! Same Document"), '', 'warning');
                                this.setState({ redirectToList: true })
                            }else{
                                Swal.fire(i18n.t("contract_service:Save unsuccessfully!"), '', 'warning');
                            }
                            this.setState({ loading: false });
                        }
                    },
                    onError: (err) => {
                        this.onError();
                    },
                },
            );
        }
    }

    onError(err) {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
    }
    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.backLink} />
        }

        return (
            <form onSubmit={this.handleSubmit} id="contract-service-create-form">
                <div className="container-fluid box">
                    <div className="row">
                        <div className="col">
                            <Translation>
                                {
                                    t =>
                                        <h4 className="mb-4">
                                            <Link to={this.props.backLink}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>{t(this.props.headerWord)}
                                        </h4>
                                }
                            </Translation>
                        </div>
                    </div>

                    <div className="content-inner box">
                        {/* row 1 */}
                        <div className="row mb-24">
                            <div className="col-sm-3 ">
                                <Translation>{t => <h6>{t("contract_service:Contract Service Document")}</h6>}</Translation>
                                <input type="text" className="form-control" value={this.state.docNumber} disabled />
                            </div>
                            <div className="col-sm-2 ">
                                <Translation>{t => <h6>{t("contractServiceView:Date")}</h6>}</Translation>
                                {/* issued_date */}
                                {
                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'period_create'])?.menu_on ? 
                                    <DatePickerAdapter
                                    name="issued_date" className="form-control mb-2 input-text"
                                    selected={this.state.issued_date}
                                    minDate={this.state.temp_month_before}
                                    // maxDate={this.state.temp_month_after}
                                    onChange={this.handleInputChange}
                                    required={true}
                                    /> 
                                    :
                                    <DatePickerAdapter
                                    name="issued_date" className="form-control mb-2 input-text"
                                    selected={this.state.issued_date}
                                    onChange={this.handleInputChange}
                                    required={true}
                                />
                                }
                                {/* <input type="text" className="form-control" value={format(new Date(), "DD/MM/YYYY")} disabled /> */}
                            </div>
                        </div>

                        {/* row 2 */}
                        <div className="row mb-24">
                            <div className="col-sm-3">
                                <Translation>{t => <h6>{t("contract_service:Receivable/Contract Company")}</h6>}</Translation>
                                <SearchSelect onChange={this.handleInputChange}
                                    value={this.state.debtorId}
                                    name="debtorId" placeholder="รายการ"
                                    queryObject={this.props.contractServiceQuery.contactViewer.allContact.edges}
                                    keyOfValue="id" require={true}
                                    keyOfLabel="refNumber:name" />
                            </div>

                            <div className="col-sm-3">
                                <Translation>{t => <h6>{t("contract_service:Category")}</h6>}</Translation>
                                <SearchSelect onChange={this.handleInputChange}
                                    value={this.state.chartOfAccountId}
                                    name="chartOfAccountId" placeholder="รายการ"
                                    queryObject={this.props.contractServiceQuery.viewer.allChartOfAccount.edges}
                                    keyOfValue="id" require={true}
                                    keyOfLabel="chartOfAccountCode:name" />
                            </div>

                        </div>

                        {/* row 3 */}
                        <div className="row mb-24">
                            <div className="col-sm-3">
                                <Translation>{t => <h6>{t("contract_service:Contract Number")}</h6>}</Translation>
                                <input type="text" className="form-control" name="contractNumber"
                                    onChange={this.handleInputChange} value={this.state.contractNumber}
                                />
                            </div>
                            <div className="col-sm-3">
                                <Translation>{t => <h6>{t("contract_service:Contract")}</h6>}</Translation>
                                <SearchSelect
                                    onChange={this.handleInputChange}
                                    value={this.state.contractForm}
                                    name="contractForm"
                                    placeholder="แบบสัญญา"
                                    queryObject={dataFilterContractServiceObjects()}
                                    keyOfValue="value"
                                    keyOfLabel="label"
                                />
                            </div>
                            {this.state.contractForm === "other" && <div className="col-2">
                                <input type="text" className="form-control"
                                    name="otherContractForm"
                                    value={this.setState.otherContractForm} 
                                    style={{ marginTop: 32 }}
                                    onChange={(e) => this.setState({otherContractForm: e.target.value})}
                                    required
                                    />
                            </div>}
                            <div className="col-2">
                                <Translation>{t => <h6>{t("contract_service:Contract Start Date")}</h6>}</Translation>

                                <DatePickerAdapter
                                    name="startContractDate" className="form-control mb-2 input-text"
                                    selected={this.state.startContractDate}
                                    onChange={this.handleInputChange}
                                    required={true}
                                />

                            </div>
                            <div className="col-2">
                                <Translation>{t => <h6>{t("contract_service:Contract Expiration Date")}</h6>}</Translation>
                                <DatePickerAdapter
                                    name="endContractDate" className="form-control mb-2 "
                                    selected={this.state.endContractDate}
                                    onChange={this.handleInputChange}
                                    required={true}
                                    minDate={this.state.startContractDate}
                                // disabled={true}
                                />

                            </div>
                        </div>

                        <div className="row col-12 mt-24">
                            <h4>{i18next.t("contractServiceView:Revenue lists")}</h4>
                        </div >
                        <div className="fade-up">
                            <table className="table table-contract table-hover mt-2">
                                <thead className="thead-light headTable">
                                    <tr className="tableList">
                                        <Translation>{t => <th>{t("contract_service:List")}</th>}</Translation>
                                        <Translation>{t => <th>{t("contract_service:Description")}</th>}</Translation>
                                        <Translation>{t => <th className="text-right">{t("contract_service:Unit")}</th>}</Translation>
                                        <Translation>{t => <th className="text-right">{t("contract_service:Price/Month (Baht)")}</th>}</Translation>
                                        <Translation>{t => <th className="text-right">{t("contract_service:Total (Baht)")}</th>}</Translation>
                                        <th />

                                    </tr>
                                </thead>

                                {/* transaction_list */}
                                <tbody>
                                    {
                                        this.state.contractRevenueList.map((transaction, index) => (
                                            <tr key={"transaction-" + index}>
                                                <td width="100">
                                                    <SearchSelect onChange={this.handleInputChange}
                                                        value={transaction.list_id}
                                                        name="chartOfAccount" placeholder="รายการ"
                                                        data-id={index} data-key="contractRevenueList"
                                                        queryObject={this.state.productViewer.allProduct.edges}
                                                        keyOfValue="id" require={true}
                                                        keyOfLabel="productCode:name" />
                                                </td>

                                                <td width="100">
                                                    <input type="text" className="form-control" data-id={index}
                                                        data-key="contractRevenueList" name="description"
                                                        value={transaction.description}
                                                        onChange={this.handleInputChange} required />
                                                </td>

                                                <td className="text-right" width="100">
                                                    <input type="text" className="form-control text-right" data-id={index}
                                                        data-key="contractRevenueList" name="num_period"
                                                        value={transaction.num_period}
                                                        onChange={this.handleInputChange} required />
                                                </td>

                                                <td className="text-right" width="50">
                                                    <input type="text" className="form-control text-right" data-id={index}
                                                        data-key="contractRevenueList" name="price_per_period"
                                                        value={transaction.price_per_period}
                                                        onChange={this.handleInputChange} required />
                                                </td>

                                                <td className="text-right" width="100">
                                                    {numberWithComma(transaction.total)}
                                                </td>

                                                <td width="20" className="align-middle text-center">
                                                    <InvoiceDelete transaction={transaction}
                                                        transaction_list={this.state.contractRevenueList}
                                                        setTransactionList={this.setTransactionList} />
                                                </td>

                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className="footer-table">
                                    <tr>
                                        <Translation>{t => <th width="100">{t("contract_service:All Total (Baht)")}</th>}</Translation>
                                        <th width="100" />
                                        <th width="100" />
                                        <th className="text-right" width="50">{numberWithComma(this.sumPriceperPeriod())}</th>
                                        <th className="text-right" width="100" >{numberWithComma(this.sumTotal())}</th>
                                        <th width="20" />

                                    </tr>
                                </tfoot>
                                {/* transaction_list */}

                            </table>
                            {this.state.productViewer?.allProduct.totalCount > 0 &&
                                <button type="button" className="btn add-button add" onClick={this.appendNewRow}>
                                    +{i18next.t("settingAll:Add list")}
                                </button>
                            }
                        </div>

                        <div className="row mt-5">
                            <div className="col d-flex align-items-center">
                                <div className="cursor attach-btn">
                                    <label
                                        className="text-blue"
                                        htmlFor="CustomFile">
                                        <img src={process.env.PUBLIC_URL + "/images/icons/attach-i.png"} alt="Document"
                                            className="mr-2" style={{ width: '20px', height: '20px' }}
                                        />
                                        {i18next.t("contractServiceView:Attrach file")}</label>
                                    <div className="upload-input custom-file cursor">
                                        {!this.state.disibleInput &&
                                            <FileInput
                                                id="CustomFile"
                                                appendFileInputRow={this.appendFileInputRow}
                                            />}
                                    </div>
                                </div>
                                <span
                                    className="alert-attach ml-4"
                                >{i18next.t("claimRequst:Support")}</span>
                            </div>
                        </div>
                        {this.state.fileAttachment &&
                            <div className="row ml-2 mt-5 d-flex align-items-center">
                                <img src={process.env.PUBLIC_URL + "/images/icons/pdf-icon.png"} alt="Document" style={{ width: '24px', height: '24px' }} />
                                <span className='text-blue' >{this.state.fileName}</span>
                            </div>
                        }

                        <div className="row mt-5">
                            <div className="col-12">
                                <h6>{i18next.t("contractServiceView:Note")}</h6>
                            </div>
                            <div className="col-6">
                                <textarea className="form-control"
                                    onChange={this.handleInputChange}
                                    name="remark"
                                    value={this.state.remark}
                                />
                            </div >
                            <div className="6" />
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h6>{i18next.t("contractServiceView:Advance notification")}</h6>
                            </div>
                            <div className="col-6 ">
                                <select className="form-control dropdown-custom-arrow"
                                    name="notiDay"
                                    value={this.state.notiDay}
                                    onChange={this.handleInputChange}
                                    style={{ fontFamily: 'Kanit' }}
                                >
                                    {/* <option value={0}>ระบุ</option> */}
                                    <option value={30}>30 {i18next.t("contractServiceView:Days")}</option>
                                    <option value={60}>60 {i18next.t("contractServiceView:Days")}</option>
                                    <option value={90}>90 {i18next.t("contractServiceView:Days")}</option>
                                </select>
                            </div >
                            <div className="6" />
                        </div>

                        <div className="row mt-5">
                            <div className="col-12">
                                <button className="btn-submit float-right" type="submit">
                                    <Translation>{t => <span>{t("contract_service:Save")}</span>}</Translation>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        )
    }
}

export default ContractServiceCreateForm;
