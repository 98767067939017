// import '../../document/styles/main.scss';
import './workSheet.scss';
import { Helmet } from "react-helmet";
import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import _ from 'lodash'
import WorkSheetTable from './workSheetTable'
import { H5 } from '../../components/Text/Text';
import { format } from 'date-fns';
import thLocale from "date-fns/locale/th";
import jwtDecode from 'jwt-decode'

const query = graphql`
    query wrapperWorkSheetQuery($startDate: DateTime, $endDate: DateTime, $betweenStartId: String, $betweenEndId: String) {
        selfProject {
            name
        }
        allActiveChartOfAccount(startDate: $startDate, endDate: $endDate, betweenStartId: $betweenStartId, betweenEndId: $betweenEndId) {
            edges {
              node {
                id
                chartOfAccountCode
                name
                totalDebit
                totalCredit
                totalHistoricalDebit
                totalHistoricalCredit
                totalPeriodDebit
                totalPeriodCredit
                balanceDebit
                balanceCredit
                balance
                update
              }
            }
        }
    }
`;

class wrapperBankDepositReport extends Component {

    constructor(props) {
        super(props);
        let month_before = new Date();
        month_before.setMonth(month_before.getMonth() - 1);
        this.state = {
            start_date: month_before,
            end_date: new Date(),
            chart_of_account_start: "",
            chart_of_account_end: "",
            project_name: '',
            allActiveChartOfAccount: [],
            loading: false,
            firstData: [],
            secondData: [],
            number_pang:0,
            printBy: "",
        };
    }

    getData = async () => {
        this.setState({ loading: true })
        let { chart_of_account_end, chart_of_account_start, end_date, start_date } = this.props.match.params
        let firstData = [], secondData = [] , number_pang = 1 , total_len = 0
        let res = await fetchQuery(environment, query, {
            startDate: new Date(start_date), endDate: new Date(end_date),
            betweenStartId: chart_of_account_start == 'all' ? '' : chart_of_account_start, betweenEndId: chart_of_account_end == 'all' ? '' : chart_of_account_end
        })


        let jwtDecodes = jwtDecode(window.localStorage.getItem('token'));
        this.setState({ printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}` });


        let noManualJV = "{\"update_debit\": \"0.0\", \"update_credit\": \"0.0\", \"before_update_debit\": \"0\", \"before_update_credit\": \"0\"}";

        let allActiveChartOfAccounts = res.allActiveChartOfAccount.edges.filter((n) => {
            return ((n.node.balanceCredit !== 0 || n.node.balanceDebit !== 0) && n.node.update !== noManualJV)
          })

        if (res) {
            if (allActiveChartOfAccounts.length > 0) {
                firstData = _.slice(allActiveChartOfAccounts, 0, 13)
                if (allActiveChartOfAccounts.length > 13) {
                    secondData = _.slice(allActiveChartOfAccounts, 13, allActiveChartOfAccounts.length)
                }
                //หาหน้าทั้งหมด
                total_len = firstData.length + secondData.length
                let firsttime = true
                while( total_len > number_pang){
                    //หน้าแรก - 13 แถว จากนั้น ลบที่ 16
                    if (firsttime){
                        total_len = total_len - 13
                        firsttime = false
                    }
                    else{
                        total_len = total_len - 16
                    }
                    //ถ้าติดลบไม่ต้องนับว่าหน้า
                    if(total_len >= 0){
                        number_pang += 1
                    }
                }
                this.setState({ firstData, secondData, number_pang })
            }
            this.setState({ allActiveChartOfAccount: allActiveChartOfAccounts || [], project_name: res.selfProject?.name, loading: false })
        } else {
            console.log('error ');
            this.setState({ loading: false })
        }
    }

    async componentDidMount() { await this.getData() }

    print = () => {
        window.print();
    }

    render() {
        let unadjusted_trial_balance_debit = 0, unadjusted_trial_balance_credit = 0, adjusted_entry_debit = 0, adjusted_entry_credit = 0, adjusted_trial_balance_debit = 0,
            adjusted_trial_balance_credit = 0, income_expenses_debit = 0, income_expenses_credit = 0, statement_of_financial_position_debit = 0, statement_of_financial_position_credit = 0

        this.state.allActiveChartOfAccount.map((chart) => {
            let code = _.get(chart, "node.chartOfAccountCode").slice(0, 1)
            let balanceDebit = _.get(chart, "node.balanceDebit")
            let balanceCredit = _.get(chart, "node.balanceCredit")
            let balance = _.get(chart, "node.balance")
            let update = JSON.parse(_.get(chart, "node.update"))

            balanceDebit > balanceCredit ? adjusted_trial_balance_debit += balance : adjusted_trial_balance_credit += balance

            if (code === '4' || code === "5") {
                balanceDebit > balanceCredit ? income_expenses_debit += balance : income_expenses_credit += balance
            } else if (code === '1' || code === "2" || code === "3") {
                balanceDebit > balanceCredit ? statement_of_financial_position_debit += balance : statement_of_financial_position_credit += balance
            }

            adjusted_entry_debit += parseFloat(update.update_debit)
            adjusted_entry_credit += parseFloat(update.update_credit)
            unadjusted_trial_balance_debit += parseFloat(update.before_update_debit)
            unadjusted_trial_balance_credit += parseFloat(update.before_update_credit)
            return chart
        })


        return (
            <React.Fragment >
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            background-color: #FAFAFA;
                          }
                        
                          * {
                            box-sizing: border-box;
                            -moz-box-sizing: border-box;
                          }
                        
                          .page {
                            width: 297mm;
                            min-height: 210mm;
                            padding: 5mm;
                            margin: 10mm auto;
                            border: 1px #D3D3D3 solid;
                            border-radius: 2px;
                            background: white;
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                            position: relative;
                          }
                          .pageNumber{
                            position: fixed;
                            bottom: 0;
                          }
                        
                          @page {
                            size: landscape;
                            margin: 0;
                          }
                          @media print {
                            html, body {
                              width: 297mm;
                              height: 210mm;
                            }

                            @page: last{
                                .pageNumber {
                                    display: block;
                                }
                            }
                            .page {
                              margin: 0;
                              border: initial;
                              border-radius: initial;
                              width: initial;
                              min-height: initial;
                              box-shadow: initial;
                              background: initial;
                              page-break-after: always;
                              position: relative;
                            }
                            .pageNumber{
                                position: fixed;
                                bottom: 0;
                            }
                          }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>Interest Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu " id='work-sheet-report-main'>
                    <div className="logo"><img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" /></div>
                    <div className="print" onClick={this.print}>PRINT</div>
                </div>
                <div className='work-sheet-report-main'>

                    {!this.state.loading && <div className="container-fluid box">
                        <div className="page" style={{position: 'relative'}}>
                            <div className="">
                                {/* <div className="logo ml-1" style={{ position: 'absolute' }}>{project_logo !== '' && <img src={project_logo} alt="silverman" style={{ width: '100px' }} />}</div> */}
                                <div className="mb-1">
                                    <H5 className="text-center">{this.state.project_name}</H5>
                                    <H5 className="text-center">กระดาษทำการ</H5>
                                    <H5 className="text-center">ณ วันที่ {format(this.props.match.params.end_date, " DD MMMM YYYY ", { locale: thLocale, })}</H5>
                                </div>
                                {this.state.firstData.length > 0 &&
                                    <WorkSheetTable
                                        // key={index}
                                        loading={this.state.loading}
                                        allActiveChartOfAccount={this.state.firstData}
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}
                                        report
                                        page={1}
                                        // pageAll={Math.ceil(((this.state.firstData.length+this.state.secondData.length) - 15) / 16)}
                                        // logo={project_logo}
                                        lastPage={this.state.allActiveChartOfAccount.length <= 13 ? true : false}
                                        sum={{
                                            unadjusted_trial_balance_debit,
                                            unadjusted_trial_balance_credit,
                                            adjusted_entry_debit,
                                            adjusted_entry_credit,
                                            adjusted_trial_balance_debit,
                                            adjusted_trial_balance_credit,
                                            income_expenses_debit,
                                            income_expenses_credit,
                                            statement_of_financial_position_debit,
                                            statement_of_financial_position_credit
                                        }}
                                    />}
                                       <div className='footer addSpaceTopFirstPage'>
                                            <div className='row'>
                                                <div className='col-5' />
                                                <div className='col-2 text-center'>
                                                    <p className='f14 pb-0 mb-0'>{1}/{this.state.number_pang}</p>
                                                </div>
                                                <div className='col-5 text-right'>
                                                    <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {this.state.printBy}  วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                                </div>
                                            </div>

                                        </div>
                            </div>
                        </div>

                    </div>}

                    {!this.state.loading && _.chunk(this.state.secondData, 16).map((transaction, index) => {
                        if (index >= 0) {
                            return (
                                <div className="container-fluid box p-3" key={"WorkSheetTable" + index} >
                                    <div className="page" style={{ padding: '10mm 5mm 0 5mm' , position: 'relative' }}>
                                        <WorkSheetTable
                                            key={index}
                                            loading={this.state.loading}
                                            allActiveChartOfAccount={transaction}
                                            start_date={this.state.start_date}
                                            end_date={this.state.end_date}
                                            report
                                            page={index + 1}
                                            // pageAll={Math.ceil(((this.state.firstData.length+this.state.secondData.length) - 15) / 16)}
                                            // logo={project_logo}
                                            lastPage={(index + 1 === _.chunk(this.state.secondData, 16).length || _.chunk(this.state.secondData, 16).length === 1) ? true : false}
                                            sum={{
                                                unadjusted_trial_balance_debit,
                                                unadjusted_trial_balance_credit,
                                                adjusted_entry_debit,
                                                adjusted_entry_credit,
                                                adjusted_trial_balance_debit,
                                                adjusted_trial_balance_credit,
                                                income_expenses_debit,
                                                income_expenses_credit,
                                                statement_of_financial_position_debit,
                                                statement_of_financial_position_credit
                                            }}
                                        />
                                       <div className='footer addSpaceTop'>
                                            <div className='row'>
                                                <div className='col-5' />
                                                <div className='col-2 text-center'>
                                                    <p className='f14 pb-0 mb-0'>{index + 2}/{this.state.number_pang}</p>
                                                </div>
                                                <div className='col-5 text-right'>
                                                    <p className='f14 pb-0 mb-0'>ผู้พิมพ์ {this.state.printBy}  วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )
                        }else{
                            return <></>
                        }
                    })}

                </div>



            </React.Fragment>
        );
    }
}

export default wrapperBankDepositReport;
