import React, { Component } from 'react'
import _ from "lodash"
import format from 'date-fns/format';
import numberWithComma from "../../../../libs/numberWithComma";
import $ from "jquery"

export default class budgetLastYearCompareWithMonth extends Component {

  constructor(props){
    super(props)

    this.state = { widthTable1: 0 }
  }

  summaryRevenueYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumRevenue_summaryYear_total_budget = 0;
    let sumRevenue_total_happend = 0;
    let sumRevenue_total_budget = 0;
    let sumRevenue_total_month = 0;

    sumRevenue_summaryYear_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
    sumRevenue_total_happend = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
    sumRevenue_total_budget = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.total_budget || 0))
    sumRevenue_total_month = _.sumBy(this.props.state.lists[0]?.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))

    switch (codeValue) {
      case "4000-00 summaryYear_total_budget":
        result = sumRevenue_summaryYear_total_budget
        break;
      case "4000-00 total_happend":
        result = sumRevenue_total_happend
        break;
      case "4000-00 total_budget":
        result = sumRevenue_total_budget
        break;
      case `4000-00 total_month[${indexMonth}]`:
        result = sumRevenue_total_month
        break;

      default:
        break;
    }
    return result;
  }

  summaryExpenseYear = (codeValue, indexMonth) => {
    let result = 0;
    let sumExpense_otherExpenses_summaryYear_total_budget = 0;
    let sumExpense_otherExpenses_total_happend = 0;
    let sumExpense_otherExpenses_total_budget = 0;
    let sumExpense_otherExpenses_total_month = 0;

    let sumExpense_items_summaryYear_total_budget = 0;
    let sumExpense_items_total_happend = 0;
    let sumExpense_items_total_budget = 0;
    let sumExpense_items_total_month = 0;

    if (codeValue.substring(0, 7) === "5800-00") {
      _.forIn(this.props.state.lists[1].children[8].children, function (item) {
        sumExpense_otherExpenses_summaryYear_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_budget || 0))
        sumExpense_otherExpenses_total_happend += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.summaryYear.total_happend || 0))
        sumExpense_otherExpenses_total_budget += _.sumBy(item.children, (item_child_child) => parseFloat(item_child_child.data.total_budget || 0))
        sumExpense_otherExpenses_total_month += _.sumBy(item.children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      })
    } else {
      if (codeValue.substring(0, 7) === "5100-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[0].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5200-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[1].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5300-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[2].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5310-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[3].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5400-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[4].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5500-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[5].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5600-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[6].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }
      if (codeValue.substring(0, 7) === "5700-00") {
        sumExpense_items_summaryYear_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_budget || 0))
        sumExpense_items_total_happend = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.summaryYear.total_happend || 0))
        sumExpense_items_total_budget = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.total_budget || 0))
        sumExpense_items_total_month = _.sumBy(this.props.state.lists[1].children[7].children, (o) => parseFloat(o.data.month_budget[indexMonth].budget || 0))
      }

    }

    switch (codeValue) {
      case "5800-00 summaryYear_total_budget":
        result = sumExpense_otherExpenses_summaryYear_total_budget
        break;
      case "5800-00 total_happend":
        result = sumExpense_otherExpenses_total_happend
        break;
      case "5800-00 total_budget":
        result = sumExpense_otherExpenses_total_budget
        break;
      case "5800-00 total_month":
        result = sumExpense_otherExpenses_total_month
        break;
      case `${codeValue.substring(0, 7)} summaryYear_total_budget`:
        result = sumExpense_items_summaryYear_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_happend`:
        result = sumExpense_items_total_happend
        break;
      case `${codeValue.substring(0, 7)} total_budget`:
        result = sumExpense_items_total_budget
        break;
      case `${codeValue.substring(0, 7)} total_month`:
        result = sumExpense_items_total_month
        break;

      default:
        break;
    }
    return result;
  }

  checkMonth(monthInput) {
    let resultMonth = ""
    let monthFormat = format(new Date(monthInput), "MMM")
    let yearFormat = format(new Date(monthInput), "YY")

    switch (monthFormat) {
      case "Jan":
        resultMonth = "ม.ค."
        break;
      case "Feb":
        resultMonth = "ก.พ."
        break;
      case "Mar":
        resultMonth = "มี.ค."
        break;
      case "Apr":
        resultMonth = "เม.ย."
        break;
      case "May":
        resultMonth = "พ.ค."
        break;
      case "Jun":
        resultMonth = "มิ.ย."
        break;
      case "Jul":
        resultMonth = "ก.ค."
        break;
      case "Aug":
        resultMonth = "ส.ค."
        break;
      case "Sep":
        resultMonth = "ก.ย."
        break;
      case "Oct":
        resultMonth = "ต.ค."
        break;
      case "Nov":
        resultMonth = "พ.ย."
        break;
      case "Dec":
        resultMonth = "ธ.ค."
        break;

      default:
        break;
    }
    return resultMonth + yearFormat
  }

  componentDidMount(){
    let width = $('table');
    this.setState({widthTable1: width.width()})
  }
  componentDidUpdate(prevProps, prevState){
    if(prevState.widthTable1 !== this.state.widthTable1){
      let width = $('table');
      this.setState({widthTable1: width.width()})
    }
  }

  toggleHandleScroll = (scroll) => {
    let scroller = document.querySelector('.table-responsive2');
    let table = document.querySelector('.table-responsive');

    if(scroll === "table-responsive2"){
      table.scrollTo(scroller.scrollLeft,0);
    }if(scroll === "table-responsive"){
      scroller.scrollTo(table.scrollLeft,0);
    }
  }

  render() {

    let last_index = 0;
    let last_index_child = 0;
    let summaryExpenseTotalMonth = [];
    let summaryExpenseTotalMonthDiffSumRevenue = [];
    let expenseDiffRevenueSummaryYearTotalBudget = 0;
    let expenseDiffRevenueTotalHappend = 0;
    let expenseDiffRevenueTotalBudget = 0;

    return (
      <div className="fade-up" id="parcel">
        <div className="table-responsive2" style={{overflowX: "auto", width: "100%", display: "block"}} onScroll={() => this.toggleHandleScroll("table-responsive2")}>
          <div className="table" style={{width: `${this.state.widthTable1}px`,height: "20px"}}></div>
        </div>
        <div className="table-responsive" onScroll={() => this.toggleHandleScroll("table-responsive")}>
          <table className="table table-hover">
            <thead>
              <tr>
                <th rowSpan={2} className="text-center">
                  ลำดับ
                </th>
                <th rowSpan={2} className="text-center">
                  รายการ
                </th>
                <th
                  colSpan={2}
                  className="text-center"
                >
                  งบประมาณปี {this.props.state.temp_year.getFullYear() - 1}
                </th>
                <th
                  colSpan={this.props.state.lists[0].children[0].data.month_budget.length}
                  className="text-center"
                >
                  งบประมาณปี {format(this.props.state.temp_start_date, "YYYY")}
                </th>
                <th rowSpan={2} className="text-center">
                  งบประมาณรวม
                </th>
              </tr>
              <tr className="border-top">
                <th className="text-center">งบประมาณ</th>
                <th className="text-center">เกิดจริง</th>
                {this.props.state.lists[0].children[0].data.month_budget.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="text-center"
                    >{this.checkMonth(item.month_date)}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {_.map(this.props.state.lists[0]?.children, (item, index) => {
                ++last_index;
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="text-center">{last_index}</td>
                      <td
                        style={{
                          paddingLeft: 50,
                          minWidth: 300,
                        }}
                      >
                        {index == 0 && (
                          <div style={{ marginLeft: -32 }}>
                            รายรับ
                          </div>
                        )}
                        {item.data.name}
                      </td>
                      <td
                        height={24}
                        style={{ minWidth: 125}}
                      >
                        <input
                          disabled={item.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center"
                          style={{ maxHeight: 24 }}
                          value={item.data.summaryYear.total_budget || ""}
                          onChange={this.props.handleChangeInput}
                          name={`lists[0].children[${index}].data.summaryYear.total_budget`}
                        />
                      </td>
                      <td
                        height={24}
                        style={{ minWidth: 125}}
                        className="td-red-scale"
                      >
                        <input
                          disabled
                          type="number"
                          className="form-control text-center td-red-scale"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.summaryYear?.total_happend || ""
                          }
                        />
                      </td>
                      {_.map(item.data.month_budget, (month, index_month) => {
                        return (
                          <td
                            key={index_month}
                            height={24}
                            style={{ minWidth: 120 }}
                          >
                            <input
                              disabled={this.props.disabledApprove()}
                              type="number"
                              className="form-control text-center"
                              style={{ maxHeight: 24 }}
                              value={month.budget || ""}
                              name={`lists[0].children[${index}].data.month_budget[${index_month}].budget`}
                              onChange={(e) =>
                                this.props.handleChangeInputMount(e,
                                  `lists[0].children[${index}].data.month_budget`, `lists[0].children[${index}].data.total_budget`, "month_budget")
                              }
                            />
                          </td>
                        )
                      })}
                      <td style={{ minWidth: 198 }}>
                        <input
                          disabled={this.props.disabledApprove()}
                          type="number"
                          className="form-control text-center"
                          style={{ maxHeight: 24 }}
                          value={
                            item.data?.total_budget || ""
                          }
                          name={`lists[0].children[${index}].data.total_budget`}
                          onChange={(e) =>
                            this.props.handleChangeInputMount(e,
                              `lists[0].children[${index}].data.month_budget`, `lists[0].children[${index}].data.month_budget`, "total_budget")
                          }
                        />
                      </td>
                    </tr>
                    {index === this.props.state.lists[0].children.length - 1 && (
                      <tr className="td-sum ">
                        <td></td>
                        <td
                          style={{
                            paddingLeft: 50,
                            minWidth: 300,
                          }}
                        >
                          รวมรายรับ
                        </td>
                        <td
                          height={24}
                          className="text-center"
                        >
                          {this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) !== 0 ?
                            numberWithComma(this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0).toFixed(2)) : "-"} 
                        </td>
                        <td
                          height={24}
                          className="text-center"
                        >
                          {this.summaryRevenueYear("4000-00 total_happend", 0) !== 0 ?
                            numberWithComma(this.summaryRevenueYear("4000-00 total_happend", 0).toFixed(2)) : "-"} 
                        </td>
                        {this.props.state.lists[0].children[0].data.month_budget.map((item, index) => {

                          summaryExpenseTotalMonthDiffSumRevenue[index] = this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) || 0

                          return (
                            <td
                              key={index}
                              height={24}
                              className="text-center"
                            >
                              {this.summaryRevenueYear(`4000-00 total_month[${index}]`, index) !== 0 ?
                                numberWithComma(this.summaryRevenueYear(`4000-00 total_month[${index}]`, index).toFixed(2)) : "-"} 
                            </td>
                          );
                        })}
                        <td 
                          className="text-center">
                          {this.summaryRevenueYear("4000-00 total_budget", 0) !== 0 ?
                            numberWithComma(this.summaryRevenueYear("4000-00 total_budget", 0).toFixed(2)) : "-"} 
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
              {_.map(this.props.state.lists[1]?.children, (item, index_code) => {
                expenseDiffRevenueSummaryYearTotalBudget += this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0)
                expenseDiffRevenueTotalHappend += this.summaryExpenseYear(`${item.data.code} total_happend`, 0)
                expenseDiffRevenueTotalBudget += this.summaryExpenseYear(`${item.data.code} total_budget`, 0)

                return (
                  <React.Fragment key={index_code}>
                    <tr>
                      <td />
                      <td>
                        <div className="ml-2">
                          {index_code === 0 && <div>รายจ่าย</div>}
                          <div>{item.data.name}</div>
                        </div>
                      </td>
                    </tr>
                    {_.map(item.children, (item_child, index) => {
                      if (index_code !== 8) { ++last_index }
                      return (
                        <React.Fragment key={index}>
                          {item_child.children?.length > 0 ?
                            <React.Fragment>
                              <tr>
                                <td />
                                <td
                                  style={{
                                    paddingLeft: 50,
                                    minWidth: 300,
                                  }}
                                >
                                  {item_child.data.name}
                                </td>
                              </tr>
                              {_.map(item_child?.children, (item_child_child, index_child_child) => {
                                ++last_index_child
                                return (
                                  <React.Fragment key={index_child_child}>
                                    <tr>
                                      <td className="text-center">
                                        {last_index + last_index_child}
                                      </td>
                                      <td
                                        style={{
                                          paddingLeft: 100,
                                          minWidth: 300,
                                        }}
                                      >
                                        {item_child_child.data.name}
                                      </td>
                                      <td
                                        height={24}>
                                        <input
                                          disabled={item_child_child.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center"
                                          style={{ maxHeight: 24 }}
                                          value={
                                            item_child_child.data?.summaryYear?.total_budget || ""
                                          }
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.summaryYear.total_budget`}
                                          onChange={this.props.handleChangeInput}
                                        />
                                      </td>
                                      <td
                                        height={24}
                                        className="td-red-scale"
                                      >
                                        <input
                                          disabled
                                          type="number"
                                          className="form-control text-center td-red-scale"
                                          style={{ maxHeight: 24 }}
                                          value={
                                            item_child_child.data?.summaryYear?.total_happend || ""
                                          }
                                        />
                                      </td>
                                      {_.map(item_child_child.data.month_budget, (month, index_month) => {
                                        return (
                                          <td
                                            key={index_month}
                                            height={24}
                                          >
                                            <input
                                              disabled={this.props.disabledApprove()}
                                              type="number"
                                              className="form-control text-center "
                                              style={{ maxHeight: 24 }}
                                              value={month.budget || ""}
                                              name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget[${index_month}].budget`}
                                              onChange={(e) =>
                                                this.props.handleChangeInputMount(e,
                                                  `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`,
                                                  `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.total_budget`, "month_budget"
                                                )}
                                            />
                                          </td>
                                        );
                                      })}
                                      <td >
                                        <input
                                          disabled={this.props.disabledApprove()}
                                          type="number"
                                          className="form-control text-center"
                                          style={{ maxHeight: 24 }}
                                          value={
                                            item_child_child.data?.total_budget || ""
                                          }
                                          name={`lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.total_budget`}
                                          onChange={(e) =>
                                            this.props.handleChangeInputMount(e,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`,
                                              `lists[1].children[${index_code}].children[${index}].children[${index_child_child}].data.month_budget`, "total_budget"
                                            )}
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              })}
                            </React.Fragment> :
                            <React.Fragment>
                              <tr>
                                <td className="text-center">
                                  {last_index}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 50,
                                    minWidth: 300,
                                  }}
                                >
                                  {item_child.data.name}
                                </td>
                                <td
                                  height={24}
                                >
                                  <input
                                    disabled={item_child.data?.summaryYear?.total_budget_check || this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center "
                                    style={{ maxHeight: 24 }}
                                    onChange={this.props.handleChangeInput}
                                    value={
                                      item_child.data?.summaryYear?.total_budget || ""
                                    }
                                    name={`lists[1].children[${index_code}].children[${index}].data.summaryYear.total_budget`}
                                  />
                                </td>
                                <td
                                  height={24}
                                  className="td-red-scale"
                                >
                                  <input
                                    disabled
                                    type="number"
                                    style={{ maxHeight: 24 }}
                                    className="form-control text-center td-red-scale"
                                    value={
                                      item_child.data?.summaryYear?.total_happend || ""
                                    }
                                  />
                                </td>
                                {_.map(item_child.data.month_budget, (month, index_month) => {
                                  return (
                                    <td
                                      key={index_month}
                                      height={24}
                                    >
                                      <input
                                        disabled={this.props.disabledApprove()}
                                        type="number"
                                        className="form-control text-center "
                                        style={{ maxHeight: 24 }}
                                        value={month.budget || ""}
                                        name={`lists[1].children[${index_code}].children[${index}].data.month_budget[${index_month}].budget`}
                                        onChange={(e) =>
                                          this.props.handleChangeInputMount(e,
                                            `lists[1].children[${index_code}].children[${index}].data.month_budget`, `lists[1].children[${index_code}].children[${index}].data.total_budget`, "month_budget")
                                        }
                                      />
                                    </td>
                                  );
                                })}
                                <td>
                                  <input
                                    disabled={this.props.disabledApprove()}
                                    type="number"
                                    className="form-control text-center"
                                    style={{ maxHeight: 24 }}
                                    value={
                                      item_child.data?.total_budget || ""
                                    }
                                    name={`lists[1].children[${index_code}].children[${index}].data.total_budget`}
                                    onChange={(e) =>
                                      this.props.handleChangeInputMount(e,
                                        `lists[1].children[${index_code}].children[${index}].data.month_budget`, `lists[1].children[${index_code}].children[${index}].data.month_budget`, "total_budget")
                                    }
                                  />
                                </td>
                              </tr>
                            </React.Fragment>
                          }
                        </React.Fragment>
                      );
                    })}

                    <tr className="td-sum ">
                      <td></td>
                      <td
                        style={{
                          paddingLeft: 50,
                          minWidth: 300,
                        }}
                      >
                        รวม{item.data.name}
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0) !== 0 ?
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} summaryYear_total_budget`, 0).toFixed(2)) : "-"} 
                      </td>
                      <td
                        height={24}
                        className="text-center"
                      >
                        {this.summaryExpenseYear(`${item.data.code} total_happend`, 0) !== 0 ?
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} total_happend`, 0).toFixed(2)) : "-"} 
                      </td>
                      {_.map(this.props.state.lists[0].children[0].data.month_budget, (item_month, index_month) => {

                        typeof(summaryExpenseTotalMonth[index_month]) !== "number"
                          ? summaryExpenseTotalMonth[index_month] = this.summaryExpenseYear(`${item.data.code} total_month`, index_month)
                            : summaryExpenseTotalMonth[index_month] += this.summaryExpenseYear(`${item.data.code} total_month`, index_month);

                        return (
                          <td
                            key={index_month}
                            height={24}
                            className="text-center"
                          >
                            {this.summaryExpenseYear(`${item.data.code} total_month`, index_month) !== 0 ?
                              numberWithComma(this.summaryExpenseYear(`${item.data.code} total_month`, index_month).toFixed(2)) : "-"} 
                          </td>
                        );
                      })}
                      <td className="text-center">
                        {this.summaryExpenseYear(`${item.data.code} total_budget`, 0) !== 0 ?
                          numberWithComma(this.summaryExpenseYear(`${item.data.code} total_budget`, 0).toFixed(2)) : "-"} 
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              <tr className="td-sum2 ">
                <td></td>
                <td
                  style={{
                    paddingLeft: 50,
                    minWidth: 300,
                  }}
                >
                  รวมรายจ่าย
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {expenseDiffRevenueSummaryYearTotalBudget !== 0 ?
                    numberWithComma(expenseDiffRevenueSummaryYearTotalBudget.toFixed(2)) : "-"} 
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {expenseDiffRevenueTotalHappend !== 0 ?
                    numberWithComma(expenseDiffRevenueTotalHappend.toFixed(2)) : "-"} 
                </td>
                {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {

                  summaryExpenseTotalMonthDiffSumRevenue[index] += (-summaryExpenseTotalMonth[index])
                  
                  return (
                    <td
                      key={index}
                      height={24}
                      className="text-center"
                    >
                      {summaryExpenseTotalMonth.length > 0 ?
                        summaryExpenseTotalMonth[index] !== 0 ?
                          numberWithComma(summaryExpenseTotalMonth[index].toFixed(2)) : "-" : "-"} 
                    </td>
                  );
                })}
                <td className="text-center">
                  {expenseDiffRevenueTotalBudget !== 0 ?
                    expenseDiffRevenueTotalBudget.toFixed(2) : "-"} 
                </td>
              </tr>
              <tr className="td-sum3 ">
                <td></td>
                <td
                  style={{
                    paddingLeft: 50,
                    minWidth: 300,
                  }}
                >
                  รายรับสูง(ต่ำ)กว่ารายจ่ายประจำงวด
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget) !== 0
                    ? numberWithComma((this.summaryRevenueYear("4000-00 summaryYear_total_budget", 0) - expenseDiffRevenueSummaryYearTotalBudget).toFixed(2)) : "-"} 
                </td>
                <td
                  height={24}
                  className="text-center"
                >
                  {(this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend) !== 0
                    ? numberWithComma((this.summaryRevenueYear("4000-00 total_happend", 0) - expenseDiffRevenueTotalHappend).toFixed(2)) : "-"} 
                </td>
                {_.map(this.props.state.lists[0].children[0].data.month_budget, (item, index) => {
                  return (
                    <td
                      key={index}
                      height={24}
                      className="text-center"
                    >
                      {summaryExpenseTotalMonthDiffSumRevenue.length > 0 ?
                        summaryExpenseTotalMonthDiffSumRevenue[index] !== 0 ?
                          numberWithComma(summaryExpenseTotalMonthDiffSumRevenue[index].toFixed(2)) : "-" : "-"} 
                    </td>
                  );
                })}
                <td className="text-center">
                  {(this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget) !== 0
                    ? numberWithComma((this.summaryRevenueYear("4000-00 total_budget", 0) - expenseDiffRevenueTotalBudget).toFixed(2)) : "-"} 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
