/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DebsFreeCertificationRequestStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "PAID" | "VOID" | "%future added value";
export type DebsFreeCertificationStatus = "ACTIVE" | "DRAFT" | "VOID" | "%future added value";
export type debtFreeCertificationListQueryVariables = {|
  status?: ?$ReadOnlyArray<?string>,
  startDate?: ?any,
  endDate?: ?any,
  search?: ?string,
  first?: ?number,
  last?: ?number,
|};
export type debtFreeCertificationListQueryResponse = {|
  +allDebsFreeCertification: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +status: DebsFreeCertificationStatus,
        +statusPrint: boolean,
        +dateStamp: boolean,
        +printDate: ?any,
        +dueDate: ?any,
        +creatorVoid: ?string,
        +voidDate: ?any,
        +voidNote: ?string,
        +debsFreeCertificationRequest: {|
          +id: string,
          +docNumber: string,
          +added: any,
          +updated: any,
          +contact: {|
            +id: string,
            +name: string,
            +firstName: string,
            +lastName: string,
          |},
          +requestingDate: ?any,
          +creatorApprove: ?string,
          +approvalDate: ?any,
          +transactionDebtType: ?string,
          +tenant: {|
            +firstName: string,
            +lastName: ?string,
          |},
          +receive: ?{|
            +id: string,
            +docNumber: string,
          |},
          +otherReceive: ?{|
            +id: string,
            +docNumber: string,
          |},
          +status: DebsFreeCertificationRequestStatus,
        |},
      |}
    |}>,
  |}
|};
export type debtFreeCertificationListQuery = {|
  variables: debtFreeCertificationListQueryVariables,
  response: debtFreeCertificationListQueryResponse,
|};
*/


/*
query debtFreeCertificationListQuery(
  $status: [String]
  $startDate: DateTime
  $endDate: DateTime
  $search: String
  $first: Int
  $last: Int
) {
  allDebsFreeCertification(status: $status, startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last) {
    totalCount
    edges {
      node {
        id
        docNumber
        status
        statusPrint
        dateStamp
        printDate
        dueDate
        creatorVoid
        voidDate
        voidNote
        debsFreeCertificationRequest {
          id
          docNumber
          added
          updated
          contact {
            id
            name
            firstName
            lastName
          }
          requestingDate
          creatorApprove
          approvalDate
          transactionDebtType
          tenant {
            firstName
            lastName
            id
          }
          receive {
            id
            docNumber
          }
          otherReceive {
            id
            docNumber
          }
          status
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "endDate"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startDate"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "statusPrint",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateStamp",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "printDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorVoid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidNote",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactNode",
  "kind": "LinkedField",
  "name": "contact",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v20/*: any*/),
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestingDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creatorApprove",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approvalDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionDebtType",
  "storageKey": null
},
v27 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "ReceiveNode",
  "kind": "LinkedField",
  "name": "receive",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "OtherReceiveNode",
  "kind": "LinkedField",
  "name": "otherReceive",
  "plural": false,
  "selections": (v27/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "debtFreeCertificationListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "DebsFreeCertificationNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertification",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestNode",
                    "kind": "LinkedField",
                    "name": "debsFreeCertificationRequest",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "debtFreeCertificationListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "DebsFreeCertificationNodeConnection",
        "kind": "LinkedField",
        "name": "allDebsFreeCertification",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestNode",
                    "kind": "LinkedField",
                    "name": "debsFreeCertificationRequest",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27910b1bfbcd1f07071ef7694b589cd3",
    "id": null,
    "metadata": {},
    "name": "debtFreeCertificationListQuery",
    "operationKind": "query",
    "text": "query debtFreeCertificationListQuery(\n  $status: [String]\n  $startDate: DateTime\n  $endDate: DateTime\n  $search: String\n  $first: Int\n  $last: Int\n) {\n  allDebsFreeCertification(status: $status, startDate: $startDate, endDate: $endDate, search: $search, first: $first, last: $last) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        status\n        statusPrint\n        dateStamp\n        printDate\n        dueDate\n        creatorVoid\n        voidDate\n        voidNote\n        debsFreeCertificationRequest {\n          id\n          docNumber\n          added\n          updated\n          contact {\n            id\n            name\n            firstName\n            lastName\n          }\n          requestingDate\n          creatorApprove\n          approvalDate\n          transactionDebtType\n          tenant {\n            firstName\n            lastName\n            id\n          }\n          receive {\n            id\n            docNumber\n          }\n          otherReceive {\n            id\n            docNumber\n          }\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '30f26a06cc03d249643523a95d34eafe';

module.exports = node;
