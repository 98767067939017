import React, {Component} from 'react';
import PrivateRoute from '../../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../../components/noMatch";
import UnknownReceiveList from './unknownReceiveList';
import UnknownReceiveCreate from './unknownReceiveCreate';
import UnknownReceiveDetail from './unknownReceiveDetail';
import WrapperUnknownReceive from './wrapperUnknownReceive';


class UnknownReceive extends Component {

    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list/:status`} component={UnknownReceiveList}/>
                <PrivateRoute exact path={`${this.props.match.url}/create`} component={UnknownReceiveCreate}/>
                <PrivateRoute exact path={`${this.props.match.url}/detail/:id`} component={UnknownReceiveDetail}/>
                <PrivateRoute exact path={`${this.props.match.url}/document/unknownReceive`} component={WrapperUnknownReceive}/>
                <Route component={NoMatch}/>
            </Switch>

        );
    }
}

export default UnknownReceive;