/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type navigationReceiveQueryVariables = {||};
export type navigationReceiveQueryResponse = {|
  +getNotificationAccounting: ?$ReadOnlyArray<?{|
    +type: ?string,
    +count: ?number,
  |}>
|};
export type navigationReceiveQuery = {|
  variables: navigationReceiveQueryVariables,
  response: navigationReceiveQueryResponse,
|};
*/


/*
query navigationReceiveQuery {
  getNotificationAccounting {
    type
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationType",
    "kind": "LinkedField",
    "name": "getNotificationAccounting",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "navigationReceiveQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "navigationReceiveQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1e9c5543f25556c4a5b4ee92e34defef",
    "id": null,
    "metadata": {},
    "name": "navigationReceiveQuery",
    "operationKind": "query",
    "text": "query navigationReceiveQuery {\n  getNotificationAccounting {\n    type\n    count\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1c64780b72dc830105a3841d719cf7d0';

module.exports = node;
