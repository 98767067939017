import './styles/receiveCreateForm.scss'
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import { Translation } from "react-i18next";

import numberWithComma from "../../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import ReceiveForm from "../../../receive/receiveForm";
import ReceiveTransactionList from "./receiveTransactionList";
import { graphql } from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";
import SearchSelect from "../../../../libs/searchSelect";
import i18n from "i18next";
import upload from '../../../../libs/upload';
import _ from "lodash"
import fetchInvoice from './fetchInvoice'
import i18next from 'i18next';

import queryCheckHouseHolderPaygroup from "../../query/queryCheckHouseHolderPaygroup"

const mutation = graphql`
    mutation receiveCreateFormMutation($input: CreateReceiveAndTransactionInput!){
        createReceiveAndTransaction(input:$input){
            ok
            warningText
        }
    }`;

const query = graphql`
    query receiveCreateFormQuery($contact_id: ID!, $type:String) {
        contact(id: $contact_id) {
            id
            typeOfContact
        }
        quickMassageViewer(typeNote:$type){
            edges{
              node{
                id
                massageNote
                typeNote
                creator
              }
            }
        }
        selfProject{
            keyProjectQr
        }
    }
`;


const $ = window.jQuery;
class ReceiveCreateForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            total: 0,
            totalReceive: 0,
            totalPaymentGateway: 0,
            remark: "",
            receiveChannel: [],
            paymentGateway: [],
            issuedDate: new Date(),
            upload: {},
            wht: 0,
            loading: false,
            totalDiscount: false,
            typeSupplier: false,
            note_list: [],
            isOther: false,
            note_other: '',
            min_date: new Date(),
            signreq: 'RT' + Math.random().toString(36),
            pay_group: "owner",
            name: "",
            address: ""
        };

        this.updateTotal = this.updateTotal.bind(this);
        this.updateReceiveChannel = this.updateReceiveChannel.bind(this);
        this.updatePaymentGateway = this.updatePaymentGateway.bind(this);
        this.deletePaymentGateway = this.deletePaymentGateway.bind(this);
        this.submitReceive = this.submitReceive.bind(this);
        this.updateIssuedDate = this.updateIssuedDate.bind(this);
        this.updateRemark = this.updateRemark.bind(this);
        this.updateWht = this.updateWht.bind(this);
        this.updateDiscount = this.updateDiscount.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateMinDateInvoice = this.updateMinDateInvoice.bind(this);
    }

    componentDidMount() {
        fetchQuery(environment, query, { contact_id: this.props.customer, type: 'received' })
            .then(response_data => {
                if (response_data.contact) {
                    if (response_data.contact.typeOfContact === "SUPPLIER") {
                        this.setState({ typeSupplier: true });
                    }
                }
                let note_list = []
                if (response_data.quickMassageViewer.edges.length > 0) {
                    response_data.quickMassageViewer.edges.forEach((data) => {
                        note_list.push({ node: { creator: data.node.creator, id: data.node.id, name: data.node.massageNote, typeNote: data.node.typeNote } })
                    })
                }
                note_list.push({ node: { creator: " ", id: "other", name: "อื่นๆ", typeNote: "RECEIVED" } })
                this.setState({ note_list: note_list })

                if (response_data.selfProject.keyProjectQr !== null) {
                    this.setState({
                        keyProjectQr: true
                    })
                }
                let max_rows = 6;
                $(document).ready(function () {
                    $('textarea[data-limit-rows=true]')
                        .on('keypress', function (event) {
                            var textarea = $(this),
                                text = textarea.val(),
                                numberOfLines = (text.match(/\n/g) || []).length + 1,
                                maxRows = max_rows

                            if (event.which === 13 && numberOfLines === maxRows) {
                                return false;
                            }
                        });
                });
            })
    }


    updateWht(wht) {
        wht = parseFloat(wht).toFixed(2) <= 0 ? 0 : parseFloat(wht).toFixed(2);
        this.setState({ wht: wht });
    }

    updateRemark(e) {
        this.setState({ remark: e.target.value });
    }

    updateIssuedDate(date) {
        this.setState({ issuedDate: date });
    }

    updateTotal(total) {
        total = parseFloat(total).toFixed(2) <= 0 ? 0 : parseFloat(total).toFixed(2);
        this.setState({ total: total });
    }

    updateDiscount(discount) {
        this.setState({ totalDiscount: discount });
    }

    updateReceiveChannel(channel_list) {
        let totalReceive = 0;
        let upload = {};
        channel_list.forEach((channel, index) => {
            if (channel.slug === "small-change" || channel.slug === "receipt-deposit" || channel.slug === "other-expense") {
                if ((this.state.total - this.state.wht) > totalReceive)
                    totalReceive += parseFloat(channel.pay);
                else
                    totalReceive -= parseFloat(channel.pay);
            } else {
                totalReceive += parseFloat(channel.pay);
            }
            if (channel.image) {
                upload[index] = channel.image;
            }

        });
        this.setState({
            receiveChannel: channel_list,
            totalReceive: totalReceive,
            upload: upload
        });
    }

    updatePaymentGateway(payment_list) {
        let upload = {};
        let totalReceive = this.state.totalReceive
        let totalPaymentGateway = 0
        payment_list.forEach((payment, index) => {
            if (payment.image) {
                upload[index] = payment.image;
            }
            totalPaymentGateway += payment.fee_amount
        });

        this.setState({
            paymentGateway: payment_list,
            totalReceive: totalReceive,
            totalPaymentGateway: totalPaymentGateway,
            upload: upload
        });
    }

    deletePaymentGateway() {
        this.setState({
            paymentGateway: [],
            totalPaymentGateway: 0,
        });
    }

    // วันที่ออกใบเสร็จ minDate 
    updateMinDateInvoice(min_date) {
        this.setState({ min_date: min_date })
    }

    callUpload = async (file) => {

        var new_file = new File([file], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${file.name}`);

        return new Promise(async (resolve, reject) => {
            let res = await upload(`/receive`, new_file);
            resolve(res);
        });
    };

    async submitReceive(e) {
        e.preventDefault();
        this.setState({ loading: true }, async () => {
            let total_wht = (this.state.total - this.state.wht) <= 0 ? 0 : (this.state.total - this.state.wht).toFixed(2);
            let totalReceive = this.state.totalReceive <= 0 ? 0 : this.state.totalReceive.toFixed(2)
            var valueArr = this.state.receiveChannel.filter((item) => {
                return item.slug === 'unknown-receive'
            }).map((item) => {
                return item.unknown_ref
            })
            var isDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) !== idx
            });
            let issuesDate = this.state.issuedDate
            // if(issuesDate < this.state.min_date){
            //     issuesDate = this.state.min_date;
            // }

            let invoicePaid = false; //มีการจ่าย iv ซ้ำ
            let allTransactionId = []
            await fetchQuery(environment, fetchInvoice, { id: this.props.customer }).then((data) => {
                _.forEach(data.invoiceViewer.allInvoiceByStatus.edges, transaction => {
                    if (transaction.node.transaction.edges.length > 0) {
                        _.forEach(transaction.node.transaction.edges, transaction_list => {
                            allTransactionId.push(transaction_list.node.id)
                        })
                    }
                })
                _.forEach(data.contact.receiptDeposit.edges, rd => {
                    if (rd.node.receiptDepositTransaction.edges.length > 0) {
                        _.forEach(rd.node.receiptDepositTransaction.edges, transaction => {
                            allTransactionId.push(transaction.node.id)
                        })
                    }
                })
            })

            let isOverDateInvoice = new Date(this.state.min_date).setHours(0, 0, 0, 0) > new Date(issuesDate).setHours(0, 0, 0, 0) ? true : false
            if (this.state.receiveChannel.length > 0) {
                for (const key in this.state.receiveChannel) {
                    if (this.state.receiveChannel[`${key}`].cheque && this.state.receiveChannel[`${key}`].cheque.chequeImage) {

                        let data = await this.callUpload(this.state.receiveChannel[`${key}`].cheque.chequeImage)
                        await this.setState((prevState) => {
                            return _.set(_.cloneDeep(prevState), `receiveChannel[${key}].cheque.chequeImage`, data.key.replace("silverman-storage/private/", ""));
                        });
                    } else if(this.state.receiveChannel[`${key}`].image_slip){
                        // สำหรับ unknown_receive ส่ง image_slip 
                        let data = await this.callUpload(this.state.receiveChannel[`${key}`].image_slip)
                        await this.setState((prevState) => {
                            return _.set(_.cloneDeep(prevState), `receiveChannel[${key}].image_slip`, data.key.replace("silverman-storage/private/", ""));
                        });
                    }
                }
            }

            _.forEach(this.props.transactions, select => {
                if (!allTransactionId.includes(select.transaction_id)) { //false  รายการถูกชำระแล้ว
                    invoicePaid = true
                }
            })

            if (total_wht === totalReceive && !isDuplicate && !isOverDateInvoice && !invoicePaid) {
                this.setState({ loading: true });
                let variables = {
                    input: {
                        clientMutationId: this.state.signreq,
                        contactId: this.props.customer,
                        issuedDate: format(issuesDate, 'YYYY-MM-DD', { locale: thLocale }),
                        remark: this.state.remark,
                        receiveChannel: JSON.stringify(this.state.receiveChannel),
                        paymentGateway: JSON.stringify(this.state.paymentGateway),
                        transactions: JSON.stringify(this.props.transactions),
                        payGroup: this.state.pay_group
                    }
                };
                let uploadables = this.state.upload;

                if (this.state.loading) {
                    fetchQuery(environment, queryCheckHouseHolderPaygroup, {
                        contactId: this.props.customer,
                        payGroup:this.state.pay_group
                     }).then((data) => {
                       let checkHouseHolderPaygroup = JSON.parse(data.checkHouseHolderPaygroup)
                       if(checkHouseHolderPaygroup.ok){
                         // create receive
                        commitMutation(
                            environment,
                            {
                                mutation,
                                variables,
                                uploadables,
                                onCompleted: (response) => {
                                    this.setState({ loading: false });
                                    if (response.createReceiveAndTransaction.ok) {
                                        Swal.fire(i18n.t('createReceive:Successful record'), '', 'success').then(() => {
                                            this.setState({ redirectToList: true })
                                        });
                                    } else {
                                        if (response.createReceiveAndTransaction.warningText) {
                                            Swal.fire(i18n.t('createReceive:Failed to accept the receive'), response.createReceiveAndTransaction.warningText, "warning");
                                        }
                                        else {
                                            Swal.fire(i18n.t('createReceive:Failed to accept the receive'), '', 'warning');
                                        }
                                    }
                                },
                                onError: (err) => {
                                    this.setState({ loading: false });
                                    Swal.fire('Error!', i18n.t('createReceive:Please try again'), 'warning')
                                },
                            },
                        );
                
                       }else if(checkHouseHolderPaygroup.ok === false && this.state.pay_group === 'owner' ){
                       
                         Swal.fire({
                        //    title: "ออกได้แต่ชื่อจะเป็นค่าช่องว่าง",
                           type: 'warning',
                           showCancelButton: true,
                           text:checkHouseHolderPaygroup.message,
                           confirmButtonColor: '#3085d6',
                           cancelButtonColor: '#d33',
                           confirmButtonText: i18next.t("Allaction:Yes"),
                           cancelButtonText: i18next.t("Allaction:cancel")
                         }).then((result) => {
                             if (result.value) {
                                  // create receive
                                  commitMutation(
                                        environment,
                                        {
                                            mutation,
                                            variables,
                                            uploadables,
                                            onCompleted: (response) => {
                                                this.setState({ loading: false });
                                                if (response.createReceiveAndTransaction.ok) {
                                                    Swal.fire(i18n.t('createReceive:Successful record'), '', 'success').then(() => {
                                                        this.setState({ redirectToList: true })
                                                    });
                                                } else {
                                                    if (response.createReceiveAndTransaction.warningText) {
                                                        Swal.fire(i18n.t('createReceive:Failed to accept the receive'), response.createReceiveAndTransaction.warningText, "warning");
                                                    }
                                                    else {
                                                        Swal.fire(i18n.t('createReceive:Failed to accept the receive'), '', 'warning');
                                                    }
                                                }
                                            },
                                            onError: (err) => {
                                                this.setState({ loading: false });
                                                Swal.fire('Error!', i18n.t('createReceive:Please try again'), 'warning')
                                            },
                                        },
                                    );
                   
                             }else{
                               this.setState({ loading: false });
                             }
                         })
                       }else{

                         Swal.fire({
                           title: "บันทึกไม่สำเร็จ",
                           type: 'warning',
                           text:checkHouseHolderPaygroup.message,
                           showCancelButton: true,
                           showConfirmButton:false,
                           cancelButtonColor: '#d33',
                           cancelButtonText: 'ปิด',
                       })
                         this.setState({ loading: false });
                       }
                       
                     })
                }

            } else {
                if (isOverDateInvoice) {
                    Swal.fire(
                        'วันที่ออกใบเสร็จไม่ถูกต้อง',
                        'กรุณาเลือกวันที่ออกใบเสร็จตั้งแต่วันที่ ' + format(this.state.min_date, 'DD-MM-YYYY', { locale: thLocale }),
                        'warning'
                    )
                    this.setState({ loading: false })
                } else if (invoicePaid) {
                    Swal.fire('Error!', 'มีการเลือกรายการที่ชำระแล้ว', 'warning')
                    this.setState({ loading: false })
                } else if (!isDuplicate) {

                    Swal.fire(
                        'ยอดชำระไม่ถูกต้อง',
                        'กรุณากรอกจำนวนเงินที่ได้รับ ให้เท่ากับ ' + numberWithComma(this.state.total - this.state.wht) + ' บาท',
                        'warning'
                    ).then(() => this.setState({ loading: false }))
                } else {
                    Swal.fire(
                        'รายละเอียดการรับชำระไม่ถูกต้อง',
                        'กรุณาเลือกเงินรับรอการตรวจสอบที่ไม่ซ้ำกัน',
                        'warning'
                    ).then(() => this.setState({ loading: false }))
                }
            }


        });

    }

    handleInputChange(e) {
        if (e.target.name === 'noteListInvoice') {
            if (e.target.value !== "other") {
                this.state.note_list.forEach((value) => {
                    if (value.node.id === e.target.value) {
                        this.setState({
                            remark: value.node.name,
                            isOther: false
                        })
                    }
                })

            } else {
                this.setState({
                    isOther: true,
                    remark: ""
                })
                let max_rows = 6;
                $(document).ready(function () {
                    $('textarea[data-limit-rows=true]')
                        .on('keypress', function (event) {
                            var textarea = $(this),
                                text = textarea.val(),
                                numberOfLines = (text.match(/\n/g) || []).length + 1,
                                maxRows = max_rows

                            if (event.which === 13 && numberOfLines === maxRows) {
                                return false;
                            }
                        });
                });
            }
        }
    }

    render() {
        if (this.props.customer && !this.state.redirectToList) {
            return (
                <form onSubmit={this.submitReceive}>
                    <div className="container-fluid box" id="receive-create-form">

                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/income/receive/form/select_customer">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:create_receive')}</span>
                                        }
                                    </Translation>
                                </h3>

                            </div>

                        </div>

                        <div className="content-inner">
                            <ReceiveTransactionList
                                updateData={(payGroup) => this.setState({ pay_group: payGroup })}
                                updateTotal={this.updateTotal}
                                updateDiscount={this.updateDiscount}
                                updateIssuedDate={this.updateIssuedDate}
                                updateMinDateInvoice={this.updateMinDateInvoice}
                                transactions={this.props.transactions}
                                paymentGateway={this.state.paymentGateway}
                                updateWht={this.updateWht}
                                customer={this.props.customer} />
                            {this.state.total > 0 &&
                                <ReceiveForm updateReceiveChannel={this.updateReceiveChannel}
                                    updatePaymentGateway={this.updatePaymentGateway}
                                    deletePaymentGateway={this.deletePaymentGateway}
                                    payment_type={'receive'}
                                    create_receive={true} typeSupplier={this.state.typeSupplier}
                                    issued_date={this.state.issuedDate} total={this.state.total - this.state.wht}
                                    receive_form={true}
                                    pay={parseFloat(this.state.totalReceive.toFixed(2))}
                                    payment_gateway_pay={parseFloat(this.state.totalPaymentGateway)}
                                    min_date={this.state.min_date} page={"receive"} />
                            }
                            <div className="row mt-4" style={{ display: 'flex', alignContent: 'flex-start' }}>
                                <div className="col-6">
                                    <label htmlFor="note"><Translation>{t => <strong>{t('createReceive:Note')}</strong>}</Translation></label>
                                    <SearchSelect
                                        onChange={this.handleInputChange}
                                        name="noteListInvoice"
                                        placeholder='เลือกหมายเหตุ'
                                        queryObject={this.state.note_list}
                                        keyOfValue="id"
                                        keyOfLabel="name"
                                        required={false}
                                        pre_wrap={true} />
                                </div>
                                <div className="col offset bg-light">
                                    <div className="row p-3 mb-2 text-dark">
                                        <div className="col text-left"> <Translation>{t => t('createReceive:Amount received')}</Translation></div>
                                        <div className="col text-right"> {numberWithComma((parseFloat(this.state.total) + parseFloat(this.state.totalPaymentGateway) - parseFloat(this.state.wht)) <= 0 ? 0 : ((parseFloat(this.state.total) + parseFloat(this.state.totalPaymentGateway)) - parseFloat(this.state.wht)), '0.00')} <Translation>{t => t('createReceive:Baht')}</Translation></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                {this.state.isOther &&
                                    <div className="col-6">
                                        <Translation>
                                            {t => <p className="text-danger" style={{ fontSize: '11px', marginBottom: '8px' }}> *{t('createReceive:Enter message not more than')} 800  {t('createReceive:characters')} {this.state.remark.length}/800 </p>}
                                        </Translation>
                                        <textarea className="form-control mt-2" rows="5" name="remark" value={this.state.remark}
                                            onChange={this.updateRemark}
                                            data-limit-rows="true"
                                            maxLength={800}
                                        />
                                    </div>
                                }
                                {
                                    // !this.state.loading ?
                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link to="/accounting/income/receive/form/select_customer">
                                                    <button type="button" className="btn btn-secondary add">
                                                        <Translation>{t => t('createReceive:Cancel')}</Translation>
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="btn-group mr-2">

                                                <button type="submit" className="btn btn-primary add"
                                                    disabled={this.state.loading}>
                                                    {this.state.loading &&
                                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                    <Translation>{t => t('createReceive:Accept')}</Translation>
                                                </button>

                                            </div>
                                        </div>
                                        // :
                                        // <div className='col  text-righ'>
                                        //     <span className='text-red'>***{i18next.t("Allaction:This will only take a moment, please wait")}***</span>
                                        // </div>
                                }
                            </div>

                        </div>
                    </div>
                </form>
            )
        }
        return <Redirect to="/accounting/income/receive/list/all" />;
    }
}

export default ReceiveCreateForm;
