    import React, { Component } from 'react';
    import { NavLink } from "react-router-dom";
    import { Translation } from "react-i18next";

    class Navigation extends Component {
        render() {
            return (
                <Translation>
                    {t =>
                        <div className='colorUse'>
                            <div className="row" id="navigation-tab">
                                <div className="col">
                                    <ul>
                                        <li>
                                            <NavLink to="/uploadfile/all">
                                                {t('uploadFileList:All')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/uploadfile/enable">
                                                {t('uploadFileList:Enable')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/uploadfile/disable">
                                                {t('uploadFileList:Disable')}
                                            </NavLink>
                                        </li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>

                    }
                </Translation>
            )
        }
    }

    export default Navigation;