/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type parcelReportQueryVariables = {|
  start_date?: ?any,
  end_date?: ?any,
|};
export type parcelReportQueryResponse = {|
  +parcelViewer: ?{|
    +notReceiveOneDay: ?number,
    +notReceiveAll: ?number,
  |}
|};
export type parcelReportQuery = {|
  variables: parcelReportQueryVariables,
  response: parcelReportQueryResponse,
|};
*/


/*
query parcelReportQuery(
  $start_date: DateTime
  $end_date: DateTime
) {
  parcelViewer {
    notReceiveOneDay: countByStatusParcel(parcelStatus: "not_receive", startDate: $start_date, endDate: $end_date)
    notReceiveAll: countByStatusParcel(parcelStatus: "not_receive")
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v2 = {
  "kind": "Literal",
  "name": "parcelStatus",
  "value": "not_receive"
},
v3 = {
  "alias": "notReceiveOneDay",
  "args": [
    {
      "kind": "Variable",
      "name": "endDate",
      "variableName": "end_date"
    },
    (v2/*: any*/),
    {
      "kind": "Variable",
      "name": "startDate",
      "variableName": "start_date"
    }
  ],
  "kind": "ScalarField",
  "name": "countByStatusParcel",
  "storageKey": null
},
v4 = {
  "alias": "notReceiveAll",
  "args": [
    (v2/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "countByStatusParcel",
  "storageKey": "countByStatusParcel(parcelStatus:\"not_receive\")"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "parcelReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "parcelReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ParcelViewer",
        "kind": "LinkedField",
        "name": "parcelViewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3d8bea5b5499246b577e260efd7cc5b",
    "id": null,
    "metadata": {},
    "name": "parcelReportQuery",
    "operationKind": "query",
    "text": "query parcelReportQuery(\n  $start_date: DateTime\n  $end_date: DateTime\n) {\n  parcelViewer {\n    notReceiveOneDay: countByStatusParcel(parcelStatus: \"not_receive\", startDate: $start_date, endDate: $end_date)\n    notReceiveAll: countByStatusParcel(parcelStatus: \"not_receive\")\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '454e0426bfab33c57d015a9577f76fbf';

module.exports = node;
