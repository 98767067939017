import getApiRoot from "../libs/getAPIRoot";
import axios from "axios";
import jwtDecode from 'jwt-decode';


// Account
async function getHomeAll() {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/all_residential`, { headers: header });
}
async function getcarVms() {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
   return axios.get(`${getApiRoot()}vms/car_vms_optimize`, { headers: header });
}

async function getSearchCarTransaction(token,id,domain) {
    
    return axios.get(`https://svm-log-qsobffrqcq-as.a.run.app/search_car_transaction/`, {
            headers: {
                'authorization': `bearer ${token}`,
                'Content-Type': 'application/json'
            },
            params: {
                search_id: id,
                domain: domain
            }
        })

}


async function addVisitorVMS(body) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/car_vms`, body, { headers: header });
}


async function getVisitorVMSReport() {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/report_car_vms_optimize`, { headers: header });
}


async function getDataDetail(codeVisit) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/car_vms`,
        {
            headers: header,
            params: {
                search: codeVisit                
            }
        },
    );
}

async function getDataReportDetail(codeVisit) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/report_car_vms`,
        {
            headers: header,
            params: {
                search: codeVisit                
            }
        },
    );
}

async function getCarParkingFeeReceipt(codeVisit) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/report_car_vms`,
        {
            headers: header,
            params: {
                carTransactionId: codeVisit                
            }
        },
    );
}

async function getSearchVisitmange(searchBox , startDate , endDate ,typePerson , objectCar) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/car_vms_optimize`,
        {
            headers: header,
            params: {
                search: searchBox,
                startDate : startDate,
                endDate : endDate,
                typePerson : typePerson,
                objectCar: objectCar
            }
        },
    );
}
async function getSearchReportVisitmange(searchBox , startDate , endDate ,typePerson , objectCar) {
    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.get(`${getApiRoot()}vms/report_car_vms`,
        {
            headers: header,
            params: {
                search: searchBox,
                startDate : startDate,
                endDate : endDate ,
                typePerson : typePerson, 
                objectCar: objectCar
            }
        },
    );
}


async function cancelVisitorVMS(body) {

    let token_id = await localStorage.getItem("token");
    let header = {
        authorization: `JWT ${token_id}`,
        "Content-Type": "application/json",
    };
    return axios.post(`${getApiRoot()}vms/cancel_car_vms`, body, { headers: header });
}

export default {
    getcarVms, addVisitorVMS, getHomeAll, getDataDetail,getVisitorVMSReport,getDataReportDetail,getSearchCarTransaction,
    getSearchVisitmange,getSearchReportVisitmange, cancelVisitorVMS,getCarParkingFeeReceipt,
}