import {Component} from 'react';
import localStorage from "./localstorage";

class ComponentPagination extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            first: localStorage.getPageLimit(), 
            last: localStorage.getPageLimit(), 
            checkList: [], 
            check_all: false, 
            checkRecord:[],
            checkRefTrans: [], 
            checkRefNumber:[],
        };
        this.changePage = this.changePage.bind(this);
        this.deleteSetState = this.deleteSetState.bind(this);
        this.calStateFirst = this.calStateFirst.bind(this);
        this.goFirst = this.goFirst.bind(this);
        this.clearCheckList = this.clearCheckList.bind(this);
    }

    goFirst() {
        this.setState({first: localStorage.getPageLimit(), last: localStorage.getPageLimit()});
    }

    appendToCheckList(id,doc=null) {
        //id
        let current_checklist = [...this.state.checkList];
        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
        } else {
            //in
            current_checklist.push(id);
        }
        //doc rt
        if(doc !== null){
            let current_checkListdoc = [...this.state.checkListdoc];
            if (current_checkListdoc.includes(doc)) {
                current_checkListdoc.splice(current_checkListdoc.indexOf(doc), 1)
            } else {
                current_checkListdoc.push(doc);
            }
            this.setState({checkListdoc: current_checkListdoc})
        }

        this.setState({checkList: current_checklist})
        if(this.props.onCheckListChange){
            this.props.onCheckListChange(current_checklist);
        }
        
    }


    appendToCheckListNoProps(id,doc=null) {
        //id
        let current_checklist = [...this.state.checkList];
        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
        } else {
            //in
            current_checklist.push(id);
        }
        //doc rt
        if(doc !== null){
            let current_checkListdoc = [...this.state.checkListdoc];
            if (current_checkListdoc.includes(doc)) {
                current_checkListdoc.splice(current_checkListdoc.indexOf(doc), 1)
            } else {
                current_checkListdoc.push(doc);
            }
            this.setState({checkListdoc: current_checkListdoc})
        }
        this.setState({checkList: current_checklist})
    }

    async appendToCheckListRecord(transaction,index_group,doc=null) {
        let current_record = [...this.state.checkRecord];
        let current_checklist = [...this.state.checkList];
        // check record doc_number อยู่ใน checklist?
        if (current_record.includes(index_group)){
            //out กด checklist ซ้ำ เอาติ้กออก
            current_record.splice(current_record.indexOf(index_group),1)
        }else {
            //in
            current_record.push(index_group)
        }
        await transaction.map(value => {
            if (current_checklist.includes(value.node.id)) {
                //out
                current_checklist.splice(current_checklist.indexOf(value.node.id), 1)
            } else {
                //in
                current_checklist.push(value.node.id);
            }
            //doc rt
            if(doc !== null){
                let current_checkListdoc = [...this.state.checkListdoc];
                if (current_checkListdoc.includes(doc)) {
                    current_checkListdoc.splice(current_checkListdoc.indexOf(doc), 1)
                } else {
                    current_checkListdoc.push(doc);
                }
                this.setState({checkListdoc: current_checkListdoc})
            }
            this.setState({checkList: current_checklist})
            return value
        })
        this.setState({checkRecord: current_record})
    }

    appendCheckListDaily(id,ref_tran,ref_number){
        //id
        let current_checklist = [...this.state.checkList];
        let current_checkreftran = [...this.state.checkRefTrans];
        let current_checkrefnum = [...this.state.checkRefNumber];
        if (current_checklist.includes(id)) {
            //out
            current_checklist.splice(current_checklist.indexOf(id), 1)
            //ref transaction
            if(current_checkreftran.includes(ref_tran)){
                current_checkreftran.splice(current_checklist.indexOf(id),1)
            }
        } else {
            //in
            current_checklist.push(id)
            current_checkreftran.push(ref_tran)
        }
        //ref number
        if(current_checkrefnum.includes(ref_number)){
            current_checkrefnum.splice(current_checkrefnum.indexOf(ref_number),1)
        }else{
            current_checkrefnum.push(ref_number)
        }
        this.setState({checkList:current_checklist, checkRefTrans:current_checkreftran, checkRefNumber:current_checkrefnum})
    }

    checkList(id) {
        return this.state.checkList.includes(id);
    }

    checkRecord(doc_number){
        return this.state.checkRecord.includes(doc_number)
    }
    getAllList(props,usefilterStatus) {
        let checklist = [...this.state.checkList];
        let current_checkListdoc = null
        if(this.state.checkListdoc)current_checkListdoc = [...this.state.checkListdoc];

        let check_all = false;

        if (!this.state.check_all) check_all = true;

        if ((check_all && (checklist.length === props.length)) || !check_all ) {
            checklist = [];
            if(this.state.checkListdoc)current_checkListdoc = [];
        } else {
            if (checklist.length !== 0) {
                checklist = [];
                current_checkListdoc = [];
            }
            let filteredProps = props;
           
            // Filter the props if usefilterStatus is true
            if (usefilterStatus) {
                filteredProps = props.filter((n) => n.node.status !== "VOID");
            }

            filteredProps.forEach((item) => {
                checklist.push(item.node.id);

                if (this.state.checkListdoc) {
                    current_checkListdoc.push(item.node.docNumber ? item.node.docNumber : 'null');
                }
            });

        }
        if(this.state.checkListdoc)this.setState({checkList: checklist, check_all: check_all, checkListdoc:current_checkListdoc})
        else this.setState({checkList: checklist, check_all: check_all})

        if(this.props.onCheckListChange){
            this.props.onCheckListChange(checklist);
        }
        
    }

    getAllListDaily(props){
        let current_checklist = [...this.state.checkList];
        let current_checkreftran = [...this.state.checkRefTrans];
        let current_checkrefnum = [...this.state.checkRefNumber];
        let check_all = false;
        if(!this.state.check_all){
            check_all = true
        }
        if((check_all && (current_checklist.length === props.length)) || !check_all){
            current_checklist = [];
            current_checkrefnum = [];
            current_checkreftran = [];
            check_all = false;
        }else {
            if(current_checklist.length !== 0){
                current_checklist = [];
                current_checkrefnum = [];
                current_checkreftran = [];
            }
            props.forEach((item) => {
                current_checklist.push(item.node.id)
                current_checkrefnum.push(item.node.refNumber)
                current_checkreftran.push(item.node.refTransaction)
            })
            
        }
        this.setState({checkList:current_checklist,checkRefNumber:current_checkrefnum,checkRefTrans:current_checkreftran,check_all:check_all})
    }



    getAllListRecord() {
        let checklist = [...this.state.checkList];
        let checkrecord = [...this.state.checkRecord]
        let current_checkListdoc = null
        if(this.state.checkListdoc)current_checkListdoc = [...this.state.checkListdoc];
        let check_all = false;
        if (!this.state.check_all){ 
            check_all = true
        }

        if ((check_all && (checklist.length === this.state.transaction_record.length)) || !check_all ) {
            checklist = [];
            if(this.state.checkListdoc)current_checkListdoc = [];
            checkrecord = [];
        } else {
            if (checklist.length !== 0) {
                checklist = [];
                current_checkListdoc = [];
            }
            this.state.transaction_record.forEach((item) => {
                checklist.push(item.node.id);
                if(this.state.checkListdoc)current_checkListdoc.push((item.node.docNumber?item.node.docNumber:'null'));
            });
            this.state.record.forEach((item) => {
                if(!this.state.checkRecord.includes(item)){
                    checkrecord.push(item)
                }
            })
        }
        if(this.state.checkListdoc)this.setState({checkList: checklist, check_all: check_all, checkListdoc:current_checkListdoc, checkRecord : checkrecord})
        else this.setState({checkList: checklist, check_all: check_all,  checkRecord : checkrecord})
    }

    changePage(param) {
        this.setState({
            first: param.first,
            last: param.last,
        });
        this.clearCheckList();
    }

    clearCheckList(){
        this.setState({
            checkList: [],
            check_all: false,
            checkRecord:[],
            checkRefTrans:[],
            checkRefNumber:[],
        })
    }

    deleteSetState(delete_count) {

        let last_page = this.state.last !== localStorage.getPageLimit();
        let last = localStorage.getPageLimit();
        let first = this.state.first;
        if (last_page) {
            if (this.state.last === 1 || this.state.last - delete_count <= 0) {

                last = localStorage.getPageLimit();
                first = this.calStateFirst(first);
            }
            else {

                if (delete_count) {
                    last = this.state.last - delete_count;
                } else {
                    last = this.state.last - 1;
                }
            }
        }
        this.setState({last: last, first:first});
    }

    calStateFirst(first){

        let limit = localStorage.getPageLimit();
        if (first - limit > 0) {
            return first - limit;
        } else {
            return limit
        }
    }
}

export  default ComponentPagination;
