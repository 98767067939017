import React, { Component } from 'react';
import $ from "jquery";
import { Helmet } from "react-helmet";
import "./contact.scss"
import _ from "lodash"
import { Translation } from 'react-i18next';

var listBangkok = ["กรุงเทพ", "กรุงเทพมหานคร", "กทม.", "กทม", "กรุงเทพฯ"]

class ContactAll extends Component {

    componentDidMount() {
        setTimeout(() => {
            let page = $('.print-contact-a4');
            let head = page.find('.head').height();
            let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head + detail + invoice + signature);
            // console.log(head + detail + daily_journal + signature);
            $('tbody tr:last-child td').css({ paddingBottom: diff });
        }, 200);
    }

    print() {
        window.print();
    }

    checkHeadTitle = (textInput) => {

        let textCheck = _.filter(listBangkok, (textKeyCheck) => textKeyCheck === textInput)
        if (textCheck.length > 0) {
            return true
        } else {
            return false
        }
    }

    render() {
        let statusFro = this.props.location?.state?.statusPrintFor
        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8" />
                    <title>ContactAll</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet" />
                </Helmet>

                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman" />
                    </div>
                    <div className="print" onClick={() => this.print()}>
                        PRINT
                    </div>
                </div>

                {
                    this.props.location?.state?.data?.map((item, index_Address) => {
                        let itemData = item.data
                        let address = statusFro === "agent" ? itemData.agentAddress : statusFro === "rent" ? itemData.renterAddress :
                            itemData.mailingAddress ? itemData.mailingAddress : itemData.registeredAddress;
                        let province = statusFro === "agent" ? itemData.agentProvince : statusFro === "rent" ? itemData.renterProvince :
                            itemData.mailingProvince ? itemData.mailingProvince : itemData.registeredProvince;
                        let District = statusFro === "agent" ? itemData.agentDistrict : statusFro === "rent" ? itemData.renterDistrict :
                            itemData.mailingDistrict ? itemData.mailingDistrict : itemData.registeredDistrict;
                        let City = statusFro === "agent" ? itemData.agentCity : statusFro === "rent" ? itemData.renterCity :
                            itemData.mailingCity ? itemData.mailingCity : itemData.registeredCity;
                        let PostalCode = statusFro === "agent" ? itemData.agentPostalCode : statusFro === "rent" ? itemData.renterPostalCode :
                            itemData.mailingPostalCode ? itemData.mailingPostalCode : itemData.registeredPostalCode;

                        let agentAddress = itemData.agentAddress;
                        let agentProvince = itemData.agentProvince;
                        let agentDistrict = itemData.agentDistrict
                        let agentCity = itemData.agentCity;
                        let agentPostalCode = itemData.agentPostalCode

                        let renterAddress = itemData.renterAddress;
                        let renterProvince = itemData.renterProvince;
                        let renterDistrict = itemData.renterDistrict;
                        let renterCity = itemData.renterCity;
                        let renterPostalCode = itemData.renterPostalCode;


                        let firstName_lastName = ''
                        if (itemData.typeOfContact === "RESIDENTIAL") {
                            firstName_lastName = `${itemData.firstName} ${itemData.lastName}`
                        } else {
                            firstName_lastName = itemData?.name
                        }

                        return (
                            <Translation>
                                {t =>
                                    <React.Fragment>
                                        {
                                            address !== "" || agentAddress !== "" || renterAddress !== "" ?
                                                <React.Fragment>
                                                    {
                                                        (statusFro === 'all' || (statusFro !== 'rent' && statusFro !== 'agent')) &&
                                                        <div id="contact" key={index_Address}>
                                                            <div className="print-contact-a4">
                                                                <div className="subpage">
                                                                    <div className="head">
                                                                        <div className="juristic-description">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Sender")}</strong>
                                                                            <div className="mt-3">{this.props.location?.state?.selfProject.name}</div>
                                                                            <div>{this.props.location?.state?.selfProject.address}</div>
                                                                            <div>{this.props.location?.state?.selfProject.juristicContactNumber ? ('โทร. ' + this.props.location?.state?.selfProject.juristicContactNumber) : ''}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="he">
                                                                        <div className="document-name">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Recipient")}</strong>
                                                                            <div className="mt-3">
                                                                                {firstName_lastName}
                                                                            </div>
                                                                            <div>{address}</div>
                                                                            <div>
                                                                                {this.checkHeadTitle(province) ? `แขวง${District} เขต${City}` : `ตำบล${District} อำเภอ${City}`
                                                                                }
                                                                                &nbsp;{province ? this.checkHeadTitle(province)
                                                                                    ? province : `จังหวัด${province}`
                                                                                    : this.checkHeadTitle(province)
                                                                                        ? province : `จังหวัด${province}`
                                                                                }
                                                                                &nbsp;{PostalCode}
                                                                            </div>
                                                                            <div>{itemData.phone ? ('โทร. ' + itemData.phone) : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (statusFro === 'all' || statusFro === 'agent') &&
                                                        <div id="contact" key={index_Address}>
                                                            <div className="print-contact-a4">
                                                                <div className="subpage">
                                                                    <div className="head">
                                                                        <div className="juristic-description">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Sender")}</strong>
                                                                            <div className="mt-3">{this.props.location?.state?.selfProject.name}</div>
                                                                            <div>{this.props.location?.state?.selfProject.address}</div>
                                                                            <div>{this.props.location?.state?.selfProject.juristicContactNumber ? ('โทร. ' + this.props.location?.state?.selfProject.juristicContactNumber) : ''}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="he">
                                                                        <div className="document-name">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Recipient")}</strong>
                                                                            <div className="mt-3">
                                                                                {
                                                                                    // `${itemData.firstNameAgent} ${itemData.lastNameAgent} (ตัวแทน)`
                                                                                    itemData.firstNameAgent && itemData.lastNameAgent ?
                                                                                        `${itemData.firstNameAgent} ${itemData.lastNameAgent}  (ตัวแทน)` :
                                                                                        `คุณ............................................................ (ตัวแทน)`
                                                                                }
                                                                            </div>
                                                                            <div>{agentAddress}</div>
                                                                            <div>
                                                                                {this.checkHeadTitle(agentProvince) ? `แขวง${agentDistrict} เขต${agentCity}` : `ตำบล${agentDistrict} อำเภอ${agentCity}`
                                                                                }
                                                                                &nbsp;{agentProvince ? this.checkHeadTitle(agentProvince)
                                                                                    ? agentProvince : `จังหวัด${agentProvince}`
                                                                                    : this.checkHeadTitle(agentProvince)
                                                                                        ? agentProvince : `จังหวัด${agentProvince}`
                                                                                }
                                                                                &nbsp;{agentPostalCode}
                                                                            </div>
                                                                            <div>{itemData.phone ? ('โทร. ' + itemData.phone) : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        (statusFro === 'all' || statusFro === 'rent' && renterAddress) &&
                                                        <div id="contact" key={index_Address}>
                                                            <div className="print-contact-a4">
                                                                <div className="subpage">
                                                                    <div className="head">
                                                                        <div className="juristic-description">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Sender")}</strong>
                                                                            <div className="mt-3">{this.props.location?.state?.selfProject.name}</div>
                                                                            <div>{this.props.location?.state?.selfProject.address}</div>
                                                                            <div>{this.props.location?.state?.selfProject.juristicContactNumber ? ('โทร. ' + this.props.location?.state?.selfProject.juristicContactNumber) : ''}</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="he">
                                                                        <div className="document-name">
                                                                            <strong style={{ fontSize: 16 }}>{t("printAllContact:Address Recipient")}</strong>
                                                                            <div className="mt-3">
                                                                                {
                                                                                    itemData.firstNameRenter && itemData.lastNameRenter ?
                                                                                        `${itemData.firstNameRenter} ${itemData.lastNameRenter}(ผู้เช่า)` :
                                                                                        `คุณ............................................................(ผู้เช่า)`
                                                                                }
                                                                            </div>
                                                                            <div>{renterAddress}</div>
                                                                            <div>
                                                                                {this.checkHeadTitle(renterProvince) ? `แขวง${renterDistrict} เขต${renterCity}` : `ตำบล${renterDistrict} อำเภอ${renterCity}`
                                                                                }
                                                                                &nbsp;{renterProvince ? this.checkHeadTitle(renterProvince)
                                                                                    ? renterProvince : `จังหวัด${renterProvince}`
                                                                                    : this.checkHeadTitle(renterProvince)
                                                                                        ? renterProvince : `จังหวัด${renterProvince}`
                                                                                }
                                                                                &nbsp;{renterPostalCode}
                                                                            </div>
                                                                            <div>{itemData.phone ? ('โทร. ' + itemData.phone) : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                                :
                                                <></>
                                        }
                                    </React.Fragment>
                                }
                            </Translation>
                        )
                    })
                }
            </React.Fragment>
        );
    }
}

export default ContactAll;
