/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DashboardfixAssetDepreciateQueryVariables = {||};
export type DashboardfixAssetDepreciateQueryResponse = {|
  +fixAssetDepreciate: ?boolean
|};
export type DashboardfixAssetDepreciateQuery = {|
  variables: DashboardfixAssetDepreciateQueryVariables,
  response: DashboardfixAssetDepreciateQueryResponse,
|};
*/


/*
query DashboardfixAssetDepreciateQuery {
  fixAssetDepreciate
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fixAssetDepreciate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardfixAssetDepreciateQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardfixAssetDepreciateQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b5179bb9fa63497f9fbede135091fc63",
    "id": null,
    "metadata": {},
    "name": "DashboardfixAssetDepreciateQuery",
    "operationKind": "query",
    "text": "query DashboardfixAssetDepreciateQuery {\n  fixAssetDepreciate\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3c691abbbac22b51cae77e4abf63ffbc';

module.exports = node;
