import '../styles/main.scss';
import {Helmet} from "react-helmet";
import React, {Component} from 'react';
import Receive from './receive';
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import {QueryRenderer} from "react-relay";
import { Translation } from 'react-i18next';
import Loading from "../../libs/loading";
import _ from "lodash";

const query = graphql`
    query wrapperReceiveQuery($id: ID!,$ref_transaction: String) {
        selfProject {
            id
            name
            nameEn
            address
            addressEn
            logo
            juristicContactNumber
            keyProjectQr
            taxIdNumber
            typeOfProject
        }
        userConfig {
            id languages
        }
        receive(id: $id) {
            id
            contact {
                refNumber
                name
                taxIdNumber
                firstName
                lastName
                phone
                email
                typeOfCompany
                typeOfSupplier
                typeOfContact
                nameBranch
                registeredAddress
                registeredCountry
                registeredProvince
                registeredDistrict
                registeredCity
                registeredPostalCode
                residential {
                    id
                    size
                    ownershipRatio
                }
            }
            docNumber
            issuedDate
            creator
            remark
            total

            firstName
            lastName
            name
            taxIdNumber
            email
            phone
            
            receiveTransaction {
                totalCount
                edges {
                    node {
                        id
                        amount
                        discount
                        transaction {
                            invoice {
                                docNumber
                                taxType
                                firstName
                                lastName
                            }
                            id
                            productAndService {
                                productCode
                            }
                            description
                            unitItems
                            price
                            whtRatePercent
                            preTaxAmount
                            discount
                            vat
                            whtRate
                            total
                            receiptDepositTransactionUsed {
                                edges {
                                    node {
                                        id
                                        amount
                                    }
                                }
                            }
                        }
                    }
                }
            }
            creditNoteReceive{
                totalCount
                edges{
                    node{
                        id
                        docNumber
                        description
                        price
                    }
                }
            }
            cashDepositReceiveTransaction {
                edges {
                    node {
                        price
                    }
                }
            }
            chequeDeposit {
                edges {
                    node {
                        id
                        date
                        bankName
                        chequeNumber
                        branchNumber
                        price
                    }
                }
            }
            withheldTaxReceive {
                edges {
                    node {
                        total
                    }
                }
            }
            bankAccountTransactionReceive {
                edges {
                    node {
                        id
                        price
                        date
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            creditCardTransaction {
                edges {
                    node {
                        amount
                    }
                }
            }
            smallChange{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
            feeAccounting{
                edges{
                    node{
                        id
                        amount
                    }
                }
            }
            paymentGatewayMethod{
                edges{
                    node{
                        id
                        paymentType
                        paymentGateway{
                            id
                            paymentType
                        }
                        amount
                        feeAmount
                        total
                        depositDate
                        image
                    }
                }
            }
        }
        allAccountRecordGroup(refTransaction: $ref_transaction) {
            edges {
                node {
                    id refNumber name added issuedDate category totalDebit totalCredit refTransaction creator
                    contact {
                        id name firstName lastName
                    }
                    accountRecord {
                        edges {
                            node {
                                id name debit credit
                                chartOfAccountCode {
                                    id name chartOfAccountCode
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

class WrapperReceive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            check_type: 'all'
        };
        this.print = this.print.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    print() {
        window.print();
    }

    handleInputChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }


    render() {

        return (
            <React.Fragment>

                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Receive</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeAll"
                                       onChange={this.handleInputChange}
                                       value="all"
                                       checked={this.state.check_type === 'all'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeAll">
                                        {t("document:All")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeManuscript"
                                       value="manuscript"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'manuscript'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeManuscript">
                                        {t("document:Original_")}
                                    </label>}
                                </Translation>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="radio"
                                       name="check_type" id="checkTypeCopy"
                                       value="copy"
                                       onChange={this.handleInputChange}
                                       checked={this.state.check_type === 'copy'}/>
                                <Translation>
                                    {t=>
                                    <label className="form-check-label" htmlFor="checkTypeCopy">
                                        {t("document:Copy_")}
                                    </label>}
                                </Translation>
                            </div>
                        </div>

                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                    environment={environment}
                    query={query}
                    cacheConfig={{use_cache: false}}
                    variables={{id: this.props.match.params.id,"ref_transaction":this.props.match.params.ref_transaction}}
                    render={({error, props}) => {
                        if (error) {
                            return <div className="alert alert-danger"
                                        role="alert">{error.message}</div>;
                        } else if (props) {
                            //edit to ref_transaction = RT-2021080039 B134 && B170
                 
                            let perPage = 0;
                            // let transaction_per_page = 0;
                            let {id, ref_transaction} = this.props.match.params;

                            let allsort = _.map(props.receive.receiveTransaction.edges, (val) =>  {
                                return val.node.transaction.description.length
                             }); 

                            let descriptionMaxlength = _.sortBy(allsort,sort => sort).reverse().shift();
        
                            let rowPerPage = 0;

                            let getLine = 45; //45 per one line on description

                            let rowPerLine = descriptionMaxlength / getLine;

                            if(id === "UmVjZWl2ZU5vZGU6MzE0MjEw" && ref_transaction === "RT-2021080039" ||
                             id === "UmVjZWl2ZU5vZGU6MzU1ODc0" && ref_transaction === "RT-2021100100" ||
                             id === "UmVjZWl2ZU5vZGU6NTMwMzk0" && ref_transaction === "RT-2022030001" ||
                             id === "UmVjZWl2ZU5vZGU6NTMwMzk2" && ref_transaction === "RT-2022030002" ||
                             id === "UmVjZWl2ZU5vZGU6NjMyNzk=" && ref_transaction === "RT-2022110029" ||
                             id === "UmVjZWl2ZU5vZGU6NjMyODE=" && ref_transaction === "RT-2022110030" ||
                             id === "UmVjZWl2ZU5vZGU6NzEzNDY4" && ref_transaction === "RT-2022110372" 
                             )
                             {
                                rowPerPage = 16; //perPage
                                // transaction_per_page = 16;
                                
                            }else if(id === "UmVjZWl2ZU5vZGU6NDk2MjEz" && ref_transaction === "RT-2022030155" 
                            || id === 'UmVjZWl2ZU5vZGU6NTE0NDY3' && ref_transaction === 'RT-2022040409'){ //edit for BTA-237
                                rowPerPage = 15; 
                                // transaction_per_page = 15;           
                            }else{
                                rowPerLine = rowPerLine;

                                if(descriptionMaxlength <= 43 && rowPerLine === 0){
                                    rowPerPage = 14;
                                }
                                else if(descriptionMaxlength > 45 && rowPerLine <= 2){
                                    rowPerPage = 16;
                                }else if(descriptionMaxlength > 45 && rowPerLine <= 4){
                                    rowPerPage = 10;
                                }else if(descriptionMaxlength > 45 && rowPerLine <= 8){
                                    rowPerPage = 4;
                                }else{
                                    rowPerPage = 16;
                                }
                            }
                            perPage = rowPerPage

                            // let count_page = Math.ceil((props.receive.receiveTransaction.totalCount + props.receive.creditNoteReceive.totalCount) / 20);
                            // let arrX = Array.from(new Array(count_page));
                            // let transaction_per_page = 20;

                            // rowPerPage = 10
                            let count_page = Math.ceil((props.receive.receiveTransaction.totalCount + props.receive.creditNoteReceive.totalCount) / rowPerPage); //perPage
                            let arrX = Array.from(new Array(count_page));
                            
                            if (this.state.check_type === "all") {

                                return (
                                    <React.Fragment>
                                    {arrX.map((value, index) => {
                                                // original
                                                return (
                                                    <Receive selfProject={props.selfProject}
                                                             receive={props.receive}
                                                             receiveTransaction={props.receive.receiveTransaction.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                                             bank_transaction={props.receive.bankAccountTransactionReceive.edges}
                                                             cash_transaction={props.receive.cashDepositReceiveTransaction.edges}
                                                             cheque_transaction={props.receive.chequeDeposit.edges}
                                                             withheld_tax={props.receive.withheldTaxReceive.edges}
                                                             credit_card_transaction={props.receive.creditCardTransaction.edges}
                                                             paymentGatewayTransaction={props.receive.paymentGatewayMethod.edges}
                                                             small_change={props.receive.smallChange.edges}
                                                             fee={props.receive.feeAccounting.edges}
                                                             check_type={"manuscript"}
                                                             receiveNumber={index + "manuscript" + atob(props.receive.id).split(':')[1]}
                                                             end_page={count_page - 1}
                                                             current_page={index}
                                                             key={index}
                                                             number_order={20*index}
                                                             otherTransaction = {props.allAccountRecordGroup.edges}
                                                             languages={props.userConfig.languages}
                                                             refTransaction={ref_transaction}
                                                            //  num_perpage={perPage}
                                                             num_perpage={rowPerPage}
                                                             query={this.props.location.state}

                                                    />
                                                )
                                            }
                                        )}

                                        {arrX.map((value, index) => {
                                                // copy
                                                return (
                                                    <Receive selfProject={props.selfProject}
                                                             receive={props.receive}
                                                             receiveTransaction={props.receive.receiveTransaction.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                                             bank_transaction={props.receive.bankAccountTransactionReceive.edges}
                                                             cash_transaction={props.receive.cashDepositReceiveTransaction.edges}
                                                             cheque_transaction={props.receive.chequeDeposit.edges}
                                                             withheld_tax={props.receive.withheldTaxReceive.edges}
                                                             credit_card_transaction={props.receive.creditCardTransaction.edges}
                                                             paymentGatewayTransaction={props.receive.paymentGatewayMethod.edges}
                                                             small_change={props.receive.smallChange.edges}
                                                             fee={props.receive.feeAccounting.edges}
                                                             check_type={"copy"}
                                                             receiveNumber={index + "copy" + atob(props.receive.id).split(':')[1]}
                                                             end_page={count_page - 1}
                                                             current_page={index}
                                                             number_order={20*index}
                                                             key={index}
                                                             otherTransaction = {props.allAccountRecordGroup.edges}
                                                             languages={props.userConfig.languages}
                                                             refTransaction={ref_transaction}
                                                            //  num_perpage={perPage}
                                                            num_perpage={rowPerPage}
                                                             query={this.props.location.state}
                                                    />
                                                )
                                            }
                                        )}
                                    </React.Fragment>
                                )

                            } else {
                                return (
                                    <React.Fragment>
                                        {arrX.map((value, index) => {
                                            return (
                                                <Receive selfProject={props.selfProject}
                                                         receive={props.receive}
                                                         receiveTransaction={props.receive.receiveTransaction.edges.slice(rowPerPage * index, rowPerPage * (index + 1))}
                                                         bank_transaction={props.receive.bankAccountTransactionReceive.edges}
                                                         cash_transaction={props.receive.cashDepositReceiveTransaction.edges}
                                                         paymentGatewayTransaction={props.receive.paymentGatewayMethod.edges}
                                                         cheque_transaction={props.receive.chequeDeposit.edges}
                                                         withheld_tax={props.receive.withheldTaxReceive.edges}
                                                         credit_card_transaction={props.receive.creditCardTransaction.edges}
                                                         small_change={props.receive.smallChange.edges}
                                                         fee={props.receive.feeAccounting.edges}
                                                         check_type={this.state.check_type}
                                                         receiveNumber={index + "receive" + atob(props.receive.id).split(':')[1]}
                                                         end_page={count_page - 1}
                                                         current_page={index}
                                                         number_order={20*index}
                                                         key={index}
                                                         otherTransaction = {props.allAccountRecordGroup.edges}
                                                         languages={props.userConfig.languages}
                                                         refTransaction={ref_transaction}
                                                         num_perpage={perPage}
                                                         query={this.props.location.state}
                                                />
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            }

                        }
                        return <div className="text-center"><Loading/></div>;
                    }}
                />

            </React.Fragment>
        );
    }
}

export default WrapperReceive;
