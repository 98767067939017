/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type BankUnitDepositStatus = "DURING" | "FINISHED" | "%future added value";
export type bankAccountQueryVariables = {|
  first?: ?number,
  last?: ?number,
  depositStatus?: ?string,
  juristic?: ?string,
|};
export type bankAccountQueryResponse = {|
  +bankAccountViewer: ?{|
    +allBankAccount: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +docNumber: string,
          +accountNumber: string,
          +accountName: string,
          +accountNameEn: ?string,
          +accountType: BankAccountAccountType,
          +receivePayment: boolean,
          +makePayment: boolean,
          +mobilePayment: boolean,
          +crossBilling: boolean,
          +bankName: string,
          +bankLogo: string,
          +branch: string,
          +status: boolean,
          +bankUnit: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +automaticCal: boolean,
                +issuedDate: ?any,
                +dueDate: ?any,
                +interestPerYear: number,
                +price: number,
                +calculationDate: ?any,
                +depositStatus: BankUnitDepositStatus,
                +bankNotification: ?{|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +status: boolean,
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |}
      |}>,
      +totalCount: ?number,
    |}
  |}
|};
export type bankAccountQuery = {|
  variables: bankAccountQueryVariables,
  response: bankAccountQueryResponse,
|};
*/


/*
query bankAccountQuery(
  $first: Int
  $last: Int
  $depositStatus: String
  $juristic: ID
) {
  bankAccountViewer {
    allBankAccount(status: true, accountType_In: "fixed_deposit", first: $first, last: $last) {
      edges {
        node {
          id
          docNumber
          accountNumber
          accountName
          accountNameEn
          accountType
          receivePayment
          makePayment
          mobilePayment
          crossBilling
          bankName
          bankLogo
          branch
          status
          bankUnit(depositStatus: $depositStatus) {
            edges {
              node {
                id
                automaticCal
                issuedDate
                dueDate
                interestPerYear
                price
                calculationDate
                depositStatus
                bankNotification(juristic: $juristic) {
                  edges {
                    node {
                      id
                      status
                    }
                  }
                }
              }
            }
          }
        }
      }
      totalCount
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "depositStatus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "juristic"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "accountType_In",
      "value": "fixed_deposit"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "last",
      "variableName": "last"
    },
    {
      "kind": "Literal",
      "name": "status",
      "value": true
    }
  ],
  "concreteType": "BankAccountNodeConnection",
  "kind": "LinkedField",
  "name": "allBankAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BankAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BankAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "docNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountNameEn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accountType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "receivePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "makePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobilePayment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "crossBilling",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "bankLogo",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "branch",
              "storageKey": null
            },
            (v5/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "depositStatus",
                  "variableName": "depositStatus"
                }
              ],
              "concreteType": "BankUnitNodeConnection",
              "kind": "LinkedField",
              "name": "bankUnit",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BankUnitNodeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "BankUnitNode",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "automaticCal",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "issuedDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dueDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "interestPerYear",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "price",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "calculationDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "depositStatus",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "juristic",
                              "variableName": "juristic"
                            }
                          ],
                          "concreteType": "BankNotificationNodeConnection",
                          "kind": "LinkedField",
                          "name": "bankNotification",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "BankNotificationNodeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "BankNotificationNode",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v4/*: any*/),
                                    (v5/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "bankAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "bankAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountViewer",
        "kind": "LinkedField",
        "name": "bankAccountViewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90ce33223ef77bc543a9f81f8a2cedda",
    "id": null,
    "metadata": {},
    "name": "bankAccountQuery",
    "operationKind": "query",
    "text": "query bankAccountQuery(\n  $first: Int\n  $last: Int\n  $depositStatus: String\n  $juristic: ID\n) {\n  bankAccountViewer {\n    allBankAccount(status: true, accountType_In: \"fixed_deposit\", first: $first, last: $last) {\n      edges {\n        node {\n          id\n          docNumber\n          accountNumber\n          accountName\n          accountNameEn\n          accountType\n          receivePayment\n          makePayment\n          mobilePayment\n          crossBilling\n          bankName\n          bankLogo\n          branch\n          status\n          bankUnit(depositStatus: $depositStatus) {\n            edges {\n              node {\n                id\n                automaticCal\n                issuedDate\n                dueDate\n                interestPerYear\n                price\n                calculationDate\n                depositStatus\n                bankNotification(juristic: $juristic) {\n                  edges {\n                    node {\n                      id\n                      status\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n      totalCount\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d4e74918c8a4a0e139ed03fe8fcb5d4';

module.exports = node;
