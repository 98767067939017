import React, { Component } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import Wrapper from "../../components/wrapper";
import WrapperContent from "../../components/wrapper/wrapperContent";
import { Translation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import ReactQuillAdapter from "../../libs/reactQuillAdapter";
import { commitMutation } from "react-relay";
import Swal from "sweetalert2";
import { format } from "date-fns";
import BackButtonIcon from '../../components/BackBtn/indexBack';
import _ from 'lodash'
import upload from "../../libs/upload";
import i18next from "i18next";


const mutation = graphql`
  mutation createUpdateEDMMutation($input: CreateAndUpdateEmailMarketingInput!) {
    createAndUpdateEmailMarketing (input: $input) {
      ok
      message   
    }
  }
`;

class CreateUpdateEDM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeEvent: "",
      title: "",
      detail: "",
      imageTitle: "",
      imageTitlePathShow: "",
      imageMore: [],
      savingData: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this)
    this.submitFrom = this.submitFrom.bind(this)
  }

  componentWillMount() {

  }

  queryNews() {
    if (this.props.match.params.id) {
      // fetchQuery(environment, query, { id: this.props.match.params.id }).then(
      //   (response) => {
      //     if (response) {
      //       if(response.news.scheduleTime) {
      //           let scheduleTimeConvert = new Date(response.news.scheduleTime);
      //           this.setState({ news: response.news, temp_schedule_date: scheduleTimeConvert, temp_schedule_time: scheduleTimeConvert, schedule_status: true, newCreate: false, status_old: response.news.status });
      //         } else {
      //           let date = new Date();
      //           this.setState({ news: response.news,temp_schedule_time: date.setDate(date.getDate()+1), newCreate: false, status_old: response.news.status });
      //       }
      //     }
      //   }
      // );
    }
  }

  // เนื้อหา When Onchage
  handleInputChange(e) {
    let event = _.cloneDeep(e);
    let value = event.target.value;


    this.setState({ detail: value })
    // this.setState((prevState) => {
    // return _.set(_.cloneDeep(prevState), event.target.name, value);
    // });
  }
  callUpload = async (path, file) => {
    return new Promise(async (resolve, reject) => {
      let res = await upload("/edm/img" + path, file);
      resolve(res);
    });
  };
  uploadFile(e, typeFor) {
    let event = _.cloneDeep(e);
    // let value = event.target.value
    let files = event.currentTarget.files[0];
    if (files) {
      if (files?.size > 10485760) {
        Swal.fire(i18next.t("Allaction:Failed to upload file"), i18next.t("edmList:Attach more pictures Image files (.jpg and .png only) and file size not over 2000 x 2000 pixels"), 'error')
      }

      else if (files?.type !== 'image/jpeg' && files?.type !== 'image/png' && files?.type !== 'image/jpg') {
        Swal.fire(i18next.t("edmList:Failed to upload file"), i18next.t("edmList:Attach more pictures Image files (.jpg and .png only) and file size not over 2000 x 2000 pixels"), 'error')
      }

      else {
        let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
        if (typeFor === "main") {
          // let fileOldList = _.cloneDeep(this.state.imageTitle);
          this.callUpload('', new_file).then((data) => {
            let dese = "edm/img/" + new_file.name
            // fileOldList.push({ emailMarketingImageId: "", fileName: dese, viewPtath: data.location })
            this.setState({ imageTitle: dese, imageTitlePathShow: data.location })
          });
        } else {
          let fileOldList = _.cloneDeep(this.state.imageMore);
          this.callUpload('', new_file).then((data) => {
            let dese = "edm/img/" + new_file.name
            fileOldList.push({ emailMarketingImageId: "", fileName: dese, viewPtath: data.location })
            this.setState({ imageMore: fileOldList })
          });
        }
      }
    }
  }

  submitFrom(e) {
    e.preventDefault();

    Swal.fire({
      title: i18next.t("edmList:Please verify your identity before using the EDM system"),
      type: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      html:
        '<div class="row  text-left">' +
        `<div class="col-12 mt-3"><label class="headerLabel18 text-center">${i18next.t("edmList:Please enter your password to verify your identity")}</label></div>` +
        '<div class="col-12 mt-3"><input type="text" id="password" class="form-control" /></div>' +
        '</div>',
      preConfirm: () => ({
        otherStatusName: document.getElementById("password").value,
      }),
    }).then((c) => {
      if (c.value.otherStatusName === "f3HYpp5,y%66TLu?") {
        this.setState({ savingData: true })
        let { title, detail, imageTitle, imageMore, typeEvent } = this.state

        let variables = {
          "input": {
            "emailMarketing": {
              "emailMarketingId": "",
              "title": title,
              "detail": detail,
              "previewImage": imageTitle,
              "status": "publish",
              "scheduleTime": format(new Date(), "YYYY-MM-DD"),
              "emailMarketingImage": imageMore
            }
          }
        }

        commitMutation(environment,
          {
            mutation, variables,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (response?.createAndUpdateEmailMarketing?.ok) {
                Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                  this.props.history.push("/contact/EDM")
                  // this.setState({ redirectToList: true });
                });
              } else {
                // Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning');
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), response?.createAndUpdateEmailMarketing?.warningText, 'warning');
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
            },
          }
        )

        // CreateUpdateStaff(data, onSubmitSuccess, onSubmitError)
        // this.props.history.push("/project/staff/loading")
      }else{
        this.setState({ savingData: false })
        Swal.fire(i18next.t("edmList:Incorrect password") , i18next.t("edmList:Please re-confirm to submit your information again") , 'error')
      }
    })
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <Translation>
            {t =>
              <form onSubmit={this.submitFrom}>
                <div className="container-fluid box claim emd" id="news" >
                  <BackButtonIcon
                    addLinkChk={false}
                    LinkBack={"/contact/EDM"}
                    LinkText={t('edmList:Create EMD System')}
                  />

                  <div className="row create">
                    <div className="col-6">
                      <div className="card p-2">
                        <div className="card-body">
                          <div className="col-12">
                            <label className="headerLabel18">{i18next.t("edmList:Type")} <span className="text-red">*</span></label>
                            <input type={"radio"} name="type" defaultChecked={true} className="ml-2" />  E-mail
                          </div>

                          <div className="col-12 mt-5">
                            <label className="headerLabel18">{i18next.t("edmList:Topic")} <span className="text-red">*</span></label>
                            <input type={"text"} name="title" className="form-control"
                              onKeyDown={(e) => this.setState({ title: e.target.value })}
                              onChange={(e) => this.setState({ title: e.target.value })}
                              onInvalid={e => e.target.setCustomValidity(i18next.t("edmList:Please enter title"))}
                              onInput={e => e.target.setCustomValidity('')} />
                          </div>

                          <div className="col-12 mt-5">
                            {
                              this.state.imageTitlePathShow ?
                                <React.Fragment>
                                  <img src={this.state.imageTitlePathShow} style={{ width: '100%', height: 200, objectFit: 'contain' }} />
                                  <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder={i18next.t("edmList:Contact number")} accept='application/.pdf,.png,.jpg'
                                    onChange={(e) => this.uploadFile(e, 'main')} />
                                </React.Fragment>

                                :

                                <React.Fragment>
                                  <label className="headerLabel18">{i18next.t("edmList:Title image Image")} <span className="text-red">  {i18next.t("edmList:files .jpg, .png only, size not over 2000 x 2000 pixels")}</span></label>
                                  <h3></h3>

                                  <label htmlFor="uploadFile" className="upload-btn-wrapper" >
                                    <button style={{
                                      alignItems: 'center',
                                      verticalAlign: 'center',
                                      padding: 30,
                                      borderStyle: 'dashed',
                                      width: '100%',
                                      backgroundColor: 'transparent', borderRadius: 10, borderWidth: 1
                                    }}>
                                      <img src={process.env.PUBLIC_URL + "/images/icons/plus-icon.png"} style={{ width: 20, height: 20 }} />


                                    </button>
                                    <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder={i18next.t("edmList:Contact number")} accept='application/.pdf,.png,.jpg'
                                      onChange={(e) => this.uploadFile(e, 'main')} />

                                  </label>
                                </React.Fragment>


                            }

                            {/* <input type={"file"} name="title" className="form-control"
                              onInvalid={e => e.target.setCustomValidity('กรุณาระบุหัวข้อ')}
                              onInput={e => e.target.setCustomValidity('')} /> */}
                          </div>

                          <div className="col-12 mt-5">
                            <label className="headerLabel18">{i18next.t("edmList:Content")} <span className="text-red">*</span></label>
                            <ReactQuillAdapter
                              name="detail"
                              value={this.state.detail}
                              onChange={this.handleInputChange}
                            // readOnly={
                            //   this.props.match.params.status === "deleted"
                            // }
                            />
                          </div>


                          <div className="col-12 mt-5">
                            <label className="headerLabel18">{i18next.t("edmList:Attach more pictures")} <span className="text-red"> {i18next.t("edmList:Image files (.jpg and .png only) and file size not over 2000 x 2000 pixels")}</span></label>
                            <h3></h3>

                            {
                              this.state.imageMore?.map((n) => {
                                return (
                                  <img src={n.viewPtath} style={{ width: 200, height: 200, objectFit: 'cover' }} />
                                )
                              })
                            }

                            <label htmlFor="uploadFileMore" className="upload-btn-wrapper"
                            >
                              <button style={{ alignItems: 'center', verticalAlign: 'center', padding: 30, borderStyle: 'dashed', backgroundColor: 'transparent', borderRadius: 10, borderWidth: 1 }}>
                                <img src={process.env.PUBLIC_URL + "/images/icons/plus-icon.png"} style={{ width: 20, height: 20 }} />


                              </button>
                              <input type="file" name='file' id="uploadFileMore" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='application/.pdf,.png,.jpg'
                                onChange={(e) => this.uploadFile(e, 'more')}
                              />

                            </label>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="col-6 text-center">
                      <div className="card p-10" style={{ alignItems: 'center' }}>
                        {
                          this.state.savingData ?
                            <button
                              type="button"
                              className="btn btn-primary mt-5 mb-5"
                              style={{ width: 300 }} disabled >
                              {i18next.t("edmList:Confirm to submit your information")}
                            </button>
                            :
                            <button
                              type="submit"
                              className="btn btn-primary mt-5 mb-5"
                              style={{ width: 300 }} >
                              {i18next.t("edmList:Confirm to submit your information")}
                            </button>
                        }

                        <div className="phone-preview">
                          <div className="inner" >
                            {this.state.imageTitle && (
                              <img
                                src={this.state.imageTitlePathShow}
                                alt="news"
                                className="img-fluid mt-2 mb-2"
                              />
                            )}
                            <h6>{this.state.title}</h6>
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: this.state.detail,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>






                </div>
              </form>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default CreateUpdateEDM;
