/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractRevenueContractForm = "A_1_YEAR" | "A_3_MONTH" | "A_6_MONTH" | "OTHER" | "%future added value";
export type ContractRevenueStatus = "IN_PROGRESS" | "NEAR_END" | "NOT_RENEW" | "RENEW" | "VOID" | "WAIT" | "%future added value";
export type contractServiceViewQueryVariables = {|
  id?: ?string
|};
export type contractServiceViewQueryResponse = {|
  +oneContractRevenue: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +issuedDate: any,
        +debtor: {|
          +refNumber: string,
          +name: string,
        |},
        +contractNumber: ?string,
        +contractForm: ContractRevenueContractForm,
        +otherContractForm: ?string,
        +startContractDate: any,
        +endContractDate: any,
        +status: ContractRevenueStatus,
        +remark: string,
        +creator: ?string,
        +voidRemark: string,
        +voider: ?string,
        +voidDate: ?any,
        +fileAttachment: string,
        +fileName: string,
        +notiBeforeEnd: ?number,
        +pricePerPeriod: ?number,
        +total: ?number,
        +chartOfAccount: ?{|
          +name: string
        |},
        +contractRevenueList: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +productAndService: ?{|
                +productCode: string,
                +name: string,
              |},
              +description: string,
              +numPeriod: ?number,
              +pricePerPeriod: ?number,
              +total: ?number,
            |}
          |}>
        |},
        +areaCompensationMonth: ?number,
        +areaCompensationYear: ?number,
      |}
    |}>
  |}
|};
export type contractServiceViewQuery = {|
  variables: contractServiceViewQueryVariables,
  response: contractServiceViewQueryResponse,
|};
*/


/*
query contractServiceViewQuery(
  $id: String
) {
  oneContractRevenue(crId: $id) {
    edges {
      node {
        id
        docNumber
        issuedDate
        debtor {
          refNumber
          name
          id
        }
        contractNumber
        contractForm
        otherContractForm
        startContractDate
        endContractDate
        status
        remark
        creator
        voidRemark
        voider
        voidDate
        fileAttachment
        fileName
        notiBeforeEnd
        pricePerPeriod
        total
        chartOfAccount {
          name
          id
        }
        contractRevenueList {
          edges {
            node {
              id
              productAndService {
                productCode
                name
                id
              }
              description
              numPeriod
              pricePerPeriod
              total
            }
          }
        }
        areaCompensationMonth
        areaCompensationYear
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "crId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issuedDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractForm",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherContractForm",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startContractDate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endContractDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creator",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidRemark",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voider",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voidDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileAttachment",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notiBeforeEnd",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pricePerPeriod",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productCode",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numPeriod",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "areaCompensationMonth",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "areaCompensationYear",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "contractServiceViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractRevenueNodeConnection",
        "kind": "LinkedField",
        "name": "oneContractRevenue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractRevenueNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractRevenueNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "debtor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractRevenueListNodeConnection",
                    "kind": "LinkedField",
                    "name": "contractRevenueList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractRevenueListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContractRevenueListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v23/*: any*/),
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v26/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "contractServiceViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ContractRevenueNodeConnection",
        "kind": "LinkedField",
        "name": "oneContractRevenue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContractRevenueNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContractRevenueNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNode",
                    "kind": "LinkedField",
                    "name": "debtor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChartOfAccountNode",
                    "kind": "LinkedField",
                    "name": "chartOfAccount",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContractRevenueListNodeConnection",
                    "kind": "LinkedField",
                    "name": "contractRevenueList",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContractRevenueListNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContractRevenueListNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductAndServiceNode",
                                "kind": "LinkedField",
                                "name": "productAndService",
                                "plural": false,
                                "selections": [
                                  (v23/*: any*/),
                                  (v6/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v26/*: any*/),
                  (v27/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fea055b4bdb88d8ed690214e9b20b4c",
    "id": null,
    "metadata": {},
    "name": "contractServiceViewQuery",
    "operationKind": "query",
    "text": "query contractServiceViewQuery(\n  $id: String\n) {\n  oneContractRevenue(crId: $id) {\n    edges {\n      node {\n        id\n        docNumber\n        issuedDate\n        debtor {\n          refNumber\n          name\n          id\n        }\n        contractNumber\n        contractForm\n        otherContractForm\n        startContractDate\n        endContractDate\n        status\n        remark\n        creator\n        voidRemark\n        voider\n        voidDate\n        fileAttachment\n        fileName\n        notiBeforeEnd\n        pricePerPeriod\n        total\n        chartOfAccount {\n          name\n          id\n        }\n        contractRevenueList {\n          edges {\n            node {\n              id\n              productAndService {\n                productCode\n                name\n                id\n              }\n              description\n              numPeriod\n              pricePerPeriod\n              total\n            }\n          }\n        }\n        areaCompensationMonth\n        areaCompensationYear\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24be472dbf89be0b87b8dbf194188d30';

module.exports = node;
