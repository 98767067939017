import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import Wrapper from "../../../components/wrapper";
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import Swal from "sweetalert2";
import { format } from "date-fns";
import _ from "lodash";
import residentialAPI from '../../serviceAPI'
import OwnershipInformation from '../../../developer/unitStatus/transferOfOwnershipCreate/ownershipInformation';
import upload from '../../../libs/upload';
import ownershipAPI from '../../ownership/ownershipAPI'
import {Redirect} from 'react-router';
import './ownerDetail.scss'
import i18next from 'i18next';
class ownershipChangeOwnership extends Component{
    constructor(props){
        super(props);
        this.state={
            token : localStorage.getItem("token"),
            roomNo : '',
            ownershipName : '',
            refNumber : '',
            size : '',
            dateConveyance : new Date(),
            tenant : [],
            redirectToList : false,
            conveyance : '',
            lastDateConveyance : ''
        }
        this.onSubmit = this.onSubmit.bind(this);

    }

    async componentDidMount(){
        await residentialAPI.getResidential(this.state.token,atob(this.props.match.params.id).split(":")[1]).then((response) => {
            if(response.data){
                let name = '';
                _.forEach(response.data.ownershipName , (contact,index) => {
                    name = index === 0 ? contact : name + " , " + contact
                 })
                this.setState({
                    roomNo : response.data.name,
                    ownershipName : name,
                    refNumber : response.data.refNumber,
                    size : response.data.size,
                    lastDateConveyance : response.data.dateConveyance,
                    conveyance : response.data.conveyance
                })
            }
        })

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    getTenant = (tenant) => {
        this.setState({
            tenant : tenant
        })
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await upload("/ownership/data" + path, file);
          resolve(res);
        });
    };

    async onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true});
        let tenantList = [];
        let validDateConveyance = false
        if(this.state.dateConveyance === '' || this.state.dateConveyance === null){
            validDateConveyance = true
            Swal.fire("กรุณาใส่วันที่โอนกรรมสิทธิ์!", "", "warning").then(() => {
                this.setState({
                    loading : false
                })
            });
        }
        Swal.fire({
            title: i18next.t("ownerShipTransfer:Are you sure to ownership transfer")+"?",
            type: 'warning',
            text: i18next.t("ownerShipTransfer:If the ownership has been changed, all members in this room will be removed."),
            showCancelButton: true,
            confirmButtonColor: '#1567B4',
            cancelButtonColor: '#E14558',
            confirmButtonText: i18next.t('settingNote:Yes'),
            cancelButtonText: i18next.t('settingNote:Cancel'),
        }).then(async (result) => {
            if (result.value) {
                if(!validDateConveyance){
                    for (const tenant of this.state.tenant) {
                        let image = ''
                        if(tenant.imageUpload !== ''){
                            await this.callUpload('', tenant.imageUpload).then((data) => {
                                image = data.key.replace("silverman-storage/private/", "");
                            });
                        }
                        tenantList.push({
                            image:image,
                            nameTitle: tenant.nameTitle !== 'other_input' ? tenant.nameTitle : tenant.namePrefixOther,
                            firstName: tenant.firstName,
                            lastName: tenant.nameTitle !== 'บริษัท' ? tenant.lastName : '',
                            firstNameEng : tenant.firstNameEng,
                            lastNameEng : tenant.nameTitle !== 'บริษัท' ? tenant.lastNameEng : '',
                            occupation : tenant.nameTitle !== 'บริษัท' ? tenant.occupation : '',
                            tenantStatus : tenant.tenantStatus,
                            ethnicity : tenant.ethnicity,
                            cardType : tenant.cardType,
                            idCardNumber:tenant.idCardNumber,
                            dateOfBirth: (tenant.nameTitle !== 'บริษัท' && tenant.dateOfBirth !== '')? format(tenant.dateOfBirth,'YYYY-MM-DD') : null,
                            gender:tenant.gender,
                            countries: tenant.countries !== '' ? tenant.countries : 'TH',
                            phone: tenant.phone,
                            email: tenant.email,
                            phone2 : tenant.phone2
                        })
                    }
        
                    let variables = {
                        residentialID : atob(this.props.match.params.id).split(":")[1],
                        dateConveyance : format(this.state.dateConveyance , 'YYYY-MM-DD'),
                        tenant: tenantList,
                    };
        
                    await ownershipAPI.changeOwnership(variables,this.state.token).then((response) => {
                        if(response.statusMessage === 'SUCCESS'){
                            this.onSuccess()
                        }else {
                            this.onError()
                        }
                    })
                }
            }else{
                this.setState({
                    loading:false
                })
            }
        })
       
    }

    onSuccess = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => { 
            this.setState({ redirectToList: true });
        });
      }
    
    onError = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
    }

    render(){
        if (this.state.redirectToList) {
            return <Redirect to={"/register/residential/detail/ownership-detail/" + this.props.match.params.id}/>;
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <Translation>
                    {
                        t =>
                            <WrapperContent>
                                <div className="container-fluid box" id="developer-form">
                                    <div className="row justify-content-between">
                                        <div className="col-12">
                                            <h4 className="mb-4">
                                                <Link to={"/register/residential/detail/ownership-detail/" + this.props.match.params.id}>
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                {t("changeOwnership:Ownership transfer")}
                                                <span className="ml-3 warning">
                                                    *{t("changeOwnership:If the ownership has been changed")}, {t("changeOwnership:all residents in the room will be removed entirely.")}
                                                </span>
                                            </h4>
                                            
                                        </div>
                                        <div className="col"></div>
                                    </div>
                                    <form onSubmit={this.onSubmit} id="ownership">
                                        <div className="content-inner content-inner-padding mt-3">
                                            <div>
                                                <div className="row d-flex align-items-center mt-4">
                                                    <div className="col form-inline">
                                                        <h4 className="mr-4">{t("changeOwnership:Ownership transfer information")}</h4>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="box-detail">
                                                    <div className="row mb-3 ">
                                                        <div className="col-3 mt-2">
                                                            <h5>{t("changeOwnership:Room no.")}</h5>
                                                        </div>
                                                        <div className="col-9 mt-2">
                                                            <h5>{t("changeOwnership:Area size")}</h5>
                                                        </div>
                                                        <div className="col-3">
                                                            <span>{this.state.roomNo}</span>
                                                        </div>
                                                        <div className="col-9">
                                                            <span>{this.state.size}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-12">
                                                            <h5>{t("changeOwnership:Assignor name")}</h5>
                                                        </div>
                                                        <div className="col-12">
                                                            <span>{this.state.ownershipName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row d-flex mt-3 align-items-center">
                                                    <div className="col form-inline">
                                                        <h4 className="mr-4 mt-4">{t("changeOwnership:Assignee name")}</h4>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mt-2">
                                                    <div className="col-12"><h5>{t("changeOwnership:Transfer date")} <span className="text-danger">*</span></h5></div>
                                                    <br/>
                                                    <div className="col-12">
                                                        <DatePickerAdapter 
                                                            selected={this.state.dateConveyance}
                                                            name="dateConveyance" className="form-control w-100"
                                                            onChange={this.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <OwnershipInformation callBack={this.getTenant} state= {this.state} />
                                                </div>
                                                <br/>
                                            </div>
                                            <div className="row d-flex align-items-center">
                                                <div className="col form-inline">
                                                    <h4 className="mr-4">{t("changeOwnership:Unit status")}</h4>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row mb-3 ">
                                                <div className="col-4">
                                                    <h5>{t("changeOwnership:Current status")}: </h5>
                                                </div>
                                                <div className="col-8">
                                                    <span>{this.state.conveyance === 'not_transferred' ? t("changeOwnership:Not transfer ownership") : t("changeOwnership:Transferred ownership")}</span>
                                                </div>
                                                <div className="col-4">
                                                    <h5>{t("changeOwnership:Latest transfer date")}: </h5>
                                                </div>
                                                <div className="col-8">
                                                    <span>{this.state.lastDateConveyance  ? format(this.state.lastDateConveyance,'DD/MM/YYYY') : t("changeOwnership:Not transfer ownership history") }</span>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-11 mt-2 float-right">
                                            <Link to={"/register/residential/detail/ownership-detail/" + this.props.match.params.id}>
                                                <button
                                                    type="button"
                                                        className="btn-cancel float-right mr-3"
                                                    >
                                                    <span>{t("Allaction:cancel")}</span>
                                                </button>
                                            </Link>
                                            <button type="submit" form="ownership" className="btn-save float-right mr-3">
                                                {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle ml-2"/>
                                                }
                                                <span>{t("Allaction:save")}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </WrapperContent>
                    }
                </Translation>
            </Wrapper>
            )
        }
}
export default ownershipChangeOwnership

