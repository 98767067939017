import React, { Component } from 'react'
import { format } from "date-fns";
import _ from "lodash"
import numberWithComma from "../../../libs/numberWithCommaReport";
import getNameResidential from "../../../libs/getNameResidential"
import { Dropdown } from "react-bootstrap";

require('core-js/modules/es.promise');
require('core-js/modules/es.string.includes');
require('core-js/modules/es.object.assign');
require('core-js/modules/es.object.keys');
require('core-js/modules/es.symbol');
require('core-js/modules/es.symbol.async-iterator');
require('regenerator-runtime/runtime');
const Excel = require('exceljs/dist/es5/exceljs.browser');

export default class stockMovementListReportExcel extends Component {


  getStatusStock = (totalStock, reorderPoint) => {
    let sumResultInput = 0
    let resultText = ""
    sumResultInput = totalStock - reorderPoint

    if(sumResultInput > 0){
      resultText = "พร้อมใช้งาน"
    }else if(sumResultInput < 0){
      resultText = "ใกล้หมด"
    }else if(sumResultInput === 0){
      resultText = "ถึงจุดสั่งซื้อแล้ว"
    }
    return resultText
  }

  downloadExcel = () => {
    let workbook = new Excel.Workbook();
    var worksheet = workbook.addWorksheet('StockMovementReportsAndSupplies');

    // setWidthcolumns
    let columns = [
      { width: 10 },
      { width: 15 },
      { width: 25 },
      { width: 25 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ]

    // setBorder
    let borders = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    let fontSizes = { size: 11.5 }
    let textCenter = { vertical: 'middle', horizontal: 'center' };
    let fontBold = { size: 11.5, bold: true };

    // worksheet.getCell('A1').value = this.props.selfProject.name
    // worksheet.getCell('A1').font = {
    //   size: 11.5,
    //   bold: true
    // };

    worksheet.getCell('A1').value = "รายงานรหัสสินค้า"
    worksheet.getCell('A1').font = fontBold;


    let cell = 0

    let valueHead = ["ลำดับ","รหัส","รายการ","วันที่เคลื่อนไหวล่าสุด","จำนวนคงเหลือ","สถานะ"]
    
    worksheet.getRow(2).values = valueHead

    _.forEach(valueHead, (itemHead, indexHead) => {
      cell = worksheet.getRow(2).getCell(1 + indexHead)
      cell.font = fontBold;
      cell.border = borders;
      cell.alignment = textCenter

    })

    _.forEach(this.props.reportProductTransactionExport, (item, index) => {
    
      console.log("item",item);

      let total_stock = 0;
      if(item.node.stock.edges.length > 0){
        item.node.stock.edges.forEach((stock, t_index) => {
            total_stock = total_stock + stock.node.leftInStock;
        }) 
      }

      var dataRow = worksheet.addRow();
      cell = dataRow.getCell(1);
      cell.value = index + 1
      cell.border = borders;
      cell.alignment = textCenter

      cell = dataRow.getCell(2);
      cell.value = item.node.productCode
      cell.border = borders;
      cell.alignment = textCenter

      cell = dataRow.getCell(3);
      cell.value = item.node.name
      cell.border = borders;
    
      cell = dataRow.getCell(4);
      cell.value = format(new Date(item.node.updated), "DD/MM/YYYY")
      cell.border = borders;
      cell.alignment = textCenter

      cell = dataRow.getCell(5);
      cell.value = total_stock
      cell.border = borders;

      cell = dataRow.getCell(6);
      cell.value = this.getStatusStock(total_stock, item.node.reorderPoint)
      cell.border = borders;
      cell.alignment = textCenter
     })


    worksheet.columns = columns;

    workbook.xlsx.writeBuffer()
    .then((data) => {
      const blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      let nameFile = `ExcelStockMovementReportsAndSupplies`
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.href = url;
      a.download = nameFile;
      a.click();
    });
  }


  render() {
    return (
      <Dropdown.Item onClick={this.downloadExcel}>
        Excel
      </Dropdown.Item>
    )
  }
}
