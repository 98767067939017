/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingFavoriteQueryVariables = {||};
export type settingFavoriteQueryResponse = {|
  +getFavoriteApp: ?$ReadOnlyArray<?string>
|};
export type settingFavoriteQuery = {|
  variables: settingFavoriteQueryVariables,
  response: settingFavoriteQueryResponse,
|};
*/


/*
query settingFavoriteQuery {
  getFavoriteApp
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "getFavoriteApp",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingFavoriteQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingFavoriteQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ab993484b3484b3118d28b9acc94b097",
    "id": null,
    "metadata": {},
    "name": "settingFavoriteQuery",
    "operationKind": "query",
    "text": "query settingFavoriteQuery {\n  getFavoriteApp\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7e9b8d27c1d664e68ba3c9baf4b3293e';

module.exports = node;
