import React from "react";
import { Component } from "react";
import {format} from "date-fns"
import _ from 'lodash'
import "../developer.scss"
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
class UnitStatusTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            residentialList : []
        }
    }
    render() {
        return (
            <Translation>
                {t =><React.Fragment>
                    <tbody>
                        {_.sortBy(this.props.residentialList,['unitStatusList','roomNo']).map((transaction,index) => {
                            return (
                                <tr key ={index}>
                                    <td className ="text-center">{this.props.indexFirst + index + 1}</td>   
                                    <td className ="text-center">
                                        <Link to={"/developer/unit-status/detail/" + transaction.unitStatusList.residentialID}>
                                            {transaction.unitStatusList.roomNo}
                                        </Link>

                                    </td>  
                                    <td className ="text-left">
                                        {transaction.unitStatusList.ownershipName.map((ownershipName,indexOwner) =>{
                                            return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
    
                                        })}
                                    </td>
                                    <td className ="text-center">{transaction.unitStatusList.type}</td>
                                    <td className ="text-center">{transaction.unitStatusList.size}</td>
                                    <td className ="text-center">{transaction.unitStatusList.ownershipRatio}</td>
                                    <td className ="text-center">{transaction.unitStatusList.floor}</td>
                                    {this.props.status === 'transfer-ownership' &&
                                        <React.Fragment>
                                            <td className="text-center">{transaction.unitStatusList.transferDate !== null ? format(transaction.unitStatusList.transferDate,'DD/MM/YYYY') : '-'}</td>	
                                            <td className="text-center">{transaction.unitStatusList.creator}</td>
                                        </React.Fragment>
                                    }
                                    
                                    {this.props.status !== 'transfer-ownership' &&
                                        <React.Fragment>
                                            
                                            <td className ="text-center">{transaction.unitStatusList.status}</td>
                                        </React.Fragment>
                                    }
                                    {this.props.status === 'not-transfer-ownership' && <td className ="text-center">{transaction.unitStatusList.numberOfResidents}</td>}
                                    {this.props.status === 'all'&&
                                        <td className ="text-center">
                                            <span className={transaction.unitStatusList.ownershipStatus === 'already_transferred' ? "round-status-ownership" :  "round-status-not-ownership"}>{t('developer:'+transaction.unitStatusList.ownershipStatus)}</span>
                                        </td>
                                    }
                                </tr> 
                            )
                        })
                    }  
                    </tbody>
                </React.Fragment>}
            </Translation>
        )

    }


} 

export default UnitStatusTable