/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChartOfAccountUpdateInput = {|
  id: string,
  chartOfAccount: ChartOfAccountInput,
  clientMutationId?: ?string,
|};
export type ChartOfAccountInput = {|
  name: string,
  nameEn?: ?string,
  chartOfAccountCode: string,
  historicalDebit: number,
  historicalCredit: number,
|};
export type ChartOfAccountUpdateMutationVariables = {|
  input: ChartOfAccountUpdateInput
|};
export type ChartOfAccountUpdateMutationResponse = {|
  +chartOfAccountUpdate: ?{|
    +ok: ?boolean,
    +warningText: ?string,
    +chartOfAccount: ?{|
      +id: string,
      +name: string,
      +nameEn: ?string,
      +nameTh: ?string,
      +chartOfAccountCode: string,
      +accountRecord: ?{|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +debit: number,
            +credit: number,
          |}
        |}>
      |},
    |},
  |}
|};
export type ChartOfAccountUpdateMutation = {|
  variables: ChartOfAccountUpdateMutationVariables,
  response: ChartOfAccountUpdateMutationResponse,
|};
*/


/*
mutation ChartOfAccountUpdateMutation(
  $input: ChartOfAccountUpdateInput!
) {
  chartOfAccountUpdate(input: $input) {
    ok
    warningText
    chartOfAccount {
      id
      name
      nameEn
      nameTh
      chartOfAccountCode
      accountRecord(typeOfRecord: "historical") {
        edges {
          node {
            id
            debit
            credit
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChartOfAccountUpdatePayload",
    "kind": "LinkedField",
    "name": "chartOfAccountUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccount",
        "kind": "LinkedField",
        "name": "chartOfAccount",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameEn",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameTh",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "chartOfAccountCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "typeOfRecord",
                "value": "historical"
              }
            ],
            "concreteType": "AccountRecordNodeConnection",
            "kind": "LinkedField",
            "name": "accountRecord",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountRecordNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountRecordNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "debit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "credit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "accountRecord(typeOfRecord:\"historical\")"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChartOfAccountUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChartOfAccountUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "876c5cffe5bbfa0b55c39de919187cc7",
    "id": null,
    "metadata": {},
    "name": "ChartOfAccountUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ChartOfAccountUpdateMutation(\n  $input: ChartOfAccountUpdateInput!\n) {\n  chartOfAccountUpdate(input: $input) {\n    ok\n    warningText\n    chartOfAccount {\n      id\n      name\n      nameEn\n      nameTh\n      chartOfAccountCode\n      accountRecord(typeOfRecord: \"historical\") {\n        edges {\n          node {\n            id\n            debit\n            credit\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '958976f05c344eb949e046da21f6504f';

module.exports = node;
