/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type reportDashboardSummaryQueryVariables = {|
  startYear?: ?number,
  startMonth?: ?number,
|};
export type reportDashboardSummaryQueryResponse = {|
  +productViewer: ?{|
    +productSummaryByStatusYearAndMonth: ?$ReadOnlyArray<?any>
  |}
|};
export type reportDashboardSummaryQuery = {|
  variables: reportDashboardSummaryQueryVariables,
  response: reportDashboardSummaryQueryResponse,
|};
*/


/*
query reportDashboardSummaryQuery(
  $startYear: Int
  $startMonth: Int
) {
  productViewer {
    productSummaryByStatusYearAndMonth(year: $startYear, month: $startMonth, productType: "service", productType2: "servicefine")
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startMonth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startYear"
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "month",
      "variableName": "startMonth"
    },
    {
      "kind": "Literal",
      "name": "productType",
      "value": "service"
    },
    {
      "kind": "Literal",
      "name": "productType2",
      "value": "servicefine"
    },
    {
      "kind": "Variable",
      "name": "year",
      "variableName": "startYear"
    }
  ],
  "kind": "ScalarField",
  "name": "productSummaryByStatusYearAndMonth",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportDashboardSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "reportDashboardSummaryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductAndServiceViewer",
        "kind": "LinkedField",
        "name": "productViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27715b26914603cc64d500bc46c463d7",
    "id": null,
    "metadata": {},
    "name": "reportDashboardSummaryQuery",
    "operationKind": "query",
    "text": "query reportDashboardSummaryQuery(\n  $startYear: Int\n  $startMonth: Int\n) {\n  productViewer {\n    productSummaryByStatusYearAndMonth(year: $startYear, month: $startMonth, productType: \"service\", productType2: \"servicefine\")\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '91dbac1f2dcd6e91fe1839f79cc09985';

module.exports = node;
