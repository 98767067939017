import React, {Component} from 'react';
import DatePickerAdapter from "../../../libs/datePickerAdapter";
import numberWithComma from "../../../libs/numberWithComma";
import Swal from "sweetalert2";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import {Redirect} from 'react-router'
import _ from "lodash"
import {format} from "date-fns";
import LightBox from "../../../libs/lightBox";
import i18n from 'i18next'


// const query = graphql`
//     query chequeClearingQuery($id: ID!){
//         chequeTransaction(id:$id){
//                 id
//           chequeNumber
//           refNumber
//           date
//           depositDate
//           price
//           status
//           payName
//           description
//           image
//           added
//           bankAccount{
//               docNumber
//               branch
//               accountNumber
//               accountName
//               bankName     
//             } 
//         }
        
//     }
// `;

// const mutation = graphql`
//     mutation chequeClearingMutation($input: UpdateChequeTransactionInput!){
//       updateChequeTransaction(input: $input){
//         ok
//       }
//     }
// `;

const cheque_detail = {
    id: '',
    chequeNumber: '',
    refNumber: '',
    date: '',
    depositDate: '',
    price: '',
    status: '',
    payName: '',
    description: '',
    bankAccount: {
        docNumber :'',
        branch: '',
        accountNumber: '',
        accountName: '',
        bankName: '',
    },

};

class ChequeDeposit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deposit_date: new Date(),
            bank_select: '',
            image_upload: '',
            loading: false,
            cheque_detail: cheque_detail,
            redirectToList: false,
            status: 'clearing',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.clearState = this.clearState.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    // componentWillMount() {
    //     fetchQuery(environment, query, {id: this.props.match.params.id}).then(data => {
    //         let deposit_date = new Date();
    //         if (data.chequeTransaction.depositDate) {
    //             deposit_date = new Date(data.chequeTransaction.depositDate)
    //         }
    //         this.setState({
    //             cheque_detail: data.chequeTransaction,
    //             deposit_date: deposit_date,
    //             image: data.chequeTransaction.image
    //         });
    //     });

    // }


    handleInputChange(e) {
        if (e.target.name === 'image_upload') {
            if (e.currentTarget.files[0] && e.currentTarget.files[0].type.split("/")[0] === 'image') {
                this.setState({image_upload: e.currentTarget.files[0]});
            }
        } else {

            this.setState({[e.target.name]: e.target.value})
        }
    }

    clearState() {
        this.setState({
            deposit_date: new Date(),
            bank_select: '',
        });
    }


    onSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        let variables = {
            input: {
                id: this.state.cheque_detail.id,
                depositDate: format(this.state.deposit_date, 'YYYY-MM-DD'),
                status: this.state.status,
            }

        };
         const uploadables = {image: this.state.image_upload};

        if (this.state.status === "clearing") {
            // commitMutation(
            //     environment,
            //     {
            //         mutation,
            //         variables,
            //         uploadables,
            //         onCompleted: (response) => {
            //             this.setState({loading: false});
            //             if (response.updateChequeTransaction.ok) {
            //                 Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
            //                     this.setState({redirectToList: true, loading: false})
            //                 });
            //             } else {
            //                 this.setState({loading: false});
            //                 Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
            //             }

            //         },
            //         onError: (err) => {
            //             this.setState({loading: false});
            //             Swal.fire('Error!', i18n.t("cheque_transaction:Please try again"), 'warning')
            //         },
            //     },
            // );
        }

        // void
        if (this.state.status === "wait") {
            Swal.fire({
                title: i18n.t("cheque_transaction:Are you sure to cancel this cheque transaction?"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: i18n.t("cheque_transaction:Cancel"),
                confirmButtonText: i18n.t("cheque_transaction:Yes")
            }).then((result) => {
                if (result.value) {
                    // commitMutation(
                    //     environment,
                    //     {
                    //         mutation,
                    //         variables,
                    //         uploadables,
                    //         onCompleted: (response) => {
                    //             this.setState({loading: false});
                    //             if (response.updateChequeTransaction.ok) {
                    //                 Swal.fire(i18n.t("cheque_transaction:Save successfully!"), '', 'success').then(() => {
                    //                     this.setState({redirectToList: true})
                    //                 });
                    //             } else {
                    //                 Swal.fire(i18n.t("cheque_transaction:Save unsuccessfully!"), '', 'warning');
                    //             }

                    //         },
                    //         onError: (err) => {
                    //             this.setState({loading: false});
                    //             Swal.fire('Error!', i18n.t("cheque_transaction:Please try again"), 'warning')
                    //         },
                    //     },
                    // );
                } else {
                    this.setState({loading: false});
                }
            })
        }

    }


    render() {
         if (this.state.redirectToList) {
            return <Redirect to="/accounting/finance/cheque/list/clearing"/>
        }
        return (

            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <div className="container-fluid box" id="invoice-list">
                        <form id="form-cheque" onSubmit={this.onSubmit}>
                            <div className="row justify-content-between">
                                <div className="col">
                                    <h3 className="mb-4">
                                        {this.state.cheque_detail.status === "WAIT" ?
                                            <Link to="/accounting/finance/cheque/list/wait">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                     alt="back" className="back"/>
                                            </Link>
                                            :
                                            <Link to="/accounting/finance/cheque/list/clearing">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                     alt="back" className="back"/>
                                            </Link>
                                        }
                                        <Translation>
                                            {
                                                t =>
                                                    <span>{this.state.cheque_detail.status === "WAIT" ? t('cheque:add_cheque_clearing') : t('cheque:cheque_transaction')}</span>
                                            }
                                        </Translation>
                                    </h3>
                                </div>
                                <div className="col text-right">
                                    {this.state.cheque_detail.status === 'CLEARING' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_delete'}) &&
                                    <Translation>
                                        {t=>
                                        <button className="btn btn-danger add mr-3" disabled={this.state.loading}
                                                onClick={() => this.setState({status: "wait"})}>
                                            {this.state.loading &&
                                            <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                            {t("cheque_transaction:Cancel this cheque transaction")}
                                        </button>}
                                    </Translation>
                                    }
                                    {this.state.cheque_detail.status === 'WAIT' && new Date(this.state.cheque_detail.added) >= new Date(2021,5,1) &&
                                        <Link
                                        to={{
                                            pathname: "/accounting/finance/cheque/list/print-cheque/all/",
                                            state: { checkList: [this.props.match.params.id] }
                                        }}>
                                        <Translation>
                                            {t=><button
                                                className="btn btn-primary add"
                                                disabled={this.state.loading}
                                            >
                                                {t("cheque_transaction:Print this cheque")}
                                            </button>}
                                        </Translation>
                                        </Link>
                                    }
                                </div>
                            </div>
                            <div className="content-inner">
                                <div className="card">
                                    <div className="card-body">


                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="contact"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Paid for")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="contact"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.payName}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Reference")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.refNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="chequeNumber"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Cheque No.")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="chequeNumber"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.chequeNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="chequeNumber"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Cheque date")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="chequeNumber"
                                                       className="col-form-label">
                                                           {format(this.state.cheque_detail.date, 'DD/MM/YYYY')}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>


                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="chequeNumber"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Bank code")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="chequeNumber"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.bankAccount.docNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Bank")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.bankAccount.bankName}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Branch")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.bankAccount.branch}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Account No.")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.bankAccount.accountNumber}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Detail")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {this.state.cheque_detail.description}
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-1">
                                                <Translation>
                                                    {t=>
                                                    <label htmlFor="ref_num"
                                                           className="col-form-label">
                                                        {t("cheque_transaction:Amount")}
                                                    </label>}
                                                </Translation>
                                            </div>
                                            <div className="col-5">
                                                <label htmlFor="ref"
                                                       className="col-form-label">
                                                    {numberWithComma(this.state.cheque_detail.price)} บาท
                                                </label>
                                            </div>
                                            <div className="col-1"/>
                                        </div>


                                        {_.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_edit'}) &&
                                        <React.Fragment>
                                            <hr/>
                                            <div className="row mt-3">
                                                <div className="col-1">
                                                    <Translation>
                                                        {t=>
                                                        <label htmlFor="dateDeposit"
                                                               className="col-form-label">
                                                            {t("cheque_transaction:Since")}
                                                        </label>}
                                                    </Translation>
                                                </div>
                                                <div className="col-2">
                                                    <DatePickerAdapter id="dateDeposit"
                                                                       className="form-control" name="deposit_date"
                                                                       selected={this.state.deposit_date}
                                                                       onChange={this.handleInputChange}
                                                                       disable={this.state.cheque_detail.status === 'CLEARING' ? true : false}
                                                                       require={true}/>
                                                </div>
                                                {this.state.cheque_detail.status === 'WAIT' &&
                                                <div className="col">
                                                    <Translation>
                                                        {t=>
                                                        <label
                                                            className={this.state.image_upload.name ? "btn btn-success mb-0 cursor font-medium mr-3" : "btn btn-secondary mb-0 cursor font-medium mr-3"}>
                                                            {this.state.image_upload.name ? <>{t("cheque_transaction:Done")}</> : <>{t("cheque_transaction:Attach file")}</>}
                                                            <input type="file" name="image_upload"
                                                                   onChange={this.handleInputChange}
                                                                   hidden/>
                                                        </label>}
                                                    </Translation>
                                                    {this.state.image_upload.name}
                                                </div>
                                                }
                                                <div className="col-1"/>
                                            </div>
                                        </React.Fragment>
                                        }

                                        <div className="p-2">
                                            {this.state.image ? <LightBox image={this.state.image} /> :<div></div>}
                                        </div>

                                    </div>
                                </div>

                                {this.state.cheque_detail.status === 'WAIT' && _.some(JSON.parse(localStorage.getItem("permission_list")), {codename: 'accounting_cheque_edit'}) &&

                                <div className="row mt-4">
                                    <div className="col-12 text-right">
                                        <div className="btn-group mr-2">
                                            {/* <Link to="/accounting/finance/cheque-deposit/list/not_deposit">
                                                <button type="button" className="btn btn-secondary add">
                                                    ยกเลิก
                                                </button>
                                            </Link> */}
                                        </div>
                                        <div className="btn-group mr-2">
                                            <Translation>
                                                {t=>
                                                <button type="submit" className="btn btn-primary add" form="form-cheque"
                                                        disabled={this.state.loading}>
                                                    {this.state.loading &&
                                                    <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                    {t("cheque_transaction:Deposit this cheque")}
                                                </button>}
                                            </Translation>
                                        </div>
                                    </div>
                                </div>
                                }


                            </div>
                        </form>

                    </div>
                </WrapperContent>

            </Wrapper>

        )
    }
}

export default ChequeDeposit;
