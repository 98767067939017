import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../env/environment";

const mutation = graphql`
  mutation updatePDPALoginMutation($input: AcceptPDPAInput!){
    acceptPDPA(input: $input) 
    {
      ok
      message
    }
  }
`

export default function updatePDPALogin(variables) {
  commitMutation(
    environment,
    {
      mutation,
      variables
    },
  )
}