import { fetchQuery } from "relay-runtime";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";

const query = graphql`
  query getSelfProjectQuery {
    selfProject {
      id
      name
      logo
      address
      useAccount
      typeOfProject
    }
  }
`;

export default function GetSelfProject() {
  return fetchQuery(environment, query, {})
    .then(data => data)
    .catch(err => {
      throw err;
    });
}
