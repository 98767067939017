import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import { format} from "date-fns";
import i18n from "i18next";
import '../../style/claim.scss'
import DatePicker from "react-datepicker";
import APIInsur from '../../../../api/insurrance'
import _ from 'lodash';
class EditInsuranceInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            file_insurance: [],
            co_insurance: [],
            data: null,
            insurance_start_date: "",
            insurance_end_date: ""
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChageData = this.onChageData.bind(this)
        this.onChageDataDate = this.onChageDataDate(this)
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            APIInsur.getDataInsuranceById(this.props.match.params.id).then((res) => {
                if (res.data) {
                    this.setState({
                        data: res.data?.insurance.insurance_detail,
                        file_insurance: res.data?.insurance.file_insurance,
                        co_insurance: res.data?.insurance.co_insurance,
                        insurance_start_date: new Date(res.data?.insurance.insurance_detail.insurance_start_date),
                        insurance_end_date: new Date(res.data?.insurance.insurance_detail.insurance_end_date),
                        loading: false
                    })
                } else {
                    Swal.fire("ผิดพลาด", "ส่งข้อมูลผิดพลาด", 'warning')
                }
            }, () => {
                Swal.fire("ผิดพลาด", "ไม่สามารถเชื่อมต่อหลังบ้านได้", 'error')
            })
        }
    }

    onChageData(e) {
        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `${e.target.name}`, e.target.value)
        this.setState({ data: NewData })
    }

    onChageDataDate(date, name) {
        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `${name}`, date)
        this.setState({ data: NewData })
    }
    onSubmit(e) {
        e.preventDefault();


        let {
            policy_number,
            beneficiary_first_name,
            beneficiary_last_name,
            building_insurance,
            condominium_property,
            unit_number,
            insurance_per_unit,
            sum_insurance_unit,
            insurance_premium,
            coverage_limit
        } = this.state.data;

        let data = {
            policy_number: policy_number,
            beneficiary_first_name: beneficiary_first_name,
            beneficiary_last_name: beneficiary_last_name,
            building_insurance: building_insurance,
            condominium_property: condominium_property,
            unit_number: unit_number,
            insurance_per_unit: insurance_per_unit,
            sum_insurance_unit: sum_insurance_unit,
            insurance_start_date: format(this.state.insurance_start_date, "DD/MM/YYYY"),
            insurance_end_date: format(this.state.insurance_end_date, "DD/MM/YYYY"),
            insurance_premium: insurance_premium,
            coverage_limit: coverage_limit,
            co_insurance: this.state.co_insurance,
            file_insurance: this.state.file_insurance
        }



        APIInsur.updateDataInsurance(this.props.match.params.id, data).then((res) => {
            if (res.data?.statusCode === "0000") {
                Swal.fire({
                    type: 'success',
                    title: i18n.t("Allaction:successcompleate"),
                }).then(() => {
                    this.props.history.push(`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Info`)
                })
            }
        })
    }


    functionCurrentMoneyFormat(money) {
        let formatMoney = 0
        if (money) {
            formatMoney = parseFloat(money).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        }
        return formatMoney ? formatMoney : "-"
    }

    render() {
  

        let { data, loading, file_insurance } = this.state

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            loading == false ?
                                <div className="container-fluid box claim" id="staff-create">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing/insurrance">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claim:Insurance')}</span>
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="content-inner mt-5 create">
                                        <div className="row mb-12 mr-5 fade-up ">
                                            <div className='mx-auto' style={{ width: 744 }}>

                                                <React.Fragment>
                                                    <form onSubmit={this.onSubmit}>
                                                        <div className='onFinishFt'>

                                                            <div className='col-12'>
                                                                <label className='headerLabel'>{t("claim:Policy Info")}</label>
                                                            </div>

                                                            <div className='col-12 mt-5 mb-5'>
                                                                <label className='label16'>{t("claim:Policy number")} <span className='text-red'>*</span></label>
                                                                <input type={"text"} name='policy_number'
                                                                    onChange={this.onChageData}
                                                                    className='form-control inputBox'
                                                                    placeholder={t("claim:Policy number")}
                                                                    required
                                                                    defaultValue={data.policy_number} />
                                                            </div>

                                                            <div className='col-12 mt-5 mb-5'>
                                                                <label className='label16 mb-5'>{t("claim:Name Policy")} <span className='text-red'>*</span></label>
                                                                {/* <div style={{ display: 'inline-flex' }}>

                                                                    <div style={{ marginRight: 32 }}> */}
                                                                        {/* <label className='label16'>{t("claim:FirsthName")} <span className='text-red'>*</span></label> */}
                                                                        <input type={"text"} name='beneficiary_first_name'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:FirsthName")}
                                                                            className='form-control inputBox'
                                                                            //  style={{ width: 332 }}
                                                                            required
                                                                            defaultValue={data.beneficiary_first_name} />
                                                                    {/* </div> */}

                                                                    {/* <div>
                                                                        <label className='label16'>{t("claim:LastName")} <span className='text-red'>*</span></label>
                                                                        <input type={"text"} name='beneficiary_last_name'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:LastName")}
                                                                            className='form-control inputBox' style={{ width: 332 }}
                                                                            required
                                                                            defaultValue={data.beneficiary_last_name} />
                                                                    </div>

                                                                </div> */}
                                                            </div>

                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:Building insurance")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:bath")}</span>
                                                                        <input type={"text"} name='building_insurance'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:Building insurance")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            required
                                                                            defaultValue={data.building_insurance} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:expand apartment")}</label>
                                                                    </div>

                                                                    <div>
                                                                        <input type={"text"} name='condominium_property'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:expand apartment")}
                                                                            className='form-control inputBox' style={{ width: 508 }}

                                                                            defaultValue={data.condominium_property} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:all suites")}</label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:unit")}</span>
                                                                        <input type={"text"} name='unit_number'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:all suites")}
                                                                            className='form-control inputBox' style={{ width: 508 }}

                                                                            defaultValue={data.unit_number} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:per suite")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:bath")}</span>
                                                                        <input type={"text"} name='insurance_per_unit'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:per suite")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            required
                                                                            defaultValue={data.insurance_per_unit} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:Total sum insured")}</label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:bath")}</span>
                                                                        <input type={"text"} name='sum_insurance_unit'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:Total sum insured")}
                                                                            className='form-control inputBox' style={{ width: 508 }}

                                                                            defaultValue={data.sum_insurance_unit} />
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div className='col-12' style={{ marginTop: 96 }}>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:Protection period Start")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div className='datePicker'>
                                                                        <DatePicker
                                                                            name='insurance_start_date'
                                                                            className="form-control searchDateBox"
                                                                            selected={this.state.insurance_start_date}
                                                                            onChange={(e) => this.setState({ insurance_start_date: e })}
                                                                            dateFormat="dd/MM/yyyy" placeholderText='dd/mm/yyyy'
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 mt-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:Protection period End")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div className='datePicker'>
                                                                        <DatePicker
                                                                            name='insurance_end_date'
                                                                            className="form-control searchDateBox"
                                                                            selected={this.state.insurance_end_date}
                                                                            onChange={(e) => this.setState({ insurance_end_date: e })}
                                                                            dateFormat="dd/MM/yyyy" placeholderText='dd/mm/yyyy'
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12 mt-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:premium")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:bath")}</span>
                                                                        <input type={"text"} name='insurance_premium'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:premium")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            required
                                                                            defaultValue={data.insurance_premium} />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className='col-12' style={{ marginTop: 96 }}>
                                                                <label className='headerLabel'>{t("claim:Policy conditions")}</label>
                                                            </div>

                                                            <div className='col-12 mt-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:Protection limit")} <i className='text-red'>*</i></label>
                                                                    </div>
                                                                    <div>
                                                                        <span>{t("claim:bath")}</span>
                                                                        <input type={"text"} name='coverage_limit'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:Protection limit")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            required
                                                                            defaultValue={data.coverage_limit} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='col-12' style={{ marginTop: 86 }}>
                                                                <label className='headerLabel'>{t("claim:Documentation")} <span className='text-red'>*</span></label>
                                                            </div>

                                                            <div className='col-12 mt-2'>
                                                                <label htmlFor="uploadFile" className="upload-btn-wrapper">
                                                                    <button className="btn"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} />{t("silvermanguard:Choose File")} </button>
                                                                    <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}>{t("silvermanguard:Max5MB")} </span>

                                                                    <input type="file" name='file' id="uploadFile"
                                                                        className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='.pdf,.png,.jpg'
                                                                        onChange={this.uploadFile}
                                                                    />
                                                                </label>
                                                            </div>


                                                            <div className='col-12 mt-2'>
                                                                {
                                                                    file_insurance?.length > 0 &&
                                                                    <table className='table boxFile'>
                                                                        <tbody>
                                                                            {
                                                                                file_insurance?.map((file, index) => {
                                                                                    let typeFile = file.file_name.split(".")
                                                                                    let typeFiles = typeFile[1] === "jpeg" && typeFile[1] === "jpg" ? "jpg" : typeFile[1]
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td style={{ width: "10%", verticalAlign: 'top' }}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFiles + "Icon.png"} style={{ width: '30px' }} />
                                                                                            </td>
                                                                                            <td style={{ width: "80%", verticalAlign: 'top' }}>
                                                                                                <Link
                                                                                                    to="route"
                                                                                                    onClick={(event) => {
                                                                                                        event.preventDefault();
                                                                                                        window.open(
                                                                                                            typeof file.file_upload === "string" || file.viewPtath
                                                                                                                ? file.viewPtath ? file.viewPtath : file.file_upload
                                                                                                                : URL.createObjectURL(file.viewPtath ? file.viewPtath : file.file_upload)
                                                                                                        )
                                                                                                    }}
                                                                                                    target="blank">{file.file_name}</Link>
                                                                                            </td>
                                                                                            <td style={{ width: "10%", textAlign: 'right', verticalAlign: 'top' }} onClick={() => this.dateFile(index, file)}>
                                                                                                <img src={process.env.PUBLIC_URL + "/images/iconAction/chat/closeGray03.svg"} />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='col-12 text-right'>
                                                            <Link to={`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Info`}>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}>{t("Allaction:cancel")}</button>
                                                            </Link>

                                                            <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }} >{t("Allaction:save")}</button>
                                                        </div>
                                                    </form>
                                                </React.Fragment>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default EditInsuranceInfo;
