import React from "react";
import { Translation } from "react-i18next";

const _ = require('lodash');


class SettingsServiceFine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fine: []
        }
    }

    render() {
        return (
            <div className="form-group row">
                <div className="col-md-12 mt-2 mb-2">
                    <Translation>{t=><p className="mb-2"><strong>{t("revenue_setting:Amount to be calculated")}</strong></p>}</Translation>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="NO_FINE" id="no-fine"
                               name="product.serviceFine.edges.0.node.fineType" onChange={this.props.handleInputChange}
                               checked={_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') === 'NO_FINE'}/>
                        <Translation>{t=><label className="form-check-label" htmlFor="no-fine">{t("revenue_setting:No fine")}</label>}</Translation>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="PERCENTAGE" id="type-percent"
                               name="product.serviceFine.edges.0.node.fineType" onChange={this.props.handleInputChange}
                               checked={_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') === 'PERCENTAGE'}/>
                        <Translation>{t=><label className="form-check-label" htmlFor="type-percent">{t("revenue_setting:Based on outstanding balance")}</label>}</Translation>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" value="STATIC" id="type-static"
                               name="product.serviceFine.edges.0.node.fineType" onChange={this.props.handleInputChange}
                               checked={_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') === 'STATIC'}/>
                        <Translation>{t=><label className="form-check-label" htmlFor="type-static">{t("revenue_setting:Constant")}</label>}</Translation>
                    </div>
                </div>

                {_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType', 'no_fine') !== 'NO_FINE' &&
                <React.Fragment>
                    <div className="col-md-12 mt-2 mb-2">
                        <Translation>{t=><p className="m-0"><strong>{t("revenue_setting:Fine rate")}</strong></p>}</Translation>
                    </div>

                    <div className="col-md-3 mt-2 mb-2">
                        <Translation>{t=><p className="m-0">{t("revenue_setting:Duration")}</p>}</Translation>
                    </div>

                    <div className="col-md-4 mt-2 mb-2">
                        <Translation>{t=><p className="m-0">{t("revenue_setting:Fine rate (per month)")}</p>}</Translation>
                    </div>

                    <div className="w-100"/>

                    {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                        <React.Fragment key={'fine-'+value}>
                            <Translation>
                                {t=>
                                <div className="col-md-3">
                                    {value <= 5 &&
                                    <p className="mt-2">{value+1} {t("revenue_setting:month")}</p>
                                    }
                                    {value > 5 &&
                                    <p className="mt-2">{t("revenue_setting:After the 6th month")}</p>
                                    }
                                </div>}
                            </Translation>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" onChange={this.props.handleInputChange}
                                           value={_.get(this.props.state.product.serviceFine, 'edges.0.node.serviceFineSeries.edges.'+value+'.node.value')}
                                           name={"product.serviceFine.edges.0.node.serviceFineSeries.edges."+value+".node.value"}
                                           required={_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') !== 'no_fine'}/>
                                    <div className="input-group-prepend">
                                        {_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') === 'percentage' &&
                                        <span className="input-group-text">%</span>
                                        }
                                        {_.get(this.props.state.product.serviceFine, 'edges.0.node.fineType') === 'static' &&
                                        <Translation>{t=><span className="input-group-text">{t("revenue_setting:Baht")}</span>}</Translation>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="w-100"/>
                        </React.Fragment>
                    ))}

                    <Translation>
                        {t=>
                        <div className="col-md-12 mt-4 mb-2">
                            <p className="mb-2"><strong>{t("revenue_setting:Stop charging when")}</strong></p>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" value="false" id="no-limit"
                                       onChange={this.props.handleInputChange}
                                       name="product.serviceFine.edges.0.node.fineLimit"
                                       checked={_.get(this.props.state.product, 'serviceFine.edges.0.node.fineLimit') === false}
                                />
                                <label className="form-check-label" htmlFor="no-limit">{t("revenue_setting:Calculate until pay")}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="date-limit" value="true"
                                       onChange={this.props.handleInputChange}
                                       checked={_.get(this.props.state.product, 'serviceFine.edges.0.node.fineLimit') === true}
                                       name="product.serviceFine.edges.0.node.fineLimit"/>
                                <label className="form-check-label" htmlFor="date-limit">{t("revenue_setting:Calculate until the end of the day")}</label>
                                <input type="number" className="form-control ml-2 mr-2" style={{width: 75}}
                                       onChange={this.props.handleInputChange}
                                       required={_.get(this.props.state.product, 'serviceFine.edges.0.node.fineLimit')}
                                       value={_.get(this.props.state.product, 'serviceFine.edges.0.node.limitDay')}
                                       name="product.serviceFine.edges.0.node.limitDay"
                                />
                                <label className="form-check-label" htmlFor="">{t("revenue_setting:Day")}</label>
                            </div>
                        </div>}
                    </Translation>
                </React.Fragment>
                }

            </div>
        )
    }
}

export default SettingsServiceFine;
