import getApiRoot from "../../../libs/getAPIRoot";
const token = localStorage.getItem("token");
async function getRightSetting() {
  //   return new Promise(async (resolve, reject) => {
  let response = await fetch(getApiRoot() + "booking_facility/right_setting", {
    // authorization: `JWT ${token}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "authorization": `JWT ${token}`,
    },
  }).catch((error) => {
    console.error("Error:", error);
  });
  return response.json();
}

async function setRightSetting(data) {
  let response = await fetch(
    getApiRoot() +
      `booking_facility/right_setting?advance_day=${data.advance_day}&advance_right=${data.advance_right}&max_reservation_per_room=${data.max_reservation_per_room}&button=True`,
    {
      // authorization: `JWT ${token}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "authorization": `JWT ${token}`,
      },
    }
  ).catch((error) => {
    console.error("Error:", error);
  });
  return response.json();
}

export default {
  getRightSetting,
  setRightSetting,
};
