/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateAndUpdateDeteriorateAssetInput = {|
  deteriorateAsset: any,
  clientMutationId?: ?string,
|};
export type assetDeteriorateMutationVariables = {|
  input: CreateAndUpdateDeteriorateAssetInput
|};
export type assetDeteriorateMutationResponse = {|
  +createAndUpdateDeteriorateAsset: ?{|
    +ok: ?boolean
  |}
|};
export type assetDeteriorateMutation = {|
  variables: assetDeteriorateMutationVariables,
  response: assetDeteriorateMutationResponse,
|};
*/


/*
mutation assetDeteriorateMutation(
  $input: CreateAndUpdateDeteriorateAssetInput!
) {
  createAndUpdateDeteriorateAsset(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAndUpdateDeteriorateAssetPayload",
    "kind": "LinkedField",
    "name": "createAndUpdateDeteriorateAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "assetDeteriorateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assetDeteriorateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "014605c6766dc87021e4636e56d09efb",
    "id": null,
    "metadata": {},
    "name": "assetDeteriorateMutation",
    "operationKind": "mutation",
    "text": "mutation assetDeteriorateMutation(\n  $input: CreateAndUpdateDeteriorateAssetInput!\n) {\n  createAndUpdateDeteriorateAsset(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9bea56b02bb57df6e12289b26d59213';

module.exports = node;
