/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type fixRequsetPDFQueryVariables = {||};
export type fixRequsetPDFQueryResponse = {|
  +selfProject: ?{|
    +id: string,
    +name: string,
    +address: ?string,
    +logo: ?string,
  |}
|};
export type fixRequsetPDFQuery = {|
  variables: fixRequsetPDFQueryVariables,
  response: fixRequsetPDFQueryResponse,
|};
*/


/*
query fixRequsetPDFQuery {
  selfProject {
    id
    name
    address
    logo
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNode",
    "kind": "LinkedField",
    "name": "selfProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "fixRequsetPDFQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "fixRequsetPDFQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5e129553fd648ad5d73c670f6aee4dbc",
    "id": null,
    "metadata": {},
    "name": "fixRequsetPDFQuery",
    "operationKind": "query",
    "text": "query fixRequsetPDFQuery {\n  selfProject {\n    id\n    name\n    address\n    logo\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d477f5cfeea7055748514aedeb63b0b';

module.exports = node;
