import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from 'react-relay';
import environment from '../../env/environment';
import { Redirect } from 'react-router';
import SearchSelect from "../../libs/searchSelect";
import getDocumentNumber from '../../libs/getDocumentNumber';
import DatePickerAdapter from '../../libs/datePickerAdapter';
import numberWithCommas from '../../libs/numberWithComma';
import Header from '../../components/header/';
import Sidebar from '../../components/sidebar/';
import Wrapper from '../../components/wrapper/';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import { fetchQuery } from "relay-runtime";
import { Translation } from "react-i18next";
import DailyJournalDelete from "./dailyJournalDelete";
import { formatDateISO } from "../../libs/date"
import BackButtonIcon from '../../components/BackBtn/indexBack';
import i18next from 'i18next';

const query = graphql`
    query dailyJournalCreateQuery($id: ID!,$glOffSite:Boolean) {
        contactViewer {
            id
            allContact {
                edges {
                    node {
                        id name
                    }
                }
            }
        }
        viewer {
            allChartOfAccount {
                edges {
                    node {
                        id name chartOfAccountCode
                    }
                }
            }
        }
        allAccountProjectManager{
            edges{
              node{
                id
                tableAccount
                startDateAccountPeriod
                endDateAccountPeriod
              }
            }
        }
        accountRecordGroup(id: $id){
            id
            refNumber
            refTransaction
            name
            issuedDate
            category
            accountRecord{
                edges{
                    node{
                        id
                        name
                        debit
                        credit
                        chartOfAccountCode{
                            id
                            chartOfAccountCode
                        }
                    }
                }
            }
        }
        allActiveChartOfAccount(glOffSite: $glOffSite) {
            edges {
              node {
                id
                chartOfAccountCode
                name
                totalDebit
                totalCredit
                totalHistoricalDebit
                totalHistoricalCredit
                totalPeriodDebit
                totalPeriodCredit
              }
            }
        }
    }
`;

const mutation = graphql`
    mutation dailyJournalCreateMutation($input: CreateAccountRecordGroupInput!) {
        createAccountRecordGroup(input: $input) {
            ok
            warningText
            newAccountRecordGroup {
                id
            }
        }
    }
`;

const _ = require('lodash');

let blank_list = { id: '', name: '', sub_account: '', chart_of_account_code: '', debit: '', credit: '' };

class DailyJournalCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '', success: '', loading: '',
            name: '', issued_date: new Date(), ref_transaction: '', category: '', total_debit: 0, total_credit: 0,
            accountList: [{ ...blank_list }, { ...blank_list }], redirect: false,
            viewer: { allChartOfAccount: { edges: [] } },
            contactViewer: {},
            accountRecordGroup: {},
            ref_not_equal: false,
            closeAccount: false,
            endDateAccountPeriod: null,
            code_304_credit: 0,
            code_304_debit: 0,
            cloneAccountList: [{ ...blank_list }, { ...blank_list }],
            loadingText: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.appendNewAccount = this.appendNewAccount.bind(this);
        this.delete = this.delete.bind(this);
        this.setTotal = this.setTotal.bind(this);
        this.submit = this.submit.bind(this);
        this.copyResult = this.copyResult.bind(this);
        this.clearCopyResult = this.clearCopyResult.bind(this);
    }

    componentDidMount() {
        let account_record_group_id = this.props.match.params.account_record_group ? this.props.match.params.account_record_group : '';
        fetchQuery(environment, query, { id: account_record_group_id, glOffSite: false }).then(data => {
            //  this.setState({ loadingText:true})
            let data_clone = _.cloneDeep(data);
            let accountList = [...this.state.accountList];
            let endDateAccountPeriod = null;
            if (data_clone.accountRecordGroup) {
                accountList = [];
                data_clone.accountRecordGroup.accountRecord.edges.forEach((acc_record) => {
                    let record_empty = _.cloneDeep(blank_list);
                    record_empty.id = acc_record.node.id;
                    record_empty.name = acc_record.node.name;
                    record_empty.chart_of_account_code = acc_record.node.chartOfAccountCode.chartOfAccountCode;
                    record_empty.debit = acc_record.node.debit;
                    record_empty.credit = acc_record.node.credit;
                    accountList.push(record_empty)
                });
                this.setState({
                    loadingText: false,
                    accountRecordGroup: data_clone.accountRecordGroup,
                    name: data_clone.accountRecordGroup.name,
                    issued_date: new Date(data_clone.accountRecordGroup.issuedDate),
                    accountList: accountList,
                    cloneAccountList: accountList,
                    ref_not_equal: data_clone.accountRecordGroup.refNumber?.substring(0, 2) !== data_clone.accountRecordGroup.refTransaction?.substring(0, 2)
                });
            }

            if (data_clone.allAccountProjectManager) {
                endDateAccountPeriod = data_clone.allAccountProjectManager.edges[0]?.node.endDateAccountPeriod
            }

            this.setState({
                contactViewer: data_clone.contactViewer,
                viewer: data_clone.viewer,
                endDateAccountPeriod: endDateAccountPeriod,
            }, () => this.setTotal());
        });
        this.setState({ category: this.getCategory(this.props.match.params.category) });
    }

    getCategory(category) {
        let status = {
            all: "",
            purchase: "",
            sales: "",
            receive: "dailyjournal:receive",
            payment: "dailyjournal:payment",
            general: "dailyjournal:general",
        };
        return status[category]
    }

    appendNewAccount() {
        this.setState({ accountList: [...this.state.accountList, { ...blank_list }] })
    }

    copyResult() {
        let value = document.getElementById("validationDefault01").value
        let accountList = [...this.state.accountList];
        accountList.forEach((account) => {
            _.set(account, 'name', value)
        });
        this.setState({ accountList: accountList });
    }

    clearCopyResult() {

        document.getElementById("validationDefault01").value = "";
        let accountList = [...this.state.accountList];
        accountList.forEach((account) => {
            _.set(account, 'name', "")
        });

        this.setState({ accountList: accountList });

    }

    handleChange(event) {
        if (event.target.dataset.key === 'accountList') {
            let accountList = [...this.state.accountList];
            accountList[event.target.dataset.id][event.target.name] = event.target.value;
            this.setState({ accountList: accountList });
        } else {
            let name = event.target.name;
            let value = event.target.value;

            this.setState({ [event.target.name]: event.target.value });
            if (name === "name") {
                let accountList = [...this.state.accountList];
                let cloneAccountLists = [...this.state.cloneAccountList];
                cloneAccountLists.forEach((account, index) => {
                    if (account.name == '') {
                        _.set(accountList[index], 'name', value)
                    }

                });
                this.setState({ accountList: accountList });
            }
        }

        this.setTotal();
    }

    setTotal() {
        let total_debit = 0;
        let total_credit = 0;
        this.state.accountList.map((account, index) => {
            total_debit = total_debit + (parseFloat(account.debit) || 0);
            total_credit = total_credit + (parseFloat(account.credit) || 0);
            return true;
        });
        this.setState({ total_debit: parseFloat(total_debit.toFixed(2)), total_credit: parseFloat(total_credit.toFixed(2)) });
    }

    delete(index) {
        let list = [...this.state.accountList];
        list.splice(index, 1);
        this.setState({ accountList: list }, () => {
            this.setTotal();
        });
    }

    toggleCloseAccount = () => {
        this.setState({
            closeAccount: !this.state.closeAccount
        })

        let timerInterval
        Swal.fire({
            type: 'warning',
            title: 'ข้อความแจ้งเตือน',
            html: '<h2>ระบบกำลังประมวลผล กรุณารอสักครู่!</h2>',
            timer: 2000,
            onBeforeOpen: () => { Swal.showLoading() },
            onClose: () => { clearInterval(timerInterval) }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                return null;
            }
        })

        if (!this.state.closeAccount) {
            let account_record_group_id = this.props.match.params.account_record_group ? this.props.match.params.account_record_group : '';
            fetchQuery(environment, query, { id: account_record_group_id, glOffSite: true }).then(data => {
                let accountList = [];
                let summary_debit = 0;
                let summary_credit = 0;

                let code_304_debit = 0;
                let code_304_credit = 0

                let data_clone = _.cloneDeep(data);
                if (data_clone.allActiveChartOfAccount) {
                    data_clone.allActiveChartOfAccount.edges.forEach((chart) => {
                        let total_debit = chart.node.totalHistoricalDebit + chart.node.totalDebit + chart.node.totalPeriodDebit;
                        let total_credit = chart.node.totalHistoricalCredit + chart.node.totalCredit + chart.node.totalPeriodCredit;
                        let record_empty = _.cloneDeep(blank_list);

                        record_empty.id = chart.node.id;
                        // record_empty.name = chart.node.name;
                        record_empty.name = "ปิดบัญชีรายรับ-รายจ่าย เข้า รายรับสูง(ต่ำ) กว่ารายจ่ายประจำงวด"
                        record_empty.chart_of_account_code = chart.node.chartOfAccountCode
                        //เดบิต มากกว่า เครดิต จะเข้า ยอด เดบิต - เครดิต จะเข้า เดบิต
                        if (total_debit > total_credit) {
                            record_empty.credit = Math.round(((total_debit - total_credit) + Number.EPSILON) * 100) / 100;
                            // summary_debit += total_debit - total_credit;
                            summary_credit += total_debit - total_credit;

                        } else {
                            record_empty.debit = Math.round(((total_credit - total_debit) + Number.EPSILON) * 100) / 100;
                            // summary_credit += total_credit - total_debit;
                            summary_debit += total_credit - total_debit;
                        }
                        if (total_credit - total_debit !== 0) {
                            accountList.push(record_empty)
                        }
                        // if(total_credit - total_debit === 0){
                        //     console.log(chart.node.chartOfAccountCode);
                        //     console.log(total_credit - total_debit);
                        // }

                    })

                }
                if (data_clone.viewer.allChartOfAccount) {
                    const code_3000 = _.find(data_clone.viewer.allChartOfAccount.edges, { node: { chartOfAccountCode: "3000-04" } })
                    let record_empty = _.cloneDeep(blank_list);

                    record_empty.id = code_3000.node.id
                    record_empty.name = code_3000.node.name;
                    record_empty.chart_of_account_code = code_3000.node.chartOfAccountCode

                    //เดบิต มากกว่า เครดิต จะเข้า ยอด เดบิต - เครดิต จะเข้า เดบิต
                    if (summary_debit > summary_credit) {
                        record_empty.credit = Math.round(((summary_debit - summary_credit) + Number.EPSILON) * 100) / 100;
                        code_304_credit = Math.round(((summary_debit - summary_credit) + Number.EPSILON) * 100) / 100;
                    } else {
                        record_empty.debit = Math.round(((summary_credit - summary_debit) + Number.EPSILON) * 100) / 100;
                        code_304_debit = Math.round(((summary_credit - summary_debit) + Number.EPSILON) * 100) / 100;
                    }
                    accountList.push(record_empty)
                }

                this.setState({
                    accountList: accountList,
                    name: 'ปิดบัญชีรายรับ-รายจ่าย เข้า รายรับสูง(ต่ำ) กว่ารายจ่ายประจำงวด',
                    code_304_credit: code_304_credit,
                    code_304_debit: code_304_debit,
                }, () => {
                    this.setTotal();
                })
            });
        } else {
            this.setState({ accountList: [{ ...blank_list }, { ...blank_list }] }, () => {
                this.setTotal();
            })
        }
    }

    submit(e) {
        e.preventDefault();
        if (this.state.total_debit !== this.state.total_credit) {
            return Swal.fire('Error', 'ไม่สามารถบันทึกได้เพราะเครดิตและ เดบิตไม่เท่ากัน', 'error');
        }
        if (this.state.accountList.length !== 0) {
            let variables = {
                input: {
                    "clientMutationId": this.state.accountRecordGroup.id,
                    "name": this.state.name,
                    "category": this.state.category.split(':')[1],
                    "totalDebit": this.state.total_debit,
                    "totalCredit": this.state.total_credit,
                    "issuedDate": this.state.issued_date,
                    "accountRecordList": JSON.stringify(this.state.accountList),
                    "closeAccount": this.state.closeAccount,
                    "code304Credit": this.state.code_304_credit,
                    "code304Debit": this.state.code_304_debit,

                }
            };
            this.setState({ success: '', loading: true, error: '' });
            commitMutation(environment, {
                mutation,
                variables,
                onCompleted: (response, errors) => {
                    if (errors) {
                        this.setState({ loading: false, error: true });
                    } else {
                        if (response.createAccountRecordGroup.ok) {
                            this.setState({
                                loading: false,
                                success: true,
                                accountList: [{ ...blank_list }, { ...blank_list }]
                            });
                        } else {
                            alert(response.createAccountRecordGroup.warningText)
                        }
                        this.setState({ redirect: true });
                    }
                },
                onError: error => {
                    alert('server error')
                }
            });
        } else {
            return Swal.fire('Error', 'ไม่สามารถบันทึกได้เพราะต้องมีรายการที่เครดิตและเดบิตต้องเท่ากันเท่านั้น', 'error');
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={`/accounting/daily-journal/${this.state.category.split(":")[1]}/list/all`} />
        }

        return (

            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <Translation>
                        {(t)=>
                            <div className="container-fluid box">
                                <BackButtonIcon
                                    LinkBack={`/accounting/daily-journal/${this.props.match.params.category}/list/all`}
                                    LinkText={i18next.t('accounting:daily journal')}
                                />

                                <div className="content-inner fade-up">
                                    <div className="row">
                                        <div className="col-12">
                                            {this.state.error &&
                                                <div className="alert alert-danger" role="alert">
                                                    {i18next.t("dailyjournalCreate:Cannot add a new account")}</div>
                                            }
                                            {this.state.loading &&
                                                <div className="alert alert-primary" role="alert">
                                                    {i18next.t("dailyjournalCreate:Saving")}</div>
                                            }
                                            {this.state.success &&
                                                <div className="alert alert-primary" role="alert">
                                                    {i18next.t("dailyjournalCreate:Successfully saved")}</div>
                                            }

                                            <div className="mb-4">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <label>{i18next.t("dailyjournalCreate:Account Book")}*</label>
                                                        <select className="custom-select"
                                                            onChange={this.handleChange}
                                                            value={this.state.category} name="category"
                                                            disabled={this.state.accountRecordGroup.category} required>
                                                            {/* <option value="" >สมุดบัญชี</option> */}
                                                            {/*<option value="dailyjournal:purchase">รายวันซื้อ(เจ้าหนี้)*/}
                                                            {/*</option>*/}
                                                            {/*<option value="dailyjournal:sales">รายวันขาย(เจ้าของร่วม/สมาชิก, ลูกหนี้)*/}
                                                            {/*</option>*/}
                                                            <React.Fragment>
                                                                {/* {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_payment_create' }) && */}
                                                                    <option
                                                                        value="dailyjournal:payment">{i18next.t("dailyjournalCreate:Daily Expenses")}
                                                                    </option>
                                                                {/* }
                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_receive_create' }) && */}

                                                                    <option
                                                                        value="dailyjournal:receive">{i18next.t("dailyjournalCreate:Daily Income")}
                                                                    </option>
                                                                {/* }
                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'daily_journal_general_create' }) && */}
                                                                    <option value="dailyjournal:general">{i18next.t("dailyjournalCreate:General")}
                                                                    </option>
                                                                {/* } */}
                                                            </React.Fragment>



                                                        </select>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label>{i18next.t("dailyjournalCreate:Record Number")}</label>
                                                        <input type="text" className="form-control"
                                                            disabled={true}
                                                            value={this.state.accountRecordGroup ? this.state.accountRecordGroup.refNumber : getDocumentNumber.get(this.state.category, 'XXXX', this.state.issued_date)} />
                                                    </div>
                                                    <div className="col-md">
                                                        <label>{i18next.t("dailyjournalCreate:Date")}*</label> <br />
                                                        <DatePickerAdapter name="issued_date"
                                                            className="form-control"
                                                            selected={this.state.issued_date}
                                                            onChange={this.handleChange}
                                                            disable={this.state.ref_not_equal}
                                                        />
                                                    </div>
                                                    {
                                                        // localStorage.getItem('site_id') && 
                                                        formatDateISO(this.state.issued_date) === this.state.endDateAccountPeriod && this.state.category === "dailyjournal:general" &&
                                                        <div className="col-md-4 align-items-center">
                                                            <br />
                                                            <input type="checkbox" name="closeAccount"
                                                                id="closeAccount" className="align-items-center" onChange={this.toggleCloseAccount} checked={this.state.closeAccount} />
                                                            <label htmlFor="closeAccount" className="ml-1 align-items-center">{i18next.t("dailyjournalCreate:I want to close the income-expenditure account into the cumulative income being higher (lower) than the accumulated expenditure.")}</label>
                                                        </div>}

                                                    {/*<div className="col-md-3">*/}
                                                    {/*    <label>อ้างอิงจาก</label>*/}
                                                    {/*    <input type="text" className="form-control"*/}
                                                    {/*           name="ref_transaction"*/}
                                                    {/*           value={this.state.ref_transaction}*/}
                                                    {/*           onChange={this.handleChange}/>*/}
                                                    {/*</div>*/}

                                                </div>
                                            </div>

                                            <div className="mb-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label
                                                            htmlFor="validationDefault01">{i18next.t("dailyjournalCreate:Account Description")}</label>
                                                        <input type="text" className="form-control"
                                                            name="name"
                                                            value={this.state.name}
                                                            onChange={this.handleChange}
                                                            id="validationDefault01" required />
                                                    </div>
                                                    <div className="col-md-2 pt-7">
                                                        <button className="btn btn-primary form-control"
                                                            disabled={this.state.loadingText}
                                                            onClick={this.copyResult} type="submit">
                                                            {this.state.loadingText &&
                                                                <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            {i18next.t("dailyjournalCreate:Copy")}
                                                        </button>

                                                        {/* <button className="btn btn-primary form-control" 
                                                                onClick={this.clearCopyResult} type="submit">
                                                                    clear 
                                                            </button> */}

                                                    </div>
                                                    {/*<div className="col-md-4">*/}
                                                    {/*    <label*/}
                                                    {/*        htmlFor="validationDefault01">ผู้ติดต่อ</label>*/}
                                                    {/*    <SearchSelect onChange={this.handleChange}*/}
                                                    {/*                  value={this.state.contact}*/}
                                                    {/*                  name="contact"*/}
                                                    {/*                  placeholder="กรุณาเลือกผู้ติดต่อ"*/}
                                                    {/*                  queryObject={props.contactViewer.allContact.edges}*/}
                                                    {/*                  keyOfValue="id"*/}
                                                    {/*                  keyOfLabel="name"/>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                            <form id="create-accounting-record" onSubmit={this.submit}>
                                                <table className="table table-bordered">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                            <th width="450">{i18next.t("dailyjournalCreate:Account Number")}*</th>
                                                            {/*<th width="300">ชื่อบัญชี</th>*/}
                                                            <th>{i18next.t("dailyjournalCreate:Transaction Description")}</th>
                                                            <th width="160">{i18next.t("dailyjournalCreate:Debit")}</th>
                                                            <th width="160">{i18next.t("dailyjournalCreate:Credit")}</th>
                                                            <th width="5"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.accountList.map((account, index) => {
                                                            return (
                                                                <tr key={'list-' + index}>
                                                                    <td>
                                                                        <SearchSelect onChange={this.handleChange}
                                                                            value={account.chart_of_account_code}
                                                                            name="chart_of_account_code"
                                                                            placeholder={i18next.t("dailyjournalCreate:Account Number")}
                                                                            data-id={index} require={true}
                                                                            data-key="accountList"
                                                                            queryObject={this.state.viewer.allChartOfAccount.edges}
                                                                            keyOfValue="chartOfAccountCode"
                                                                            keyOfLabel="chartOfAccountCode:name" />
                                                                    </td>
                                                                    {/*<td>*/}
                                                                    {/*    {this.state.viewer.allChartOfAccount.edges.map((chartOfAccount) => {*/}
                                                                    {/*            return (*/}
                                                                    {/*                chartOfAccount.node.chartOfAccountCode === account.chart_of_account_code && chartOfAccount.node.name*/}
                                                                    {/*            )*/}
                                                                    {/*        }*/}
                                                                    {/*    )}*/}
                                                                    {/*</td>*/}
                                                                    <td>
                                                                        <input type="text" className="form-control"
                                                                            onChange={this.handleChange}
                                                                            data-id={index}
                                                                            data-key="accountList" name="name"
                                                                            value={account.name}
                                                                            required
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                            className="form-control text-right"
                                                                            onChange={this.handleChange}
                                                                            data-id={index}
                                                                            data-key="accountList" name="debit"
                                                                            value={account.debit} disabled={account.credit} />
                                                                    </td>
                                                                    <td>
                                                                        <input type="number"
                                                                            className="form-control text-right"
                                                                            onChange={this.handleChange}
                                                                            data-id={index}
                                                                            data-key="accountList" name="credit"
                                                                            value={account.credit} disabled={account.debit} />
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src="/images/icons/delete.png"
                                                                            onClick={() => this.delete(index)}
                                                                            alt="delete"
                                                                            className="clickable mt-2 mx-auto d-block" />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                        <tr>
                                                            <td colSpan="2">
                                                                <button className="btn btn-primary add"
                                                                    onClick={this.appendNewAccount}
                                                                    type="button">
                                                                    <img src="/images/icons/plus.png" alt="plus" />

                                                                    <Translation>
                                                                        {
                                                                            t => <span>{t("settingAll:Add list")}</span>
                                                                        }
                                                                    </Translation>
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <input type="text" onChange={this.handleChange}
                                                                    value={numberWithCommas(this.state.total_debit)}
                                                                    className="form-control text-right"
                                                                    disabled={true} />
                                                            </td>
                                                            <td>
                                                                <input type="text" onChange={this.handleChange}
                                                                    value={numberWithCommas(this.state.total_credit)}
                                                                    className="form-control text-right"
                                                                    disabled={true} />
                                                            </td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <button className="btn btn-primary float-right mt-3 ml-2"
                                                    disabled={this.state.loading} type="submit">
                                                    {this.state.loading &&
                                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                    {i18next.t("Allaction:save")}
                                                </button>
                                                {
                                                    this.state.ref_not_equal &&
                                                    <DailyJournalDelete id={this.props.match.params.account_record_group} this={this} />
                                                }


                                                <Link to={`/accounting/daily-journal/${this.props.match.params.category}/list/all`}>
                                                    <button className="btn btn-secondary float-right mr-2 mt-3"
                                                        type="button"> {i18next.t("Allaction:cancel")}
                                                    </button>
                                                </Link>
                                            </form>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper>
        )
    }
}

export default DailyJournalCreate;