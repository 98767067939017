import React, { Component } from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SettingDocumentPaidTable from './settingDocumentPaidTable';

class SettingDocumentPaidLawyerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }}

    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {
                            t => 
                            <div className="container-fluid box" id="lawyer-summary-report">
                                <div className="row justify-content-between">
                                    <div className="col">
                                        <h3 className="mb-4">
                                            <Link to="/setting">
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back"/>
                                            </Link>
                                            <Translation>
                                                {
                                                    t => <span>{t('collectionLawFirm:Debt Collection Attorney Summary Report')}</span>
                                                }
                                            </Translation>                                           
                                        </h3>
                                    </div>
                                </div>
                                <div className='content-inner row'>
                                    <SettingDocumentPaidTable status={this.props.match.params.status}/>
                                </div>
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default SettingDocumentPaidLawyerContainer;