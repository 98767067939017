import {
    commitMutation,

} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";


const mutation = graphql`
 mutation CreatePurchaseMutation($input: UpdatePurchaseRecordGroupAndTransactionInput!){
      updatePurchaseRecordGroupAndTransaction(input: $input){
        ok
        warningText
      }
    }
`;


export default function CreatePurchaseMutation(variables,uploadables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            uploadables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                // console.log(err);
                error_callback(err);
            },
        },
    )
}
