import React, { Component } from 'react';
import Swal from "sweetalert2";
import Wrapper from '../../components/wrapper';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link, Redirect } from "react-router-dom";
import { Translation } from "react-i18next";
import _, { cloneDeep } from 'lodash';
import API from '../../api/silverGraund'
import jwt from 'jwt-decode'
import { format } from "date-fns";
import SearchSelect from "../../libs/searchSelect";
import upload from '../../libs/upload'
import SettingTopMenuNavigation from '../settingTopMenuNavigation';
import i18next from 'i18next';


let fileIsSel = false
class VisitMangeDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeContact: [
                { name: "เยี่ยมชมโครงการล่วงหน้า", value: 'เยี่ยมชมโครงการล่วงหน้า' },
                { name: "เยี่ยมชมโครงการไม่ได้นัดหมาย", value: 'เยี่ยมชมโครงการไม่ได้นัดหมาย' },
                { name: "ผู้รับเหมา", value: 'ผู้รับเหมา' },
                { name: "ติดต่อเจ้าหน้าที่", value: 'ติดต่อเจ้าหน้าที่' },
                { name: "อื่น ๆ (ระบุเหตุผลเองได้)", value: 'other' },
            ],
            isCheckIn: true,
            provinceAll: [],
            brandAll: [],
            data: {},
            fileUpload: [],
            selct: [],
            redirect: false,
            brandName: "",
            reasonName: "",
            homeAll: []

        }
        // this.setStates = this.setStates.bind(this)
        this.dropDownChanged = this.dropDownChanged.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.keyWord = this.keyWord.bind(this)
        this.chkIspacking = this.chkIspacking.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.TypeCar = this.TypeCar.bind(this)
        this.TypePerSon = this.TypePerSon.bind(this)
        this.dateFile = this.dateFile.bind(this)
        this.typeContactSelct = this.typeContactSelct.bind(this)
    }
    componentWillMount() {
        this.setState({ provinceAll: require('./json/provice.json') });
        this.setState({ brandAll: require('./json/brand.json') });
        this.getHomeAll()
        this.getDataDetail()

    }

    getHomeAll() {
        API.getHomeAll().then((ress) => {
            if (ress.data) {
                let mapHome = []
                ress.data.allResidential.map((result) => {
                    let homeIdS = {}
                    _.set(homeIdS, 'node.homeId', result.homeId);
                    _.set(homeIdS, 'node.name', result.name);
                    return mapHome.push(homeIdS)
                })
                this.setState({ homeAll: mapHome })
            }
        })
    }

    getDataDetail() {
        let idCode = this.props.match.params.id
        API.getDataDetail(idCode).then((ress) => {
            if (ress.data.data) {
                let datTFile = cloneDeep(ress.data.data[0])
                datTFile.file.forEach((n, index) => {
                    _.set(datTFile, "file[" + index + "].id", index);
                    _.set(datTFile, "file[" + index + "].status", "");
                });
                let nameBrand = ""
                let nameReason = ""
                let CarFilter = this.state.brandAll.filter((n) => ress.data.data[0].brandCar === n.brandCode)
                let typeContactS = this.state.typeContact.filter((n) => ress.data.data[0].reason === n.value)

                if (CarFilter.length < 1) {
                    datTFile.brandCar = "other"
                    nameBrand = ress.data.data[0].brandCar
                }
                if (typeContactS.length < 1) {
                    datTFile.reason = "other"
                    nameReason = ress.data.data[0].reason
                } 
                this.setState({ brandName: nameBrand, reasonName: nameReason, data: datTFile})

            }
        }, () => Swal.fire(i18next.t("Allaction:Unsuccessful "), i18next.t("settingToolandConnect:Unable to connect to the API at this time."), 'error'))
    }

    dropDownChanged(e) {
        let data_list = _.cloneDeep(this.state.data)
        data_list[e.target.name] = e.target.value;
        this.setState({ data: data_list })
    }

    keyWord(e) {
        let data_list = _.cloneDeep(this.state.data)

        let brandName = ""
        let reasonName = ""
        if (e.target.name === "brandName") {
            brandName = e.target.value;
        }if (e.target.name === "reasonName") {
            reasonName = e.target.value;
        }

        let dataName = ""
        let dataValu = ""
        let TimeNows = Date.now()
        if (e.target.name === "startDate" || e.target.name === "endDate") {
            let selctTime = new Date(e.target.value).getTime()
            let TimeNow = new Date(format(TimeNows, "YYYY-MM-DD")).getTime()
            let SelctTimeSt = new Date(TimeNows).getTime()
            if (selctTime < TimeNow) {
                e.target.value = format(this.state.data.startDate, "YYYY-MM-DD")
                dataName = e.target.name
                dataValu = format(this.state.data.startDate, "YYYY-MM-DD")
                data_list[dataName] = dataValu;
            // } else if (selctTime < SelctTimeSt) {
            //     e.target.value = format(this.state.data.endDate, "YYYY-MM-DD")
            //     dataName = e.target.name
            //     dataValu = format(this.state.data.endDate, "YYYY-MM-DD")
            //     data_list[dataName] = dataValu;
            } else {
                dataName = e.target.name
                dataValu = e.target.value
                data_list[dataName] = dataValu;
            }
        } else {
            dataName = e.target.name
            dataValu = e.target.value
            data_list[dataName] = dataValu;
        }
        // this.setState({ [dataName]: dataValu })
        this.setState({ data: data_list, brandName: brandName , reasonName: reasonName })
    }
    TypeCar(e) {
        let data_list = _.cloneDeep(this.state.data)
        data_list.typeTrackandCar = e;
        this.setState({ data: data_list })
    }
    TypePerSon(type, e) {
        let data_list = _.cloneDeep(this.state.data)
        if (type == '') {
            data_list.typeRole = e;
            this.setState({ data: data_list })
        } else {
            data_list.typePerson = type;
            data_list.typeRole = e;
            this.setState({ data: data_list })
        }

    }

    typeContactSelct(e) {
        let data_list = _.cloneDeep(this.state.data)
        data_list.reason = e.target.value
        this.setState({ data: data_list})
    }
    chkIspacking(e) {
        let data_list = _.cloneDeep(this.state.data)
        if (e.target.checked) {
            data_list[e.target.name] = true;
            this.setState({ data: data_list })
        } else {
            data_list[e.target.name] = false;
            this.setState({ data: data_list })
        }

    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/vms/file" + path, file);
            resolve(res);
        });
    };


    dateFile = (index, datas, type) => {
        let cloneDataFile = _.cloneDeep(this.state.fileUpload)
        let DataFile = _.cloneDeep(this.state.data)
        let fileupload = []
        if (type === 'upload') {
            _.set(cloneDataFile, "[" + index + "].status", "delete")
            fileupload = _.get(cloneDataFile, "[" + index + "]")
        }
        else {
            const index_data_file = DataFile.file.findIndex(ex => ex.fileId === datas.fileId)
            _.set(DataFile, "file[" + index_data_file + "].status", "delete")
            fileupload = _.get(DataFile, "file[" + index_data_file + "]")
        }

        if (cloneDataFile.findIndex(ex => ex.fileId === fileupload.fileId) < 0) {
            cloneDataFile.push(fileupload)
        }

        if (datas.status === '') {
            this.setState({ fileUpload: cloneDataFile, data: DataFile })
        }
    }
    uploadFile(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value
        let files = event.currentTarget.files[0];
        if (files.size > 2097152) {
            console.log("OVERIMG");
        } else {
            fileIsSel = false
            let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
            let fileOldList = _.cloneDeep(this.state.fileUpload);
            this.callUpload('', new_file).then((data) => {
                let dese = "vms/file/" + new_file.name
                let i = 1
                fileOldList.push({ id: i, fileId: "", fileName: new_file.name, filePath: dese, status: "", viewPath: data.location })
                this.setState({ fileUpload: fileOldList })
                i += 1
            });

        }
    }
    onSubmit(e) {
        e.preventDefault()
        const user = jwt(localStorage.getItem('token'))
        let data = {
            "creatorType": "juristic_wab",
            "carTransactionId": parseInt(this.state.data.carTransactionId),
            "carId": parseInt(this.state.data.carId),
            "fullnameVisit": this.state.data.fullnameVisit,
            "idCard": this.state.data.idCard,
            "idCardImage": "",
            "phone": this.state.data.phone,
            "carRegistration": this.state.data.carRegistration,
            "province": this.state.data.province,
            "typeTrackandCar": this.state.data.typeTrackandCar,
            // "brandCar": this.state.data.brandCar,
            "brandCar": this.state.data.brandCar === "other" ? this.state.brandName : this.state.data.brandCar,

            "versionCar": this.state.data.versionCar,
            "colorCar": this.state.data.colorCar,
            "isPacking": this.state.data.isPacking,
            "typePerson": this.state.data.typePerson,
            "typeRole": this.state.data.typeRole,
            "homeId": parseInt(this.state.data.homeId),
            "dateUsingSt": format(new Date(this.state.data.startDate), "DD/MM/YYYY"),
            "timeUsingSt": this.state.data.timeUsingSt,
            "dateUsingEn": format(new Date(this.state.data.endDate), "DD/MM/YYYY"),
            "timeUsingEn": this.state.data.timeUsingEn,
            "codeSticker": this.state.data.codeSticker,
            "calPacking": this.state.data.calPacking,
            "reason": this.state.data.reason === "other" ? this.state.reasonName : this.state.data.reason,
            "descriptionCar": "",
            "yearCar": this.state.data.yearCar,
            "sizeCar": this.state.data.sizeCar,
            "status": this.state.data.isPacking == true ? "park" : this.state.data.status,
            "createBy": user.is_staff ? user.first_name + " " + user.last_name : this.state.data.homeId,
            "file": this.state.fileUpload,
            //{ "fileId": "", "fileName": "คู่มือ", "filePath": "project/logo/Pimancondo-Park-logo.jpeg", "status": "" },
            //{ "fileId": "", "fileName": "เอกสาร", "filePath": "project/logo/Pimancondo-Park-logo.jpeg", "status": "" }
        }

        fileIsSel = false
        try {
            API.addVisitorVMS(data).then((res) => {
                if (res.data.statusMessage === "SUCCESS") {
                    Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Saved Successfully"), 'success').then(() => {
                        this.setState({ redirect: true })
                    })
                }else if(res.data.statusMessage === "service error"){
                    Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Technow is experiencing an issue. Please contact the administrator."), 'error') 
                }else if(res.data.statusMessage === "date error") {
                     Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:The chosen date must be the present date."), 'error') 
                }
            }, () => {
                Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), '', 'error')
            })
        } catch (error) {
            Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this Time"), 'error')
        }

    }


    onError = () => {
        this.setState({
            src: process.env.PUBLIC_URL + "/images/icons/select-photo.png",
            errored: true,
        });
    }

    onCancel() {

        Swal.fire({
            title: `${i18next.t("manageVisitor:Do you want to confirm the cancellation of this list")}`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
                Swal.fire({
                    title: i18next.t("manageVisitor:Comments"),
                    html: `<input type="text" class="swal2-input" id="cancelRemark">`,
                    showCancelButton: true,
                    confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel"),
                    reverseButtons: true,
                    preConfirm: () => {
                        let cancelRemark = Swal.getPopup().querySelector('#cancelRemark').value
                        return { cancelRemark: cancelRemark}
                      }
                }).then((result) => {
                    if (result.value) {
                        let data = JSON.stringify({
                            carTransactionId: [this.state.data.carTransactionId],
                            remark: `${result.value.cancelRemark}`
                        });
                        try {
                            API.cancelVisitorVMS(data).then((res) => {
                                if (res.data.statusMessage === "SUCCESS") {
                                    Swal.fire(i18next.t("Allaction:Saved Successfully"), i18next.t("Allaction:Canceled Successfully"), 'success').then(() => {
                                        this.setState({ redirect: true })
                                    })
                                }
                            }, () => {
                                Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), '', 'error')
                            })
                        } catch (error) {
                            Swal.fire(i18next.t("Allaction:Canceled Unsuccessful"), i18next.t("Allaction:Unable to Connect to API at this Time"), 'error')
                        }
                    }else{
                        this.setState({loading: false});
                    }
                })                
            }
          })
    }
      


    render() {
        let item = _.filter(this.state.data.file, (n) => n.status === "")
        let itemNewUpload = _.filter(this.state.fileUpload, (n) => n.status === "")
        let idCode = this.props.match.params.id

        if (this.state.redirect) {
            return <Redirect to="/silvermanguard/visitormanage/all" />
        }

        let statsusTypeOfCar = this.state.data.status !== "coming" ? "divTypeCar actived" : "divTypeCar active"
        let chkStasTypeOfCar = this.state.data.status !== "coming" ? "/images/iconAlerts/iconSelected-Gray.png" : "/images/iconAlerts/iconSelected-Blues.png"
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                <SettingTopMenuNavigation mini={true}  />
                    <div className="container-fluid box" id="silvermanguard">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/silvermanguard/visitormanage/all">
                                        <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('graud:codeVisitor') + " " + idCode}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                        </div>

                        <div className='row'>
                            <form onSubmit={this.onSubmit}>
                                <div className='col-10 mx-auto'>

                                    <br /><br />
                                    {
                                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename:'vms_manage_visitor_print' }) &&
                                        <div className='mb-12'>
                                        <Link to={"/silvermanguard/visitormanage/print/" + idCode} className='pullright'>
                                            <button type='button' className='btn-primary-outlie'><Translation>{t => t("manageVisitor:Print visitor card")}</Translation></button>
                                        </Link>
                                    </div>
                                    }
                                   
                                    
                                    <h3 ><Translation>{t => t("claimRequst:GeneralInfo")}</Translation></h3>
                                    <hr />

                                    <label style={{ marginTop: '24px' }}><Translation>{t => t("silvermanguard:nameVisitor")}</Translation> <span className='text-red'>*</span></label>
                                    <input type="text" name='fullnameVisit' className='form-control' placeholder='กรอก'
                                        defaultValue={this.state.data.fullnameVisit} required={true} onChange={this.keyWord}
                                        onKeyUp={this.keyWord} pattern='[A-Za-zก-๏ ]+$' disabled={this.state.data.status !== "coming"}
                                    />

                                    <label><Translation>{t => t("silvermanguard:idCard")}</Translation> <span className='text-red'>*</span></label>
                                    <input type="text" name='idCard' className='form-control' placeholder='x-xx-xxx-xxxx-xx-x'
                                        defaultValue={this.state.data.idCard} pattern='[0-9]{13}' maxLength={13} minLength={13}
                                        required={true} onChange={this.keyWord} onKeyUp={this.keyWord} disabled={this.state.data.status !== "coming"} />

                                    <label><Translation>{t => t("juristicManager:Phone")}</Translation> <span className='text-red'>*</span></label>
                                    <input type="text" name='phone' className='form-control' 
                                        // placeholder={<Translation>{t => t("silvermanguard:Phone")}</Translation> }
                                        placeholder='xxx-xx-xxx-xx'
                                        defaultValue={this.state.data.phone} required={true} onChange={this.keyWord} onKeyUp={this.keyWord}
                                        pattern='[0-9]{10}' minLength={10} maxLength={10} disabled={this.state.data.status !== "coming"}
                                    />


                                    <div style={{ display: 'inline-flex' }}>
                                        <h3 style={{ marginTop: '40px' }}><Translation>{t => t("silvermanguard:file")}</Translation></h3>
                                        <label htmlFor="uploadFile" className={this.state.data.status !== "coming" ? "display-none" : "" + "upload-btn-wrapper"} >
                                            <button className="btn"><img src={process.env.PUBLIC_URL + '/images/icons/fileUpload.png'} /> <Translation>{t => t("PageDetailProjectInfo:ChooseFile")}</Translation></button>
                                            <span style={{ color: '#BFBFBF', fontSize: '12px', marginLeft: '16px' }}><Translation>{t=> t("manageVisitor:.jpg or .pdf file and file size not over 2 MB")}</Translation></span>
                                            <input type="file" name='file' id="uploadFile" className='form-control uploadFile' placeholder='ระบุเบอร์โทร' accept='.pdf,.png,.jpg'
                                                onChange={this.uploadFile.bind(this)} disabled={this.state.data.status !== "coming"} />
                                            {
                                                fileIsSel &&
                                                <p className='text-red'><Translation>{t=> t("manageVisitor:Please upload file")}</Translation>!</p>
                                            }
                                        </label>
                                    </div>
                                    {
                                        item &&
                                        item.map((n, inx) => {
                                            return (
                                                <p key={inx}>
                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} style={{ width: '30px' }} alt="pdfIcon"/> &nbsp;
                                                    {/* <Link target={'_blank'} to={{pathname: n.filePath}} style={{ fontSize: '16px', }}>{n.fileName}</Link> */}
                                                    <Link
                                                        to="route"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open(
                                                                typeof n.filePath === "string"
                                                                    ? n.filePath
                                                                    : URL.createObjectURL(n.filePath)
                                                            )
                                                        }}
                                                        target="blank">
                                                        {n.fileName}
                                                        {/* {fileName.length >= 20 ? fileName.substr(0, 20) : filePath} */}
                                                    </Link>
                                                    {
                                                        this.state.data.status === "coming" &&
                                                        <React.Fragment>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span onClick={() => this.dateFile(inx, n, 'old')}>X</span>

                                                        </React.Fragment>

                                                    }



                                                </p>
                                            )
                                        })
                                    }
                                    {
                                        itemNewUpload &&
                                        itemNewUpload.map((n, inx) => {
                                            return (
                                                <p key={inx}>
                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} style={{ width: '30px' }} alt="pdfIcon"/> &nbsp;
                                                    <Link
                                                        to="route"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open(
                                                                typeof n.viewPath === "string"
                                                                    ? n.viewPath
                                                                    : URL.createObjectURL(n.viewPath)
                                                            )
                                                        }}
                                                        target="blank">
                                                        {n.fileName}

                                                    </Link>
                                                    &nbsp;&nbsp;&nbsp; <span onClick={() => this.dateFile(inx, n, 'upload')}>X</span>
                                                </p>
                                            )
                                        })

                                    }




                                    <h3 style={{ marginTop: '58px' }}><Translation>{t => t("silvermanguard:CarInformation")}</Translation></h3>
                                    <hr />

                                    <label style={{ marginTop: '24px' }}><Translation>{t => t("silvermanguard:CarRegis")}</Translation> <span className='text-red'>*</span></label>
                                    <input type="text" name='carRegistration' className='form-control' 
                                        defaultValue={this.state.data.carRegistration} required={true}
                                        onChange={this.keyWord} onKeyUp={this.keyWord}
                                        placeholder="เช่น 1กก5525 / กก1111 / 71-1111 / กกฟ952" disabled={this.state.data.status !== "coming"} />

                                    <label><Translation>{t => t("silvermanguard:Province")}</Translation> <span className='text-red'>*</span></label>
                                    <select className='form-control' disabled={this.state.data.status !== "coming"}
                                        name='provinceCar' required={true}
                                        value={this.state.data.province} onChange={this.dropDownChanged}>
                                        <option disabled>-<Translation>{t => t("Allaction:choose")}</Translation>-</option>
                                        {
                                            this.state.provinceAll.map((n) => {
                                                return (
                                                    <React.Fragment key={n.node.PROVINCE_ID}>
                                                        <option defaultValue={n.node.PROVINCE_NAME}>{n.node.PROVINCE_NAME}</option>
                                                    </React.Fragment>
                                                )
                                            })
                                        }

                                    </select>


                                    <label><Translation>{t => t("silvermanguard:TypeCar")}</Translation> </label><br />
                                    <div className={this.state.data.status !== "coming" ? "pointer-eventsNone" : ""} style={{ display: 'inline-flex' }}>
                                        <div className={this.state.data.typeTrackandCar === "sedan" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('sedan')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -02.png'} alt="car"/>
                                            {
                                                this.state.data.typeTrackandCar === "sedan" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' alt='selected'/>
                                            }
                                            <p><Translation>{t => t("silvermanguard:car")}</Translation></p>
                                        </div>

                                        <div className={this.state.data.typeTrackandCar === "pickup" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('pickup')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -03.png'} />
                                            {
                                                this.state.data.typeTrackandCar === "pickup" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' />
                                            }
                                            <p><Translation>{t => t("silvermanguard:pickup")}</Translation></p>
                                        </div>

                                        <div className={this.state.data.typeTrackandCar === "van" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('van')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -04.png'} alt="car"/>
                                            {
                                                this.state.data.typeTrackandCar === "van" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' alt="car"/>
                                            }
                                            <p><Translation>{t => t("silvermanguard:van")}</Translation></p>
                                        </div>

                                        <div className={this.state.data.typeTrackandCar === "taxi" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('taxi')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/type Cars -05.png'} alt="car" />
                                            {
                                                this.state.data.typeTrackandCar === "taxi" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' />
                                            }
                                            <p><Translation>{t => t("silvermanguard:taxi")}</Translation></p>
                                        </div>

                                        <div className={this.state.data.typeTrackandCar === "motorcy" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('motorcy')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor.png'} alt="motor"/>
                                            {
                                                this.state.data.typeTrackandCar === "motorcy" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' />
                                            }
                                            <p><Translation>{t => t("silvermanguard:motocy")}</Translation></p>
                                        </div>

                                        <div className={this.state.data.typeTrackandCar === "motorcycle" ? statsusTypeOfCar : 'divTypeCar'} onClick={() => this.TypeCar('motorcycle')}>
                                            <img src={process.env.PUBLIC_URL + '/images/typeIconCar/motor - BB.png'} />
                                            {
                                                this.state.data.typeTrackandCar === "motorcycle" &&
                                                <img src={process.env.PUBLIC_URL + chkStasTypeOfCar} className='selected' />
                                            }
                                            <p><Translation>{t => t("silvermanguard:motocyBB")}</Translation></p>
                                        </div>


                                    </div>

                                    <label style={{ marginTop: '60px' }}><Translation>{t => t("silvermanguard:brandCar")}</Translation> <span className='text-red'>*</span></label>
                                    <select disabled={this.state.data.status !== "coming"}
                                        className='form-control' required={true} name='brandCar'
                                        value={this.state.data.brandCar} onChange={this.dropDownChanged}>
                                        <option disabled value={""}>-<Translation>{t => t("Allaction:choose")}</Translation>-</option>
                                        {
                                            this.state.brandAll.map((n, idx) => {
                                                return (
                                                    <React.Fragment key={idx}>
                                                        <option value={n.brandCode} >{n.brandName}</option>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </select>

                                    {
                                        this.state.data.brandCar === "other" &&
                                        <React.Fragment>
                                            <label><Translation>{t => t("silvermanguard:InputBrandCar")}</Translation> <span className='text-red'>*</span></label>
                                            <input type="text" name='brandName' className='form-control'
                                                defaultValue={this.state.brandName} onChange={this.keyWord} onKeyUp={this.keyWord}
                                                required={true} />
                                        </React.Fragment>
                                    }

                                    {/* <label>รุ่น <span className='text-red'>*</span></label>
                                    <input type="text" disabled={this.state.data.status !== "coming"} name='versionCar' className='form-control' placeholder='กรอก' defaultValue={this.state.data.versionCar} onChange={this.keyWord} onKeyUp={this.keyWord} /> */}

                                    <label><Translation>{t => t("silvermanguard:ColorCar")}</Translation><span className='text-red'>*</span></label>
                                    <input type="text" disabled={this.state.data.status !== "coming"} name='colorCar' className='form-control'
                                     placeholder='' defaultValue={this.state.data.colorCar} onChange={this.keyWord} onKeyUp={this.keyWord} />

                                    {/* <label>ปี</label>
                                    <input type="text" disabled={this.state.data.status !== "coming"} name='yearCar' className='form-control' placeholder='กรอก' defaultValue={this.state.data.yearCar} onChange={this.keyWord} onKeyUp={this.keyWord} />

                                    <label>ขนาด</label>
                                    <input type="text" disabled={this.state.data.status !== "coming"} name='sizeCar' className='form-control' placeholder='กรอก' defaultValue={this.state.data.sizeCar} onChange={this.keyWord} onKeyUp={this.keyWord} /> */}

                                    {/* <br /> */}
                                    <label /*style={{ marginTop: '48px' }}*/><Translation>{t => t("silvermanguard:codeticket")}</Translation> </label><br />
                                    <input type="text" disabled={this.state.data.status !== "coming"}
                                        name='codeSticker' className='form-control' placeholder='กรอก'
                                        defaultValue={this.state.data.codeSticker} onChange={this.keyWord}
                                        onKeyUp={this.keyWord} />


                                    <h3 style={{ marginTop: '36px' }}><Translation>{t => t("silvermanguard:role")}</Translation></h3>
                                    <hr />

                                    <label style={{ marginTop: '24px' }}><Translation>{t => t("silvermanguard:typePerson")}</Translation> </label><br />
                                    <label className="containerRadio"><Translation>{t => t("silvermanguard:tenant")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="radio"
                                            checked={this.state.data.typePerson === "tenant"}
                                            onChange={() => this.TypePerSon('tenant', 'perm')} name='typePerson' />
                                        <span className="checkmark"></span>
                                    </label>

                                    <label style={{ marginTop: '13px', marginLeft: '35px' }}><Translation>{t => t("silvermanguard:FrisPacking")}</Translation></label>
                                    <label className="containerRadio" style={{ marginLeft: '35px' }}><Translation>{t => t("silvermanguard:park")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="checkbox" name="isPacking"
                                            onClick={this.chkIspacking} checked={this.state.data.isPacking == true && this.state.data.typePerson === "tenant"} />
                                        <span className="checkmarkCheckBox"></span>
                                    </label>

                                    <label className="containerRadio"><Translation>{t => t("silvermanguard:contact")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="radio" name='typePerson' checked={this.state.data.typePerson === "contact"} onChange={() => this.TypePerSon('contact', 'temp')} />
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="containerRadio"><Translation>{t => t("vmsDashboard:Appointment")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="radio" name='typePerson' checked={this.state.data.typePerson === "appointment"} onChange={() => this.TypePerSon('appointment', 'temp')} />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="containerRadio"><Translation>{t => t("vmsDashboard:Person")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="radio" name='typePerson' checked={this.state.data.typePerson === "person"} onChange={() => this.TypePerSon('person', 'temp')} />
                                        <span className="checkmark"></span>
                                    </label>


                                    <label style={{ marginTop: '24px' }}><Translation>{t => t("silvermanguard:typeRole")}</Translation> </label><br />
                                    <label className="containerRadio"><Translation>{t => t("silvermanguard:temp")}</Translation>
                                        <input type="radio" name="typeRole"
                                            checked={this.state.data.typeRole === "temp"}
                                            onChange={() => this.TypePerSon('', 'temp')}
                                            disabled={this.state.data.status !== "coming"}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                    <label className="containerRadio"><Translation>{t => t("vmsDashboard:Permanent (with expiration date)")}</Translation>
                                        <input
                                            disabled={
                                                (
                                                    this.state.data.status !== "coming" &&
                                                    this.state.data.typePerson !== "tenant"
                                                ) ||
                                                (this.state.data.typePerson !== "tenant")
                                            }
                                            // disabled={
                                            //     this.state.data.status !== "coming"

                                            //     //  ||  this.state.data.typePerson !== "tenant"
                                            // } 
                                            type="radio" name="typeRole"
                                            checked={this.state.data.typeRole === "perm"}
                                            onChange={() => this.TypePerSon('', 'perm')} />
                                        <span className="checkmark"></span>
                                    </label>


                                    {
                                        this.state.data.typePerson === "contact" &&
                                        <React.Fragment>
                                            <div className='boxAlert' style={{ width: '440px' }}>
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                <span><Translation>{t => t("silvermanguard:contact")}</Translation><Translation>{t => t("silvermanguard:oneTimeOnly")}</Translation> </span>
                                                &nbsp;
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                            </div>
                                        </React.Fragment>

                                        ||

                                        this.state.data.typePerson === "appointment" &&
                                        <React.Fragment>
                                            <div className='boxAlert' style={{ width: '561px' }}>
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                <span> <Translation>{t => t("vmsDashboard:Appointment")}</Translation><Translation>{t => t("silvermanguard:oneTimeOnly")}</Translation></span>
                                                &nbsp;
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                            </div>
                                        </React.Fragment>

                                        ||

                                        this.state.data.typePerson === "person" &&
                                        <React.Fragment>
                                            <div className='boxAlert' style={{ width: '561px' }}>
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/iconAlert-BlueSmall.png'} /> &nbsp;
                                                <span><Translation>{t => t("vmsDashboard:Person")}</Translation><Translation>{t => t("silvermanguard:oneTimeOnly")}</Translation></span>
                                                &nbsp;
                                                <img src={process.env.PUBLIC_URL + '/images/iconAlerts/close-Blue.png'} style={{ marginLeft: '10px' }} />
                                            </div>
                                        </React.Fragment>

                                    }

                                    {
                                        this.state.data.typePerson === "tenant" &&
                                        <React.Fragment>
                                            <label style={{ marginTop: '42px' }}><Translation>{t => t("silvermanguard:HomeId")}</Translation> <span className='text-red'>*</span></label><br />
                                            {/* <input type="text" name='homeId' className='form-control' placeholder='กรอก' defaultValue={this.state.data.homeId} onChange={this.keyWord} onKeyUp={this.keyWord}  /> */}
                                            <SearchSelect
                                                disabled={this.state.data.status !== "coming"}
                                                onChange={this.dropDownChanged}
                                                value={this.state.data.homeId}
                                                name="homeId" placeholder="รายการ"
                                                queryObject={this.state.homeAll}
                                                keyOfValue="homeId"
                                                require={true}
                                                keyOfLabel="name" />
                                        </React.Fragment>
                                    }
                                    {
                                        (this.state.data.typePerson === "appointment" || this.state.data.typePerson === "person") &&
                                        <React.Fragment>
                                            <label style={{ marginTop: '42px' }}><Translation>{t => t("silvermanguard:HomeIdContact")}</Translation><span className='text-red'>*</span></label><br />
                                            <SearchSelect disabled={this.state.status !== "coming"}
                                                onChange={this.dropDownChanged}
                                                value={this.state.data.homeId}
                                                name="homeId" placeholder="รายการ"
                                                queryObject={this.state.homeAll}
                                                keyOfValue="homeId"
                                                require={true}
                                                keyOfLabel="name" />
                                        </React.Fragment>
                                    }

                                    {
                                        (this.state.data.typePerson === "appointment" || this.state.data.typePerson === "person") &&
                                        <React.Fragment>
                                            <label><Translation>{t => t("silvermanguard:Object")}</Translation> <span className='text-red'>*</span></label><br />
                                            <input disabled={this.state.data.status !== "coming"} type="text" name='reason' className='form-control' placeholder='กรอก' defaultValue={this.state.data.reason} onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </React.Fragment>
                                    }

                                    {
                                        this.state.data.typePerson === "contact" &&
                                        <React.Fragment>
                                            <label><Translation>{t => t("silvermanguard:Object")}</Translation> <span className='text-red'>*</span></label><br />
                                            <select name='reason' className='form-control' disabled={this.state.data.status !== "coming"} onChange={this.typeContactSelct}
                                                defaultValue={this.state.data.reason}>
                                                {
                                                    this.state.typeContact.map((n) => {
                                                        return (
                                                            <option value={n.value} key={n.value}>{n.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </React.Fragment>
                                    }

                                    {
                                        this.state.data.reason === "other" &&
                                        <React.Fragment>
                                            <label><Translation>{t => t("silvermanguard:InputObjects")}</Translation> <span className='text-red'>*</span></label><br />
                                            <input disabled={this.state.data.status !== "coming"} type="text" name='reasonName' 
                                                className='form-control' placeholder='' defaultValue={this.state.reasonName} 
                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </React.Fragment>
                                    }



                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ width: '254px' }}>
                                            <label><Translation>{t => t("silvermanguard:DateUse")}</Translation> <span className='text-red'>*</span></label><br />
                                            <input disabled={this.state.data.status !== "coming"}
                                                key={this.state.data.startDate}
                                                type="date" className='form-control' placeholder='กรอก'
                                                defaultValue={format(this.state.data.startDate, "YYYY-MM-DD")}
                                                name='startDate' onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </div>
                                        <div style={{ width: '254px' }}>
                                            <label><Translation>{t => t("silvermanguard:TimeUse")}</Translation> <span className='text-red'>*</span></label><br />
                                            <input disabled={this.state.data.status !== "coming"}
                                                type="time" className='form-control' placeholder='กรอก'
                                                defaultValue={this.state.data.timeUsingSt} name='timeUsingSt'
                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </div>
                                    </div>





                                    <br />
                                    <div style={{ display: 'inline-flex' }}>
                                        <div style={{ width: '254px' }}>
                                            <label><Translation>{t => t("silvermanguard:DateEnd")}</Translation> <span className='text-red'>*</span></label><br />
                                            {/* {
                                                this.state.data.dateUsingEn
                                            } */}

                                            <input disabled={this.state.data.status !== "coming"} type="date"
                                                key={this.state.data.endDate}
                                                className='form-control' placeholder='กรอก'
                                                defaultValue={format(this.state.data.endDate, "YYYY-MM-DD")}
                                                name='endDate' onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </div>
                                        <div style={{ width: '254px' }}>
                                            <label><Translation>{t => t("silvermanguard:TimeEnd")}</Translation> <span className='text-red'>*</span></label><br />
                                            <input disabled={this.state.data.status !== "coming"} type="time"
                                                className='form-control' placeholder='กรอก'
                                                defaultValue={this.state.data.timeUsingEn} name='timeUsingEn'
                                                onChange={this.keyWord} onKeyUp={this.keyWord} />
                                        </div>
                                    </div>  



                                    <br />
                                    <label className="containerRadio"><Translation>{t => t("silvermanguard:isCalculatePacking")}</Translation>
                                        <input disabled={this.state.data.status !== "coming"} type="checkbox"
                                            name="calPacking" onClick={this.chkIspacking}
                                            checked={this.state.data.calPacking == true} />
                                        <span className="checkmarkCheckBox"></span>
                                    </label>

                                     {
                                     this.state.data.status !== 'cancel' &&  
                                     _.some(JSON.parse(localStorage.getItem("permission_list")), { codename:'vms_manage_visitor_edit' }) &&     
                                        (<div className='row mt-2'>
                                            <div className='col-12 text-right'>
                                                <button type='submit' className='btn btn-primary mr-2'><Translation>{t => t("PageDetailProjectInfo:Save")}</Translation></button>
                                                <div onClick={()=>this.onCancel()} className='btn btn-danger'><Translation>{t => t("PageDetailProjectInfo:Cancle")}</Translation></div>
                                            </div>
                                        </div>)
                                    }

                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </form>

                        </div>
                    </div>


                </WrapperContent>
            </Wrapper>
        )
    }
}

export default VisitMangeDetail;
