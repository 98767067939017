import React, { Component } from 'react';
import { Link } from "react-router-dom";
import numberWithCommas from "../../../libs/numberWithComma";
import { graphql } from "babel-plugin-relay/macro";
import Wrapper from "../../../components/wrapper";
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import { Translation } from "react-i18next";
import { fetchQuery } from "relay-runtime";
import environment from "../../../env/environment";
import { format, parse } from "date-fns";
import thLocale from "date-fns/locale/th";
import PayChannel from "../pay/payChannel";
import Swal from "sweetalert2";
import { commitMutation } from "react-relay";
import Redirect from "react-router-dom/Redirect";
import i18next from 'i18next';

const _ = require('lodash');

const query = graphql`
    query pettyCashPaymentDetailTableQuery($id: ID!){
        pettyCashPayment(id: $id){
            id
            docNumber
            issuedDate
            total
            pettyCashRecord{
                edges{
                    node{
                        id
                        docNumber
                        issuedDate
                        chartOfAccount{
                            chartOfAccountCode
                            name
                        }
                        description
                        price
                        setPettyCash{
                            docNumber
                            withdrawer
                            description
                            withdrawAmount
                        }
                    }
                }
            }
            otherExpensePaymentChannel{
                edges{
                    node{
                        id
                        otherExpense{
                            id
                            docNumber
                            issuedDate
                            otherExpenseTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        productAndService{
                                            id
                                            name
                                            productCode
                                        }
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }

                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
            payPaymentChannel{
                edges{
                    node{
                        id
                        payRecordGroup{
                            id
                            docNumber
                            issuedDate
                            payRecord{
                                edges{
                                    node{
                                        id
                                        purchaseRecord{
                                            id
                                            description
                                            productAndService{
                                                id
                                                name
                                                productCode
                                            }
                                            chartOfAccount{
                                                id
                                                name
                                                chartOfAccountCode
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        description
                        price
                        status
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }
            advancePettyCashChannel{
                totalCount
                edges{
                    node{
                        id
                        advance{
                            id
                            docNumber
                            issuedDate
                            dueDate
                            withdrawer
                            description
                            amount
                            status
                            voidRemark
                            chartOfAccount{
                                id
                                name
                                chartOfAccountCode
                            }
                        }
                        clearAdvance{
                            id
                            docNumber
                            description
                            issuedDate
                            status
                            advance{
                                id
                                withdrawer
                            }
                            clearAdvanceTransaction{
                                edges{
                                    node{
                                        id
                                        description
                                        price
                                        refClearAdvanceTransactionDoc
                                        chartOfAccount{
                                            id
                                            name
                                            chartOfAccountCode
                                        }
                                    }
                                }
                            }
                        }
                        description
                        price
                        statusSetPettyCash
                        date
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }

            clearGuaranteeMoneyReceivedPettyCashChannel{
                edges{
                    node{
                        id
                        clearGuaranteeMoneyReceived{
                            id
                            docNumber
                            issuedDate
                            status
                            contact{
                                refNumber
                                firstName
                                lastName
                                name
                            }
                            guarantee {
                                docNumber
                                chartOfAccount {
                                    id
                                    chartOfAccountCode
                                    name
                                }
                            }
                        }
                        description
                        price
                        statusSetPettyCash
                        setPettyCash{
                            docNumber
                            description
                            withdrawer
                        }
                    }
                }
            }

            bankAccountTransaction {
                edges {
                    node {
                        id
                        price
                        date
                        imageSlip
                        bankAccount {
                            bankName
                            accountNumber
                        }
                    }
                }
            }
            cashTransaction {
                edges {
                    node {
                        id
                        price
                    }
                }
            }
            chequeTransaction {
                edges {
                    node {
                        id
                        date
                        price
                        chequeNumber
                        bankAccount {
                            id
                            accountName
                            branch
                            bankName
                        }
                    }
                }
            }
        }
    }
`;

const mutation = graphql`
    mutation pettyCashPaymentDetailTableMutation($input: UpdatePettyCashPaymentStatusInput!){
        updatePettyCashPaymentStatus(input:$input){
            warningText
            pettyCashPayment{
                id
            }
        }
    }
`;

const petty_cash = {
    node: {
        id: "",
        docNumber: "",
        issuedDate: "",
        chartOfAccount: { chartOfAccountCode: "", name: "", },
        description: "",
        price: 0,
        setPettyCash: {
            docNumber: "",
            withdrawer: "",
            description: "",
        }
    }
};

class PettyCashPaymentDetailTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            petty_cash_list: [petty_cash],
            set_petty_cash: {},
            doc_number: "",
            price: '',
            bank_account: [],
            cash_transaction: [],
            cheque_transaction: [],
            issuedDate: new Date(),
            total: 0.0,
            redirect: false,
            records: [],
        };
        this.onChangeStatusVoid = this.onChangeStatusVoid.bind(this);
    }

    componentWillMount() {
        fetchQuery(environment, query, { id: this.props.match.params.id }).then(data => {
            let record = [];
            if (_.isObject(data.pettyCashPayment)) {
                data.pettyCashPayment.pettyCashRecord.edges.map(value => {
                    record.push(value);
                })
                data.pettyCashPayment.payPaymentChannel.edges.map(value => {
                    record.push(value);
                })
                data.pettyCashPayment.otherExpensePaymentChannel.edges.map(value => {
                    record.push(value);
                })
                data.pettyCashPayment.advancePettyCashChannel.edges.map(value => {
                    record.push(value);
                })
                data.pettyCashPayment.clearGuaranteeMoneyReceivedPettyCashChannel.edges.map(value => {
                    record.push(value);
                })

                this.setState({
                    doc_number: data.pettyCashPayment.docNumber,
                    issuedDate: parse(data.pettyCashPayment.issuedDate),
                    bank_account: data.pettyCashPayment.bankAccountTransaction.edges,
                    cash_transaction: data.pettyCashPayment.cashTransaction.edges,
                    cheque_transaction: data.pettyCashPayment.chequeTransaction.edges,
                    total: data.pettyCashPayment.total,
                    records: record,
                });

            }
        });
    }

    onChangeStatusVoid() {
        Swal.fire({
            title: i18next.t("pettyCashView:Do you cancel a petty cash reimbursement")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: i18next.t("Allaction:cancel"),
            confirmButtonText: i18next.t("Allaction:yes")
        }).then(async (result) => {
            if (result.value) {

                let { value: void_remark } = await Swal.fire({
                    title: `${i18next.t("Allaction:Note")}`,
                    input: 'text',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: `${i18next.t("Allaction:cancel")}`,
                    confirmButtonText: `${i18next.t("Allaction:Confirm")}`,
                    inputValidator: (value) => {
                        if (!value) {
                            return `${i18next.t("Allaction:Please fill input before cancel")}`
                        }
                    }
                });

                if (void_remark) {
                    this.setState({ loading: true });
                    let variables = {
                        input: {
                            clientMutationId: this.props.match.params.id,
                            status: "void",
                            voidRemark: void_remark
                        }
                    };
                    commitMutation(
                        environment,
                        {
                            mutation,
                            variables,
                            onCompleted: (response) => {
                                if (response.updatePettyCashPaymentStatus.pettyCashPayment) {
                                    Swal.fire(i18next.t("Allaction:Canceled Successfully"), '', 'success').then(() => {
                                        this.setState({ redirect: true, loading: false });
                                    })
                                } else {

                                    Swal.fire(i18next.t("Allaction:Canceled Successfully"), response.updatePettyCashPaymentStatus.warningText, 'warning');
                                    this.setState({ loading: false });
                                }
                            },
                            onError: (err) => {
                                Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                                this.setState({ loading: false });
                            },
                        },
                    );
                }

            } else {
                this.setState({ loading: false });
            }
        })
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to="/accounting/expense/petty_cash/list/payment" />
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true} />
                    <div className="container-fluid box" id="invoice-detail">
                        <div className="row justify-content-between pb-2">
                            <div className="col">
                                <h3 className="mb-4">
                                    <Link to="/accounting/expense/petty_cash/list/payment">
                                        <img
                                            src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                            alt="back" className="back" />
                                    </Link>
                                    <Translation>
                                        {
                                            t => <span>{t('accounting:add_petty_cash_payment')+" "+"(PCP)"}</span>
                                        }
                                    </Translation>
                                </h3>
                            </div>
                            <div className="col text-right">
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_delete' }) &&
                                    <button type="button" className="btn btn-danger add mr-3"
                                        disabled={this.state.loading}
                                        onClick={this.onChangeStatusVoid}>
                                        {this.state.loading &&
                                            <span
                                                className="spinner-border spinner-border-sm align-middle mr-2" />}
                                        <Translation>{t => t("pettyCashView:Cancellation a petty cash reimbursement")}</Translation>
                                    </button>
                                }
                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_petty_cash_print' }) &&
                                    <Link to={"/document/petty-cash-payment/" + this.props.match.params.id}
                                        target="_blank">
                                        <button className="btn btn-primary add"
                                            disabled={this.state.loading}>
                                            <Translation>{t => t("pettyCashView:Print")}</Translation>
                                        </button>
                                    </Link>
                                }
                            </div>
                        </div>
                        <Translation>
                            {t =>
                        <div className="content-inner">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <p><strong>{i18next.t("pettyCashView:List of petty cash")}</strong></p>
                                            <p>{this.state.set_petty_cash.docNumber} {this.state.set_petty_cash.withdrawer} {this.state.set_petty_cash.description}</p>
                                        </div>
                                        <div className="col">
                                            <p><strong>{i18next.t("pettyCashView:Document No.")}</strong></p>
                                            <p>{this.state.doc_number} </p>
                                        </div>
                                        <div className="col">
                                            <p><strong>{i18next.t("pettyCashView:Date")}</strong></p>
                                            <p>{format(this.state.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</p>
                                        </div>

                                    </div>
                                    <hr />

                                    <div className="table-responsive fade-up">
                                        <table className="table table-hover mt-2">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{i18next.t("pettyCashList:No.")}</th>
                                                    <th>{i18next.t("pettyCashList:Date")}</th>
                                                    <th>{i18next.t("pettyCashView:Account")}</th>
                                                    <th>{i18next.t("pettyCashView:List")}</th>
                                                    <th className="text-right">{i18next.t("pettyCashView:Amount")}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.records.map((record, index_oe) => {
                                                    if (record.node.otherExpense) {
                                                        return (
                                                            record.node.otherExpense.otherExpenseTransaction.edges.map((otherExpenseTransaction, index) => {
                                                                let code = "";
                                                                let name_code = "";
                                                                let last_row = record.node.otherExpense.otherExpenseTransaction.edges.length;
                                                                if (otherExpenseTransaction.node.productAndService) {
                                                                    code = otherExpenseTransaction.node.productAndService.productCode;
                                                                    name_code = otherExpenseTransaction.node.productAndService.name;
                                                                } else {
                                                                    code = otherExpenseTransaction.node.chartOfAccount.chartOfAccountCode;
                                                                    name_code = otherExpenseTransaction.node.chartOfAccount.name;
                                                                }
                                                                return (
                                                                    <tr key={record.node.id}>
                                                                        <td>{index === 0 && record.node.otherExpense.docNumber}</td>
                                                                        <td> {format(record.node.otherExpense.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        <td>{code} {name_code}</td>
                                                                        <td>{record.node.description}</td>
                                                                        <td className="text-right">{index + 1 === last_row ? numberWithCommas(record.node.price) : "-"}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                        )
                                                    }
                                                    else if (record.node.payRecordGroup) {
                                                        return (
                                                            record.node.payRecordGroup.payRecord.edges.map((payRecord, index) => {
                                                                let code = "";
                                                                let name_code = "";
                                                                let last_row = record.node.payRecordGroup.payRecord.edges.length;
                                                                if (payRecord.node.purchaseRecord.productAndService) {
                                                                    code = payRecord.node.purchaseRecord.productAndService.productCode;
                                                                    name_code = payRecord.node.purchaseRecord.productAndService.name;
                                                                } else {
                                                                    code = payRecord.node.purchaseRecord.chartOfAccount.chartOfAccountCode;
                                                                    name_code = payRecord.node.purchaseRecord.chartOfAccount.name;
                                                                }
                                                                return (
                                                                    <tr key={record.node.id}>
                                                                        <td>{index === 0 && record.node.payRecordGroup.docNumber}</td>
                                                                        <td> {format(record.node.payRecordGroup.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        <td>{code} {name_code}</td>
                                                                        <td>{payRecord.node.purchaseRecord.description}</td>
                                                                        <td className="text-right">{index + 1 === last_row ? numberWithCommas(record.node.price) : "-"}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                        )
                                                    }
                                                    else if (record.node.advance && record.node.clearAdvance === null) {
                                                        let code = "";
                                                        let name_code = "";
                                                        code = record.node.advance.chartOfAccount.chartOfAccountCode;
                                                        name_code = record.node.advance.chartOfAccount.name;
                                                        
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td>{record.node.advance.docNumber}</td>
                                                                <td> {format(record.node.advance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td>{code} {name_code}</td>
                                                                <td>{record.node.advance.description}</td>
                                                                <td className="text-right">{numberWithCommas(record.node.price)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    else if (record.node.advance === null && record.node.clearAdvance) {
                                                        return (
                                                            record.node.clearAdvance.clearAdvanceTransaction.edges.map((clearAdvanceTransaction, index) => {
                                                                let code = "";
                                                                let name_code = "";
                                                                let last_row = record.node.clearAdvance.clearAdvanceTransaction.edges.length;
                                                                code = clearAdvanceTransaction.node.chartOfAccount.chartOfAccountCode;
                                                                name_code = clearAdvanceTransaction.node.chartOfAccount.name;
                                                                
                                                                return (
                                                                    <tr key={record.node.id}>
                                                                        <td>{index === 0 && record.node.clearAdvance.docNumber}</td>
                                                                        <td> {format(record.node.clearAdvance.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                        <td>{code} {name_code}</td>
                                                                        <td>{record.node.description}</td>
                                                                        <td className="text-right">{index + 1 === last_row ? numberWithCommas(record.node.price) : "-"}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                        )
                                                    }
                                                    else if (record.node.clearGuaranteeMoneyReceived) {
                                                        let code = "";
                                                        let name_code = "";
                                                        code = record.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.chartOfAccountCode;
                                                        name_code = record.node.clearGuaranteeMoneyReceived.guarantee.chartOfAccount.name;
                                                        
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td>{record.node.clearGuaranteeMoneyReceived.docNumber}</td>
                                                                <td> {format(record.node.clearGuaranteeMoneyReceived.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td>{code} {name_code}</td>
                                                                <td>{record.node.description}</td>
                                                                <td className="text-right">{numberWithCommas(record.node.price)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <tr key={record.node.id}>
                                                                <td>{record.node.docNumber}</td>
                                                                <td>{format(record.node.issuedDate, 'DD/MM/YYYY', { locale: thLocale })}</td>
                                                                <td>{record.node.chartOfAccount.chartOfAccountCode} {record.node.chartOfAccount.name}</td>
                                                                <td>{record.node.description}</td>
                                                                <td className="text-right">{numberWithCommas(record.node.price)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <PayChannel
                                total={this.state.total}
                                bankTransaction={this.state.bank_account}
                                cashTransaction={this.state.cash_transaction}
                                chequeTransaction={this.state.cheque_transaction}
                                account_type="pay"
                            />

                            <div className="row mt-4">
                                <div className="col-12 ">
                                    <div className="col-4 float-right">
                                        <div className="row p-3 mb-2 bg-light text-dark">
                                            <div className="col text-left"> {i18next.t("pettyCashView:Total")}</div>
                                            <div
                                                className="col text-right">{numberWithCommas(this.state.total)} {i18next.t("pettyCashView:Baht")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                            }

                        </Translation>
                    </div>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default PettyCashPaymentDetailTable;
