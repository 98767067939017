import React, { Component } from "react";
import { Translation } from "react-i18next";
import Select from 'react-select';
import "./settingCollection.scss"
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import Swal from "sweetalert2";
import {commitMutation} from "react-relay";
import _ from 'lodash';
import Switch from "react-switch";
import getForm from './formOneQuery'
import Loading from '../../libs/loading';
import i18next from "i18next";


const selectDay = [
    { value :'the_first', label : '1'},
    { value :'the_second', label : '2'},
    { value :'the_third', label : '3'},
    { value :'the_fourth',label :  '4'},
    { value :'the_fifth', label : '5'},
    { value :'the_sixth',label :  '6'},
    { value :'the_seventh', label : '7'},
    { value :'the_eighth', label : '8'},
    { value :'the_ninth',label :  '9'},
    { value :'the_tenth', label : '10'},
    { value :'the_eleventh', label : '11'},
    { value :'the_twelfth', label : '12'},
    { value :'the_thirteenth',label :  '13'},
    { value :'the_fourteenth',label :  '14'},
    { value :'the_fifteenth',label :  '15'},
    { value :'the_sixteenth',label :  '16'},
    { value :'the_seventeenth', label : '17'},
    { value :'the_eighteenth',label :  '18'},
    { value :'the_nineteenth',label :  '19'},
    { value :'the_twentieth',label :  '20'},
    { value :'the_twenty_first', label : '21'},
    { value :'the_twenty_second',label :  '22'},
    { value :'the_twenty_third',label :  '23'},
    { value :'the_twenty_fourth',label :  '24'},
    { value :'the_twenty_fifth',label :  '25'},
    { value :'the_twenty_sixth',label :  '26'},
    { value :'the_twenty_seventh',label :  '27'},
    { value :'the_twenty_eighth', label : '28'},
    { value :'end_of_month', label : 'ทุกสิ้นเดือน'},
]

const query = graphql`
query settingAutomaticQuery{
    allSettingAutomaticDebit{
        edges{
            node{
                id
                automaticStatus
                setMethod
                setDateMethod
                setDuedateMethod
                setCustomMethod1
                setCustomMethod2
                setCustomMethod3
                setCustomMethod4
                debtStatus
                receivableOutstanding
                statusUsing
            }
        }
    }
}`;

const mutation = graphql`
  mutation settingAutomaticMutation ($input: CreateAndUpdateSettingAutomaticDebitInput!)  {
    createAndUpdateSettingAutomaticDebit (input: $input) {
        ok
        message
        newSettingAutomaticDebit{
            id
            automaticStatus
            setMethod
            setDateMethod
            setDuedateMethod
            setCustomMethod1
            setCustomMethod2
            setCustomMethod3
            setCustomMethod4
            debtStatus
            receivableOutstanding
        }
    }
  }
`;


class SettingAutomatic extends Component {
    constructor(props){
        super(props)
        this.state = {
            setMethod : 'date',
            setDateMethod : '',
            automaticStatus : false,
            setDuedateMethod : null,
            setCustomMethod1 : null,
            setCustomMethod2 : null,
            setCustomMethod3 : null,
            setCustomMethod4 : null,
            debtStatus : 'custom',
            receivableOutstanding : false,
            statusUsing: null,
            id : '',
            loading : false,
            loadingData : false,
            isNullSettingForm: false , //เช็คว่ามีบันทึก form แล้วหรือยัง -> true = no , false = yes
            //check permission to edit setting
            permissionEdit : _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingautomaticdebit_edit'}) ?
                _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_settingautomaticdebit_edit' }) : false
        }
    }

    componentDidMount(){
        this.fetchQuery()
    }

    fetchQuery = async() => {
        this.setState({loadingData : true})
        for (const num of  [1,2,3,4]){
            let form = await getForm(num)
            if(form.allSettingFormDebit?.edges.length === 0){
             this.setState({
                 isNullSettingForm : true
             })
            }
        }
        fetchQuery(environment, query, {}).then((data) => {
            if(data.allSettingAutomaticDebit?.edges.length > 0){
                let setDateMethod = ''
                if(data.allSettingAutomaticDebit?.edges[0].node.setDateMethod){
                    selectDay.forEach((option) => {
                        if (option.value === _.lowerCase(data.allSettingAutomaticDebit?.edges[0].node.setDateMethod).replace(" ",'_')) {
                            setDateMethod = option
                        }
                    });
                }
                this.setState({
                    id : data.allSettingAutomaticDebit?.edges[0].node.id ? data.allSettingAutomaticDebit?.edges[0].node.id : '',
                    setMethod : _.lowerCase(data.allSettingAutomaticDebit?.edges[0].node.setMethod) === 'custom' ? 'customs' : _.lowerCase(data.allSettingAutomaticDebit?.edges[0].node.setMethod),
                    setDateMethod : setDateMethod ,
                    automaticStatus : data.allSettingAutomaticDebit?.edges[0].node.automaticStatus,
                    setDuedateMethod : data.allSettingAutomaticDebit?.edges[0].node.setDuedateMethod,
                    setCustomMethod1 : data.allSettingAutomaticDebit?.edges[0].node.setCustomMethod1,
                    setCustomMethod2 : data.allSettingAutomaticDebit?.edges[0].node.setCustomMethod2,
                    setCustomMethod3 : data.allSettingAutomaticDebit?.edges[0].node.setCustomMethod3,
                    setCustomMethod4 : data.allSettingAutomaticDebit?.edges[0].node.setCustomMethod4,
                    debtStatus : _.lowerCase(data.allSettingAutomaticDebit?.edges[0].node.debtStatus),
                    receivableOutstanding : data.allSettingAutomaticDebit?.edges[0].node.receivableOutstanding,
                    statusUsing: data.allSettingAutomaticDebit?.edges[0].node.statusUsing,
                },() => {this.setState({loadingData : false })})
            }else{
                this.setState({loadingData : false , statusUsing: false})
            }
        });
    }

    handleChangeAuto = (automaticStatus) => {
        if(this.state.isNullSettingForm){
            Swal.fire(i18next.t("settingForm:Incomplete form settings"), i18next.t("settingForm:Set the document format first before proceeding with the transaction"), 'warning')
        }else{
            this.setState({ automaticStatus });
        }
    }

    handleChangeReceivableOutstanding = (receivableOutstanding) => {
        this.setState({ receivableOutstanding });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    toggleSetMethod = (e) => {
        this.setState({
            setMethod : e.target.name
        })
    }

    toggleDebtStatus = (e) => {
        this.setState({
            debtStatus : e.target.name
        })
    }

    selectDay = (value) => {
        this.setState({
            setDateMethod : value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.setState({loading:true})
        let variables = {
            input : {
                settingAutomatic: {
                    settingAutomaticDebitId : this.state.id,
                    automaticStatus : this.state.automaticStatus ,
                    setMethod: this.state.setMethod === 'customs' ? 'custom' : this.state.setMethod,
                    setDateMethod: this.state.setDateMethod !== '' ? this.state.setDateMethod.value : '',
                    setDuedateMethod: this.state.setDuedateMethod ? parseInt(this.state.setDuedateMethod) : null,
                    setCustomMethod1: this.state.setCustomMethod1 ? parseInt(this.state.setCustomMethod1) : null,
                    setCustomMethod2: this.state.setCustomMethod2 ? parseInt(this.state.setCustomMethod2) : null,
                    setCustomMethod3: this.state.setCustomMethod3 ? parseInt(this.state.setCustomMethod3) : null,
                    setCustomMethod4: this.state.setCustomMethod4 ? parseInt(this.state.setCustomMethod4) : null,
                    debtStatus: this.state.debtStatus,
                    receivableOutstanding: true,
                }
            }
        }

        Swal.fire(i18next.t("settingCollectionLetter:Warning"),i18next.t("settingCollectionLetter:Please check correct before save, if were the demand documents have already been issued, will you can not edit data setup."),"info").then(()=> {
            this.saveData(variables)
        })
    }

    saveData = (variables)=> {
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createAndUpdateSettingAutomaticDebit && response.createAndUpdateSettingAutomaticDebit?.ok) {
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(() => {
                            this.setState({loading: false},() =>{
                                window.location.reload()
                            });
                        });
                    } else {
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() =>{
                            this.setState({loading: false});
                        })
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                },
            },
        )
    }

    render(){
        return(
            <Translation>
                {
                    t => 
                    <React.Fragment>
                        {this.state.loadingData ? 
                         <Loading/>
                            :
                            <React.Fragment>
                                <form onSubmit={this.onSubmit} id="setting-auto">
                                    <div className="row col-12 pr-4 pl-4" >
                                        <div className={this.state.automaticStatus ? "row input-automatic mt-4" : "row input-automatic mt-4 disable"} >
                                            <div className="col-12 form-inline">
                                                <h5 className="mr-4">{i18next.t("settingForm:Automatically generate collection letters")}</h5>
                                                <Switch
                                                    checked={this.state.automaticStatus}
                                                    onChange={this.handleChangeAuto}
                                                    onColor="#F5F5F5"
                                                    onHandleColor="#1567B4"
                                                    offHandleColor="#BFBFBF"
                                                    offColor="#F5F5F5"
                                                    handleDiameter={25}
                                                    uncheckedIcon={<div className="switch-close"><span>{i18next.t("settingForm:Close")}</span></div>}
                                                    checkedIcon={<div className="switch-open"><span>{i18next.t("settingForm:Open")}</span></div>}
                                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                    height={20}
                                                    width={70}
                                                    className="react-switch ml-3"
                                                    id="material-switch"
                                                    disabled={!this.state.permissionEdit || this.state.statusUsing}
                                                />
                                            </div>
                                            <div className="row col-12 mt-2 d-flex align-items-center">
                                                <div className="col-1 d-flex justify-content-center">
                                                    <input type="radio" name="date" 
                                                        id="date"
                                                        onChange={(e) => this.toggleSetMethod(e)} 
                                                        checked={this.state.setMethod === 'date'}
                                                        disabled={!this.state.automaticStatus || !this.state.permissionEdit || this.state.statusUsing}
                                                    />
                                                </div>
                                                <div className={this.state.automaticStatus ? "col-11" : "col-11 disable"}>
                                                    <span htmlFor="data">
                                                    {i18next.t("settingForm:Date setting format")}
                                                    </span>
                                                </div>
                                                <div className="col-1" />
                                                <div className={(this.state.setMethod !== 'date' || !this.state.automaticStatus) ? "disable col-2" : "col-2"}>
                                                    <span>{i18next.t("settingForm:Issue every")} </span>
                                                </div>
                                                <div className="col-2">
                                                    <Select
                                                        value={this.state.setDateMethod || ''}
                                                        name="setDateMethod"
                                                        placeholder={i18next.t("settingForm:Specify date")}
                                                        onChange={this.selectDay}
                                                        options={selectDay}
                                                        isDisabled={this.state.setMethod !== 'date' || !this.state.automaticStatus || !this.state.permissionEdit || this.state.statusUsing}
                                                        required={this.state.setMethod === 'date'}
                                                    />  
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'date') ? "disable col" : "col"} >
                                                    <span>{i18next.t("settingForm:of the month")} </span>
                                                </div>
                                            </div>
                                            <div className="row col-12 mt-2 d-flex align-items-center">
                                                <div className="col-1 d-flex justify-content-center">
                                                    <input type="radio" 
                                                        id="duedate"
                                                        onChange={(e) => this.toggleSetMethod(e)} 
                                                        name="duedate"
                                                        checked={this.state.setMethod === 'duedate'}
                                                        disabled={!this.state.automaticStatus || !this.state.permissionEdit || this.state.statusUsing}
                                                    />
                                                </div>
                                                <div className={this.state.automaticStatus ? "col-11" : "col-11 disable"}>
                                                    <span htmlFor="duedate">
                                                    {i18next.t("settingForm:Count from the due date")}
                                                    </span>
                                                </div>
                                                <div className="col-1 mt-2" />
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'duedate') ? "disable col-md-2 col-sm-6 mt-2" : "col-md-2 col-sm-6 mt-2"}>
                                                    <span>{i18next.t("settingForm:Issue after the due date")} </span>
                                                </div>
                                                <div className="col-1 mt-2">
                                                    <input type="number" min="1" 
                                                        className="form-control text-right"
                                                        onChange={this.handleChange}
                                                        value={this.state.setDuedateMethod || ''}
                                                        name="setDuedateMethod"
                                                        disabled={this.state.setMethod !== 'duedate' || !this.state.automaticStatus || !this.state.permissionEdit || this.state.statusUsing}
                                                        required={this.state.setMethod === 'duedate'}
                                                    />
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'duedate') ? "disable col-2 mt-2" : "col-2 mt-2"}><span>{i18next.t("settingForm:day(s)")}</span></div>
                                            </div>
                                            {/* test ไม่สมบูรณ์ แบบที่สาม */}
                                            {/* <div className="row col-12 mt-2 d-flex align-items-center ">
                                                <div className="col-1 d-flex justify-content-center">
                                                    <input type="radio" 
                                                        onChange={(e) => this.toggleSetMethod(e)} 
                                                        name="customs"
                                                        id="customs"
                                                        checked={this.state.setMethod === 'customs'}
                                                        disabled={!this.state.automaticStatus}
                                                    />
                                                </div>
                                                <div className={this.state.automaticStatus ? "col-11" : "col-11 disable"}>
                                                    <span htmlFor="customs">
                                                        แบบกำหนดระยะเวลาเอง
                                                    </span>
                                                </div>
                
                                                <div className="col-1 mt-2" />
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-7 col-md-4 mt-2 disable" : "col-sm-7 col-md-4 mt-2"}>
                                                    <span>ระยะเวลาออกหนังสือทวงถามครั้งที่ 1 หลังจากวันครบกำหนด </span>
                                                </div>
                                                <div className="col-1 mt-2">
                                                    <input type="number" 
                                                        min="1" className="form-control text-right"
                                                        onChange={this.handleChange}
                                                        name="setCustomMethod1"
                                                        value={this.state.setCustomMethod1 || ''}
                                                        disabled={this.state.setMethod !== 'customs' || !this.state.automaticStatus}
                                                        required={this.state.setMethod === 'customs'}
                                                    
                                                    />
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-2 col-md-6 mt-2 disable" : "col-sm-2 col-md-6 mt-2"}>
                                                    <span>วัน</span>
                                                </div>
                
                
                                                <div className="col-1 mt-2" />
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-7 col-md-4  mt-2 disable"  :"col-sm-7 col-md-4  mt-2"}>
                                                    <span>ระยะเวลาออกหนังสือทวงถามครั้งที่ 2 หลังจากครั้งที่ 1 </span>
                                                </div>
                                                <div className="col-1 mt-2">
                                                    <input 
                                                        type="number" min="1" className="form-control text-right"
                                                        onChange={this.handleChange}
                                                        value={this.state.setCustomMethod2 || ''}
                                                        name="setCustomMethod2"
                                                        disabled={this.state.setMethod !== 'customs' || !this.state.automaticStatus}
                                                        required={this.state.setMethod === 'customs'}
                                                    />
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-2 col-md-6 mt-2 disable" : "col-sm-2 col-md-6 mt-2"}>
                                                    <span>วัน</span>
                                                </div>
                                                
                                                <div className="col-1 mt-2" />
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-7 col-md-4  mt-2 disable" : "col-sm-7 col-md-4  mt-2"}>
                                                    <span>ระยะเวลาออกหนังสือทวงถามครั้งที่ 3 หลังจากครั้งที่ 2</span>
                                                </div>
                                                <div className="col-1 mt-2">
                                                    <input 
                                                        type="number" min="1" className="form-control text-right"
                                                        value={this.state.setCustomMethod3 || ''}
                                                        onChange={this.handleChange}
                                                        name="setCustomMethod3"
                                                        disabled={this.state.setMethod !== 'customs' || !this.state.automaticStatus}
                                                        required={this.state.setMethod === 'customs'}
                                                    />
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs' )? "col-sm-1 col-md-6 mt-2 disable" : "col-sm-1 col-md-6 mt-2"}>
                                                    <span>วัน</span>
                                                </div>
                
                                                <div className="col-1 mt-2" />
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "col-sm-7 col-md-4 mt-2 disable" : "col-sm-7 col-md-4 mt-2"}>
                                                    <span>ระยะเวลาออกหนังสือทวงถามหลังจากครั้งที่ 3 เป็นต้นไป ทุกๆ</span>
                                                </div>
                                                <div className="col-1 mt-2">
                                                    <input 
                                                        type="number" min="1" className="form-control text-right"
                                                        onChange={this.handleChange}
                                                        value={this.state.setCustomMethod4 || ''}
                                                        name="setCustomMethod4"
                                                        disabled={this.state.setMethod !== 'customs' || !this.state.automaticStatus}
                                                        required={this.state.setMethod === 'customs'}
                                                    />
                                                </div>
                                                <div className={(!this.state.automaticStatus || this.state.setMethod !== 'customs') ? "disable col-sm-2 col-md-6 mt-2" : "col-sm-2 col-md-6 mt-2"}>
                                                    <span>วัน</span>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </form>
                                <div className="row col-12 pr-4 pl-4" >
                                    <div className="row input-automatic mt-4">
                                        <div className="col-12">
                                            <h5>{i18next.t("settingForm:Date to include the outstanding balance in the collection letter")}</h5>
                                        </div>
                                        <div className="row col-12 mt-2 d-flex align-items-center ">
                                            {/* <div className="col-2 d-flex justify-content-center">
                                                <input type="radio" 
                                                    onChange={(e) => this.toggleDebtStatus(e)}
                                                    checked={this.state.debtStatus === 'duedate'}
                                                    name="duedate"
                                                    disabled={!this.state.automaticStatus}
                                                />
                                            </div>
                                            <div className="col-10">
                                                <span className={this.state.automaticStatus ? '' : 'disable'}>
                                                    แสดงยอดหนี้ ณ วันที่ครบกำหนด
                                                </span>
                                            </div> */}
                                            <div className="col-2 d-flex justify-content-center mt-2">
                                                <input type="radio" 
                                                    onChange={(e) => this.toggleDebtStatus(e)}
                                                    checked={this.state.debtStatus === 'custom'}
                                                    name="custom"
                                                    disabled={!this.state.automaticStatus || !this.state.permissionEdit || this.state.statusUsing}
                                                />
                                            </div>
                                            <div className="col-10 mt-2">
                                                <span className={this.state.automaticStatus ? '' : 'disable'}>
                                                {i18next.t("settingForm:Show the outstanding balance as of the collection letter issuance date")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row col-12 pr-4 pl-4" >
                                    <div className="row col-12 input-automatic mt-4">
                                        <div className="col-12 form-inline">
                                            <h5 className="mr-3">แสดงรายการลูกหนี้คงค้างแบบละเอียดแนบประกอบ</h5>
                                            <Switch
                                                checked={this.state.receivableOutstanding}
                                                onChange={this.handleChangeReceivableOutstanding}
                                                onColor="#F5F5F5"
                                                onHandleColor="#1567B4"
                                                offHandleColor="#BFBFBF"
                                                offColor="#F5F5F5"
                                                handleDiameter={25}
                                                uncheckedIcon={<div className="switch-close"><span>ปิด</span></div>}
                                                checkedIcon={<div className="switch-open"><span>เปิด</span></div>}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                height={20}
                                                width={70}
                                                className="react-switch ml-3"
                                                id="material-switch"
                                                disabled={!this.state.permissionEdit}
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row" id="setting-collection">
                                    {this.state.permissionEdit && 
                                        <div className="col-11 d-flex justify-content-end p-4">
                                            <button type="submit" className="btn-confirm" form="setting-auto" disabled={this.state.statusUsing}>
                                                {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                {i18next.t("Allaction:save")}
                                            </button>
                                        </div>
                                    }
                                    <div className="col-1"/>
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
                
            </Translation>
        );
    }



}
export default SettingAutomatic;