/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountAccountType = "CURRENT_ACCOUNT" | "FIXED_DEPOSIT" | "SAVING_ACCOUNT" | "SAVING_DEPOSIT" | "%future added value";
export type BankAccountUnit = "DAY" | "MONTH" | "%future added value";
export type bankEditModalQueryVariables = {|
  id: string
|};
export type bankEditModalQueryResponse = {|
  +bankAccount: ?{|
    +id: string,
    +docNumber: string,
    +accountNumber: string,
    +accountName: string,
    +bankName: string,
    +branch: string,
    +bankLogo: string,
    +accountType: BankAccountAccountType,
    +receivePayment: boolean,
    +makePayment: boolean,
    +crossBilling: boolean,
    +status: boolean,
    +carryingBalance: ?number,
    +hasCheque: boolean,
    +description: ?string,
    +closeRemark: ?string,
    +numberOfMonth: ?number,
    +mobilePayment: boolean,
    +issuedDate: ?any,
    +dueDate: ?any,
    +taxRate: ?number,
    +accountNameEn: ?string,
    +numberOfUnit: ?number,
    +unit: BankAccountUnit,
    +notiStatus: boolean,
    +prompay: boolean,
  |},
  +selfProject: ?{|
    +prompayQr: ?string,
    +prompayQrShow: ?string,
    +keyProjectQr: ?string,
  |},
  +lastBankAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +notiStatus: boolean,
        +numberOfUnit: ?number,
        +unit: BankAccountUnit,
      |}
    |}>
  |},
  +myUser: ?{|
    +id: string,
    +username: string,
    +juristic: ?{|
      +id: string,
      +firstName: string,
      +lastName: string,
      +image: ?string,
      +email: string,
      +phone: string,
    |},
  |},
|};
export type bankEditModalQuery = {|
  variables: bankEditModalQueryVariables,
  response: bankEditModalQueryResponse,
|};
*/


/*
query bankEditModalQuery(
  $id: ID!
) {
  bankAccount(id: $id) {
    id
    docNumber
    accountNumber
    accountName
    bankName
    branch
    bankLogo
    accountType
    receivePayment
    makePayment
    crossBilling
    status
    carryingBalance
    hasCheque
    description
    closeRemark
    numberOfMonth
    mobilePayment
    issuedDate
    dueDate
    taxRate
    accountNameEn
    numberOfUnit
    unit
    notiStatus
    prompay
  }
  selfProject {
    prompayQr
    prompayQrShow
    keyProjectQr
    id
  }
  lastBankAccount {
    edges {
      node {
        notiStatus
        numberOfUnit
        unit
        id
      }
    }
  }
  myUser {
    id
    username
    juristic {
      id
      firstName
      lastName
      image
      email
      phone
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfUnit",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notiStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "BankAccountNode",
  "kind": "LinkedField",
  "name": "bankAccount",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branch",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bankLogo",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "makePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "crossBilling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "carryingBalance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCheque",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeRemark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mobilePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "issuedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountNameEn",
      "storageKey": null
    },
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prompay",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prompayQr",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prompayQrShow",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keyProjectQr",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "UserNode",
  "kind": "LinkedField",
  "name": "myUser",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JuristicNode",
      "kind": "LinkedField",
      "name": "juristic",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bankEditModalQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "lastBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bankEditModalQuery",
    "selections": [
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNode",
        "kind": "LinkedField",
        "name": "selfProject",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BankAccountNodeConnection",
        "kind": "LinkedField",
        "name": "lastBankAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BankAccountNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BankAccountNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "cb08e25468e759ac3dac7d7abf0db2fd",
    "id": null,
    "metadata": {},
    "name": "bankEditModalQuery",
    "operationKind": "query",
    "text": "query bankEditModalQuery(\n  $id: ID!\n) {\n  bankAccount(id: $id) {\n    id\n    docNumber\n    accountNumber\n    accountName\n    bankName\n    branch\n    bankLogo\n    accountType\n    receivePayment\n    makePayment\n    crossBilling\n    status\n    carryingBalance\n    hasCheque\n    description\n    closeRemark\n    numberOfMonth\n    mobilePayment\n    issuedDate\n    dueDate\n    taxRate\n    accountNameEn\n    numberOfUnit\n    unit\n    notiStatus\n    prompay\n  }\n  selfProject {\n    prompayQr\n    prompayQrShow\n    keyProjectQr\n    id\n  }\n  lastBankAccount {\n    edges {\n      node {\n        notiStatus\n        numberOfUnit\n        unit\n        id\n      }\n    }\n  }\n  myUser {\n    id\n    username\n    juristic {\n      id\n      firstName\n      lastName\n      image\n      email\n      phone\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '02b41ba4d48a55024e787146012a3b0f';

module.exports = node;
