/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProjectTypeOfProject = "APARTMENT" | "BUILDING" | "CONDOMINIUM" | "DORMITORY" | "LAND_ALLOCATE" | "MALL" | "OFFICE" | "OTHER" | "SINGLE_HOME" | "TOWN_HOUSE" | "VILLAGE" | "%future added value";
export type pricingCalculateTableQueryVariables = {|
  first?: ?number,
  last?: ?number,
  service_id: string,
|};
export type pricingCalculateTableQueryResponse = {|
  +allResidential: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +size: number,
        +ownershipRatio: ?number,
        +contact: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +firstName: string,
              +lastName: string,
            |}
          |}>
        |},
        +servicePricing: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +pricing: number,
            |}
          |}>
        |},
      |}
    |}>,
    +totalCount: ?number,
  |},
  +areaSummary: ?number,
  +totalServicePricingEachUnit: ?number,
  +allProjects: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +typeOfProject: ?ProjectTypeOfProject,
      |}
    |}>
  |},
|};
export type pricingCalculateTableQuery = {|
  variables: pricingCalculateTableQueryVariables,
  response: pricingCalculateTableQueryResponse,
|};
*/


/*
query pricingCalculateTableQuery(
  $first: Int
  $last: Int
  $service_id: ID!
) {
  allResidential(first: $first, last: $last) {
    edges {
      node {
        id
        name
        size
        ownershipRatio
        contact {
          edges {
            node {
              id
              firstName
              lastName
            }
          }
        }
        servicePricing(service_Id: $service_id) {
          edges {
            node {
              id
              pricing
            }
          }
        }
      }
    }
    totalCount
  }
  areaSummary
  totalServicePricingEachUnit(serviceId: $service_id)
  allProjects {
    edges {
      node {
        id
        typeOfProject
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "service_id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "last",
        "variableName": "last"
      }
    ],
    "concreteType": "ResidentialNodeConnection",
    "kind": "LinkedField",
    "name": "allResidential",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ResidentialNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResidentialNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "size",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownershipRatio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNodeConnection",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ContactNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "service_Id",
                    "variableName": "service_id"
                  }
                ],
                "concreteType": "ServicePricingEachUnitNodeConnection",
                "kind": "LinkedField",
                "name": "servicePricing",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServicePricingEachUnitNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ServicePricingEachUnitNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "pricing",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "areaSummary",
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "serviceId",
        "variableName": "service_id"
      }
    ],
    "kind": "ScalarField",
    "name": "totalServicePricingEachUnit",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ProjectNodeConnection",
    "kind": "LinkedField",
    "name": "allProjects",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProjectNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeOfProject",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pricingCalculateTableQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pricingCalculateTableQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1954d281479e0b1aaeae18b2feac9ec5",
    "id": null,
    "metadata": {},
    "name": "pricingCalculateTableQuery",
    "operationKind": "query",
    "text": "query pricingCalculateTableQuery(\n  $first: Int\n  $last: Int\n  $service_id: ID!\n) {\n  allResidential(first: $first, last: $last) {\n    edges {\n      node {\n        id\n        name\n        size\n        ownershipRatio\n        contact {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n            }\n          }\n        }\n        servicePricing(service_Id: $service_id) {\n          edges {\n            node {\n              id\n              pricing\n            }\n          }\n        }\n      }\n    }\n    totalCount\n  }\n  areaSummary\n  totalServicePricingEachUnit(serviceId: $service_id)\n  allProjects {\n    edges {\n      node {\n        id\n        typeOfProject\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39b8f61f0b824c41f98c1a9f26a993f5';

module.exports = node;
