
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import DownloadDocsCollectionLawFrim from "./downloadDocsCollectionLawFrim";
import UploadDocsCollectionLawFrim from "./uploadDocsCollectionLawFrim"
import {commitMutation} from 'react-relay'
import environment from "../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import Swal from "sweetalert2";
import upload from '../../libs/upload';
import { format } from 'date-fns';
import { Redirect } from 'react-router';
import i18n from 'i18next';
import ConfrimCollectionLawFrim from "./confrimCollectionLawFrim"
import AllowPdpaRegisterCollectionLawFirm from "./allowPdpaRegisterCollectionLawFirm"
import SwitchLanguages from '../../components/header/switchLanguages';
import { Translation } from 'react-i18next';
import './regisCollectionLawFrim.scss';
import i18next from 'i18next';
const _ = require("lodash");


const mutation = graphql`
mutation regisCollectionLawFrimCreateMutation ($input: CreateAndUpdateRegisterCollectionLawFirmInput!) {
    createAndUpdateRegisterCollectionLawFirm(input:$input){
        ok
        massage
    }
}`;

let email_object = {
    juristicId:'',
    name:'',
    email:''
};

const query = graphql`
       query regisCollectionLawFrimCreateQuery{
        allRegisterCollectionLawFirm {
            edges{
                node{
                    id
                    serviceContract
                    email
                    linkDowaloadFile
                    status
                    accept
                    remark
                    creator
                    creatorEdit
                    creatorApproval
                    language
                    pdpaAccept
                    pdpaAcceptMarketing
                    documentRegisterCollectionLawFirm{
                        edges{
                            node{
                                fileName
                                fileUpload
                                fileUrl
                            }
                        }
                    }
                }
            } 
        }
        
        allJuristic{
            totalCount
            edges {
                node {
                id
                firstName
                lastName
                role
                image
                user {
                    id
                    username
                    lastLogin
                }
                }
            }
        }
}
`;



class RegisCollectionLawFrimCreate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            regisCLFStep1: true,
            regisCLFStep1Finish: false,

            regisCLFStep2: false,
            regisCLFStep2Finish: false,

            regisCLFStep3: false,
            regisCLFStep3Finish: false,
            regisCLFStep3new:false,

            regisCLFStep4: false,
            regisCLFStep4Finish: false,

            regisCLFStep5: false,
            regisCLFStep5Finish: false,

            regisCLFStep6: false,
            regisCLFStep6Finish: false,

            email_list:[],
            emailName:'',
            fileMore: [],
            newsFile: [],
            fileMoreOld:[],
            status:'',
            accept:false,
            showEmailAlert: false,
            redirect:false,
            AllUser:[],
            selectList : [],
            loading: true,
            docs1:[],
            docs2:[],
            docs3:[],
            docs4:[],
            docs5:[],
            docs6:[],
            pdpaAccept: false,
            pdpaAcceptMarketing: false,
        }

        this.onSetStepFinish = this.onSetStepFinish.bind(this)
        this.onSetStepFinish2 = this.onSetStepFinish2.bind(this)
        this.onSetStepFinish3 = this.onSetStepFinish3.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.callBackUploadFile = this.callBackUploadFile.bind(this)
        this.uploadFile = this.uploadFile.bind(this)
        this.deleFile = this.deleFile.bind(this)

        this.appendNewRow = this.appendNewRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
    

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleInputChange2 = this.handleInputChange2.bind(this)
    }

    componentDidMount() {
        this.getJuristicAll()
   }

   componentWillMount() {
    this.setState({
        email_list: [...this.state.email_list, {...email_object}],
    });}
    
    getJuristicAll() {
        fetchQuery(environment, query).then(data => {
            let mapNewData = data.allJuristic.edges.map((item) => ({
                ...item.node,
                label: `(${item.node.user.username}) - ${item.node.firstName} ${item.node.lastName}`,
                value: item.node.id
            }))
          //  mapNewData.unshift({ label: "(All) - ทั้งหมด", value: "" })
            this.setState({ AllUser:  mapNewData})
        })
    }

    onSetStepFinish (val) {
        let variables = {
            input:{
                jsonObject: {
                    pdpaAccept: this.state.pdpaAccept,
                    pdpaAcceptMarketing: this.state.pdpaAcceptMarketing,
                }
            }
        }
        AllowPdpaRegisterCollectionLawFirm(variables);
        this.setState({
            regisCLFStep1Finish:val,
            regisCLFStep1: false,
            regisCLFStep2: true,
        })
    }

    onSetStepFinish2 (val) {
        this.setState({
            regisCLFStep2Finish:val,
            regisCLFStep1Finish:true,
            regisCLFStep2: false,
            regisCLFStep3: true,
        })
    }

    onSetStepFinish3(val){
        let elements = document.getElementsByName("userName")
        let showEmailAlert2 = false
        let email_temp = _.map(elements, (item) => {
            showEmailAlert2 = false
            if(item.value) {
              return {
                juristicId: item.value,
                name: "",
                email: ""
              }
            }
            showEmailAlert2 = true
          })

        if(showEmailAlert2){
            this.setState({
                showEmailAlert: true
            })    
         }else{
            this.setState({
                showEmailAlert: false,
                email_list:email_temp,
                regisCLFStep3Finish:val,
                regisCLFStep1Finish:true,
                regisCLFStep2Finish:true,
                regisCLFStep3: false,
                regisCLFStep4: true,
            })
        }
    }

    callBackUploadFile(data,index) {
        const dynamicKey = `docs${index}`;
        const updatedState = {[dynamicKey]: data};
        this.setState(updatedState)
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
            let res = await upload("/" + path, file);
            resolve(res);
        });
    };

    onUploadFile(e ,fileState ,pathname ,callback ,index) {
        let event = _.cloneDeep(e);
        let files = event.currentTarget.files[0];
        if (files) {
            // 10 MB
            if (files?.size > 10485760) {
                Swal.fire(i18next.t("Allaction:Unsuccessful "), `${i18next.t("Allaction:Please upload a new file as it exceeds the specified size of")} 10 MB`, 'error')
            }else {
                let new_file = new File([files], `${Math.floor(Math.random() * 1000000001)}${format(new Date(), "YYYYMMDDHHmmss")}${files.name}`);
                let fileOldList = _.cloneDeep(fileState);
    
                this.callUpload(pathname, new_file).then((data) => { 
                    let dese = pathname+ "/" + new_file.name
                    fileOldList.push({tital:index, fileName: new_file.name, fileUpload: dese, fileUrl: data.location})
                    callback(fileOldList,index)
                });
            }
        }
    }

    uploadFile(e,index){
         let pathname = "law/files"
         this.onUploadFile(e, this.state.fileMore, pathname, this.callBackUploadFile,index);
    };
     

    deleFile(index, file) {
        let cloneData = _.cloneDeep([file])
        cloneData.splice(0, 1)
        const dynamicKey = `docs${index}`;
        const updatedState = {[dynamicKey]: cloneData};
        this.setState(updatedState)
    }

    appendNewRow() {
        this.setState({email_list: [...this.state.email_list, {...email_object}]});
    }

    deleteRow(idx) {
        let email_list_new = [...this.state.email_list];

        if (idx !== -1 && idx !== 0) {
            Swal.fire({
                title: i18n.t('settingNote:Are you sure to delete this note?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons:true,
                confirmButtonText: i18n.t('settingNote:Yes'),
                cancelButtonText: i18n.t('settingNote:Cancel')
            }).then((result) => {
               email_list_new.splice(idx, 1)
                this.setState({
                    email_list: email_list_new
                });
             })  
            
        }
    }

    handleInputChange = (event) => {
        this.setState((prevState) => ({
            pdpaAccept: !prevState.pdpaAccept,
          }));
    };

    handleInputChange2 = (event) => {
        this.setState((prevState) => ({
            pdpaAcceptMarketing: !prevState.pdpaAcceptMarketing,
          }));
    };

    onSubmit(e) {

        e.preventDefault();
        const concatenatedArray = [...this.state.docs1, ...this.state.docs2,...this.state.docs3, ...this.state.docs4,...this.state.docs5, ...this.state.docs6];

        if(this.state.docs1.length >= 1 && this.state.docs2.length >= 1 && this.state.docs3.length >= 1 && this.state.docs4.length >= 1 && this.state.docs5.length >= 1){
            let variables = {
                input:{
                    jsonObject: {
                        EmailList: this.state.email_list,
                        language: "th",
                        DocumentList: concatenatedArray,
                        accept: true,
                    }
                }
            }
   
            commitMutation(
                environment,
                {
                    mutation,
                    variables,
                    onCompleted: (response) => {
                        this.setState({
                            regisCLFStep3new:true,
                            regisCLFStep4Finish:true
                        })  
                        if(response.createAndUpdateRegisterCollectionLawFirm?.ok){   
                            Swal.fire({
                                type: 'success',
                                title: i18n.t('collectionLawFirm:Successfully registered'),
                                text: i18n.t('collectionLawFirm:With our program, you can use the service to generate legal documents and have them tracked by an attorney instantly'),
                                showCancelButton: false,
                                confirmButtonText: i18n.t('collectionLawFirm:Next'),
                              }).then((result) => {
                                if (result.value) {
                                    this.setState({
                                        redirect:true
                                    })
                                }
                              });
                        }
                    },
                    onError: (err) => {
                        this.setState({loading: false});
                        Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning');
                    },
                },
            )
        }else{
            
            Swal.fire('Error!',i18n.t(`collectionLawFirm:Please attach your file`),'warning')
        }
       
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/accounting/collection-law-firm/list/law' />
        }
        return (
            <>
               <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #f7f7f7;
                            overflow-x: hidden;
                            text-align: center;
                            font-size: 14px;
                            font-family: 'Kanit';
                            font-weight: 400;
                        }
                    `
                    }]}>
                </Helmet>
                 <div id='regisCollectionLawFrim'>
                    <form onSubmit={this.onSubmit}>
                        <div className="row background-top" >
                            <div className="container"> 
                                <div className='law-lang-container'>
                                    <SwitchLanguages isLawlang={false} />
                                </div>
                                <div className="col mt-3 justify-content-center">
                                    <div className="row">
                                        <div className="col mb-4 mt-4">
                                            <div className='d-flex justify-content-center'>
                                                <img src={process.env.PUBLIC_URL + '/images/logo/SVM-Logo.png'} style={{width:'220px'}} className='pr-6'/>
                                                <img src={process.env.PUBLIC_URL + '/images/logo/house-condo-lawyer-logo.png'} />
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Translation>
                                                {
                                                    t => <h3>{t('collectionLawFirm:Register for the debt collection service by an attorney (E-Notice)')}</h3>
                                                }
                                            </Translation>  
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Translation>
                                                {
                                                    t => <p style={{color:'#FFFFFF'}}>{t('collectionLawFirm:Terms and Conditions for Using Debt Collection Attorney Service')}</p>
                                                }
                                            </Translation>  
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-1"></div>
                                        <div className="col-md-10 justify-content-center background-box">
                                            {
                                            this.state.regisCLFStep1 &&
                                                <div>
                                                    <ConfrimCollectionLawFrim 
                                                    handleInputChange={this.handleInputChange}
                                                    handleInputChange2={this.handleInputChange2}
                                                    pdpaAccept={this.state.pdpaAccept}
                                                    pdpaAcceptMarketing={this.state.pdpaAcceptMarketing}
                                                    onSetStepFinish={this.onSetStepFinish} />
                                                </div>
                                            }
                                            {this.state.regisCLFStep2 && this.state.regisCLFStep1Finish && 
                                                <div className='lawyer-request'>
                                                    <Translation>
                                                    {
                                                     t => (
                                                    <>
                                                     <span> {t('collectionLawFirm:Next, you need to prepare')} <strong> {t('collectionLawFirm:the power of attorney appointment letter')}</strong>{t('collectionLawFirm:based on the resolution passed at the meeting')}</span><br/>
                                                    <span className='text-danger'> {t('collectionLawFirm:(do this only once when initially using the service or when there is a change in the committee)')}</span><br/>
                                                    {t('collectionLawFirm:By downloading an Excel file to input the information, after successfully filling in the data in the file')}<br/>
                                                    {t('collectionLawFirm:attach the power of attorney appointment letter along with other documents as specified and upload them to the system')}
                                                    <div style={{paddingTop:'5%'}}>
                                                        <button onClick={() => this.onSetStepFinish2(true)} className="btn btn-primary" 
                                                        type='button' style={{width:'220px',height:'48px'}}>
                                                            {t('collectionLawFirm:Next')}
                                                        </button> 
                                                    </div> 
                                                    </>
                                                    )}
                                                    </Translation>
                                                </div>
                                            }     
                                            {this.state.regisCLFStep3 && this.state.regisCLFStep2Finish && 
                                                <div>
                                                    <DownloadDocsCollectionLawFrim onSetStepFinish3={this.onSetStepFinish3} 
                                                        regisCLFStepFinish1={this.state.regisCLFStep1Finish} 
                                                        regisCLFStepFinish2={false} 
                                                        email_list={this.state.email_list}
                                                        showEmailAlert={this.state.showEmailAlert}
                                                        appendNewRow={this.appendNewRow}
                                                        deleteRow={this.deleteRow}
                                                        AllUser={this.state.AllUser}
                                                        email_object={email_object}
                                                     
                                                    />
                                                </div>
                                               
                                            }
                                            {this.state.regisCLFStep4 && this.state.regisCLFStep3Finish && 
                                                    <div>
                                                    <UploadDocsCollectionLawFrim 
                                                        regisCLFStepFinish1={this.state.regisCLFStep1Finish}
                                                        regisCLFStepFinish2={this.state.regisCLFStep2Finish} 
                                                        regisCLFStepFinish3={this.state.regisCLFStep3new} 
                                                        regisCLFStepFinish4={this.state.regisCLFStep4Finish} 
                                                        callBackUploadFile={this.callBackUploadFile}
                                                        uploadFile={this.uploadFile}
                                                        fileMore={this.state.fileMore}
                                                        deleFile={this.deleFile}
                                                        docs1={this.state.docs1}
                                                        docs2={this.state.docs2}
                                                        docs3={this.state.docs3}
                                                        docs4={this.state.docs4}
                                                        docs5={this.state.docs5}
                                                        docs6={this.state.docs6}
                                                    />
                                                    <Translation>
                                                       {t => (
                                                        <div className='text-right pr-5'>
                                                            <button type="submit" className="btn btn-primary" style={{width:'134px'}}>
                                                            <span>{t('collectionLawFirm:Submit')}</span>
                                                            </button>
                                                        </div> )
                                                        }
                                                        </Translation>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </form>
                    <div className="row text-left content-howto pt-3 pb-3" style={{marginTop:'80vh'}}>
                    </div>
                    {/* <div className="footer">
                        <p className="pt-2">หากมีข้อสงสัย ติดต่อ Silverman Call Center 081 442 6888</p>
                    </div>  */}
                </div>
            </>
        );
    }
}

export default RegisCollectionLawFrimCreate;