import {commitMutation,} from 'react-relay'
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../env/environment";


const mutation = graphql`
    mutation collectionLetterAttachMutation ($input:CreateUpdateDocumentCollectionLettersInput!) {
        createUpdateDocumentCollectionLetters (input: $input) {
            ok
            message
        }
    }
`;


export default function CollectionLetterAttachMutation(variables, callback, error_callback) {

    commitMutation(
        environment,
        {
            mutation,
            variables,
            onCompleted: (response) => {
                callback(response)
            },
            onError: (err) => {
                error_callback(err);
            },
        },
    )
}
