import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import { Translation } from 'react-i18next';

class Navigation extends Component {
    render() {
        return (
            <div className="row" id="navigation-tab">
                <div className="col">
                    <Translation>
                        {t=>
                        <ul>
                            <li>
                                <NavLink to="/accounting/income/unknown-receive/list/all">
                                    {t("unknown_receive:All")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/unknown-receive/list/wait">
                                    {t("unknown_receive:Not cleared")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/unknown-receive/list/clear">
                                    {t("unknown_receive:Cleared")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounting/income/unknown-receive/list/void">
                                    {t("unknown_receive:List of cancellation")}
                                </NavLink>
                            </li>
                        </ul>}
                    </Translation>
                </div>
            </div>
        )
    }
}

export default Navigation;