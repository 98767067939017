import React from 'react';
import ResidentialhistoryListTable from './residentialhistoryListTable';
import {graphql} from "babel-plugin-relay/macro";
import ComponentPagination from "../../libs/componentPagination";

const residentialhistoryList = graphql`
    query residentialhistoryListQuery($search: String, $start_date : Date, $end_date : Date, $order:String, $status:String,$first: Int, $last: Int) {
        allResidentialHistory(status:$status,search:$search, startDate:$start_date, endDate:$end_date, order:$order, first: $first, last: $last, type_In:"owner,resident,renter,agent"){
            totalCount
            edges{
                node{
                    id
                    nameResidential
                    residential{
                        id
                        name
                    }
                    firstName
                    lastName
                    countries
                    phone
                    email
                    remark
                    type
                    status
                    liveing
                    quit
                    added
                }
            }
        }

    }
`;

class ResidentialhistoryList extends ComponentPagination {
    render() {
        return (
            <React.Fragment>
                <ResidentialhistoryListTable
                        historyDetail={residentialhistoryList}
                        // id={this.props.match.params.id}
                        first={this.state.first}
                        last={this.state.last}
                        changePage={this.changePage}
                    />
            </React.Fragment>

        );
    }
}

export default ResidentialhistoryList;
