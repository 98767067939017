import React, { Component } from 'react'
import _ from 'lodash'
import { Translation } from 'react-i18next';

export default class basicInformation extends Component {

    handleChangInput = (e) => {
        this.props.onChange({
            target: {
                name: e.target.name,
                value: e.target.value,
            }
        });
    }

    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <h6>{this.props.projectInfo?.name}</h6>
                        <div className="row mt-3">
                            <div className="col-4">
                                <span>{t("PageDetailProjectInfo:ProjectNameTh")}</span>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <input type="text"
                                        className="form-control form-control-sm"
                                        name="projectInfo.nameTh"
                                        value={this.props.projectInfo?.nameTh}
                                        onChange={this.handleChangInput}
                                    />
                                    :
                                    <span>
                                        {this.props.projectInfo?.nameTh}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:ProjectNameEn")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <input type="text"
                                        className="form-control form-control-sm"
                                        name="projectInfo.nameEn"
                                        value={this.props.projectInfo?.nameEn}
                                        onChange={this.handleChangInput}
                                    />
                                    :
                                    <span >
                                        {this.props.projectInfo?.nameEn}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:ProjectType")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <select className="form-control  col-6"
                                        name="projectInfo.typeOfProject"
                                        value={_.toLower(this.props.projectInfo?.typeOfProject)}
                                        onChange={this.handleChangInput}
                                    >
                                        <option value={null || ''} disabled>{t("PageDetailProjectInfo:PleaseChoose")}</option>
                                        <option value="village">{t("PageDetailProjectInfo:Village")}</option>
                                        <option value="single_home">{t("PageDetailProjectInfo:Single Home")}</option>
                                        <option value="town_house">{t("PageDetailProjectInfo:Town House")}</option>
                                        <option value="building">{t("PageDetailProjectInfo:BuildingOffice")}</option>
                                        <option value="land_allocate">{t("PageDetailProjectInfo:Land Allocate")}</option>
                                        <option value="condominium">{t("PageDetailProjectInfo:Condominium")}</option>
                                        <option value="apartment">{t("PageDetailProjectInfo:Apartment")}</option>
                                        <option value="dormitory">{t("PageDetailProjectInfo:Dormitory")}</option>
                                        <option value="office">{t("PageDetailProjectInfo:Office")}</option>
                                        <option value="mall">{t("PageDetailProjectInfo:Mall")}</option>
                                        <option value="other">{t("PageDetailProjectInfo:Other")}</option> 

                                        {/* <option value="service_apartment">เซอร์วิส อพาร์ทเม้นท์</option>
                                            <option value="office">อาคารสำนักงาน</option>
                                            <option value="office_rent">ออฟฟิศให้เช่า</option>
                                            <option value="department">ห้างสรรพสินค้า</option>
                                            <option value="other">อื่นๆ</option> */}
                                    </select>
                                    :
                                    <select className="form-control col-6"
                                        name="projectInfo.typeOfProject"
                                        // value={this.props.projectInfo.typeOfProject ? _.lowerCase(this.props.projectInfo?.typeOfProject) : 'condominium'}
                                         value={ _.toLower(this.props.projectInfo?.typeOfProject)}
                                        onChange={this.handleChangInput}
                                        disabled
                                    >
                                        <option value={null || ''} disabled>{t("PageDetailProjectInfo:PleaseChoose")}</option>
                                        <option value="village">{t("PageDetailProjectInfo:Village")}</option>
                                        <option value="single_home">{t("PageDetailProjectInfo:Single Home")}</option>
                                        <option value="town_house">{t("PageDetailProjectInfo:Town House")}</option>
                                        <option value="building">{t("PageDetailProjectInfo:BuildingOffice")}</option>
                                        <option value="land_allocate">{t("PageDetailProjectInfo:Land Allocate")}</option>
                                        <option value="condominium">{t("PageDetailProjectInfo:Condominium")}</option>
                                        <option value="apartment">{t("PageDetailProjectInfo:Apartment")}</option>
                                        <option value="dormitory">{t("PageDetailProjectInfo:Dormitory")}</option>
                                        <option value="office">{t("PageDetailProjectInfo:Office")}</option>
                                        <option value="mall">{t("PageDetailProjectInfo:Mall")}</option>
                                        <option value="other">{t("PageDetailProjectInfo:Other")}</option> 

                                        {/* <option value="service_apartment">เซอร์วิส อพาร์ทเม้นท์</option>
                                            <option value="office">อาคารสำนักงาน</option>
                                            <option value="office_rent">ออฟฟิศให้เช่า</option>
                                            <option value="department">ห้างสรรพสินค้า</option>
                                            <option value="other">อื่นๆ</option> */}
                                    </select>
                                }
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-4">
                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                <p>{textData?.data?.basicInformation.developmentCompanyProject}</p>
                                :<p>{t("PageDetailProjectInfo:DevelopmentCompanyProject")}</p>
                            }    
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <input type="text"
                                        className="form-control form-control-sm"
                                        name="projectInfo.nameDeveloper"
                                        value={this.props.projectInfo?.nameDeveloper || ''}
                                        // disabled={this.props.settingDeveloper}
                                        disabled={true}
                                        onChange={this.handleChangInput}
                                    />
                                    :
                                    <span>
                                        {this.props.projectInfo?.nameDeveloper || '-'}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:Location")}</p>
                            </div>

                            {this.props.editProjectInfo
                                ?
                                <div className="col-8 mb-2">
                                    <textarea
                                        style={{ height: "78px", maxHeight: "78px" }}
                                        className="form-control"
                                        name="projectInfo.address"
                                        value={this.props.projectInfo?.address || ''}
                                        onChange={this.handleChangInput}
                                    />
                                </div>
                                :
                                <div className="col-5">
                                    <span>
                                        {this.props.projectInfo?.address}
                                    </span>
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:LocationEn")}</p>
                            </div>

                            {this.props.editProjectInfo
                                ?
                                <div className="col-8 mb-2">
                                    <textarea
                                        style={{ height: "78px", maxHeight: "78px" }}
                                        className="form-control"
                                        name="projectInfo.addressEn"
                                        value={this.props.projectInfo?.addressEn || ''}
                                        onChange={this.handleChangInput}
                                    />
                                </div>
                                :
                                <div className="col-5">
                                    <span>
                                        {this.props.projectInfo?.addressEn}
                                    </span>
                                </div>
                            }
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:Longitude")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <input type="text"
                                        className="form-control form-control-sm"
                                        name="projectInfo.long"
                                        value={this.props.projectInfo?.long || ''}
                                        onChange={this.handleChangInput}
                                    />
                                    :
                                    <span>
                                        {this.props.projectInfo?.long}
                                    </span>
                                }
                            </div>
                        </div>

                        <div className="row mb-3">
                            <div className="col-4">
                                <p>{t("PageDetailProjectInfo:Latitude")}</p>
                            </div>
                            <div className="col-8">
                                {this.props.editProjectInfo
                                    ?
                                    <input type="text"
                                        className="form-control form-control-sm"
                                        name="projectInfo.lat"
                                        value={this.props.projectInfo?.lat || ''}
                                        onChange={this.handleChangInput}
                                    />
                                    :
                                    <span>
                                        {this.props.projectInfo?.lat}
                                    </span>
                                }
                                
                            </div>
                        </div>

                        <React.Fragment>
                            {
                                this.props.editProjectInfo ?
                                    <>
                                        <div className="row">
                                            <div className="col-4">
                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                <p>{textData?.data?.basicInformation.officeHoursOpen}</p>
                                                    :
                                                <p>{t("PageDetailProjectInfo:OfficeHoursOpen")}</p>
                                            }
                                            </div>
                                            <div className="col-8">
                                                <input type="time"
                                                    className="form-control form-control-sm"
                                                    name="projectInfo.openTime"
                                                    value={this.props.projectInfo?.openTime || ''}
                                                    onChange={this.handleChangInput}
                                                />

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                <p>{textData?.data?.basicInformation.officeHoursEnd}</p>
                                                    :
                                                <p>{t("PageDetailProjectInfo:OfficeHoursEnd")}</p>
                                            }
                                            </div>
                                            <div className="col-8">
                                                <input type="time"
                                                    className="form-control form-control-sm"
                                                    name="projectInfo.endTime"
                                                    value={this.props.projectInfo?.endTime || ''}
                                                    onChange={this.handleChangInput}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="row">
                                        <div className="col-4">
                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                <p>{textData?.data?.basicInformation.officeHoursOpen}</p>
                                                    :
                                                <p>{t("PageDetailProjectInfo:OfficeHoursOpen")}</p>
                                            }
                                        </div>
                                        <div className="col-8">
                                            <span> {this.props.projectInfo?.openTime} - {this.props.projectInfo?.endTime} น. </span>
                                        </div>
                                    </div>

                            }

                        </React.Fragment>
                    </React.Fragment>
                }
            </Translation>
        )
    }
}
