import React, { Component } from 'react';
import DatePickerAdapter from '../../../libs/datePickerAdapter';
import "../../developer.scss";
import _ from "lodash";
import numberWithCommas from '../../../libs/numberWithComma';
import OnDelete from './onDelete';
import SelectService from './selectService';
import { Translation } from 'react-i18next';
import i18n from 'i18next';
import getDateTime from '../../../libs/getDateTime';
import revenueContractAPI from '../revenueContractAPI';
import i18next from 'i18next';

const newTransaction ={
    totalPrice : '',
    customerChartOfAccount : '',
    chartOfAccount : '',
    price : '',
    productAndServiceID : '',
    issuedDate : new Date(),
    receiveDeposit : '',
    pricingType : '',
    isReceiveDeposit : false,
    charged : 'once',
    billingMonthList : [{value: '' , label : ''}],
    billingMonthSelected : '',
    advance : {value : 0 , label : 'ไม่'},
    burdenStatus : false,
    agentStatus : false,
    activeStatus : true,
}

const charged = [
    {value : 'once' , label : i18n.t('revenueContract:once')},
    {value : 'one_month' , label : i18n.t('revenueContract:one_month')},
    {value : 'two_month' , label : i18n.t('revenueContract:two_month')},
    {value : 'three_month' , label : i18n.t('revenueContract:three_month')},
    {value : 'four_month' , label : i18n.t('revenueContract:four_month')},
    // {value : 'five_month' , label : i18n.t('revenueContract:five_month')},
    {value : 'six_month' , label : i18n.t('revenueContract:six_month')},
    {value : 'one_year' , label : i18n.t('revenueContract:one_year')},
]

const advance = [
    { value: 0, label: i18next.t("settingAll:No") },
    { value: 1, label: i18next.t("settingAll:1 month in advance") },
    { value: 2, label: i18next.t("settingAll:2 months in advance") },
    { value: 3, label: i18next.t("settingAll:3 months in advance") },
    { value: 4, label: i18next.t("settingAll:4 months in advance") },
    { value: 5, label: i18next.t("settingAll:5 months in advance") },
    { value: 6, label: i18next.t("settingAll:6 months in advance") },
    { value: 7, label: i18next.t("settingAll:7 months in advance") },
    { value: 8, label: i18next.t("settingAll:8 months in advance") },
    { value: 9, label: i18next.t("settingAll:9 months in advance") },
    { value: 10, label: i18next.t("settingAll:10 months in advance") },
]

class ConditionContract extends Component {

    constructor(props){

        super(props);
        this.state={
            transactionList : (this.props.condition && this.props.condition.length > 0) ? this.props.condition : [newTransaction],
            productAndService : this.props.productAndService,
            contactList : this.props.contactList,
            token: localStorage.getItem("token"),
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this)

    }

    componentDidUpdate(prevProps){
        if(this.props.productAndService !== this.state.productAndService){
            this.setState({
                productAndService : this.props.productAndService
            })
        }
        if(this.props.contactList !== this.state.contactList){
            this.setState({
                contactList : this.props.contactList
            })
        }
        if((this.props.condition && this.props.condition.length > 0) && (prevProps.condition !== this.props.condition)){
            let transactionList = []
            _.forEach(this.props.condition , condition => {
                transactionList.push({...condition})
            })
            this.setState({
                transactionList : transactionList
            })
        }
    }

    async handleChange(e){
        let event = _.cloneDeep(e)
        let id = event.target.dataset.id
        let name = event.target.name
        let value = event.target.value
        let transactionList = _.cloneDeep(this.state.transactionList)
        if(event.target.dataset.key === 'transactionList'){
            if(name === "productAndService"){
                let productAndService = _.find(_.cloneDeep(this.state.productAndService),{productAndServiceID : event.target.value})
                transactionList[id]["productAndServiceID"] = productAndService.productAndServiceID;
                transactionList[id]["customerChartOfAccount"] = productAndService.customerChartOfAccountCode + ' ' + productAndService.customerChartOfAccount;
                transactionList[id]["chartOfAccount"] = productAndService.chartOfAccountCode +' ' + productAndService.chartOfAccount;
                if(productAndService.pricingType === 'individual'){
                    let obligation = this.props.edit ? 'already_obligation' : 'not_obligation'
                    await revenueContractAPI.getObligationRoom(this.state.token,this.state.contactList[0].unitStatusList.refNumber,obligation,productAndService.productAndServiceID).then((response) => {
                        transactionList[id]["price"] = response.data.residential_list[0].unitStatusList.servicePricingEachUnit
                    })
                }else{
                    transactionList[id]["price"] = productAndService.price;
                }
                transactionList[id]["periodAdjustment"] = productAndService.periodAdjustment
                transactionList[id]["receiveDeposit"] = productAndService.receiveDepositCode +' ' + productAndService.receiveDeposit;
                transactionList[id]["pricingType"] = productAndService.pricingType;
                transactionList[id]["charged"] = productAndService.pricingType === '' ? 'one_month' : 'once';
                transactionList[id]["billingMonthList"] = [{value: '' , label : ''}]
                transactionList[id]["billingMonthSelected"] = '';
                transactionList[id]["advance"] = 0;
                this.setState({
                    transactionList : transactionList
                },() => this.updateTotal(id))
            }else{
                transactionList[id][name] = value;
                this.setState({
                    transactionList : transactionList
                },() => this.updateTotal(id))
            }
        }else if(event.target.dataset.key === 'billingMonth'){
            this.getBillingMonth(value,id)
        }else if(name === 'charged'){
            transactionList[id][e.target.name] = e.target.value;
            this.setState({
                transactionList : transactionList
            },() => this.getBillingMonthList(id,e.target.value))
        }else if(name === 'burdenStatus'){
            transactionList[id]['burdenStatus'] = !transactionList[id].burdenStatus;
            transactionList[id]['agentStatus'] = false;
            this.setState({
                transactionList : transactionList
            })
        }
        else if(name === 'agentStatus'){
            transactionList[id]['agentStatus'] = !transactionList[id].agentStatus;
            transactionList[id]['burdenStatus'] = false;
            
            this.setState({
                transactionList : transactionList
            })
        }
        else{
            transactionList[id][e.target.name] = e.target.value;
            this.setState({
                transactionList : transactionList
            })
        }
    }

    appendNewRow = () => {
        this.setState({ transactionList: [...this.state.transactionList, { ...newTransaction}] });
       
    }

    // คิดราคางวดเรียเก็บใช้สำหรับแสดงแค่ตัวหน้าบ้าน ตัวราคาจริงๆจะถูกคำนวณที่หลังบ้านหลังจากกดสร้าง
    updateTotal = (id) => {
        let transactionList = _.cloneDeep(this.state.transactionList);
        let total = 0;
        if(this.state.contactList.length > 0){
            if(transactionList[id].pricingType === 'electric_meter' || transactionList[id].pricingType === 'water_meter'){
                total = '-'
            }else if(transactionList[id].pricingType === 'area'){
                total = transactionList[id].price * this.state.contactList[0].unitStatusList.size
            }else if(transactionList[id].pricingType === 'car_park'){
                total = transactionList[id].price * this.state.contactList[0].unitStatusList.car
            }else if(transactionList[id].pricingType === 'motorcycle_park'){
                total = transactionList[id].price * this.state.contactList[0].unitStatusList.motorcycle
            }else if(transactionList[id].pricingType === 'static' || transactionList[id].pricingType === 'individual'){
                total = transactionList[id].price * 1
            }else{
                total = '-'
            }
        }
        if(transactionList[id].charged === 'two_month'){
            total = total * 2
        }else if(transactionList[id].charged === 'three_month'){
            total = total * 3
        }else if(transactionList[id].charged === 'four_month'){
            total = total * 4
        }else if(transactionList[id].charged === 'six_month'){
            total = total * 6
        }else if(transactionList[id].charged === 'one_year'){
            total = total * 12
        }

        transactionList[id]["totalPrice"] = total;

        this.setState({
            transactionList : transactionList
        })

    }

    getBillingMonth = (list,id) => {
        let billingMonth = []
        let transactionList = _.cloneDeep(this.state.transactionList)
        _.split(list, ' ').forEach((months) => {
            billingMonth.push(months)
        })
        transactionList[id]['billingMonthSelected'] = list;
        transactionList[id]['billingMonth'] = billingMonth;
        this.setState({
            transactionList : transactionList
        },() => this.updateTotal(id))
    }

    getBillingMonthList = (id,charged) => {
        let transactionList = _.cloneDeep(this.state.transactionList)
        let billingMonth = []
        // เลขเดือนตามงวดที่เลือก
        let billingMonthSet = {
            two_month : [[1,3,5,7,9,11],[2,4,6,8,10,12]],
            three_month : [[1,4,7,10],[2,5,8,11],[3,6,9,12]],
            four_month : [[1,5,9],[2,6,10],[3,7,11],[4,8,12]],
            six_month : [[1,7],[2,8],[3,9],[4,10],[5,11],[6,12]],
        }
        if(charged !== 'once' || charged !== 'one_month' || charged !== 'one_year'){
            _.forEach(billingMonthSet[charged],months =>{
                let label = ''
                _.forEach(months,(value,index) => {
                    if(index === 0){
                        label = this.getMonth(value)
                    }else{
                        label = label + ' ' + this.getMonth(value)
                    }
                })
                billingMonth.push({value : label , label : label})
            })
        }
        if(charged === 'one_year'){
            _.forEach([1,2,3,4,5,6,7,8,9,10,11,12] , months =>{
                billingMonth.push({value : this.getMonth(months) , label : this.getMonth(months)})
            })
        }
        transactionList[id]['billingMonthList'] = billingMonth;
        transactionList[id]['billingMonthSelected'] = '';
        this.setState({
            transactionList : transactionList
        }, () => this.updateTotal(id))
    }

    setTransactionList = (newTransaction) => {
        this.setState({ transactionList: newTransaction });
    }

    onSubmit(e){
        e.preventDefault();
        this.props.updateCondition(this.state.transactionList)
        
    }

    getMonth = (value) => {
        switch(value){
            case 1: 
                return 'Jan'
            case 2: 
                return 'Feb'
            case 3: 
                return 'Mar'
            case 4: 
                return 'Apr'
            case 5: 
                return 'May'
            case 6: 
                return 'Jun'
            case 7: 
                return 'Jul'
            case 8: 
                return 'Aug'
            case 9: 
                return 'Sep'
            case 10: 
                return 'Oct'
            case 11: 
                return 'Nov'
            case 12: 
                return 'Dec'

            default:
                break;
            
        }
    }

    render(){
        return (
            <Translation>
                {t =>
                    <React.Fragment>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h4>{t("settingAll:Conditions")}</h4>
                            </div>
                            <div className="col-12">
                                <h5 className="text-red">{t("settingAll:Resident is responsible for these expenses.")}</h5>
                            </div>
                        </div>

                        <div className="row form-inline mt-4">
                            <div className="col-2"><h4>{t("settingAll:Condition lists")}</h4></div>
                        </div>
                        <hr />
                        <form onSubmit={this.onSubmit} id="condition">
                            <div className="row card-bd-none transaction-table">
                                <table className="table col-12 mr-3 p-4" style={{fontSize : '12px'}}>
                                    <thead  className="thead-light">
                                        <tr >
                                            <th className="text-left bg-grey" width={200}>{t("settingAll:Services code")}</th>
                                            <th className="text-left bg-grey" >{t("settingAll:Income account")}</th>
                                            <th className="text-left bg-grey" >{t("settingAll:Account receivables")}</th>
                                            <th className="text-center bg-grey" >{t("settingAll:Calculation method")}</th>
                                            <th className="text-center bg-grey">{t("revenue_setting:Price")}</th>
                                            <th className="text-left bg-grey" >{t("settingAll:Average income")}</th>
                                            <th className="text-right bg-grey" >{t("settingInvoiceTerms:deferred")}</th>
                                            <th className="text-center bg-grey"width={130} >{t("settingAll:Billing period")}</th>
                                            <th className="text-center bg-grey" width={150}>{t("settingAll:Billing month")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Advance notice(month)")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Price/Billing period")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Invoiced start date")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Invoiced due date")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Tenants participate")}</th>
                                            <th className="text-right bg-grey" >{t("settingAll:Agent participate")}</th>
                                            {this.state.transactionList.length !== 1 &&
                                                <th className="text-center bg-grey" ></th>
                                            }
                                        </tr>
                                    </thead>
                                        <tbody>
                                            {this.state.transactionList.map((transaction,index) => {
                                                let month = ''
                                                if(transaction.obligationTenantID){
                                                    _.forEach(transaction.billingMonth, (months,index) => {
                                                        month = index === 0 ? months : month + " , " + months
                                                    })
                                                }
                                                if((transaction.status && transaction.status !== 'delete') || !transaction.status){
                                                    return(
                                                    <tr key={index}>
                                                        <td className="text-left">
                                                            <SelectService onChange={this.handleChange}
                                                                value={transaction.productAndServiceID}
                                                                name="productAndService"
                                                                placeholder="รายการ"
                                                                queryObject={this.state.productAndService}
                                                                keyOfValue="productAndServiceID" 
                                                                require={true}
                                                                keyOfLabel="productCode:name"
                                                                data-id={index} 
                                                                data-key="transactionList"
                                                                disabled = {transaction.obligationTenantID}
                                                                />
                                                        </td>
                                                        <td className="text-left">
                                                            <span>{transaction.chartOfAccountCode ?( transaction.chartOfAccountCode + ' ') : ''} {transaction.chartOfAccount}</span>
                                                        </td>
                                                        <td className="text-left">
                                                            <span>{transaction.customerChartOfAccountCode ? (transaction.customerChartOfAccountCode +' ') : ''} {transaction.customerChartOfAccount}</span>
                                                        </td>
                                                        <td className="text-left">
                                                            <span>{t(`revenueContract:${transaction.pricingType}`)}</span>
                                                        </td>
                                                        <td className="text-right">
                                                            <span>{numberWithCommas(transaction.price,"-")}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <input type="checkbox" disabled={true} checked={transaction.periodAdjustment} />
                                                        </td>
                                                        <td className="text-left">
                                                            <span>{transaction.periodAdjustment && (transaction.receiveDepositCode ? transaction.receiveDepositCode+ ' '+ transaction.receiveDeposit :transaction.receiveDeposit )}</span>
                                                        </td>
                                                        {/* งวดการเรียกเก็บ */}
                                                        <td className="text-center">
                                                            <SelectService 
                                                                onChange={this.handleChange}
                                                                value={transaction.charged}
                                                                name="charged"
                                                                placeholder="รายการ"
                                                                queryObject={charged}
                                                                keyOfValue="value" 
                                                                require={true}
                                                                keyOfLabel="label"
                                                                data-id={index} 
                                                                data-key="charged"
                                                                className="text-left"
                                                                disabled={transaction.productAndServiceID === '' || transaction.pricingType === '' || transaction.activeStatus === false || transaction.userInTran}
                                                            />
                                                        </td>
                                                        {/* เดือนเรียกเก็บ */}
                                                        <td className="text-center">
                                                            {!transaction.billingMonthList && transaction.obligationTenantID ?
                                                                <span>{month}</span>
                                                                :
                                                                <SelectService 
                                                                    onChange={this.handleChange}
                                                                    value={transaction.billingMonthSelected}
                                                                    name="billingMonth"
                                                                    placeholder="รายการ"
                                                                    queryObject={transaction.billingMonthList ? transaction.billingMonthList : []}
                                                                    keyOfValue="value" 
                                                                    keyOfLabel="label"
                                                                    data-id={index} 
                                                                    data-key="billingMonth"
                                                                    disabled={transaction.productAndServiceID === '' || transaction.pricingType === '' || transaction.activeStatus === false || transaction.userInTran}
                                                                />
                                                            }
                                                        </td>

                                                        <td className="text-center">
                                                            <SelectService 
                                                                onChange={this.handleChange}
                                                                value={transaction.advance}
                                                                name="advance"
                                                                placeholder="รายการ"
                                                                queryObject={advance}
                                                                keyOfValue="value" 
                                                                keyOfLabel="label"
                                                                data-id={index} 
                                                                data-key="advance"
                                                                disabled={transaction.charged === 'once' ||
                                                                    transaction.pricingType === "static" || transaction.pricingType === "water_meter" || 
                                                                    transaction.pricingType === "electric_meter" ||  transaction.pricingType === "car_park" ||
                                                                    transaction.pricingType === "motorcycle_park" ||  
                                                                    transaction.activeStatus === false || transaction.userInTran}
                                                            />
                                                        </td>
                                                        <td className="text-right">
                                                            <span>{numberWithCommas(transaction.totalPrice,"-")}</span>
                                                        </td>
                                                        <td className="text-center">
                                                            <DatePickerAdapter 
                                                                selected={getDateTime(transaction.issuedDate)}
                                                                className="form-control text-center"
                                                                data-key="issuedDate"
                                                                data-id={index} 
                                                                name="issuedDate"
                                                                require= {true}
                                                                onChange={this.handleChange}
                                                                disable={transaction.activeStatus === false || transaction.userInTran}
                                                                // disable={transaction.obligationTenantID}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <DatePickerAdapter 
                                                                selected={getDateTime(transaction.dueDate)}
                                                                className="form-control text-center"
                                                                data-key="dueDate"
                                                                data-id={index} 
                                                                name="dueDate"
                                                                onChange={this.handleChange}
                                                                minDate={getDateTime(transaction.issuedDate)}
                                                                disable={transaction.activeStatus === false || transaction.userInTran}
                                                                // disable={transaction.obligationTenantID}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <input type="checkbox" name="burdenStatus" 
                                                            onChange={this.handleChange} data-id={index} 
                                                            checked={transaction.burdenStatus} 
                                                            // disabled={transaction.pricingType === ''}
                                                            // disabled={transaction.activeStatus === false || transaction.userInTran}
                                                            disabled={transaction.activeStatus === false}
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                            <input type="checkbox" name="agentStatus" 
                                                            onChange={this.handleChange} data-id={index} 
                                                            checked={transaction.agentStatus} 
                                                            // disabled={transaction.pricingType === ''}
                                                            // disabled={transaction.activeStatus === false || transaction.userInTran}
                                                            disabled={transaction.activeStatus === false}
                                                            />
                                                        </td>
                                                        {(this.state.transactionList.length !== 1 && transaction.activeStatus && transaction.userInTran !== true) && (
                                                            <td width={1}>
                                                            {
                                                                <OnDelete
                                                                    transaction={transaction}
                                                                    transactionList={this.state.transactionList}
                                                                    setTransactionList={this.setTransactionList}
                                                                />
                                                            }
                                                            </td>
                                                        )}
                                                    </tr>
                                                );}
                                                
                
                                            })
                
                                            }
                                        </tbody>
                                    
                                </table>
                                <div className="col-12 form-inline mt-3 mb-3 d-flex align-items-center">
                                        <button
                                            type="button"
                                            className="btn add-button add"
                                            onClick={this.appendNewRow}
                                        >
                                            <span className="text-blue" >+ {t('createUpdateForm:add_list')}</span>
                                        </button>  
                                </div>
            
                            </div>
                        </form>
                        <div className=" d-flex mb-2 p-4">
                            <div className="mr-auto mt-3">
                                {/* <button type="button" onClick={() => this.props.backPage(this.state.transactionList)}  className="btn btn-blue add  float-right">
                                    <span>หน้าก่อน</span>
                                </button> */}
                            </div>
                            <div className="mt-3">
                                <button type="submit" form="condition"  className="btn btn-blue add  float-right" disabled={this.props.loading_data}>
                                    {this.props.loading_data && <span className="spinner-border spinner-border-sm align-middle mr-2"/>} 
                                    <span>บันทึก</span>
                                </button>
                            </div>
                        </div>
                    </React.Fragment> 
                }
            </Translation>

        );
    }




}export default ConditionContract;