import React from 'react';
import { Component } from 'react';
import { Translation } from 'react-i18next';
import "../../developer.scss";
import _ from "lodash";
import revenueAPI from "../revenueContractAPI"
import Freebies from './freebies';
import ConditionContract from './conditionContract';
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import {Redirect} from 'react-router';
import Wrapper from "../../../components/wrapper/index";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { Link } from 'react-router-dom';
import residentialAPI from '../../../register/serviceAPI'
import { lastDayOfMonth } from 'date-fns';
import i18next from 'i18next';

class RevenueContractEdit extends Component{

    constructor(props){
        super(props);
        this.state={
            contactList : [],
            remark : "",
            residentialID : this.props.match.params.id,
            transactionFreebies : [],
            transactionCondition : [],
            token: localStorage.getItem("token"),
            productAndService : [],
            freebiesSelect : '',
            loading : false,
            conditionContract : this.props.match.params.type === 'condition' ? true : false,
            redirectToList : false,
            freebies : [],
            condition : [],
            roomNo : '',
            ownershipName : '',
            refNumber : '',
            freebieItemList : [],
        }
    }

    

    getService = () => {
        revenueAPI.getService(this.state.token)
            .then((response) => {
                if(response.data.productAndService){
                    this.setState({
                        productAndService : response.data.productAndService
                    })
                }
            })
            residentialAPI.getResidential(this.state.token,this.props.match.params.id).then((response) => {
                if(response.data){
                    let name = '';
                    _.forEach(response.data.ownershipName , (contact,index) => {
                        name = index === 0 ? contact : name + " , " + contact
                     })
                    revenueAPI.getObligationRoom(this.state.token,response.data.refNumber,'already_obligation').then((response) => {
                        this.setState({
                            contactList : response.data.residential_list
                        })
                    })
                    this.setState({
                        roomNo : response.data.name,
                        ownershipName : name,
                        refNumber : response.data.refNumber
                    })
                }
            })
            
            revenueAPI.getFreebies(this.state.token,this.props.match.params.id).then((response) => {
                if(response.data){
                    this.setState({
                        freebies : response.data.obligationDeveloper
                    })
                }
            })


            revenueAPI.getCondition(this.state.token,this.props.match.params.id).then((response) => {
                if(response.data){
                    this.setState({
                        condition : response.data.obligationTenant
                    })
                }
            })

            revenueAPI.getFreebiesItem(this.state.token,this.props.match.params.id).then((response) => {
                if(response.data){
                    this.setState({
                        freebieItemList : response.data.ownerGift
                    })
                }
            })
            

    }

    componentDidMount(){
        this.getService()
    }

    updateFreebies = (transactionList,freebiesSelect, freebiesItems) =>{
        this.setState({
            transactionFreebies : transactionList,
            freebiesSelect : freebiesSelect, 
        }, () => {
            let freebies = [];
            _.forEach(this.state.transactionFreebies , data => {
                let newDueDate = (lastDayOfMonth(data.dueDate))
                freebies.push({
                    obligationDeveloperID : data.obligationDeveloperID ? data.obligationDeveloperID : '', 
                    residentialID : this.props.match.params.id, 
                    productAndServiceID : data.productAndServiceID , 
                    freebies : this.state.freebiesSelect ,
                    price : data.price , 
                    issuedDate : format(data.issuedDate,'YYYY-MM-DD') ,
                    dueDate : format(newDueDate,'YYYY-MM-DD'),
                    status : data.status ? data.status : ''
                })
            })
            const freebiesItemsUpdate = freebiesItems.map(({ ownerGiftId, residentialId, ...rest }) => rest);

            revenueAPI.createFreebies(freebies,this.state.token).then((response) => {
                if(response.status === 200){ 
                    revenueAPI.editFreebiesItem(freebiesItemsUpdate,this.state.token, this.props.match.params.id).then((response) => {
                        if(response.status === 200){
                            this.onSuccess()
                        }else{
                            this.onError()
                        }
                    })
                }                
            })
        })
        
    }

    onSuccess = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() => { 
            this.setState({ redirectToList: true });
        });
      }
    
      onError = () => {
        this.setState({ loading: false });
        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
      }


    updateCondition = (transactionList) =>{
        this.setState({
            transactionCondition : transactionList,
        },() => {

            let condition = [];
            _.forEach(this.state.transactionCondition , data => {
                condition.push({
                    obligationTenantID : data.obligationTenantID ? data.obligationTenantID : '', 
                    residentialID : this.props.match.params.id , 
                    productAndServiceID : data.productAndServiceID , 
                    charged : data.charged ,
                    billingMonth : data.billingMonth ? data.billingMonth : '', 
                    advance : data.advance,
                    issuedDate : format(data.issuedDate,'YYYY-MM-DD'),
                    burdenStatus : data.burdenStatus,
                    agentStatus : data.agentStatus,
                    activeStatus : data.activeStatus,
                    status : data.status ? data.status : '',
                    dueDate: data.dueDate ? format(data.dueDate,'YYYY-MM-DD') : null
                })
            })
            
            revenueAPI.createCondition(condition,this.state.token).then((response) => {
                if(response.status === 200){
                    this.onSuccess()
                }else{
                    this.onError()
                }
            })
        })
    }

    render(){
        let contactName = ''
        if(this.state.contactList.length === 1){
            this.state.contactList[0].unitStatusList.ownershipName.forEach((name) => {
                contactName = contactName +' ' + name
            })
        }
        if(this.state.redirectToList){
            return <Redirect to={"/developer/revenue-contract/detail/" +this.props.match.params.id }/>;
        }
        return(
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    
                <Translation>
                    {t =>   <div className="container-fluid box" id="developer-form">
                                <div className="row">
                                    <div className="col">
                                        <h4 className="mb-4">
                                            <Link to={"/developer/revenue-contract/detail/" + this.props.match.params.id}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back"/>
                                            </Link>
                                            {t('developer:Bind revenue contract')}
                                        </h4>
                                    </div>
                                </div>
                                <div className="content-inner pr-4">
                                    <div className="row d-flex align-items-center">
                                        <div className="col form-inline">
                                            <h4 className="mr-4">{t("settingAll:Co-owner's information")}</h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row d-flex mt-3 mb-2">
                                        <div className="row col-12">
                                            <div className="col-2">
                                                <h6>เลขห้อง</h6>
                                            </div>​
                                            <div className="col-2">
                                                <h6>ชื่อ</h6>
                                            </div>​
                                        </div>
                                        <div className="row col-12">
                                            <div className="col-2">
                                                <span className="text-grey">{this.state.roomNo}</span>
                                            </div>​
                                            <div className="col-10">
                                                <span className="text-grey">{this.state.ownershipName}</span>
                                            </div>​
                                        </div>
                                    </div>
                                    
                                    {!this.state.conditionContract ? 
                                        <Freebies productAndService = {this.state.productAndService} freebies={this.state.freebies} 
                                        updateFreebies ={this.updateFreebies} freebieItemList={this.state.freebieItemList} edit={true}/>
                                    :
                                        <ConditionContract productAndService = {this.state.productAndService}  contactList={this.state.contactList} 
                                        updateCondition = {this.updateCondition}  edit={true} condition={this.state.condition} /> 
                                    }
                                    
                                </div>
                            </div>
                    }
                </Translation>
                </WrapperContent>
            </Wrapper>
            
        );
    }


}export default RevenueContractEdit;