import {
    commitMutation,
  } from 'react-relay'
  import { graphql } from "babel-plugin-relay/macro";
  import environment from "../../../../env/environment";
  
  
  const mutation = graphql`
    mutation editPreviousBudgetMutation($input:UpdatePreviousBudgetInput!){
      updatePreviousBudget(input:$input) 
      {
        ok
        message
        budgetId
      }
    }
  `;
  
  export default function editPreviousBudget(variables, callback, error_callback) {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response) => {
          callback(response)
        },
        onError: (err) => {
          error_callback(err);
        },
      },
    )
  }