/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTeamInput = {|
  team: any,
  clientMutationId?: ?string,
|};
export type updateTeamMutationVariables = {|
  input: UpdateTeamInput
|};
export type updateTeamMutationResponse = {|
  +updateTeam: ?{|
    +ok: ?boolean
  |}
|};
export type updateTeamMutation = {|
  variables: updateTeamMutationVariables,
  response: updateTeamMutationResponse,
|};
*/


/*
mutation updateTeamMutation(
  $input: UpdateTeamInput!
) {
  updateTeam(input: $input) {
    ok
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTeamPayload",
    "kind": "LinkedField",
    "name": "updateTeam",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTeamMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateTeamMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ecb9720f1c4cd523c4e7c28170e98668",
    "id": null,
    "metadata": {},
    "name": "updateTeamMutation",
    "operationKind": "mutation",
    "text": "mutation updateTeamMutation(\n  $input: UpdateTeamInput!\n) {\n  updateTeam(input: $input) {\n    ok\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e32d3dd6f722ac9194efa15a7a02e5a';

module.exports = node;
