import React, { Component } from 'react';
import Header from '../../../../components/header';
import Sidebar from '../../../../components/sidebar/index';
import Wrapper from '../../../../components/wrapper/index';
import WrapperContent from '../../../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import i18n from "i18next";
import Select from 'react-select';
import '../../style/claim.scss'
import APIInsur from '../../../../api/insurrance'
import _ from 'lodash';
class EditInsurancePolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            loading: true,
            co_insurance: [],
            file_insurance: [],
            data: null,
            insurance_start_date: "",
            insurance_end_date: "",
            dataInsuranceCompany: []

        }
        this.onSubmit = this.onSubmit.bind(this)
        this.onChageData = this.onChageData.bind(this)
        this.addListInsur = this.addListInsur.bind(this)
        this.SeleteCompanyName = this.SeleteCompanyName.bind(this)
        this.co_insuranceUpdate = this.co_insuranceUpdate.bind(this)
        this.getCompanyAll = this.getCompanyAll.bind(this);
    }

    componentWillMount() {
        if (this.props.match.params.id) {
            this.getCompanyAll()
            APIInsur.getDataInsuranceById(this.props.match.params.id).then((res) => {
                if (res.data) {
                    this.setState({
                        data: res.data?.insurance.insurance_detail,
                        file_insurance: res.data?.insurance.file_insurance,
                        co_insurance: res.data?.insurance.co_insurance,
                        loading: false
                    })
                } else {
                    Swal.fire("ผิดพลาด", "ส่งข้อมูลผิดพลาด", 'warning')
                }
            }, () => {
                Swal.fire("ผิดพลาด", "ไม่สามารถเชื่อมต่อหลังบ้านได้", 'error')
            })
        }
    }

    SeleteCompanyName(e, index, main) {

        let data = _.cloneDeep(this.state.data)
        _.set(data, `main_insurance_company.id`, e.id)
        _.set(data, `co_insurance[${index ? index : 0}].id`, e.id)
        this.setState({ data: data, main_insurance_company_id: e })
    }

    co_insuranceUpdate(data, index, e) {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        _.set(co_insurance, `[${index}].insurance_ratio`, e.target.value)

        this.setState({ co_insurance: co_insurance })

    }

    addListInsur() {
        let co_insurance = _.cloneDeep(this.state.co_insurance)
        let co_insurancechk = co_insurance.filter((n) => n.name !== "")

        if (co_insurancechk.length >= 0) {
            co_insurance.push({ co_insurance_company_id: "", name: "", insurance_ratio: 0 })
            this.setState({ co_insurance: co_insurance })
        }
    }

    onChageData(e) {
        let data = _.cloneDeep(this.state.data)
        let NewData = _.set(data, `${e.target.name}`, e.target.value)
        this.setState({ data: NewData })
    }


    onSubmit(e) {
        e.preventDefault();


        let {
            main_insurance_company_id,
            insurance_company_address,
            main_insurance_company_tax_number,
            main_insurance_company_website,
            main_insurance_company_tel,
            main_insurance_company_email,
            main_insurance_company_name
        } = this.state.data;

        let data = {
            "main_insurance_company_id": this.state.main_insurance_company_id?.id ? this.state.main_insurance_company_id?.id : this.state.co_insurance[0].co_insurance_company.id,
            "main_insurance_company_name": main_insurance_company_name,
            "insurance_company_address": insurance_company_address,
            "main_insurance_company_tax_number": main_insurance_company_tax_number,
            "main_insurance_company_website": main_insurance_company_website,
            "main_insurance_company_tel": main_insurance_company_tel,
            "main_insurance_company_email": main_insurance_company_email,
            co_insurance: this.state.co_insurance,
            file_insurance: this.state.file_insurance
        }

        APIInsur.updateDataInsurance(this.props.match.params.id, data).then((res) => {
            if (res.data?.statusCode === "0000") {
                Swal.fire({
                    type: 'success',
                    title: i18n.t("Allaction:successcompleate"),
                }).then(() => {
                    this.props.history.push(`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Policy`)
                })
            }
        })
    }

    getCompanyAll() {
        APIInsur.getCompanyAll().then((res) => {
            if (res.data) {
                let data = res.data.company_list;
                this.setState({ dataInsuranceCompany: [...data.map((n) => ({ ...n, value: n.id, label: n.name, insurance_ratio: 0 })), { id: 'other', value: 'other', label: 'อื่น ๆ', insurance_ratio: 0 }] })
            }
        })
    }


    render() {


        let { data, loading, file_insurance } = this.state

        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {t =>
                            loading == false ?
                                <div className="container-fluid box claim" id="staff-create">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/purchasing/insurrance">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span>{t('claim:Insurance')}</span>
                                            </h3>
                                        </div>
                                    </div>


                                    <div className="content-inner mt-5 create">
                                        <div className="row mb-12 mr-5 fade-up ">
                                            <div className='mx-auto' style={{ width: 744 }}>

                                                <React.Fragment>
                                                    <form onSubmit={this.onSubmit}>
                                                        <div className='onFinishSe'>

                                                            <div className='col-12'>
                                                                <label className='headerLabel'>{t("claim:PrimaryInsurer")}</label>
                                                                <br />
                                                                <label className='label16 mt-5'>{t("claim:PictureLogoInsurrance")}</label>
                                                                <br />

                                                                {
                                                                    this.state.main_insurance_company ?
                                                                        <img src={data.main_insurance_company.logo_link}
                                                                            style={{ width: 124, height: 124, borderRadius: "50%", marginTop: 12 }}
                                                                            onError={({ currentTarget }) => {
                                                                                currentTarget.onerror = null; // prevents looping
                                                                                currentTarget.src = process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png';
                                                                            }} />
                                                                        :
                                                                        <img src={process.env.PUBLIC_URL + '/images/iconAction/claim/logoCompany.png'}
                                                                            style={{ width: 124, height: 124 }} />
                                                                }
                                                            </div>

                                                            {/* main_insurance_company */}
                                                            <div className='col-12 mt-5 mb-1'>
                                                                <label className='label16'>{t("claim:CompanyName")}</label>
                                                                <div style={{ position: 'relative' }}>

                                                                    {
                                                                        this.state.dataInsuranceCompany.length > 0 ?
                                                                            <div className='seletedInputBox'>
                                                                                <Select
                                                                                    placeholder={t("claim:CompanyName")}
                                                                                    options={this.state.dataInsuranceCompany}
                                                                                    isSearchable={true}
                                                                                    onChange={(e) => this.SeleteCompanyName(e, null, true)}
                                                                                    defaultValue={{ value: data?.main_insurance_company?.id, label: data?.main_insurance_company?.name }}
                                                                                />
                                                                                <input name="main_insurance_company_id" type="text" value={data?.main_insurance_company?.id}
                                                                                    style={{ position: "absolute", top: 5, left: 5, width: 5, zIndex: -1 }}
                                                                                    onChange={() => this.inputTitle.value = ""} ref={el => this.inputTitle = el}
                                                                                    required={true} />

                                                                            </div>
                                                                            :
                                                                            ""
                                                                    }

                                                                </div>
                                                            </div>

                                                            {/* main_insurance_company_id === "other" , main_insurance_company_name  */}
                                                            {
                                                                this.state.main_insurance_company_id?.value === "other" &&
                                                                <div className='col-12 mt-5 mb-1'>
                                                                    <label className='label16'>{t("claim:Company Name Other")}</label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <div className='seletedInputBox'>
                                                                            <input type={'text'} name='main_insurance_company_name' className='form-control'
                                                                                // onChange={(e) => this.updateCoinsurance(e)}
                                                                                onChange={(e) => this.setState({
                                                                                    co_insurance: _.set(_.cloneDeep(this.state.co_insurance), '[0].name', e.target.value),
                                                                                    data: _.set(_.cloneDeep(this.state.data), `main_insurance_company_name`, e.target.value)
                                                                                })}
                                                                                defaultValue={this.state.main_insurance_company_name}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {/* insurance_company_address */}
                                                            <div className='col-12 mt-2 mb-1'>
                                                                <label className='label16 mt-5'>{t("claim:CompanyAddress")}</label>
                                                                <textarea
                                                                    onChange={this.onChageData}
                                                                    className='form-control textareaBox'
                                                                    placeholder={t("claim:CompanyAddress")}
                                                                    defaultValue={data.insurance_company_address}
                                                                    style={{ resize: 'none' }}
                                                                />
                                                            </div>


                                                            {/* main_insurance_company_tax_number */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <label className='headerLabel18'>{t("claim:TaxId")}</label>
                                                                    </div>

                                                                    <div>
                                                                        <input type={"text"} name='main_insurance_company_tax_number'
                                                                            pattern='[0-9]{13}'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:TaxId")}
                                                                            maxLength={13} minLength={13}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={data.main_insurance_company_tax_number} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* WebSite */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <img src={process.env.PUBLIC_URL + '/images/iconAction/claim/language.png'}
                                                                            className='imgIcon' />
                                                                        <label className='headerLabel18'>{t("claim:WebSite")}</label>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span>{t("claim:unit")}</span> */}
                                                                        <input type={"url"} name='main_insurance_company_website'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:WebSite")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={data.main_insurance_company_website} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Phone */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <img
                                                                            className='imgIcon'
                                                                            src={process.env.PUBLIC_URL + '/images/iconAction/claim/phone.png'} />
                                                                        <label className='headerLabel18'>{t("claim:Phone")}</label>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span>{t("claim:bath")}</span> */}
                                                                        <input type={"tel"} name='main_insurance_company_tel'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:Phone")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={data.main_insurance_company_tel} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Email */}
                                                            <div className='col-12 mt-2 mb-2'>
                                                                <div className='divinlineFlex'>
                                                                    <div className='divInlineWithInput'>
                                                                        <img
                                                                            className='imgIcon'
                                                                            src={process.env.PUBLIC_URL + '/images/iconAction/claim/mail.png'} />
                                                                        <label className='headerLabel18'>{t("claim:Email")}</label>
                                                                    </div>
                                                                    <div>
                                                                        {/* <span>{t("claim:bath")}</span> */}
                                                                        <input type={"email"} name='main_insurance_company_email'
                                                                            onChange={this.onChageData}
                                                                            placeholder={t("claim:Email")}
                                                                            className='form-control inputBox' style={{ width: 508 }}
                                                                            defaultValue={data.main_insurance_company_email} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className='col-11 text-right mt-10 pr-1'>
                                                            <Link to={`/purchasing/insurrance/insur-detail/${this.props.match.params.id}/Policy`}>
                                                                <button type='button' className='btn-outline-blue' style={{ width: 128, height: 48 }}>{t("Allaction:cancel")}</button>
                                                            </Link>
                                                            <button type='submit' className='btn-blue-primary' style={{ width: 128, height: 48 }}>{t("Allaction:save")}</button>
                                                        </div>
                                                    </form>
                                                </React.Fragment>




                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                    </Translation>
                </WrapperContent>
            </Wrapper>
        );
    }
}

export default EditInsurancePolicy;
