import React, {Component} from 'react';
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import WrapperContent from "../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../accountingTopMenuNavigation";
import Wrapper from "../../components/wrapper";
import {Link} from "react-router-dom";
import _ from "lodash";
import {getMonth, getYear} from "date-fns";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import {graphql} from "babel-plugin-relay/macro";
import SearchSelect from "../../libs/searchSelect";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import {commitMutation} from "react-relay";
import Swal from "sweetalert2";
import {Redirect} from 'react-router';
import { Translation } from 'react-i18next';
import i18n from 'i18next'

const query = graphql`
    query electricMeterCreateHistoryQuery($id: ID!) {
        allResidential{
            edges{
                node{
                    id
                    name
                }
            }
        }
        
        electricMeterUnit(id: $id){
            id
            issuedDate
            meterValue
            initialChangeMeterValue
            residential{
                id
            }
            remark
            added
            editDate
        }
    }
`;

const mutation = graphql`
    mutation electricMeterCreateHistoryMutation($input: CreateChangeElectricMeterInput!){
        createChangeElectricMeter(input: $input){
            ok
            warningText
        }
    }
`;


class ElectricMeterCreateHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToList: false,
            residential_list:[],
            residential:"",
            date: new Date(),
            edit_date: new Date(),
            unit:0,
            initial_unit:0,
            remark:"",
            loadong: false,
            mode_edit: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        fetchQuery(environment, query, {id: this.props.match.params.id || false}).then(data => {
            if (_.isObject(data.allResidential)) {
                this.setState({residential_list: data.allResidential.edges});
            }
            if (_.isObject(data.electricMeterUnit)) {
                this.setState({
                    residential: data.electricMeterUnit.residential.id,
                    date: new Date(data.electricMeterUnit.issuedDate),
                    edit_date: new Date(data.electricMeterUnit.editDate),
                    unit: data.electricMeterUnit.meterValue,
                    initial_unit: data.electricMeterUnit.initialChangeMeterValue,
                    remark: data.electricMeterUnit.remark,
                    mode_edit: true,
                });
            }
        })
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit(e){
        e.preventDefault();

        this.setState({loading: true});
        let variables = {
            input: {
                residential: this.state.residential,
                year: getYear(this.state.date),
                month: getMonth(this.state.date) + 1,
                unit: this.state.unit,
                initialUnit: this.state.initial_unit,
                remark: this.state.remark,
                editDate: this.state.edit_date,
            }
        };        
        commitMutation(
            environment,
            {
                mutation,
                variables,
                onCompleted: (response) => {
                    this.setState({loading: false});
                    if (response.createChangeElectricMeter.ok) {
                        Swal.fire(i18n.t("electric_meter_record:Save successfully"), '', 'success').then(() => {
                            this.setState({redirectToList: true});
                        });
                    } else {
                        Swal.fire(i18n.t("electric_meter_record:Failed to save"), response.createChangeElectricMeter.warningText, 'warning');
                    }
                },
                onError: (err) => {
                    this.setState({loading: false});
                    Swal.fire('Error!', i18n.t("electric_meter_record:Please try again"), 'warning');
                },
            },
        )

    }

    render() {
        if (this.state.redirectToList) {
            return <Redirect to={this.props.match.params.id ? "/accounting/electric-meter/history" : "/accounting/electric-meter/record"}/>;
        }
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent disabledOverflowX={true}>
                    <AccountingTopMenuNavigation mini={true}/>

                    <div className="container-fluid box" id="invoice-list">
                        <div className="row justify-content-between">
                            <div className="col">
                                <Translation>
                                    {t=>
                                    <h3 className="mb-4">
                                        <Link to={this.props.match.params.id ? "/accounting/electric-meter/history" : "/accounting/electric-meter/record"}>
                                            <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                 alt="back" className="back"/>
                                        </Link>
                                       {t("electric_meter_record:Electric meter change record")}
                                    </h3>}
                                </Translation>
                            </div>
                        </div>
                        <div className="content-inner">
                            <div className="row">
                                <div className="col-xl-6">
                                    <form onSubmit={this.onSubmit} id="create_change_electric_meter">
                                        <div className="form-group row">
                                            <div className="col-md-6">
                                                <Translation>{t=><label>{t("electric_meter_record:Room No.")}</label>}</Translation>
                                                <SearchSelect onChange={this.handleChange}
                                                              value={this.state.residential}
                                                              name="residential" placeholder="รายการ"
                                                              queryObject={this.state.residential_list}
                                                              keyOfValue="id" require={true}
                                                              disabled={this.state.mode_edit}
                                                              keyOfLabel="name"/>
                                            </div>
                                            <div className="col-md-3">
                                                <Translation>{t=><label>{t("electric_meter_record:Recording date")}</label>}</Translation>
                                                <DatePickerAdapter name="edit_date"
                                                                   className="form-control"
                                                                   selected={this.state.edit_date}
                                                                   onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-md-3">
                                                <Translation>{t=><label>{t("electric_meter_record:Year/month")}</label>}</Translation>
                                                <DatePickerAdapter name="date"
                                                           showMonthYearPicker={true}
                                                           className="form-control"
                                                           dateFormat="MM/yyyy"
                                                           selected={this.state.date}
                                                           disable={this.state.mode_edit}
                                                           onChange={this.handleChange}/>
                                            </div>
                                             <div className="col-md-3">
                                                <Translation>{t=><label>{t("meter:New meter start")}</label>}</Translation>
                                                <input type="number" className="form-control" name="initial_unit"
                                                value={this.state.initial_unit} onChange={this.handleChange} required={true}/>
                                            </div>
                                            <div className="col-md-3">
                                                <Translation>{t=><label>{t("meter:New current meter")}</label>}</Translation>
                                                <input type="number" className="form-control" name="unit"
                                                value={this.state.unit} onChange={this.handleChange} required={true}/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-md-8 col-sm-6">
                                                <Translation>{t=><label>{t("electric_meter_record:Note")}</label>}</Translation>
                                                <textarea cols="" rows="4" className="form-control" name="remark"
                                                  value={this.state.remark} onChange={this.handleChange} required={true}
                                        />
                                            </div>
                                        </div>
                                    </form>
                                    <div className="row mt-3 fade-up">
                                        <div className="col text-right">
                                            <div className="btn-group mr-2">
                                                <Link
                                                    to="/accounting/electric-meter/record">
                                                    <Translation>
                                                        {t=>
                                                        <button type="button"
                                                                className="btn btn-secondary add">
                                                            {t("electric_meter_record:Cancel")}
                                                        </button>}
                                                    </Translation>
                                                </Link>
                                                <Translation>
                                                    {t=>
                                                    <button type="submit" form="create_change_electric_meter"
                                                            className="btn btn-primary add ml-3" disabled={this.state.loading}>
                                                        {this.state.loading && <span className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                        {t("electric_meter_record:Save")}
                                                    </button>}
                                                </Translation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </WrapperContent>
            </Wrapper>
        );
    }
}

export default ElectricMeterCreateHistory;
