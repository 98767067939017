import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Translation} from "react-i18next";
import i18n from 'i18next'
import '../styles/register.scss'
import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery} from "relay-runtime";
import environment from "../../env/environment";
import SearchSelect from "../../libs/searchSelect";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import NewsImageRender from '../../contact/news/newsimageRender';
import UploadImageInput from '../../libs/uploadImageInput';
import {commitMutation} from "react-relay";
import Swal from "sweetalert2";
import {Redirect} from 'react-router'
import {format, parse} from "date-fns";
import "./styles/createUpdateVehicles.scss"
import uploadPublic from '../../libs/uploadPublic'
import i18next from 'i18next';

const _ = require('lodash');

const mutation = graphql`
    mutation createUpdateVehicleMutation($input: CreateAndUpdateCarInput!){
      createAndUpdateCar(input: $input){
        ok
        message
      }
    }
`;


const query = graphql`
    query createUpdateVehicleQuery($category: String) {
         allCarBrand(category: $category) {
            edges {
              node {
                id
                brandName
                carmodelnameSet {
                  edges {
                    node {
                      id
                      carModelName
                    }
                  }
                }
              }
            }   
        }
    }
`;

const car_object = {
    id:'',
    licencePlate: '',
    carDescription: '',
    province: '',
    carBrand: '',
    carModel: '',
    carYear: '',
    startDate: '',
    carType: '',
    chargedFee: true,
    carSticker: '',
    carSize : '',
    floor : '',
    space:''
};

class CreateUpdateVehicle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            province_list: [],
            car_brand_query: [],
            car_brand_list: [],
            type_car:true,
            car_model_name_list: [],
            loading: false,
            car_object: car_object,
            brand_select: '',
            car_model_select: '',
            car_image_new:{
                edges:[]
            },
            upload_image_list: [],
            previewImage: "",

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.fetchQueryCar = this.fetchQueryCar.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        this.setState({province_list: require('./json/provice.json')});
        if (_.isObject(this.props.detail_car.car)){

            let car = {...this.props.detail_car.car};
            let start_date = parse(car.startDate);
            car.startDate = start_date;

            let type_car = true;
            if (car.carType === 'MOTORCYCLE') {
                type_car = false;
            }

            this.setState({car_object: car, type_car: type_car});
            this.fetchQueryCar(this.props.detail_car.car.carType.toLowerCase(), this.props.detail_car.car.carBrand);

        }else {
            let car = {...this.state.car_object};
            car.carType = 'CAR';

            this.setState({car_object: car});
            this.fetchQueryCar('car')
        }

    }

    fetchQueryCar(category, brand) {
        fetchQuery(environment, query, {category: category}).then(response => {
            let car = _.cloneDeep(this.state.car_object);
            if (response.allCarBrand.edges) {
                let data = _.cloneDeep(response);
                let brand_select = "";
                let node = {
                    brandName: "ระบุเอง",
                    id: "other_input"
                };
                if (!brand) {
                    brand_select = ''
                } else if (!data.allCarBrand.edges.some(e => e.node.brandName === car.carBrand)) {
                    brand_select = 'ระบุเอง'
                } else {
                    brand_select = car.carBrand
                }
                this.setState({
                    car_brand_query: data.allCarBrand.edges,
                    car_brand_list: [...data.allCarBrand.edges, {node}],
                    brand_select: brand_select
                })
            }

            if (brand) {
                let car_model_select = "ระบุเอง";
                let node = {
                    carModelName: "ระบุเอง",
                    id: "other_input"
                };
                let car_model = [{node}];
                response.allCarBrand.edges.forEach((car_brand) => {
                    if (car_brand.node.brandName.toLowerCase() === brand.toLowerCase()) {
                        car_model = [...car_brand.node.carmodelnameSet.edges, ...car_model];
                        if (car_brand.node.carmodelnameSet.edges.some(e => e.node.carModelName === car.carModel)) {
                            car_model_select = car.carModel
                        }
                    }
                });
                this.setState({car_model_name_list: car_model, car_model_select: car_model_select})
            }
        });
    }

    callUpload = async (path, file) => {
        return new Promise(async (resolve, reject) => {
          let res = await uploadPublic("/vehicle/data" + path, file);
          resolve(res);
        });
    };

    handleInputChange(e) {
        let event = _.cloneDeep(e);
        let value = event.target.value;

        this.setState((prevState) => {
            return _.set(_.cloneDeep(prevState), event.target.name, value);
        });
    }

    deleteNewsImage(news_image_id, index) {
        Swal.fire({
            title: i18next.t("juristicManager1:Are you sure to remove this data")+"?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: i18next.t("Allaction:Yes"),
            cancelButtonText: i18next.t("Allaction:cancel")
        }).then((result) => {
            if (result.value) {
                if (news_image_id) {
                    let carImage = _.cloneDeep(this.state.car_object.carImage.edges)
                    let index = _.findIndex(_.cloneDeep(carImage),{node : {id : news_image_id}})
                    this.setState(prevState => {
                        return _.set(
                            _.cloneDeep(prevState),
                            'car_object.carImage.edges['+index+'].node.status',
                            'delete'
                        );
                    });
                } else {
                    let upload_image_list = [...this.state.upload_image_list];
                    upload_image_list.splice(index, 1);
                    this.setState({ upload_image_list: upload_image_list });
                }
            }
        })
        
    }

    handleChangeInput(e) {
        let node = {
            carModelName: "ระบุเอง",
            id: "other_input"
        };
        let key = e.target.dataset.key;
        let car_update = {...this.state.car_object};
        if (key === 'car_object') {
            if (e.target.type === 'radio') {
                let type = !this.state.type_car ? 'car' : 'motorcycle';
                car_update.carType = type.toUpperCase();
                car_update.carModel = "";
                car_update.carBrand = "";

                this.setState({type_car: !this.state.type_car, car_object: car_update}, () => {
                    this.fetchQueryCar(type)
                });

            } else if (e.target.type === 'checkbox') {
                car_update.chargedFee = e.target.checked;
                this.setState({car_object: car_update});

            } else if (e.target.name === 'carBrand') {
                car_update[e.target.name] = e.target.value;
                this.setState({car_object: car_update})

            } else {
                car_update[e.target.name] = e.target.value;
                this.setState({car_object: car_update})
            }
        } else {
            let before_change = this.state[e.target.name];
            let name = e.target.name;
            let value = e.target.value;
            if (e.target.name === 'brand_select') {
                if (this.state.car_brand_query.some(e => e.node.brandName === value)) {
                    if (before_change !== value) {
                        this.state.car_brand_list.forEach((car_brand) => {
                            if (car_brand.node.brandName.toLowerCase() === value.toLowerCase()) {
                                car_update.carModel = "";
                                car_update.carBrand = value;
                                this.setState({
                                    car_model_name_list: [...car_brand.node.carmodelnameSet.edges, {node}],
                                    car_object: car_update
                                })
                            }
                        })
                    }
                } else {
                    if (before_change !== value) {
                        car_update.carModel = "";
                        car_update.carBrand = "";
                        this.setState({
                            car_model_name_list: [{node}],
                            car_object: car_update,
                            car_model_select: ""
                        })
                    }
                }
            }
            if (name === 'car_model_select') {
                if (before_change !== value) {
                    if (value === 'ระบุเอง') {
                        car_update.carModel = "";
                    } else {
                        car_update.carModel = value;
                    }
                    this.setState({car_object: car_update})
                }
            }
            this.setState({[name]: value})
        }
    }

    onSubmit = async (e) => {

        e.preventDefault();
        this.setState({loading: true});
        let carObject = _.cloneDeep(this.state.car_object)
        carObject['startDate'] = format(new Date(this.state.car_object.startDate) , 'YYYY-MM-DD')
        carObject['carType'] = _.lowerCase(this.state.car_object.carType)

        let imageList = []
        for (const image of this.state.upload_image_list) {
            await this.callUpload('', image).then((data) => {
                imageList.push({carImageID :'' , description: '', image :  data.key.replace("silverman-storage/public/", ""), status : '' });
            });
        }
        if(carObject.carImage?.edges.length > 0){
            for (const image of carObject.carImage?.edges){
                if(image.node.status !== ''){
                    imageList.push({carImageID : image.node.id  , description: '', image :'', status : image.node.status })
                }
            }
        }
        
        carObject['carImage'] = imageList
        let variables = {
            input: {
                carObject : JSON.stringify(carObject),
                residential: this.props.id
            }
        };

        commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response, errors) => {
                this.setState({loading: false});
                if(response.createAndUpdateCar.message === "SUCCESS"){
                   Swal.fire(i18n.t("venhicle:Save successfully!"), '', 'success').then(() => {
                          this.setState({redirectToList: true});
                        });
                }else if(response.createAndUpdateCar.message === "Duplicate"){
                   Swal.fire(i18n.t("venhicle:Save unsuccessfully!"), 'ทะเบียนรถซ้ำ', 'warning');
                }else{
                   Swal.fire(i18n.t("venhicle:Save unsuccessfully!"), '', 'warning');
                }
            },
            onError: error => {
                this.setState({loading: false,});
                alert('server error')
            }
        });

    }

    render() {

        if (this.state.redirectToList) {
            return <Redirect
                to={{
                    pathname: "/register/residential/detail/vehicle-detail/" + this.props.id
                }}/>
        }
        return (
            <div className="content-inner">
                <form action="" onSubmit={this.onSubmit} id="form-add-car">
                <div className="row mb-6">
                    <div className="col-md-6">
                        <div className="card p-4 fade-up">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col ">
                                        <div className='line-bottom'>
                                            <h4 className="mb-3">
                                                <Translation>
                                                    {
                                                        t => <span>{t('register:add_new_vehicle')} 
                                                        <div className="tooltipImg">
                                                            <img src={process.env.PUBLIC_URL + '/images/icons/Tooltips.png'} 
                                                                    alt="question" className="question ml-1 mr-1 pb-2" style={{width:'12px'}}/>
                                                            <span className="tooltiptext" style={{width:'200px',height:'50px'}}>
                                                             
                                                               {t('venhicle:requrie')} 
                                                            </span>
                                                        </div>
                                                        </span>
                                                    }
                                                </Translation>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                    <div className="row mt-6">
                                        <div className="col-md-6">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input"
                                                       id="car_radio" name="car"
                                                       checked={this.state.type_car}
                                                       data-key="car_object"
                                                       onChange={this.handleChangeInput}/>
                                                <Translation>
                                                    {t=><label className="custom-control-label"
                                                           htmlFor="car_radio">{t("venhicle:Car")}</label>}
                                                </Translation>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" className="custom-control-input"
                                                       id="motorcycle_radio" name="motorcycle"
                                                       checked={!this.state.type_car}
                                                       data-key="car_object"
                                                       onChange={this.handleChangeInput}/>
                                                <Translation>
                                                    {t=><label className="custom-control-label"
                                                           htmlFor="motorcycle_radio">{t("venhicle:Motorcycle")}</label>}
                                                </Translation>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-6">
                                            <Translation>
                                                {t => <h6>{t("venhicle:Registration No.")} <span className='text-danger'>*</span></h6>}
                                            </Translation>
                                            <input type="text" className="form-control"
                                                name="licencePlate"
                                                value={this.state.car_object.licencePlate}
                                                data-key="car_object"
                                                require
                                                onChange={this.handleChangeInput} required />
                                        </div>

                                        <div className="col-6">
                                            <Translation>{t => <h6>{t("venhicle:Province")}<span className='text-danger'>*</span></h6>}</Translation>
                                            <SearchSelect onChange={this.handleChangeInput}
                                                data-key="car_object"
                                                value={this.state.car_object.province}
                                                name="province" placeholder="รายการ"
                                                queryObject={this.state.province_list}
                                                keyOfValue={"PROVINCE_NAME"} require={true}
                                                keyOfLabel={"PROVINCE_NAME"} />
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Brand")}<span className='text-danger'>*</span></h6>}</Translation>
                                            <SearchSelect onChange={this.handleChangeInput}
                                                          value={this.state.brand_select}
                                                          name="brand_select" placeholder="รายการ"
                                                          queryObject={this.state.car_brand_list}
                                                          keyOfValue="brandName" require={true}
                                                          keyOfLabel="brandName"/>
                                        </div>

                                    {this.state.brand_select === 'ระบุเอง' &&
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Specify brand")}<span className='text-danger'>*</span></h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="carBrand"
                                                   data-key="car_object"
                                                   value={this.state.car_object.carBrand || ""}
                                                   onChange={this.handleChangeInput}
                                                   required/>
                                        </div>
                                    }
                                    </div>


                                    <div className="row mt-4">
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Model")}<span className='text-danger'>*</span></h6>}</Translation>
                                            <SearchSelect onChange={this.handleChangeInput}
                                                          value={this.state.car_model_select}
                                                          name="car_model_select" placeholder="รายการ"
                                                          queryObject={this.state.car_model_name_list}
                                                          keyOfValue="carModelName" require={true}
                                                          keyOfLabel="carModelName"/>
                                        </div>

                                    {this.state.car_model_select === 'ระบุเอง' &&
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Specfy model")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="carModel"
                                                   data-key="car_object"
                                                   value={this.state.car_object.carModel || ""}
                                                   onChange={this.handleChangeInput}
                                                   required/>
                                        </div>
                                    }
                                    </div>


                                    <div className="row mt-4">
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Year")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="carYear"
                                                   data-key="car_object"
                                                   value={this.state.car_object.carYear}
                                                   onChange={this.handleChangeInput}/>
                                        </div>
                                        <div className="col">
                                            <Translation>{t => <h6>{t("venhicle:Number of seats")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                name="carSize"
                                                data-key="car_object"
                                                value={this.state.car_object.carSize}
                                                onChange={this.handleChangeInput} 
                                                />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Additional characteristics (such as color, defect)")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="carDescription"
                                                   data-key="car_object"
                                                   value={this.state.car_object.carDescription}
                                                   onChange={this.handleChangeInput}/>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col'>
                                             {/* <div className="card-body"> */}
                                             <Translation>{t=><div><h6>{t("venhicle:Attach vehicle picture")}</h6></div>}</Translation>
                                            {/* </div> */}
                                        {/* <img width="300px" height="80px"
                                            src={this.state.news.previewImage}
                                            alt="news"
                                            className="img-fluid card-img"
                                        /> card-columns */ }
                                    <div className="card-columns">
                                        {this.state.car_object.carImage?.edges && this.state.car_object.carImage?.edges.map(
                                            (news_image, index) => {
                                                if((news_image.node.status && news_image.node.status !== 'delete') || !news_image.node.status ){
                                                    return(
                                                        <NewsImageRender
                                                            key={index}
                                                            src={news_image.node.image}
                                                            onClickDelete={() =>
                                                                // !this.state.status &&
                                                                this.deleteNewsImage(
                                                                    news_image.node.id,
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    )
                                                }else{
                                                    return <></>
                                                }
                                            }
                                        )}
                                        {this.state.upload_image_list.map(
                                            (news_image, index) => (
                                                <NewsImageRender
                                                    key={index}
                                                    src={URL.createObjectURL(news_image)}
                                                    onClickDelete={() =>
                                                        // !this.state.status &&
                                                        this.deleteNewsImage(false, index)
                                                    }
                                                />
                                            )
                                        )}
                                        {/* {!this.state.status && ( */}
                                            <div className="upload-file">
                                                <div>
                                                    <label
                                                        htmlFor="uploadNewsImages"
                                                        className="create news-button-image btn btn-light mt-2"
                                                    >
                                                        <img
                                                            src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/plus-icon.png"
                                                            } style={{position:'relative',bottom:'-25px'}}
                                                            alt="plus-icon"
                                                            className="img-fluid plus news-image"
                                                        />
                                                    </label>
                                                </div>
                                                <div className="upload-input">
                                                    <UploadImageInput
                                                        id="uploadNewsImages"
                                                        setField={
                                                            "upload_image_list[" +
                                                            this.state.upload_image_list.length +
                                                            "]"
                                                        }
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        {/* )} */}
                                    </div>
                                                </div>
                                    </div>
                                    

                                    {/*{this.state.car_object.chargedFee &&*/}
                                    {/*<div className="row mt-2 fade-up">*/}
                                    {/*    <div className="col-8">*/}
                                    {/*        <div className="input-group">*/}
                                    {/*            <input type="number" step="0.01" className="form-control"*/}
                                    {/*                   name="feeRatePerMonth" value={this.state.car_object.feeRatePerMonth}*/}
                                    {/*                   onChange={this.handleChangeInput}*/}
                                    {/*                   data-key="car_object"*/}
                                    {/*                   required/>*/}
                                    {/*            <div className="input-group-append">*/}
                                    {/*                        <span*/}
                                    {/*                            className="input-group-text input-date">บาท/เดือน</span>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*}*/}


                                {/* <div className="row mt-4">
                                    <div className="col">

                                        <Translation>
                                            {t=>
                                            <button type="submit" form="form-add-car"
                                                    className="btn btn-primary bt-style form-update"
                                                    disabled={this.state.loading}>
                                                {this.state.loading && <span
                                                    className="spinner-border spinner-border-sm align-middle mr-2"/>}
                                                {t("venhicle:Save")}
                                            </button>}
                                        </Translation>
                                    </div>
                                    <div className="col">
                                        <Link
                                            to={{
                                                pathname: "/register/residential/detail/vehicle-detail/" + this.props.id}}>

                                            <Translation>
                                                {t=>
                                                <button type="button"
                                                        className="btn btn-secondary bt-style form-update">
                                                    {t("venhicle:Cancel")}
                                                </button>}
                                            </Translation>
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                    <div className="col-6">
                        <div className="card p-4 fade-up">
                            <div className="row mt-4">
                                    <div className="col">
                                     <Translation>{t=><h6>{t("venhicle:Parking lot sticker")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="carSticker"
                                                   data-key="car_object"
                                                   value={this.state.car_object.carSticker}
                                                   onChange={this.handleChangeInput}/>
                                     </div>
                            </div>
                            <div className='line-bottom'>
                                 <h4 className="mb-3 mt-6" >{i18next.t("venhicle:Position parking")}</h4>
                            </div>
                            <div className="row mt-4">
                                    <div className="col">
                                        <Translation>{t=><h6>{t("venhicle:Floor")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                   name="floor"
                                                   data-key="car_object"
                                                   value={this.state.car_object.floor}
                                                   onChange={this.handleChangeInput}/>
                                        </div>
                                        <div className="col">
                                        <Translation>{t=><h6>{t("venhicle:Space")}</h6>}</Translation>
                                            <input type="text" className="form-control"
                                                name="space"
                                                data-key="car_object"
                                                value={this.state.car_object.space}
                                                onChange={this.handleChangeInput} 
                                                />
                                        </div>
                                    </div>
                            <div className="row mt-4">
                                    <div className="col">
                                            <Translation>{t=><h6>{t("venhicle:Start date")}<span className='text-danger'>*</span></h6>}</Translation>
                                            <div className="input-group">
                                                <DatePickerAdapter
                                                    name="startDate" className="form-control"
                                                    selected={this.state.car_object.startDate}
                                                    onChange={this.handleChangeInput}
                                                    data-key="car_object"
                                                    required={true}
                                                    disable={this.state.car_object.id ? "disabled" : false}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-4">
                                        <div className="col">
                                            <div className="custom-control custom-checkbox mr-sm-2">
                                                <input type="checkbox" className="custom-control-input"
                                                       name="chargedFee"
                                                       checked={this.state.car_object.chargedFee}
                                                       data-key="car_object"
                                                       id="customControlAutosizing" onChange={this.handleChangeInput}/>
                                                <label className="custom-control-label"
                                                       htmlFor="customControlAutosizing">
                                                    <Translation>{t=><strong>{t("venhicle:Charge for parking")}<span className='text-danger ml-1'>*</span></strong>}</Translation>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                       

                        <div className="row mt-4">
                           
                            <div className="col">
                                <Link
                                    to={{
                                        pathname: "/register/residential/detail/vehicle-detail/" + this.props.id
                                    }}>

                                    <Translation>
                                        {t =>
                                            <button type="button"
                                                className="btn btn-secondary bt-style form-update">
                                                {t("venhicle:Cancel")}
                                            </button>}
                                    </Translation>
                                </Link>
                            </div>
                            <div className="col">
                                <Translation>
                                    {t =>
                                        <button type="submit" form="form-add-car"
                                            className="btn btn-primary bt-style form-update"
                                            disabled={this.state.loading}>
                                            {this.state.loading && <span
                                                className="spinner-border spinner-border-sm align-middle mr-2" />}
                                            {t("venhicle:Save")}
                                        </button>}
                                </Translation>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            </div>
        );
    }
}

export default CreateUpdateVehicle;
