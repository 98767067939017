import React, { Component } from 'react'
import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-runtime";
import environment from '../../env/environment';
import SearchSelect from "../../libs/searchSelect"
import _ from "lodash"

const query = graphql`
  query selectCreditorQuery{
    contactViewer {
      allContact(isActive: true){
        edges{
          node{
            id
            refNumber
            name
            firstName
            lastName
          }
        }
      }
    }
  }
` 

const customStyles = {
  control: (style, { isDisabled }) => ({
    ...style,
    height: 48,
    minHeight: 48,
    backgroundColor: isDisabled ? '#E9ECEF' : '#FFFFFF',
  }),
  indicatorSeparator: (style) => ({
    ...style,
    display: 'none'
  }),
};

export default class selectCreditor extends Component {

  constructor(props){
    super(props)

    this.state = {
      selectData: [],
    }
  }

  componentWillMount(){
    this.getData()
  }

  getData = () => {
    fetchQuery(environment, query).then((response) => {
      let textInput = []

      _.map(response.contactViewer.allContact.edges, (item) => {
        if(item.node.refNumber.substring(0, 1) === "S"){
          textInput.push(item)
        }
      })
      this.setState({selectData: textInput})
    })
  }

  render() {
    return (
      <SearchSelect 
        onChange={this.props.handleChangeInput} 
        disabled={this.props.disibleInput}
        value={this.props.creditorId}
        name={`purchaseRequisition.creditorId`} placeholder="รายการ"
        queryObject={this.state.selectData}
        keyOfValue="id" require={true}
        keyOfLabel="refNumber:name"
        style={customStyles}
        />
    )
  }
}
