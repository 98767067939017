import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Sidebar from '../../components/sidebar'
import Header from "../../components/header/index"
import { Link } from 'react-router-dom'
import "./styles/commonArea.scss"
import { Translation } from 'react-i18next'
import i18next from 'i18next'

export default class addUpdateServiceSetting extends Component {

    render() {
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <Translation>
                        {t=> 
                        <React.Fragment>
                            <div className="container-fluid gray" style={{ width: "100%", height: "48px" }}/>
                    <div className="container-fluid box">
                        <div className="row justify-content-between">
                            <div className="col">
                                <h3>
                                    <Link to="/contact/global-area-singha/services/all">
                                        <img
                                            src={process.env.PUBLIC_URL +
                                                "/images/icons/chevron-back.png"}
                                            alt="back"
                                            className="back"
                                            />
                                    </Link>
                                   { this.props.match.params.status === "create" ?  "เพิ่มบริการ" : this.props.match.params.id}
                                   
                                </h3>
                            </div>
                        </div>

                        <div className="frameMargin">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6>รายละเอียดบริการ</h6>
                                        <form className="form-inline">
                                            <label className="mr-2">สถานะ:</label>
                                            <select className="form-control">
                                                <option value="เปิดให้บริการ">เปิดให้บริการ</option>
                                                <option value="ยังไม่เปิดให้บริการ">ยังไม่เปิดให้บริการ</option>
                                            </select>
                                        </form>
                                    </div>
                                    <div className="my-2" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/> 

                                    <div>
                                        <p>เลขที่บริการ</p>
                                        <input type="text" className="form-control col-8" disabled/>
                                    </div>

                                    <div className="mt-2">
                                        <p>ชื่อบริการ</p>
                                        <input type="text" className="form-control col-8" />
                                    </div>

                                    <div className="mt-2">
                                        <p>การผูกพื้นที่ส่วนกลาง</p>
                                        <div className="d-flex">
                                            <input type="radio" className="mt-1 mr-2"/>
                                            <p>ไม่ผูกกับพื้นที่ส่วนกลาง</p>
                                        </div>
                                        <div className="d-flex">
                                            <input type="radio"  className="mt-1 mr-2"/>
                                            <p >ผูกกับพื้นที่ส่วนกลาง</p>
                                        </div>
                                        {true &&
                                            <div className="col-12">
                                                <p>เลือกพื้นที่ส่วนกลางที่ต้องการผูกบริการ</p>
                                                <select className="form-control col-4">
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </select>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h6>รายละเอียดค่าบริการ</h6>
                                    <div className="mb-2 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div className="row">
                                        <div className="col-6">
                                            <p>ค่าบริการต่อหน่วย (บาท)</p>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div className="col-6">
                                            <p>หน่วย</p>
                                            <select className="form-control col-lg-4 col-6">
                                                <option value="ชั่วโมง">ชั่วโมง</option>
                                                <option value="2">2</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h6>การผูกผังบัญชี</h6>
                                    <div className="mb-2 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div className="row">
                                        <div className="col-6">
                                            <p>{t("settingAll:Income account")}</p>
                                            <select className="form-control">
                                                <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                            </select>
                                        </div>
                                        <div className="col-6">
                                            <p>{i18next.t("settingAll:Account receivables")}</p>
                                            <select className="form-control">
                                                <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                                <option value="4100-06">4100-06 รายรับ - ค่าคีย์การ์ด</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h6>รายละเอียดบัญชีการคืนเงิน หรือยกเลิกรายการ</h6>
                                    <div className="mb-2 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div>
                                        <p>{t("settingAll:Income account")}</p>
                                        <input type="text" className="form-control col-6" disabled/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary">บันทึก</button>
                                    <button type="button" className="btn btn-outline-primary ml-3">ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                        </React.Fragment>
                        
                        
                        }
                    </Translation>
                    
                </WrapperContent>
            </Wrapper>
        )
    }
}
