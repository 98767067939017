/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type balanceSheetAccountStatementsQueryVariables = {|
  codeList?: ?$ReadOnlyArray<?string>,
  startDateFirst?: ?any,
  endDateFirst?: ?any,
  startDateSecond?: ?any,
  endDateSecond?: ?any,
|};
export type balanceSheetAccountStatementsQueryResponse = {|
  +allAccountProjectManager: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tableAccount: ?any,
      |}
    |}>
  |},
  +chartOfAccountBalanceSheetAccount: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +chartOfAccountCode: string,
        +col1Debit: ?number,
        +col1Credit: ?number,
        +col2Debit: ?number,
        +col2Credit: ?number,
        +totalHistoricalDebit: ?number,
        +totalHistoricalCredit: ?number,
      |}
    |}>
  |},
  +Income: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +chartOfAccountCode: string,
        +name: string,
        +yearFirst: ?number,
        +yearSecond: ?number,
        +totalHistoricalDiffFirst: ?number,
        +totalHistoricalDiffSecond: ?number,
      |}
    |}>
  |},
  +Expense: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +chartOfAccountCode: string,
        +name: string,
        +yearFirst: ?number,
        +yearSecond: ?number,
        +totalHistoricalDiffFirst: ?number,
        +totalHistoricalDiffSecond: ?number,
      |}
    |}>
  |},
|};
export type balanceSheetAccountStatementsQuery = {|
  variables: balanceSheetAccountStatementsQueryVariables,
  response: balanceSheetAccountStatementsQueryResponse,
|};
*/


/*
query balanceSheetAccountStatementsQuery(
  $codeList: [String]
  $startDateFirst: DateTime
  $endDateFirst: DateTime
  $startDateSecond: DateTime
  $endDateSecond: DateTime
) {
  allAccountProjectManager {
    edges {
      node {
        id
        tableAccount
      }
    }
  }
  chartOfAccountBalanceSheetAccount(codeList: $codeList, startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
    edges {
      node {
        id
        name
        chartOfAccountCode
        col1Debit
        col1Credit
        col2Debit
        col2Credit
        totalHistoricalDebit
        totalHistoricalCredit
      }
    }
  }
  Income: chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "4", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
    edges {
      node {
        chartOfAccountCode
        name
        yearFirst
        yearSecond
        totalHistoricalDiffFirst
        totalHistoricalDiffSecond
        id
      }
    }
  }
  Expense: chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: "5", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {
    edges {
      node {
        chartOfAccountCode
        name
        yearFirst
        yearSecond
        totalHistoricalDiffFirst
        totalHistoricalDiffSecond
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "codeList"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateFirst"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDateSecond"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateFirst"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDateSecond"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountProjectManagerNodeConnection",
  "kind": "LinkedField",
  "name": "allAccountProjectManager",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountProjectManagerNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountProjectManagerNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tableAccount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "endDateFirst",
  "variableName": "endDateFirst"
},
v8 = {
  "kind": "Variable",
  "name": "endDateSecond",
  "variableName": "endDateSecond"
},
v9 = {
  "kind": "Variable",
  "name": "startDateFirst",
  "variableName": "startDateFirst"
},
v10 = {
  "kind": "Variable",
  "name": "startDateSecond",
  "variableName": "startDateSecond"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "chartOfAccountCode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "codeList",
      "variableName": "codeList"
    },
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ChartOfAccountNodeConnection",
  "kind": "LinkedField",
  "name": "chartOfAccountBalanceSheetAccount",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChartOfAccountNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChartOfAccountNode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col1Debit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col1Credit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col2Debit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "col2Credit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalDebit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalHistoricalCredit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "chartOfAccountCode_Istartswith",
    "value": "4"
  },
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearFirst",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearSecond",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalDiffFirst",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalHistoricalDiffSecond",
  "storageKey": null
},
v19 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ChartOfAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v11/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v20 = [
  {
    "kind": "Literal",
    "name": "chartOfAccountCode_Istartswith",
    "value": "5"
  },
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v21 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ChartOfAccountNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartOfAccountNode",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v11/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "balanceSheetAccountStatementsQuery",
    "selections": [
      (v6/*: any*/),
      (v13/*: any*/),
      {
        "alias": "Income",
        "args": (v14/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": (v19/*: any*/),
        "storageKey": null
      },
      {
        "alias": "Expense",
        "args": (v20/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": (v19/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "balanceSheetAccountStatementsQuery",
    "selections": [
      (v6/*: any*/),
      (v13/*: any*/),
      {
        "alias": "Income",
        "args": (v14/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": (v21/*: any*/),
        "storageKey": null
      },
      {
        "alias": "Expense",
        "args": (v20/*: any*/),
        "concreteType": "ChartOfAccountNodeConnection",
        "kind": "LinkedField",
        "name": "chartOfAccountSummaryIncomeExpense",
        "plural": false,
        "selections": (v21/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25def555f9b30849d2f1705c877453d5",
    "id": null,
    "metadata": {},
    "name": "balanceSheetAccountStatementsQuery",
    "operationKind": "query",
    "text": "query balanceSheetAccountStatementsQuery(\n  $codeList: [String]\n  $startDateFirst: DateTime\n  $endDateFirst: DateTime\n  $startDateSecond: DateTime\n  $endDateSecond: DateTime\n) {\n  allAccountProjectManager {\n    edges {\n      node {\n        id\n        tableAccount\n      }\n    }\n  }\n  chartOfAccountBalanceSheetAccount(codeList: $codeList, startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {\n    edges {\n      node {\n        id\n        name\n        chartOfAccountCode\n        col1Debit\n        col1Credit\n        col2Debit\n        col2Credit\n        totalHistoricalDebit\n        totalHistoricalCredit\n      }\n    }\n  }\n  Income: chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: \"4\", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {\n    edges {\n      node {\n        chartOfAccountCode\n        name\n        yearFirst\n        yearSecond\n        totalHistoricalDiffFirst\n        totalHistoricalDiffSecond\n        id\n      }\n    }\n  }\n  Expense: chartOfAccountSummaryIncomeExpense(chartOfAccountCode_Istartswith: \"5\", startDateFirst: $startDateFirst, endDateFirst: $endDateFirst, startDateSecond: $startDateSecond, endDateSecond: $endDateSecond) {\n    edges {\n      node {\n        chartOfAccountCode\n        name\n        yearFirst\n        yearSecond\n        totalHistoricalDiffFirst\n        totalHistoricalDiffSecond\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '261feba1a80a9eabf226a58f15fdcd02';

module.exports = node;
