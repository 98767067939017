import React, {Component} from 'react';
import {QueryRenderer} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import {Redirect} from 'react-router'
import ReceiptDepositCreateForm from "./receiptDepositCreateForm";
import Loading from '../../../libs/loading';

const receiptDepositContactCreateForm = graphql`
    query receiptDepositContactCreateFormQuery($id: ID!, $chartOfAccountCode: String) {
        contact(id: $id) {
            id
            refNumber
            name
            firstName
            lastName

            registeredAddress
            registeredCountry
            registeredProvince
            registeredDistrict
            registeredCity
            registeredPostalCode
            typeOfContact
            typeOfSupplier

            ownerName
            mailingAddress
            mailingDistrict
            mailingCity
            mailingProvince
            mailingPostalCode
            
            renterName
            renterAddress
            renterDistrict
            renterCity
            renterProvince
            renterPostalCode

            agentName
            agentAddress
            agentDistrict
            agentCity
            agentProvince
            agentPostalCode
        }
        viewer {
            allChartOfAccount(chartOfAccountCode_Istartswith: $chartOfAccountCode) {
                edges {
                    node {
                        id
                        name
                        chartOfAccountCode
                    }
                }
            }
        }
    }
`;

class ReceiptDepositContactCreateForm extends Component {

    render() {
        if (this.props.customerList.length > 0) {
            return (
                <QueryRenderer
                    environment={environment}
                    query={receiptDepositContactCreateForm}
                    variables={{id: this.props.customerList[0], chartOfAccountCode: this.props.chartOfAccountCode}}
                    cacheConfig={{use_cache: false}}
                    render={({error, props}) => {
                        if (props) {
                            return <ReceiptDepositCreateForm customerList={this.props.customerList}
                                                             contact={props.contact} receiptDepositQuery={props}
                                                             query={receiptDepositContactCreateForm}
                                                             category={this.props.category}
                                                             headerWord={this.props.headerWord}
                                                             backLink={this.props.backLink}
                                                             isHasAgentRole={true}
                            />
                        } else {
                            return <Loading/>
                        }
                    }}
                />
            )
        }
        return <Redirect to={this.props.backLink}/>;
    }
}

export default ReceiptDepositContactCreateForm;
