import { graphql } from "babel-plugin-relay/macro";

const CrossBillingPaymentGet = graphql`
    query crossBillingPaymentGetQuery($id: ID){
        invoiceViewer {
            id
            allInvoiceByStatus(id:$id) {
                totalCount
                edges {
                    node {
                        id
                        docNumber
                        taxType
                        status
                        transaction {
                            edges {
                                node {
                                    id
                                    total
                                    paid
                                    discount
                                    vat
                                    whtRatePercent
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

// export default queryAllInvoices;
export default CrossBillingPaymentGet;