import React, { Component } from 'react';
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../env/environment";
import { fetchQuery } from 'react-relay';
import WrapperReceiveSirirajGenInvoice from './wrapperReceiveSirirajGenInvoice';

import _ from "lodash";
import Swal from 'sweetalert2';
import ExportReceiveDetailPDF from '../../../accounting/income/receive/receive_detail/receiveDetailPDF';
import i18next from 'i18next';

const query = graphql`
    query wrapperReceiveSirirajDataQuery($id:ID!){
    batchReceive(id:$id){
                receiveBatch{
                    edges{
                        node{
                            receive{
                                id
                        docNumber
                        firstName lastName
                        payGroup
                        contact {
                            id
                            name
                            refNumber
                            firstName
                            lastName
                            typeOfContact
                        }
                        issuedDate
                        added
                        total
                        status
                        voidRemark
                        creator
                        updated
                        receiveTransaction{
                            edges{
                                node{
                                    id
                                    amount
                                    receiver
                                    transaction{
                                        id
                                        productAndService {
                                            id
                                        }
                                        description
                                        total
                                        paid
                                        invoice{
                                            firstName
                                            lastName
                                            payGroup
                                        }
                                    }
                                    }
                                
                                    }
                                }
                            }
                        }
                    }
                }
            }
}`

class WrapperReceiveSiriraj
    extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rtIdList: [],
            rtDocList: [],
        }
    }

    componentDidMount() {
        this.fecthAllData()
    }

    fecthAllData() {
        let rtIdList = [];
        let rtDocList = [];

        Swal.fire({
            imageUrl: '/images/iconAlerts/loading.gif',
            imageWidth: 150,
            showConfirmButton: false,
            titleText: i18next.t("Allaction:Preparing information"),
            timer: 9999999999,
            onOpen: () => { Swal.isLoading() },
            allowOutsideClick: () => !Swal.isLoading(),
            onRender: () => {
                fetchQuery(environment, query, {
                    id: this.props.match.params.id
                }).then(data => {
                    data.batchReceive.receiveBatch.edges.map((receiveBatch) => {
                        rtIdList.push(receiveBatch.node.receive.id)
                        rtDocList.push(receiveBatch.node.receive.docNumber)
                    })

                    this.setState({
                        rtIdList: rtIdList,
                        rtDocList: rtDocList,
                    })
                    Swal.stopTimer()
                    Swal.close()
                })
            }
        })


    }

    render() {


        return (
            this.state.rtIdList.length > 0 &&
            <ExportReceiveDetailPDF
                fromEmail={true}
                checkList={{ checkList: this.state.rtIdList, checkListdoc: this.state.rtDocList }}
            />

            // <WrapperReceiveSirirajGenInvoice
            //     rtIdList={this.state.rtIdList}
            //     rtDocList={this.state.rtDocList}
            // />
        );
    }
}

export default WrapperReceiveSiriraj
    ;