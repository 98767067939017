import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import WrapperContent from '../../components/wrapper/wrapperContent'
import Header from "../../components/header/index"
import Sidebar from '../../components/sidebar'
import { Link } from 'react-router-dom'
import "./styles/commonArea.scss"

export default class accountingDetails extends Component {
    render() {
        return (
            <Wrapper>
                 <Header/>
                <Sidebar/>
                <WrapperContent>
                    <div className="container-fluid gray" style={{ width: "100%", height: "48px" }}/>
                    <div className="container-fluid box">
                        <div className="row">
                            <div className="col">
                                <h3>
                                    <Link to="/contact/global-area/accounting/all">
                                        <img
                                            src={process.env.PUBLIC_URL +
                                                "/images/icons/chevron-back.png"}
                                            alt="back"
                                            className="back"
                                            />
                                    </Link>
                                    {this.props.match.params.id}
                                </h3>
                            </div>
                        </div>

                        <div className="frameMargin">
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6>รายละเอียดการจองบริการส่วนกลาง</h6>
                                        <div className="d-flex">
                                            <p className="mr-2">สถานะ:</p>
                                            <h6 style={{color: "#FFBD2D"}}>รอรับชำระ</h6>
                                        </div>
                                    </div>
                                    <div className="my-2" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/> 

                                    <div className="my-4">
                                        <h6>เลขที่การจอง: BK-202110100001</h6>
                                    </div>

                                    <div>
                                        <p>วันที่ทำรายการ</p>
                                        <input type="text" className="form-control col-6" disabled/>
                                    </div>

                                    <div className="mt-2">
                                        <p>พื้นที่ส่วนกลาง</p>
                                        <input type="text" className="form-control col-9" disabled/>
                                    </div>

                                    <div className="mt-2 row">
                                        <div className="col-3 pr-0">
                                            <p>วันที่จะใช้สิทธิ์</p>
                                            <input type="text" className="form-control" disabled/>
                                        </div>

                                        <div className="col-3 pr-0">
                                            <p>เวลาที่จะใช้สิทธิ์</p>
                                            <input type="text" className="form-control" disabled/>
                                        </div>
                                    </div>

                                    <div className="mt-2 row">
                                        <div className="col-3 pr-0">
                                            <p>ผู้เข้าร่วมใช้งาน (คน)</p>
                                            <input type="text" className="form-control" disabled/>
                                        </div>
                                    </div>

                                    <div className="mt-2">
                                        <p>หมายเหตุ</p>
                                        <textarea style={{maxHeight: "100px", height: "100px"}} className="form-control col-9" disabled/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h6>ผู้ทำการจอง</h6>
                                    <div className="mb-2 mt-3" style={{width: "100%", height: "1px", backgroundColor: "#E2E2E2"}}/>

                                    <div className="mt-2">
                                        <p>พื้นที่ส่วนกลาง</p>
                                        <input type="text" className="form-control col-9" disabled/>
                                    </div>

                                    <div className="mt-2">
                                        <p>เลขที่ยูนิต</p>
                                        <input type="text" className="form-control col-9" disabled/>
                                    </div>

                                    <div className="mt-2">
                                        <p>เบอร์โทรศัพท์ที่สามารถติดต่อได้</p>
                                        <input type="text" className="form-control col-9" disabled/>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12">
                                    <h6>สรุปค่าบริการ</h6>
                                    <div className="mb-2 mt-3"/>

                                    <div style={{background: "#E6F2FE", width: "100%", height: "auto", borderRadius: "2px"}}>
                                        <div style={{padding: "16px 16px"}}>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="title">ค่าบริการส่วนกลาง</div>
                                                <div className="title-detail">400 บาท</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="title">ค่าบริการส่วนกลาง</div>
                                                <div className="title-detail">400 บาท</div>
                                            </div>
                                            <div className="d-flex justify-content-between mb-2">
                                                <div className="title">ค่าบริการส่วนกลาง</div>
                                                <div className="title-detail">400 บาท</div>
                                            </div>
                                            <div className="my-3" style={{width: "100%", height: "1px", backgroundColor: "#000"}}/>
                                            
                                            <div className="d-flex justify-content-between">
                                                <div className="title">รวมทั้งหมด</div>
                                                <div className="title-detail" style={{fontSize: "18px", fontWeight: "bold"}}>482 บาท</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col d-flex justify-content-end">
                                    <button type="button" className="btn btn-primary">ถัดไป</button>
                                    <button type="button" className="btn btn-outline-primary ml-3">ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </WrapperContent>
            </Wrapper>
        )
    }
}
