import React , {Component}  from 'react';
import {Helmet} from "react-helmet";
import environment from "../../../env/environment";
import {graphql} from "babel-plugin-relay/macro";
import {QueryRenderer} from 'react-relay';
import ChequeTransactionPrint from './chequeTransactionPrint';
import ChequeTransactionPrintRight from './chequeTransactionPrintRight';
import ChequeTransactionPrintCenter from './chequeTransactionPrintCenter';
import ChequeHolderName from './chequeHolderName';
import Loading from "../../../libs/loading"
import _ from "lodash"
import './style/chequePrint.scss'
import DatePickerAdapter from '../../../libs/datePickerAdapter';

const query = graphql`
    query wrapperChequeTransactionPrintQuery($id_In: [String]){
        chequeTransactionViewer{
        allChequeTransaction(id_In:$id_In){
            totalCount
            edges{
            node{
                id
                date
                chequeDate
                payName
                description
                price
                chequeNumber
                status
                refNumber
                docNumber
                bankAccount{
                docNumber
                branch
                accountNumber
                accountName
                bankName     
                }
                depositDate          
            }
            }
        }
        }
    }
`;

class WrapperChequeTransactionPrint extends Component {
    constructor(props){
        super(props);
        this.state = {
            isACPayee : true,
            isCrossOut : true,
            data : null,
            checkList: props.location.state?.checkList ? props.location.state.checkList : null ,
            selectSidePrint: window.localStorage.getItem("selectSideCheque") || "center", 
            sideLeftRight: {
                left: "",
                right: ""
            },
            disibleSideLeftRight: false,
            inputLeftRight: "",
            inputSideTop: window.localStorage.getItem("chequeInputSideTop") || "",
            setSideTop: window.localStorage.getItem("chequeInputSideTop") || "",
            showFormChangeName: "",
            setFirstName: "",
            setLastName: "",
            payerName: "",
            showDate: true,
            showDatePicker: false,
            selectedDate: "",
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.print = this.print.bind(this)
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handlePayerNameChange = this.handlePayerNameChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this)
    }

    print() {
        window.print();
    }
    componentDidMount(){
        if(this.state.selectSidePrint !== "center"){
            if(this.state.selectSidePrint  === "left"){
                this.setState({
                    sideLeftRight: {
                        left: window.localStorage.getItem("sideLeft") || "0",
                        right: "0"
                    },
                    inputLeftRight: window.localStorage.getItem("sideLeft") === "0" 
                        ? "" 
                        : window.localStorage.getItem("sideLeft") || "",
                })
            }else if(this.state.selectSidePrint  === "right"){
                this.setState({
                    sideLeftRight: {
                        left: window.localStorage.getItem("sideRight") || "0",
                        right: "0"
                    },
                    inputLeftRight: window.localStorage.getItem("sideRight") === "0" 
                        ? "" 
                        : window.localStorage.getItem("sideRight") || "",
                })
            }
            this.setState({disibleSideLeftRight: true}) 
        }
    }

    handleInputChange(e){
        let value = null;
        if(e.target.name === 'isACPayee'){
            value = !this.state.isACPayee
        }else if(e.target.name === 'isCrossOut'){
            value = !this.state.isCrossOut
        }else{
            value = e.target.value
        }
        this.setState({[e.target.name]: value})
    }

    handleSelectSide(e){
        let resultvalue = ""
        if(e.target.value === ""){
            resultvalue = "center"
        }else{
            resultvalue = e.target.value
        }
        window.localStorage.setItem("selectSideCheque", resultvalue)

        if(resultvalue  === "center"){ 
            this.setState({
                sideLeftRight: {
                    left: "0",
                    right: "0"
                },
                disibleSideLeftRight: false
            }) 

        }else{ 
            if(resultvalue  === "left"){
                this.setState({
                    sideLeftRight: {
                        left: window.localStorage.getItem("sideLeft") || "0",
                        right: "0"
                    },
                    inputLeftRight: window.localStorage.getItem("sideLeft") === "0" 
                        ? "" 
                        : window.localStorage.getItem("sideLeft") || "",
                })
            }else if(resultvalue  === "right"){
                this.setState({
                    sideLeftRight: {
                        left: window.localStorage.getItem("sideRight") || "0",
                        right: "0"
                    },
                    inputLeftRight: window.localStorage.getItem("sideRight") === "0" 
                        ? "" 
                        : window.localStorage.getItem("sideRight") || "",
                })
            }
            this.setState({disibleSideLeftRight: true}) 
        }

        this.setState({
            selectSidePrint: resultvalue,
        })
    }

    setMarginValue = (e) => {
        let name =  ""
        let value = e.target.value;
        
        if(value === ""){
            value = "0"
        }

        switch (this.state.selectSidePrint) {
            case "left":

                window.localStorage.setItem("sideLeft", value)
                name = "sideLeftRight.left"
                value = value
                break;
            case "right":

                window.localStorage.setItem("sideRight", value)
                name = "sideLeftRight.left"
                value = value
                break;
        }
        this.setState({inputLeftRight: e.target.value})
        this.setState((prev) => {
            return _.set(_.cloneDeep(prev), name, value)
        })
    }

    handleInputSideTopChange = (event) => {

        let { value, min, max } = event.target;

        let valueSetSideTop = ""
        if(event.target.value === ""){
            valueSetSideTop = "0"
            value = event.target.value
        }else{
            valueSetSideTop =  Math.max(Number(min), Math.min(Number(max), Number(value)))
            value =  Math.max(Number(min), Math.min(Number(max), Number(value)))
        }
        this.setState({setSideTop: valueSetSideTop})
        window.localStorage.setItem("chequeInputSideTop", value)
        this.setState({[event.target.name]: value})
    }
    handleChequeHolderName = ()=> {
        if (this.state.showFormChangeName === "") {
            this.setState({showFormChangeName: "show"})}
        else {
            this.setState({showFormChangeName: ""})}
    }   
    handleHolderFirstNameChange = (value) => {
        this.setState({ setFirstName: value });
      }
    handleHolderLastNameChange = (value) => {
        this.setState({ setLastName: value });
    }
    handleButtonClick() {
        this.setState({ showFormChangeName: "" });
    }
    handlePayerNameChange(name) {
        this.setState({ payerName: name });
    }

    handleDateChange(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleShowDate(showDate) {
        if(showDate){
            this.setState({showDate: false})
        } else {
            this.setState({showDate: true})
        }
    }

    handleshowDatePicker(showDatePicker) {
        if(showDatePicker){
            this.setState({showDatePicker: false})
        } else {
            this.setState({showDatePicker: true})
        } 
    }

    handleDateChange(e) {
        this.setState({ selectedDate: e.target.value }, () => {
        });
      }
    render(){
        return (
            <React.Fragment>
                <Helmet
                    style={[{
                        "cssText": `
                        body {
                            background-color: #ececec;
                        }
                    `
                    }]}>
                    <meta charSet="utf-8"/>
                    <title>Cheque Transaction Print</title>
                    <link href="https://fonts.googleapis.com/css?family=Sarabun&display=swap" rel="stylesheet"/>
                </Helmet>
                <div className="print-top-menu">
                    <div className="logo">
                        <img src="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/etc/logo-header.png" alt="silverman"/>
                        <div className="d-flex justify-content-center mt-3">
                            <div className="form-check m-1">
                                <input className="form-check-input" type="checkbox"
                                       name="isACPayee" id="isACPayee"
                                       onChange={this.handleInputChange}
                                       checked={this.state.isACPayee}
                                       value={this.state.isACPayee}
                                       />
                                <label className="form-check-label" htmlFor="isACPayee">
                                    ขีดคร่อม “A/C Payee Only”
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="checkbox"
                                       name="isCrossOut" id="isCrossOut"
                                       onChange={this.handleInputChange}
                                       checked={this.state.isCrossOut}/>
                                <label className="form-check-label" htmlFor="isCrossOut">
                                    ขีดฆ่า “หรือ ผู้ถือ” ออก
                                </label>
                            </div>
                            <div className="form-check m-1">
                                <input className="form-check-input" type="checkbox"
                                       name="showChequeDate" id="showChequeDate"
                                       onChange={() => this.handleShowDate(this.state.showDate)}
                                       checked={this.state.showDate}
                                    />
                                <label className="form-check-label" htmlFor="showChequeDate">
                                    ใส่วันที่
                                </label>
                            </div>
                            {this.state.showDate &&
                            <React.Fragment>
                                <div className="form-check m-1">
                                    <input className="form-check-input" type="checkbox"
                                       name="showChequeDatePicker" id="showChequeDatePicker"
                                       onChange={() => this.handleshowDatePicker(this.state.showDatePicker)}
                                       checked={this.state.showDatePicker}
                                        />
                                    <label className="form-check-label" htmlFor="showChequeDatePicker">
                                        ใส่วันที่อื่น
                                    </label>
                                </div>
                                {this.state.showDatePicker &&
                                <div>
                                        <div className="float-left mr-1 datePicker">
                                            <DatePickerAdapter 
                                                            onChange={(e)=>this.handleDateChange(e)} className="form-control"
                                                            selected={this.state.selectedDate} name="selected_date"/>
                                        </div>
                                </div>
                                }
                            </React.Fragment>
                            }
                            <div className="d-flex ml-3">
                                <span className="mt-1 mr-1">การออกแบบหน้า</span>
                                <div className="form-group">
                                    <select 
                                        className="form-control" 
                                        id="exampleFormControlSelect1"
                                        value={this.state.selectSidePrint}
                                        onChange={(e) => this.handleSelectSide(e)}
                                        >
                                        <option value="left">ชิดซ้าย</option>
                                        <option value="right">ชิดขวา</option>
                                        <option value="center">กลาง</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.disibleSideLeftRight &&
                                <div className="d-flex ml-3">
                                    <span className="mt-1 mr-1">ระยะขอบซ้าย (cm)</span>
                                    <input
                                        type="number"
                                        name="sideLeftRight"
                                        className="form-control ml-1"
                                        style={{width: "69px"}}
                                        value={this.state.inputLeftRight}
                                        onChange={this.setMarginValue}
                                    />
                                </div>
                            }
                            <div className="d-flex ml-3">
                                <span className="mt-1 mr-1">ระยะขอบบน (cm)</span>
                                <input
                                    type="number"
                                    min="-1" max="10"
                                    name="inputSideTop"
                                    className="form-control ml-1"
                                    style={{width: "69px"}}
                                    value={this.state.inputSideTop}
                                    onChange={this.handleInputSideTopChange}
                                    required
                                    />
                            </div>
                            <div className="ml-3">
                                <button className="btn btn-primary add" onClick={this.handleChequeHolderName}>แก้ไขชื่อ</button>
                            </div>
                        </div>
                    </div>
                    <div className="print" onClick={this.print}>
                        PRINT
                    </div>
                </div>
                <QueryRenderer
                            environment={environment}
                            query={query}
                            variables={{
                                // id: this.props.match.params.id
                                id_In: this.state.checkList
                            }}
                            render={({error, props}) => {
                                if (error) {
                                    return <div>{error.message}</div>;
                                } else if (props) {
                                    return (
                                        <React.Fragment>
                                            {this.state.selectSidePrint === "left" && props.chequeTransactionViewer.allChequeTransaction.edges.map((cheque,index) =>
                                                <ChequeTransactionPrint key={index} cheque = {cheque} isACPayee = {this.state.isACPayee} isCrossOut = {this.state.isCrossOut} 
                                                totalCount = {props.chequeTransactionViewer.allChequeTransaction.totalCount} index = {index} side={this.state.selectSidePrint}
                                                sideLeftRight = {this.state.sideLeftRight} sideTop={this.state.setSideTop} setFirstName={this.state.setFirstName} setLastName={this.state.setLastName} payerName={this.state.payerName}
                                                onPayerNameChange={this.handlePayerNameChange} showDate={this.state.showDate} showDatePicker={this.state.showDatePicker} selectedDate={this.state.selectedDate}/>  
                                            )
                                            }
                                            {this.state.selectSidePrint === "center" && props.chequeTransactionViewer.allChequeTransaction.edges.map((cheque,index) =>
                                                <ChequeTransactionPrintCenter key={index} cheque = {cheque} isACPayee = {this.state.isACPayee} isCrossOut = {this.state.isCrossOut} 
                                                totalCount = {props.chequeTransactionViewer.allChequeTransaction.totalCount} index = {index} side={this.state.selectSidePrint}
                                                sideLeftRight = {this.state.sideLeftRight} sideTop={this.state.setSideTop} setFirstName={this.state.setFirstName} setLastName={this.state.setLastName} payerName={this.state.payerName}
                                                onPayerNameChange={this.handlePayerNameChange} showDate={this.state.showDate} showDatePicker={this.state.showDatePicker} selectedDate={this.state.selectedDate}/>  
                                            )
                                            }
                                            {this.state.selectSidePrint === "right" && props.chequeTransactionViewer.allChequeTransaction.edges.map((cheque,index) =>
                                                <ChequeTransactionPrintRight key={index} cheque = {cheque} isACPayee = {this.state.isACPayee} isCrossOut = {this.state.isCrossOut} 
                                                totalCount = {props.chequeTransactionViewer.allChequeTransaction.totalCount} index = {index} side={this.state.selectSidePrint}
                                                sideLeftRight = {this.state.sideLeftRight} sideTop={this.state.setSideTop} setFirstName={this.state.setFirstName} setLastName={this.state.setLastName} payerName={this.state.payerName}
                                                onPayerNameChange={this.handlePayerNameChange} showDate={this.state.showDate} showDatePicker={this.state.showDatePicker} selectedDate={this.state.selectedDate}/>  
                                            )
                                            }
                                            {this.state.showFormChangeName === "show" && <ChequeHolderName onAddFirstName={this.handleHolderFirstNameChange} onAddLastName={this.handleHolderLastNameChange} onHide={this.handleButtonClick} payerName={this.state.payerName}/>}
                                        </React.Fragment>   
                                    );
                                }
                                return <Loading/>
                            }}
                        />
            </React.Fragment>
        );
    }
}

export default WrapperChequeTransactionPrint;