import React from "react";
import { Component } from "react";
import {format} from "date-fns"
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";

class RevenueContractTable extends Component {

    render() {
        return (
            <Translation>
                {t =>
                <React.Fragment>
                    <tbody>
                       {this.props.revenueContractList.map((transaction,index) => {
                           return(
                            <tr key={index}>
                                    <td className ="text-center">{this.props.indexFirst + index + 1}</td>
                                    <td className ="text-center">
                                        {this.props.status === 'contracted' ? 
                                            <Link to={"/developer/revenue-contract/detail/" + transaction.revenueContactList.residentialID}>
                                                {transaction.revenueContactList.roomNo}
                                            </Link>
                                            :
                                            transaction.revenueContactList.roomNo
                                        }
                                        
                                    </td>
                                    <td className ="text-left">
                                        {transaction.revenueContactList.ownershipName.map((ownershipName,indexOwner) =>{
                                            return <div key={indexOwner}><span>{ownershipName}</span><br /></div>
    
                                        })}
                                    </td>
                                    <td className ="text-center">{transaction.revenueContactList.type}</td>
                                    <td className ="text-center">{transaction.revenueContactList.size}</td>
                                    <td className ="text-center">{transaction.revenueContactList.ownershipRatio}</td>
                                    <td className ="text-center">{transaction.revenueContactList.floor}</td>
                                    <td className="text-center">{transaction.revenueContactList.transferDate !== null ? format(transaction.revenueContactList.transferDate,'DD/MM/YYYY') : '-'}</td>	
                                    <td className="text-center">{transaction.revenueContactList.creator}</td>
                                    
                                    {this.props.status === 'all'&&
                                        <td className ="text-center">
                                            <span className={transaction.revenueContactList.statusContract === 'not_obligation' ? "round-status-not-ownership" :  "round-status-ownership"}>{t('revenueContract:'+transaction.revenueContactList.statusContract)}</span>
                                        </td>
                                    }
                               
                            </tr>
                           );
                       })}
                        
                    </tbody>
                </React.Fragment>}
            </Translation>
        )

    }


} 

export default RevenueContractTable