import React, { Component } from 'react';
import $ from "jquery";
import numberWithCommas from '../../../libs/numberWithComma';
import i18next from 'i18next';

class ClaimReportPDF extends Component{

    componentDidMount() {
        setTimeout(() => {
            let page = $('.print-page-a4');
            let head = page.find('.head').height();
            // let detail = page.find('.detail').height();
            let invoice = page.find('.invoice-body').height();
            // let signature = page.find('.footer').height();
            let page_height = 1125 - 150;
            let diff = page_height - (head  + invoice );
            $('tbody tr:last-child td').css({paddingBottom: diff});
            
        }, 600);
    }

    getStatus(status){
        let valueResult = ""
    
        switch (status) {
            case "consider_fail": 
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "not_approve":
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "void":
                valueResult = i18next.t("Allaction:cancel")
                break;
            case "finish": 
                valueResult = "ปิดเคลม"
                break;
                
            default:
                break;
        }
        return (
            <span>{valueResult}</span>
        )
    }

    render(){
        return(
            <div className="print-page-a4" id="unit-report">
                <div className="subpage">
                    <div className="head row" style={{width:'110%'}}>
                        <div className="col row">
                            <div className="col-12 d-flex justify-content-center"><span>รายงานค่าสินไหม <br/></span></div>
                            {this.props.startDate !== '' && this.props.endDate !== '' &&
                                <div className="col-12 d-flex justify-content-center">
                                    <span>
                                        ตั้งแต่วันที่ {this.props.startDate.toLocaleDateString('th-TH' , { year: 'numeric', month: 'long', day: 'numeric',})} - 
                                    {this.props.endDate.toLocaleDateString('th-TH' , { year: 'numeric', month: 'long', day: 'numeric'})}
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="invoice-body">
                        <table className="table table-bordered">
                            <thead className="thead-black" style={{backgroundColor:"#0f0f0f"}}>
                                <tr>
                                    {this.props.head_table.map((head,index) => {
                                        return (<th className= "text-center" height="30px" key={index}><strong>{head.label}</strong></th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.claimReportList.map((n,index)=>{
                                    return(
                                        <tr key={index} >
                                             <td>{n.claim_detail.doc_number} </td>
                                             <td>{n.claim_detail.issued_date}</td>
                                             <td>{n.claim_detail.header}</td>
                                             <td>{n.claim_detail.claim_type === 'public' ? 'พื้นที่ส่วนกลาง' : n.claim_detail.residential.name}</td>
                                             <td>{n.claim_detail.phone_contact}</td>
                                             <td>{n.claim_detail.claim_amount}</td>
                                             <td>{n.claim_detail.approved_amount}</td>
                                             <td>{this.getStatus(n.claim_detail.status)}</td>
                                        </tr>
                                    );
                                })
                                }
                                <tr style={{borderBottom : !this.props.lastPage ? '1px solid #212529' : ''}}>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>
                                    <td className="text-center"></td>

                                </tr>
                            </tbody>
                            {this.props.lastPage &&
                            <tfoot>
                                <tr>
                                   <td colSpan={"4"}> </td>
                                   <td style={{textAlign:'right'}}>รวม</td>
                                   <td className='text-center'>{numberWithCommas(this.props.state.allClaimAmount)}</td>
                                   <td className='text-center'>{numberWithCommas(this.props.state.allApprovedAmount)}</td>
                                   <td></td>
                                </tr>
                            </tfoot> 
                            }
                        </table>
                    </div>
                </div>
            </div>

        );
    }

}

export default ClaimReportPDF;