import React from "react";
import Wrapper from "../../../../components/wrapper";
import Header from "../../../../components/header";
import Sidebar from "../../../../components/sidebar";
import WrapperContent from "../../../../components/wrapper/wrapperContent";
import AccountingTopMenuNavigation from "../../../accountingTopMenuNavigation";
import { withRouter } from "react-router-dom";
import { Translation } from "react-i18next";
import DatePickerAdapter from "../../../../libs/datePickerAdapter";
import ComponentPagination from "../../../../libs/componentPagination";
import ContactReportList from "../contactReportList";
import DailyPaymentReceiptReportTable from "./dailyPaymentReceiptReportTable";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../../../env/environment";
import { fetchQuery } from "relay-runtime";
import ReactPaginate from "../../../../libs/ReactPaginate";
import _ from "lodash"
import { format } from "date-fns";
import DailyPaymentReceiptExportsNew from "./dailyPaymentReceiptExportsNew";
import DailyPaymentReceiptExportsNew2 from "./dailyPaymentReceiptExportsNew2"
import ProductServiceList from "../productServiceList";
import Loading from '../../../../libs/loading';
import postApiUseing from '../../../../libs/postApiUseing';
import '../../../../document/account/reportDebtor/receivable.scss'
import Swal from "sweetalert2";
import BackButtonIcon from "../../../../components/BackBtn/indexBack";
import i18next from "i18next";
const query = graphql`
  query dailyPaymentReceiptReportQuery(
      # $first: String, 
      # $last: String,
      $startDate: Date, 
      $endDate: Date, 
      $productAndService: String, 
      $paymentChannel: String,
      $payment_gateway_method_type: String,
      $contact: String
      $search: String
    ){
    summaryDayReceiveReport(
      # first: $first,
      # last: $last,
      startDate: $startDate,
      endDate: $endDate,
      productAndService: $productAndService, 
      paymentChannel: $paymentChannel,
      paymentGatewayMethodType: $payment_gateway_method_type,
      contact: $contact,
      search: $search
    ){
      dataTable
      paymentChannelTable
      productAndServiceHeadTable
      summaryTable
      numRow
    }
    summaryDayReceiveReportExport: summaryDayReceiveReport(
      startDate: $startDate,
      endDate: $endDate,
      productAndService: $productAndService, 
      paymentChannel: $paymentChannel,
      paymentGatewayMethodType: $payment_gateway_method_type,
      contact: $contact, 
      #search: $search
    ){
      dataTable
      paymentChannelTable
      productAndServiceHeadTable
      summaryTable
      numRow
    }
    productViewer{
      allProduct (type_In: "product, service, fine"){
        edges{
          node{
            id
            name
            productCode
            type
            price
            chartOfAccount{
                id
                chartOfAccountCode
                name
            }
            description
            totalLeftInStock
          }
        }
      }
    }
    
    allPaymentGatewayMethodType{
      dataTable
    }
    selfProject{
      name
      address
      logo
    }
  }
`

class dailyPaymentReceiptReport extends ComponentPagination {

  constructor(props) {
    super(props)

    this.state = {
      current_date: new Date(),
      queryList: {},
      queryListExport: {},
      list: [],
      checkFetchState: false,
      customer_type: "",
      temp_contact: "",
      temp_product_and_service: "",
      start_date: new Date(),
      end_date: new Date(),
      temp_start_date: new Date(),
      temp_end_date: new Date(),
      temp_payment_channel: "",
      temp_payment_gateway_method_type: "",
      allPaymentGatewayMethodType: {},
      search_input: "",
      first: 0,
      last: 10,
      page_count: 0,
      num_record: 0,
      all_item: 10,
      page_select: 0,
      index_pages: [],
      product_list: [],
      projectName: "",

      showDropDowm: false,
    }
  }

  componentWillMount() {

    let bodyFormData = new FormData();
    bodyFormData.append('title', "dailyPaymentReceiptReportQuery");
    bodyFormData.append('body', '');

    postApiUseing.PostApiUseing(bodyFormData).then(res => {
      return;
    }).catch(error => {
      console.log(error);
    })

    this.fetchDataQuery()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { page_select, all_item, num_record } = this.state;

    if (prevState.page_select !== page_select) {
      this.fetchDataQuery(true);
    }

    if (prevState.all_item !== all_item) {
      let first_index = 0;
      let last_index = _.cloneDeep(all_item);
      let index_pages = [];
      let page_count = Math.ceil(num_record / all_item);
      if (page_count > 1) {
        for (let index = 0; index < page_count; index++) {
          index_pages[index] = {
            first: first_index,
            last: last_index,
          };
          if (index == page_count - 2) {
            first_index += all_item;
            last_index +=
              num_record % all_item != 0 ? num_record % all_item : all_item;
          } else {
            first_index += all_item;
            last_index += all_item;
          }
        }
      } else {
        index_pages[0] = {
          first: 0,
          last: num_record,
        };
      }
      this.setState(
        {
          page_select: 0,
          index_pages: index_pages,
        },
        () => this.fetchDataQuery(true)
      );
    }
  }

  fetchDataQuery = (update, search) => {
    const {
      first,
      last,
      all_item,
      index_pages,
      page_select,
    } = this.state;
    this.setState({ checkFetchState: false })

    fetchQuery(
      environment,
      query,
      {
        first: update || search === "search" ? index_pages[page_select].first.toString() : first.toString(),
        last: update || search === "search" ? index_pages[page_select].last.toString() : last.toString(),
        startDate: format(this.state.start_date, "YYYY-MM-DD"),
        endDate: format(this.state.end_date, "YYYY-MM-DD"),
        search: this.state.search_input,
        paymentChannel: this.state.payment_channel,
        payment_gateway_method_type: this.state.payment_gateway_method_type,
        contact: this.state.contact,
        productAndService: this.state.product_and_service,
      }
    ).then((data) => {
      let first_index = _.cloneDeep(first);
      let last_index = _.cloneDeep(last);
      let index_pages = [];
      let page_count = Math.ceil(data.summaryDayReceiveReport.numRow / this.state.all_item);

      if (page_count > 1) {
        for (let index = 0; index < page_count; index++) {
          index_pages[index] = {
            first: first_index,
            last: last_index,
          };
          if (index == page_count - 2) {
            first_index += all_item;
            last_index +=
              data.summaryDayReceiveReport.numRow % all_item != 0
                ? data.summaryDayReceiveReport.numRow % all_item
                : all_item;
          } else {
            first_index += all_item;
            last_index += all_item;
          }
        }
      } else {
        index_pages[0] = {
          first: 0,
          last: data.summaryDayReceiveReport.numRow,
        };
      }
      this.setState({
        queryList: data.summaryDayReceiveReport,
        queryListExport: data.summaryDayReceiveReportExport,
        checkFetchState: true,
        page_count: page_count,
        num_record: data.summaryDayReceiveReport.numRow,
        index_pages: !update ? index_pages : this.state.index_pages,
        product_list: data.productViewer.allProduct.edges,
        allPaymentGatewayMethodType: data.allPaymentGatewayMethodType.dataTable,
        projectName: data.selfProject.name,
      })
    }).catch(error => {
      Swal.fire("Error!", "", "error"); // if an errors, anything then return in catch
    });
  }

  handleSearch = (event) => {
    let index_pages = []
    index_pages[0] = {
      first: 0,
      last: this.state.all_item,
    }
    this.setState(
      {
        start_date: this.state.temp_start_date,
        end_date: this.state.temp_end_date,
        search_input: event.target.value,
        checkFetchState: false,
        product_and_service: this.state.temp_product_and_service,
        contact: this.state.temp_contact,
        payment_channel: this.state.temp_payment_channel,
        payment_gateway_method_type: this.state.temp_payment_gateway_method_type,
        page_select: 0,
        index_pages: this.state.index_pages.length > 1 ? this.state.index_pages : index_pages
      },
      () => this.fetchDataQuery(false, "search")
    );
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page_select: page });
  };

  changePageLimit = (value) => {
    this.setState({
      all_item: value,
    });
  };

  onClickGetData(data) {
    // href={`/accounting/report/account-receivable/dailyPaymentReceiptReport/PDF`}
    this.props.history.push(`/accounting/report/account-receivable/dailyPaymentReceiptReport/PDF`, { data: data, product_list: this.state.product_list})
  }

  onClickGetDataAll(data) {
    this.props.history.push(`/accounting/report/account-receivable/dailyPaymentReceiptReport/PDF-All`, { data: data, product_list: this.state.product_list})
  }

  findShowDropDown = (set) => {
    // let expanded = document.getElementById('test').getAttribute('aria-expanded')
    // console.log(expanded);
    // this.setState({ showDropDowm: set })
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent disabledOverflowX={true}>
          <AccountingTopMenuNavigation mini={true} />
          <Translation>
            {t =>
              <div className="container-fluid box">
                <BackButtonIcon
                  LinkBack={"/accounting/report/account-receivable"}
                  LinkText={i18next.t("receivable_dashboard:Daily Summary Payment Receipts Report")}
                  boxHtmlText={
                    <div className="col fixRequestFast">
                      <div className={`${this.state.checkFetchState ? "dropdown" : ''} printDoc`}>
                        {
                          !this.state.checkFetchState ?
                            <React.Fragment>
                              <button type="button" className="btn-primary-scale-outline floatRight">
                                <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                <Translation>{t => t("meter:Preparing information")}</Translation>
                              </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              <button type="button" className="btn-primary-scale-outline floatRight dropdown-toggle" data-toggle="dropdown">
                                <Translation>{t => t("PageList:Print")}</Translation>
                              </button>
                            </React.Fragment>
                        }
                        <div className={`dropdown-menu`}>
                          <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetData(this.state)}>PDF</a>
                          {/* <a className="dropdown-item" target={"_blank"} onClick={() => this.onClickGetDataAll(this.state)}>PDF All</a>
                          {/* <DailyPaymentReceiptExports state={this.state} /> */}
                          <DailyPaymentReceiptExportsNew2 state={this.state} />
                          {/* <DailyPaymentReceiptExportsNew state={this.state} /> */}
                          {/* <ExportfixRequestExcelReport status={this.props.match.params.tabName} data={items} for={"tenant"}
                      startDate={this.state.searchStartDate} endDate={this.state.searchEndDate} /> */}
                        </div>

                      </div>

                      {/*  */}
                    </div>
                  }
                />
                <div className="content-inner">
                  <div className="row justify-content-end mb-2">
                    <div className="col">
                      <div className="input-group float-right w-auto ml-2">
                        <input
                          type="text"
                          className="form-control input-size"
                          placeholder={i18next.t("AllFilter:Search")}
                          value={this.state.search_input}
                          name="search"
                          onChange={(e) =>
                            this.setState({ search_input: e.target.value })
                          }
                        />
                        <button
                          type="submit"
                          className="btn btn-primary form-control search-button"
                          name="search"
                          value={this.state.search_input}
                          onClick={(e) => this.handleSearch(e)}
                        >
                          {i18next.t("AllFilter:Search")}
                        </button>
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_end_date"
                          className="form-control"
                          selected={this.state.temp_end_date}
                          maxDate={this.state.current_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>

                      <div className="float-right ml-2">
                        <DatePickerAdapter
                          name="temp_start_date"
                          className="form-control"
                          selected={this.state.temp_start_date}
                          maxDate={this.state.temp_end_date}
                          onChange={this.handleChange}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row fade-up">
                    <div className="col">
                      <div className="card advance-search">
                        <div className="row">
                          <ContactReportList
                            handleChange={this.handleChange}
                            customer_type={this.state.customer_type}
                            contact={this.state.temp_contact}
                          />

                          <ProductServiceList
                            handleChange={this.handleChange}
                            product_and_service={this.state.temp_product_and_service}
                          />

                          <div className="col-md-4">
                            <div className="form-group">
                              <label>{i18next.t("receiveReport:Payment Method")}</label>
                              <select
                                name="temp_payment_channel"
                                value={this.state.temp_payment_channel}
                                onChange={this.handleChange}
                                className="form-control dropdown-custom-arrow"
                              >
                                <option value="">{i18next.t("receiveReport:All")}</option>
                                <option value="cash">{i18next.t("receiveReport:Cash")}</option>
                                <option value="bank">{i18next.t("receiveReport:Transfer")}</option>
                                <option value="cross_bill">Cross bill</option>
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'credit_card'])?.menu_on &&
                                  <option value="credit_card">{i18next.t("receiveReport:Credit Card")}</option>
                                }
                                {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'payment_gateway'])?.menu_on &&
                                  <option value="payment_gateway">{i18next.t("receiveReport:Payment Gateway")}</option>
                                }
                                <option value="cheque">{i18next.t("receiveReport:Cheque")}</option>
                                <option value="other">{i18next.t("receiveReport:Other")}</option>
                              </select>
                            </div>
                          </div>

                          {this.state.temp_payment_channel === 'payment_gateway' &&
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>{i18next.t("PaymentChannel:Payment Gateway Format")}</label>
                                <select
                                  name="temp_payment_gateway_method_type"
                                  onChange={this.handleChange}
                                  className="form-control dropdown-custom-arrow"
                                >
                                  <option key={"type-all"} value="">{i18next.t("receiveReport:All")}</option>
                                  {
                                    this.state.allPaymentGatewayMethodType &&
                                    this.state.allPaymentGatewayMethodType.map(element => {
                                      let type = JSON.parse(element)
                                      if (type.name !== "crossbill") {
                                        return (
                                          <option key={type.name} value={type.name}>{type.name}</option>
                                        )
                                      } else {
                                        return null
                                      }
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          }


                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.checkFetchState
                    ? <React.Fragment>
                      <DailyPaymentReceiptReportTable query={this.state.queryList} state={this.state} />
                      <div style={{ marginBottom: "80px" }}>
                        <ReactPaginate
                          state={this.state}
                          changePageLimit={this.changePageLimit}
                          handlePageClick={this.handlePageClick}
                        />
                      </div>
                    </React.Fragment>
                    : <React.Fragment>
                      <div style={{ marginTop: "20px" }}>
                        <div className="d-flex justify-content-center" style={{ alignItems: "center", height: "50vh" }}>
                          <Loading />
                        </div>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
            }
          </Translation>

        </WrapperContent>
      </Wrapper>
    )
  }
}
export default withRouter(dailyPaymentReceiptReport);
