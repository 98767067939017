import React, { Component } from 'react'
import Wrapper from '../../components/wrapper'
import Sidebar from '../../components/sidebar'
import Header from '../../components/header'
import _ from "lodash"
import { Link } from 'react-router-dom'
import { Translation } from 'react-i18next'
import ManageRequestTable from './manageRequestTable'
import ClaimTopMenuNavigation from '../claim/claimTopMenuNavigation'
export default class manageRequestList extends Component {
  render() {
    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <ClaimTopMenuNavigation mini={true}/>
          <div className="container-fluid box" id="manageRequestList">

            <div className="row">
              <div className="col">
                <Translation>
                  {
                    t =>
                      <h4 className="mb-4">
                        <Link to="/purchasing">
                          <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                            alt="back" className="back" />
                        </Link>
                        {t("PageListPRPO:Procurement")+" "+"(PRF)"}
                      </h4>
                  }
                </Translation>

              </div>
            </div>
            
            <div className="row">
              <div className="col">
                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'accounting_procurement_form_create' }) &&
                  <Link to="/purchasing/manageRequest/createManageRequest">
                    <button type="button" className="btn btn-create add float-right mr-2">
                      <img src={process.env.PUBLIC_URL + '/images/icons/plus.png'} alt="plus" />
                      <Translation>
                        {
                          t => <span>{t("PageListPRPO:Procurement Create")}</span>
                        }
                      </Translation>
                    </button>
                  </Link>
                }
                </div>
            </div>
            <div className="content-inner">
              <ManageRequestTable status={this.props.match.params.status}/>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}
