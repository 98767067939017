
import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import '../styles/register.scss'

import Navigation from "./navigation";
import { fetchQuery } from "relay-runtime";
import environment from "../../env/environment";
import { graphql } from "babel-plugin-relay/macro";
import { parse } from "date-fns";
import DatePicker from "react-datepicker";
import i18next from 'i18next';
const _ = require('lodash');


const query = graphql`
    query viewResidentialHistoryDetailQuery($id:ID!){
    residentialHistory(id:$id){
    nameResidential
    residential{
        id
        name
    }
    tenant{
        id
        firstName
        lastName
    }
    nameTitle
    firstName
    lastName
    idCardNumber
    dateOfBirth
    gender
    image
    email
    countries
    phone
    phone2
    channel
    acceptPolicy
    acceptTermOfUse
    acceptMarketing
    acceptPartner
    acceptOtp
    firstNameEng
    lastNameEng
    occupation
    tenantStatus
    ethnicity
    cardType
    note
    dateIn
    dateOut
    type
    status
    liveing
    quit
    remark
    logVerifyIdentityDocument{ edges { node { id fileName fileUpload } } }
    logPassport{ edges { node { id fileName fileUpload } } }
    logLease{ edges { node { id fileName fileUpload } } }
    logRentOwnerLease{ edges { node { id fileName fileUpload } } }
    logDailyForm{ edges { node { id fileName fileUpload } } }
}
} `;



const owner_object = {
    id: "",
    firstName: "",
    lastName: "",
    firstNameEng: "",
    lastNameEng: "",
    idCardNumber: "",
    cardType: "unspecified",
    occupation: "",
    dateOfBirth: "",
    gender: "",
    email: "",
    countries: "TH",
    phone: "",
    nameTitle: "",
    nameTitleSelect: "",
    tenantStatus: "unspecified",
    ethnicity: 'TH',
    user: {
        id: "",
        username: ""
    },
    filepdf: [],
    phone2: '',
    //New Add
    verifyIdentityDocument: [],
    passport: [],
    lease: [],
    rentOwnerLease: [],
    dailyForm: [],
    dateIn: new Date(),
};

const name_title_list = ["นาย", "นาง", "นางสาว", "เด็กหญิง", "เด็กชาย", "Miss", "Mrs.", "Mr.", "Master", "บริษัท", "คุณ"];
const name_title_listJson = require('../../api/Json/listTitleName.json');


class ViewResidentialHistoryDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            residential_name: "",
            active: false,
            owner_object: owner_object,
            type_resident: 'owner',
            country_list: [],
            image: '',
            image_upload: '',
            redirectToList: false,
            loading: false,
            isFirstName: false, //เช็คว่ามีข้อมูลชื่อจากในระบบหรือไม่มี ถ้ามีจะ disable input
            isLastName: false,
            residential_id: ""
        };
        // this.handleInputChange = this.handleInputChange.bind(this);
        // this.handleInputImage = this.handleInputImage.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
        // this.onClickResetPassword = this.onClickResetPassword.bind(this);
        // this.uploadFile = this.uploadFile.bind(this)
        this.dateFile = this.dateFile.bind(this)
    }

    componentWillMount() {
        // let country_list = require('country-list').getData()
        // let country_listmore = { code: 'other', name: "ระบุเอง" }
        let country_nationality = require('../../api/Json/countryNationalityList.json');
        country_nationality = country_nationality.sort((a,b) => (a.nationality.toLowerCase() < b.nationality.toLowerCase()) ? -1 : ((b.nationality.toLowerCase() > a.nationality.toLowerCase()) ? 1 : 0));
        let country_listmore = { alpha_2_code: 'other', nationality: "ระบุเอง" }
        this.setState({ country_list: [...country_nationality, country_listmore] });


        fetchQuery(environment, query, { id: this.props.match.params.id || "" }).then(data => {


            if (_.isObject(data.residentialHistory)) {
                let data_set = _.cloneDeep(data.residentialHistory);

                if (!_.isNull(_.get(data_set, 'dateOfBirth'))) {
                    _.set(data_set, 'dateOfBirth', parse(_.get(data_set, 'dateOfBirth')));
                }
                if (!_.isNull(_.get(data_set, 'cardType'))) {
                    if (_.get(data_set, 'cardType') === 'GOVERNMENT_OFFICER') {
                        _.set(data_set, 'cardType', 'government_officer');
                    } else {
                        _.set(data_set, 'cardType', _.get(data_set, 'cardType') === null ? 'unspecified' : _.get(data_set, 'cardType').toLowerCase());
                    }
                } else {
                    _.set(data_set, 'cardType', 'unspecified');
                }

                if (_.isNull(_.get(data_set, 'tenantStatus'))) {
                    _.set(data_set, 'tenantStatus', _.get(data_set, 'tenantStatus') === null ? 'unspecified' : _.lowerCase(_.get(data_set, 'tenantStatus')));
                } else {
                    _.set(data_set, 'tenantStatus', 'unspecified');
                }

                if (_.isNull(_.get(data_set, 'nameTitle'))) {
                    _.set(data_set, 'nameTitle', "");
                    _.set(data_set, 'nameTitleSelect', "");

                } else if (name_title_list.some(e => e === _.get(data_set, 'nameTitle'))) {
                    _.set(data_set, 'nameTitleSelect', _.get(data_set, 'nameTitle'));

                } else {
                    _.set(data_set, 'nameTitleSelect', "other_input");
                }


                if (!_.isNull(_.get(data_set, 'firstName'))) {
                    this.setState({ isFirstName: true });
                }
                if (!_.isNull(_.get(data_set, 'lastName'))) {
                    this.setState({ isLastName: true });
                }

                //เอา edges , node ออก

                let verifyIdentityDocument = _.get(data_set, 'verifyIdentityDocument.edges') ? _.get(data_set, 'verifyIdentityDocument.edges') : []
                let passport = _.get(data_set, 'passport.edges') ? _.get(data_set, 'passport.edges') : []
                let lease = _.get(data_set, 'lease.edges') ? _.get(data_set, 'lease.edges') : []
                let rentOwnerLease = _.get(data_set, 'rentOwnerLease.edges') ? _.get(data_set, 'rentOwnerLease.edges') : []
                let dailyForm = _.get(data_set, 'dailyForm.edges') ? _.get(data_set, 'dailyForm.edges') : []

                let verifyIdentityDocumentArray = []
                let passportArray = []
                let leaseArray = []
                let rentOwnerLeaseArray = []
                let dailyFormArray = []

                verifyIdentityDocument.forEach((element) => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    verifyIdentityDocumentArray.push(objects)
                });
                passport.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    passportArray.push(objects)
                });
                lease.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    leaseArray.push(objects)
                });
                rentOwnerLease.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    rentOwnerLeaseArray.push(objects)
                });
                dailyForm.forEach(element => {
                    let objects = {
                        id: element.node.id,
                        fileName: element.node.fileName,
                        fileUpload: element.node.fileUpload,
                        status: ""
                    }
                    dailyFormArray.push(objects)
                });

                _.set(data_set, 'verifyIdentityDocument', verifyIdentityDocumentArray);
                _.set(data_set, 'passport', passportArray);
                _.set(data_set, 'lease', leaseArray);
                _.set(data_set, 'rentOwnerLease', rentOwnerLeaseArray);
                _.set(data_set, 'dailyForm', dailyFormArray);

                this.setState({
                    owner_object: data_set,
                    type_resident: data.residentialHistory.type.toLowerCase(),
                    image: _.get(data_set, 'image'),
                    residential_id:data.residentialHistory.residential.id,
                    residential_name: data.residentialHistory.residential.name
                });
            }

        });
    }

    dateFile(index, data, name) {
        let owner_object = _.cloneDeep(this.state.owner_object)

        if (data.id !== '') {
            let objects = {
                id: data.id,
                fileName: data.fileName,
                fileUpload: data.fileUpload,
                status: "delete",
            }
            _.set(owner_object[name], index, objects)
        } else {
            owner_object[name].pop(index)
        }

        this.setState({ owner_object: owner_object })
    }
    
    render() {
        let countriesOther = this.state.country_list?.filter((n) => n.alpha_2_code === this.state.owner_object.countries)
        let ethnicityOther = this.state.country_list?.filter((n) => n.alpha_2_code === this.state.owner_object.ethnicity)
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));

        return (
            <Wrapper>
            <Header />
            <Sidebar />
            <WrapperContent>
                <Translation>
                    {
                        t =>
                            <div className="container-fluid p-4 staff-create" id="form-detail">
                                <div className="row justify-content-between">
                                    <div className="col header-color">
                                        <h3 className="mb-4">
                                            <Link to={{ pathname: "/register/residentialhistory/list"}}>
                                                <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                    alt="back" className="back" />
                                            </Link>
                                            {this.state.residential_name} 
                                        </h3>
                                        <div> 
                                            <Navigation id={this.state.residential_id} />
                                        </div>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <form action="" onSubmit={this.onSubmit} id="form-update-owner">

                                        <div className="row mb-4 fade-up">
                                            <div className="col-md-6 col-xl-6">
                                                <div className="card p-3">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 className="mb-3"> <span>{t('register:owner')}</span> </h5>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-3">
                                                            <div className="col-3">
                                                                {this.state.image ?
                                                                    <div className="rounded-circle avatar-img profile-img"
                                                                        style={{ backgroundImage: `url(${this.state.image})` }}>
                                                                    </div>
                                                                    :
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                        alt="owner-profile"
                                                                        className="rounded-circle avatar-img profile-img" />
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" className="custom-control-input"
                                                                        id="owner_radio" name="owner"
                                                                        checked={this.state.type_resident === 'owner'}
                                                                        disabled
                                                                    />
                                                                    <label className="custom-control-label label16"
                                                                        htmlFor="owner_radio">{
                                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                            textData?.data?.ownerDetail.owner
                                                                                :                                                                                
                                                                            t("resident:Owner")
                                                                        }</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" className="custom-control-input"
                                                                        id="resident_radio" name="resident"
                                                                        checked={this.state.type_resident === 'resident'}
                                                                        disabled
                                                                    />
                                                                    <label className="custom-control-label label16"
                                                                        htmlFor="resident_radio">{
                                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                            textData?.data?.ownerDetail.resident
                                                                            :
                                                                            t("resident:Resident")
                                                                        }</label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" className="custom-control-input"
                                                                        id="renter_radio" name="renter"
                                                                        checked={this.state.type_resident === 'renter'}
                                                                        onChange={this.handleInputChange}
                                                                        disabled
                                                                    />
                                                                    <label className="custom-control-label label16"
                                                                        htmlFor="renter_radio">{t("resident:Tenant")}</label>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col-6">
                                                                <select className="form-control inputBox pt-0 pb-0 dropdown-custom-arrow"
                                                                    value={this.state.owner_object?.nameTitleSelect}
                                                                    onChange={this.handleInputChange}
                                                                    required={true}
                                                                    name="owner_object.nameTitleSelect"
                                                                    disabled

                                                                >
                                                                    <option value="">{t("resident:Please select")}</option>

                                                                    <option value="นาย">นาย</option>
                                                                    <option value="นาง">นาง</option>
                                                                    <option value="นางสาว">นางสาว</option>
                                                                    <option value="เด็กหญิง">เด็กหญิง</option>
                                                                    <option value="เด็กชาย">เด็กชาย</option>
                                                                    <option value="Miss">Miss</option>
                                                                    <option value="Mrs.">Mrs.</option>
                                                                    <option value="Mr.">Mr</option>
                                                                    <option value="Master">Master</option>
                                                                    <option value="บริษัท">บริษัท</option>
                                                                    <option value="คุณ">คุณ</option>
                                                                    <option value="other_input">{t("resident:Specify")}</option>
                                                                </select>
                                                            </div>

                                                            {this.state.owner_object?.nameTitleSelect === "other_input" &&
                                                                <div className="col">
                                                                    <h6>
                                                                        {t("resident:Specifies")} <span className="text-danger">*</span>
                                                                    </h6>
                                                                    <input type="text" className="form-control inputBox"
                                                                        name="owner_object.nameTitle"
                                                                        onChange={this.handleInputChange}
                                                                        defaultValue={this.state.owner_object?.nameTitle || ""}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <h6>
                                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                        textData?.data?.createResidential.nameSurname
                                                                            :
                                                                        t("resident:Name")
                                                                        } <span className="text-danger">*</span>
                                                                </h6>
                                                                <input type="text" className="form-control inputBox"
                                                                    name="owner_object.firstName"
                                                                    defaultValue={this.state.owner_object?.firstName || ""}
                                                                    required={true}
                                                                    disabled

                                                                />
                                                            </div>
                                                            {this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                                <div className="col">
                                                                    <h6>
                                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                        textData?.data?.createResidential.department
                                                                            :
                                                                        t("resident:Surname")
                                                                        } <span className="text-danger">*</span>
                                                                    </h6>

                                                                    <input type="text" className="form-control inputBox"
                                                                        name="owner_object.lastName"
                                                                        defaultValue={this.state.owner_object?.lastName || ""}
                                                                        required={true}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <h6>
                                                                        {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                        textData?.data?.createResidential.nameSurnameEng
                                                                            :
                                                                        "Name"}
                                                                </h6>
                                                                <input type="text" className="form-control inputBox"
                                                                    name="owner_object.firstNameEng"
                                                                    onChange={this.handleInputChange}
                                                                    defaultValue={this.state.owner_object?.firstNameEng || ''}
                                                                    disabled

                                                                />
                                                            </div>
                                                            {this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                                <div className="col">
                                                                    <h6>
                                                                            {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                            textData?.data?.createResidential.departmentEng
                                                                                :
                                                                            "Surname"} 
                                                                    </h6>
                                                                    <input type="text" className="form-control inputBox"
                                                                        name="owner_object.lastNameEng"
                                                                        onChange={this.handleInputChange}
                                                                        defaultValue={this.state.owner_object?.lastNameEng || ''}
                                                                        disabled
                                                                    />
                                                                </div>
                                                            }
                                                        </div>

                                                        {this.state.owner_object?.nameTitleSelect !== 'บริษัท' &&
                                                            <React.Fragment>
                                                                <div className="row mt-4">
                                                                    <div className="col">

                                                                        <span> <strong>{i18next.t("Ownership:Occupation")} </strong></span>
                                                                        <input type="text" className="form-control mt-1"
                                                                            name="owner_object.occupation"
                                                                            onChange={this.handleInputChange}
                                                                            defaultValue={this.state.owner_object?.occupation || ''}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">

                                                                    <div className="col">
                                                                        <span><strong>{i18next.t("Ownership:Marital Status")} </strong></span>
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" className="custom-control-input"
                                                                                        id="unspecified_radio" name="tenantStatus"
                                                                                        value="unspecified"
                                                                                        checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'unspecified'}
                                                                                        onChange={this.handleChangeTenantStatus}
                                                                                        disabled
                                                                                    />
                                                                                    <label className="custom-control-label"
                                                                                        htmlFor="unspecified_radio">{i18next.t("Ownership:Not Specified")} </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" className="custom-control-input"
                                                                                        id="single_radio" name="tenantStatus"
                                                                                        value="single"
                                                                                        checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'single'}
                                                                                        onChange={this.handleChangeTenantStatus}
                                                                                        disabled
                                                                                    />
                                                                                    <label className="custom-control-label"
                                                                                        htmlFor="single_radio">{i18next.t("Ownership:Single")} </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" className="custom-control-input"
                                                                                        id="married_radio" name="tenantStatus"
                                                                                        value="married"
                                                                                        checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'married'}
                                                                                        onChange={this.handleChangeTenantStatus}
                                                                                        disabled
                                                                                    />
                                                                                    <label className="custom-control-label"
                                                                                        htmlFor="married_radio">{i18next.t("Ownership:Married")} </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" className="custom-control-input"
                                                                                        id="divorce_radio" name="tenantStatus"
                                                                                        value="divorce"
                                                                                        checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'divorce'}
                                                                                        onChange={this.handleChangeTenantStatus}
                                                                                        disabled
                                                                                    />
                                                                                    <label className="custom-control-label"
                                                                                        htmlFor="divorce_radio">{i18next.t("Ownership:Divorced")} </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <div className="custom-control custom-radio custom-control-inline">
                                                                                    <input type="radio" className="custom-control-input"
                                                                                        id="widow_radio" name="tenantStatus"
                                                                                        value="widow"
                                                                                        checked={_.lowerCase(this.state.owner_object?.tenantStatus) === 'widow'}
                                                                                        onChange={this.handleChangeTenantStatus}
                                                                                        disabled
                                                                                    />
                                                                                    <label className="custom-control-label"
                                                                                        htmlFor="widow_radio">{i18next.t("Ownership:Widowed")} </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <div className="col">
                                                                        <img src={process.env.PUBLIC_URL + '/images/icons/flag.png'}
                                                                            alt="flag-icon" />
                                                                        <span className="ml-3">
                                                                            <strong>{t("resident:Nationality")}</strong>
                                                                        </span>
                                                                        <div className='row'>

                                                                            <div className={countriesOther.length > 0 ? 'col' : 'col-6'}>
                                                                                {
                                                                                    countriesOther.length > 0 ?
                                                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                            value={this.state.owner_object?.countries}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} name="owner_object.countries"
                                                                                            disabled
                                                                                        >
                                                                                            {this.state.country_list.map((country) =>
                                                                                                <option value={country.alpha_2_code}
                                                                                                    key={country.alpha_2_code}>{country.nationality}</option>
                                                                                            )}
                                                                                        </select>
                                                                                        :
                                                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                            value={"other"}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} name="owner_object.countries"
                                                                                            disabled
                                                                                        >
                                                                                            {this.state.country_list.map((country) =>
                                                                                                <option value={country.alpha_2_code}
                                                                                                    key={country.alpha_2_code}>{country.nationality}</option>
                                                                                            )}
                                                                                        </select>
                                                                                }

                                                                            </div>
                                                                            {
                                                                                (this.state.owner_object?.countries === "other" || countriesOther.length === 0) &&
                                                                                <div className='col-6'>
                                                                                    <input type="text" name="owner_object.countriesOther"
                                                                                        className='form-control mt-1' onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.countries}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <div className="col">
                                                                    <span><strong>{t("resident:Ethnicity")}</strong></span>      
                                                                        <div className='row'>
                                                                            <div className={ethnicityOther.length > 0 ? 'col' : 'col-6'}>
                                                                                {
                                                                                    ethnicityOther.length > 0 ?
                                                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                            value={this.state.owner_object?.ethnicity}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} name="owner_object.ethnicity"
                                                                                            disabled
                                                                                        >
                                                                                            {this.state.country_list.map((country) =>
                                                                                                <option value={country.alpha_2_code}
                                                                                                    key={country.alpha_2_code}>{country.nationality}</option>
                                                                                            )}

                                                                                        </select>
                                                                                        :
                                                                                        <select className="form-control mt-1 dropdown-custom-arrow"
                                                                                            value={"other"}
                                                                                            onChange={this.handleInputChange}
                                                                                            required={true} name="owner_object.ethnicity"
                                                                                            disabled
                                                                                        >
                                                                                            {this.state.country_list.map((country) =>
                                                                                                <option value={country.alpha_2_code}
                                                                                                    key={country.alpha_2_code}>{country.nationality}</option>
                                                                                            )}
                                                                                        </select>
                                                                                }


                                                                            </div>
                                                                            {
                                                                                (this.state.owner_object?.ethnicity === "other" || ethnicityOther.length === 0) &&
                                                                                <div className='col-6'>
                                                                                    <input type="text" name="owner_object.ethnicityOther"
                                                                                        className='form-control mt-1' onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.ethnicity}
                                                                                        disabled
                                                                                    />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <div className="col">
                                                                        <h6>
                                                                            {t("resident:Date of birth")}
                                                                        </h6>

                                                                        <div className="datePicker">
                                                                            <DatePicker
                                                                                name='owner_object.dateOfBirth'
                                                                                className="form-control inputBoxDate"
                                                                                selected={this.state.owner_object?.dateOfBirth}
                                                                                onChange={(date) => this.setState(n => { return _.set(n, "owner_object.dateOfBirth", date) })}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                disabled
                                                                            // disabled={!this.state.status}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <h6>
                                                                            {t("resident:Sex")}
                                                                        </h6>
                                                                        <select className="form-control inputBox pt-0 pb-0 mt-1 dropdown-custom-arrow"
                                                                            name="owner_object.gender"
                                                                            onChange={this.handleInputChange}
                                                                            value={this.state.owner_object?.gender ? this.state.owner_object?.gender.toLowerCase() : ''}
                                                                            disabled
                                                                        >
                                                                            <option value="">{t("resident:Please select")}</option>
                                                                            <option value="female">{t("resident:Female")}</option>
                                                                            <option value="male">{t("resident:Male")}</option>
                                                                            <option value="unspecified">{i18next.t("Ownership:Not Specified")} </option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="row mt-4">
                                                                    <div className="col-6">
                                                                        <h6>
                                                                            {t("resident:dateIn")}
                                                                            <span className='text-red'> *</span>
                                                                        </h6>

                                                                        <div className="datePicker">
                                                                            <DatePicker
                                                                                required
                                                                                name='owner_object.dateIn'
                                                                                className="form-control inputBoxDate"
                                                                                selected={this.state.owner_object.dateIn ? new Date(this.state.owner_object.dateIn) : new Date()}
                                                                                onChange={(date) => this.setState(n => { return _.set(n, "owner_object.dateIn", date) })}
                                                                                dateFormat="dd/MM/yyyy"
                                                                                disabled
                                                                            // disabled={!this.state.status}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.state.owner_object?.dateOut &&
                                                                        <div className="col-6">
                                                                            <h6>
                                                                                {t("resident:dateOut")}
                                                                            </h6>
                                                                            <div className="datePicker">
                                                                                <DatePicker
                                                                                    name='owner_object.dateOut'
                                                                                    className="form-control inputBoxDate"
                                                                                    selected={this.state.owner_object?.dateOut}
                                                                                    // onChange={(date) => this.setState({ []: date })}
                                                                                    onChange={this.handleInputChange}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    disabled
                                                                                // disabled={!this.state.status}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-xl-6">
                                                <div className="card p-4 ">
                                                    <div className="card-body">
                                                        {/* {this.props.match.params.owner_id && */}
                                                            <div className="row  mb-3">
                                                                <div className="col-8">
                                                                {i18next.t("Ownership:Authentication Status of the Application")}  :
                                                                    <strong>{(this.state.owner_object?.acceptPolicy && this.state.owner_object?.acceptTermOfUse) ? "ยืนยันตัวตนแล้ว" : "ยังไม่ยืนยันตัวตน"}</strong>
                                                                </div>
                                                            </div>
                                                        {/* } */}
                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/images/icons/id-card-icon.png'}
                                                                    alt="id-card-icon" />
                                                                <span className="ml-3">
                                                                    <strong>{i18next.t("Ownership:Card Type")} </strong></span>
                                                                <div className="row mt-2">
                                                                    <div className="col">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="id_card" name="typeOfCard"
                                                                                value="id_card"
                                                                                checked={this.state.owner_object?.cardType === 'id card' || this.state.owner_object?.cardType === 'id_card'}
                                                                                onChange={this.handleChangeCardType}
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="id_card">{i18next.t("Ownership:National ID")} </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="government_officer" name="typeOfCard"
                                                                                value="government_officer"
                                                                                checked={this.state.owner_object?.cardType === 'government_officer'}
                                                                                onChange={this.handleChangeCardType}
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="government_officer">{i18next.t("Ownership:Government Officer ID")} </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="driving" name="typeOfCard"
                                                                                value="driving"
                                                                                checked={this.state.owner_object?.cardType === 'driving'}
                                                                                onChange={this.handleChangeCardType}
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="driving">{i18next.t("Ownership:Driver's License")} </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-4">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="passport" name="typeOfCard"
                                                                                value="passport"
                                                                                checked={this.state.owner_object?.cardType === 'passport'}
                                                                                onChange={this.handleChangeCardType}
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="passport">Passport</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="other" name="typeOfCard"
                                                                                checked={this.state.owner_object?.cardType === 'other'}
                                                                                onChange={this.handleChangeCardType}
                                                                                value="other"
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="other">{i18next.t("Ownership:Other")} </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <div className="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" className="custom-control-input"
                                                                                id="unspecified" name="typeOfCard"
                                                                                checked={this.state.owner_object?.cardType === 'unspecified'}
                                                                                onChange={this.handleChangeCardType}
                                                                                value="unspecified"
                                                                                disabled
                                                                            />
                                                                            <label className="custom-control-label"
                                                                                htmlFor="unspecified">{i18next.t("Ownership:Not Specified")} </label>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                                <>
                                                                    {
                                                                        this.state.owner_object?.cardType === "id_card" || this.state.owner_object?.cardType === "id card" ?
                                                                            <input type="text" className="form-control inputBox mt-2"
                                                                                name="owner_object.idCardNumber"
                                                                                onChange={this.handleInputChange}
                                                                                defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                placeholder={`${i18next.t("Ownership:Specified")} ${i18next.t("Ownership:National ID")}`}
                                                                                pattern="[0-9]{13}" maxLength={13} minLength={13}
                                                                                required={true}
                                                                                disabled
                                                                            />
                                                                            :
                                                                            this.state.owner_object?.cardType === "government_officer" ?
                                                                                <input type="text" className="form-control inputBox mt-2"
                                                                                    name="owner_object.idCardNumber"
                                                                                    onChange={this.handleInputChange}
                                                                                    defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                    placeholder={`${i18next.t("Ownership:Specified")} ${i18next.t("Ownership:Government Officer ID")}`}
                                                                                    pattern="[0-9]+$" maxLength={13}
                                                                                    required={true}
                                                                                    disabled

                                                                                />
                                                                                :
                                                                                this.state.owner_object?.cardType === "unspecified" ?
                                                                                    <input type="text" className="form-control inputBox mt-2"
                                                                                        name="owner_object.idCardNumber"
                                                                                        onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                        placeholder={i18next.t("Ownership:Enter Card / Tax ID Number")} 
                                                                                        disabled
                                                                                    />
                                                                                    :
                                                                                    <input type="text" className="form-control inputBox mt-2"
                                                                                        name="owner_object.idCardNumber"
                                                                                        onChange={this.handleInputChange}
                                                                                        defaultValue={this.state.owner_object?.idCardNumber || ''}
                                                                                        placeholder={i18next.t("Ownership:Enter Card / Tax ID Number")} 
                                                                                        required={true}
                                                                                        disabled
                                                                                    />
                                                                    }
                                                                </>

                                                                <div className='FileApprove'>
                                                                    <div className="uploadBTN mt-3">
                                                                        {/* <label htmlFor="FileApprove" className="upload-btn-wrapper">
                                                                            <button className={"btn"} type='button'>
                                                                                <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                    className='mr-3' />
                                                                                {"แนบไฟล์"}
                                                                            </button>

                                                                            <span>{"รองรับไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 2 MB "}</span>

                                                                            <input type="file" name='verifyIdentityDocument' id="FileApprove"
                                                                                className='form-control uploadFile'
                                                                                accept='image/*' onChange={this.uploadFile}
                                                                            />

                                                                        </label> */}
                                                                    </div>
                                                                    {
                                                                        this.state.owner_object?.verifyIdentityDocument?.length > 0 &&
                                                                        this.state.owner_object?.verifyIdentityDocument?.map((file, inx) => {

                                                                            if (file.node) {
                                                                                let typeFile = file.node.fileName.split(".")
                                                                                return (
                                                                                    file.node.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.node.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.node.viewPtath === "string"
                                                                                                            ? file.node.viewPtath
                                                                                                            : URL.createObjectURL(file.node.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.node.fileUpload === "string"
                                                                                                            ? file.node.fileUpload
                                                                                                            : URL.createObjectURL(file.node.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.node.fileName}
                                                                                        </Link>

                                                                                    </p>
                                                                                )
                                                                            } else {
                                                                                let typeFile = file.fileName.split(".")
                                                                                return (
                                                                                    file.status !== "delete" &&
                                                                                    <p key={inx}>
                                                                                        <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                        <Link
                                                                                            to="route"
                                                                                            onClick={(event) => {
                                                                                                event.preventDefault();
                                                                                                file.viewPtath ?
                                                                                                    window.open(
                                                                                                        typeof file.viewPtath === "string"
                                                                                                            ? file.viewPtath
                                                                                                            : URL.createObjectURL(file.viewPtath)
                                                                                                    )
                                                                                                    :
                                                                                                    window.open(
                                                                                                        typeof file.fileUpload === "string"
                                                                                                            ? file.fileUpload
                                                                                                            : URL.createObjectURL(file.fileUpload)
                                                                                                    )
                                                                                            }}
                                                                                            target="blank">
                                                                                            {file.fileName}
                                                                                        </Link>

                                                                                    </p>
                                                                                )
                                                                            }
                                                                        })

                                                                    }



                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* แนบเอกสารประกอบ */}
                                                <div className="card p-4 mt-3">
                                                    <div className="card-body">
                                                        <div className='withDocument'>
                                                            <label className='headerLabel'>{i18next.t("Ownership:Attach Supporting Documents")} </label><br />
                                                            {/* <span>{"รองรับไฟล์ในประเภท .png, .jpg, และ .jpegเท่านั้น และไฟล์ขนาดไม่เกิน 2 MB "}</span> */}
                                                        </div>
                                                        <hr />

                                                        {/* 1.Passport */}
                                                        <div className="row mt-4">
                                                            <div className='col-8'>
                                                                <label className='label16'>1. Passport</label>
                                                            </div>
                                                            <div className="col-4">
                                                                {/* <div className="uploadBTN">
                                                                    <label htmlFor="uploadPassport" className="upload-btn-wrapper mt-0">
                                                                        <button className={"btn"} type='button'>
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                className='mr-3' />
                                                                            {"แนบไฟล์"}
                                                                        </button>

                                                                        <input type="file" name='passport' id="uploadPassport"
                                                                            className='form-control uploadFile'
                                                                            accept='image/*' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div> */}
                                                            </div>
                                                            <div className="col">
                                                                {
                                                                    this.state.owner_object?.passport?.length > 0 &&
                                                                    this.state.owner_object?.passport?.map((file, inx) => {
                                                                        if (file.node) {
                                                                            let typeFile = file.node.fileName.split(".")
                                                                            return (
                                                                                file.node.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.node.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.node.viewPtath === "string"
                                                                                                        ? file.node.viewPtath
                                                                                                        : URL.createObjectURL(file.node.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.node.fileUpload === "string"
                                                                                                        ? file.node.fileUpload
                                                                                                        : URL.createObjectURL(file.node.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.node.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        } else {
                                                                            let typeFile = file.fileName.split(".")
                                                                            return (
                                                                                file.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.viewPtath === "string"
                                                                                                        ? file.viewPtath
                                                                                                        : URL.createObjectURL(file.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.fileUpload === "string"
                                                                                                        ? file.fileUpload
                                                                                                        : URL.createObjectURL(file.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        }

                                                                    })

                                                                }
                                                            </div>
                                                        </div>

                                                        {/* 2. เอกสารสัญญาเช่า */}

                                                        <div className="row mt-4">
                                                            <div className='col-8'>
                                                                <label className='label16'>2. {i18next.t("Ownership:Lease Agreement")} </label>
                                                            </div>
                                                            <div className="col-4">
                                                                {/* <div className="uploadBTN">
                                                                    <label htmlFor="uploadLease" className="upload-btn-wrapper mt-0">
                                                                        <button className={"btn"} type='button'>
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                className='mr-3' />
                                                                            {"แนบไฟล์"}
                                                                        </button>

                                                                        <input type="file" name='lease' id="uploadLease"
                                                                            className='form-control uploadFile'
                                                                            accept='image/*' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div> */}
                                                            </div>

                                                            <div className="col">
                                                                {
                                                                    this.state.owner_object?.lease?.length > 0 &&
                                                                    this.state.owner_object?.lease?.map((file, inx) => {
                                                                        if (file.node) {
                                                                            let typeFile = file.node.fileName.split(".")
                                                                            return (
                                                                                file.node.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.node.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.node.viewPtath === "string"
                                                                                                        ? file.node.viewPtath
                                                                                                        : URL.createObjectURL(file.node.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.node.fileUpload === "string"
                                                                                                        ? file.node.fileUpload
                                                                                                        : URL.createObjectURL(file.node.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.node.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        } else {
                                                                            let typeFile = file.fileName.split(".")
                                                                            return (
                                                                                file.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.viewPtath === "string"
                                                                                                        ? file.viewPtath
                                                                                                        : URL.createObjectURL(file.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.fileUpload === "string"
                                                                                                        ? file.fileUpload
                                                                                                        : URL.createObjectURL(file.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        }
                                                                    })

                                                                }
                                                            </div>
                                                        </div>


                                                        {/* 3. สัญญาระหว่างผู้เช่ากับเจ้าของห้อง */}

                                                        <div className="row mt-4">
                                                            <div className='col-8'>
                                                                    {_.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?                                                                    
                                                                    <label  className='label16'>3. {i18next.t("Ownership:Agreement between Tenant With")} {textData?.data?.ownerDetail.ownership}</label>
                                                                    :
                                                                    <label className='label16'>3. {i18next.t("Ownership:Agreement between Tenant and Room Owner")} </label>}
                                                            </div>
                                                            <div className="col-4">
                                                                {/* <div className="uploadBTN">
                                                                    <label htmlFor="uploadRentOwnerLease" className="upload-btn-wrapper mt-0">
                                                                        <button className={"btn"} type='button'>
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                className='mr-3' />
                                                                            {"แนบไฟล์"}
                                                                        </button>

                                                                        <input type="file" name='rentOwnerLease' id="uploadRentOwnerLease"
                                                                            className='form-control uploadFile'
                                                                            accept='image/*' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div> */}
                                                            </div>

                                                            <div className="col">
                                                                {
                                                                    this.state.owner_object?.rentOwnerLease?.length > 0 &&
                                                                    this.state.owner_object?.rentOwnerLease?.map((file, inx) => {
                                                                        if (file.node) {
                                                                            let typeFile = file.node.fileName.split(".")
                                                                            return (
                                                                                file.node.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.node.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.node.viewPtath === "string"
                                                                                                        ? file.node.viewPtath
                                                                                                        : URL.createObjectURL(file.node.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.node.fileUpload === "string"
                                                                                                        ? file.node.fileUpload
                                                                                                        : URL.createObjectURL(file.node.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.node.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        } else {
                                                                            let typeFile = file.fileName.split(".")
                                                                            return (
                                                                                file.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.viewPtath === "string"
                                                                                                        ? file.viewPtath
                                                                                                        : URL.createObjectURL(file.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.fileUpload === "string"
                                                                                                        ? file.fileUpload
                                                                                                        : URL.createObjectURL(file.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        }
                                                                    })

                                                                }
                                                            </div>

                                                        </div>

                                                        {/* 4. แบบฟอร์มรายวัน (การเปลี่ยนคีย์การ์ด) */}

                                                        <div className="row mt-4">
                                                            <div className='col-8'>
                                                                <label className='label16'>4. {i18next.t("Ownership:Daily Log Form (Key Card Change)")} </label>
                                                            </div>
                                                            <div className="col-4">
                                                                {/* <div className="uploadBTN">
                                                                    <label htmlFor="uploadFormForDaily" className="upload-btn-wrapper mt-0">
                                                                        <button className={"btn"} type='button'>
                                                                            <img src={process.env.PUBLIC_URL + '/images/icons/attach-i.png'}
                                                                                className='mr-3' />
                                                                            {"แนบไฟล์"}
                                                                        </button>

                                                                        <input type="file" name='dailyForm' id="uploadFormForDaily"
                                                                            className='form-control uploadFile'
                                                                            accept='image/*' onChange={this.uploadFile}
                                                                        />

                                                                    </label>
                                                                </div> */}
                                                            </div>

                                                            <div className="col">
                                                                {
                                                                    this.state.owner_object?.dailyForm?.length > 0 &&
                                                                    this.state.owner_object?.dailyForm?.map((file, inx) => {
                                                                        if (file.node) {
                                                                            let typeFile = file.node.fileName.split(".")
                                                                            return (
                                                                                file.node.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.node.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.node.viewPtath === "string"
                                                                                                        ? file.node.viewPtath
                                                                                                        : URL.createObjectURL(file.node.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.node.fileUpload === "string"
                                                                                                        ? file.node.fileUpload
                                                                                                        : URL.createObjectURL(file.node.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.node.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        } else {
                                                                            let typeFile = file.fileName.split(".")
                                                                            return (
                                                                                file.status !== "delete" &&
                                                                                <p key={inx}>
                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                    <Link
                                                                                        to="route"
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            file.viewPtath ?
                                                                                                window.open(
                                                                                                    typeof file.viewPtath === "string"
                                                                                                        ? file.viewPtath
                                                                                                        : URL.createObjectURL(file.viewPtath)
                                                                                                )
                                                                                                :
                                                                                                window.open(
                                                                                                    typeof file.fileUpload === "string"
                                                                                                        ? file.fileUpload
                                                                                                        : URL.createObjectURL(file.fileUpload)
                                                                                                )
                                                                                        }}
                                                                                        target="blank">
                                                                                        {file.fileName}
                                                                                    </Link>

                                                                                </p>
                                                                            )
                                                                        }
                                                                    })

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="card p-4 mt-3">
                                                    <div className="card-body">
                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'}
                                                                    alt="phone-icon" style={{ width: '4%' }} />
                                                                <span className="ml-3">
                                                                    <strong>{t("resident:Phone number")}</strong>
                                                                </span>
                                                                <input type="text" className="form-control inputBox mt-1"
                                                                    name="owner_object.phone" pattern='^[0-9-  \t+#()]*$'
                                                                    title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                                    onChange={this.handleInputChange}
                                                                    // required={true}
                                                                    value={this.state.owner_object?.phone || ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/phone-manager-icon.png'}
                                                                    alt="phone-icon" style={{ width: '4%' }} />
                                                                <span className="ml-3">
                                                                    <strong>{i18next.t("Ownership:Contact Number")} </strong>
                                                                </span>
                                                                <input type="text" className="form-control inputBox mt-1"
                                                                    name="owner_object.phone2" pattern='^[0-9-  \t+#()]*$'
                                                                    title="กรุณาใส่แค่ตัวเลข เครื่องหมาย (- , # , ( ) , + หรือ กด spacebar) เท่านั้น"
                                                                    onChange={this.handleInputChange}
                                                                    value={this.state.owner_object?.phone2 || ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row mt-4">
                                                            <div className="col">
                                                                <img src={process.env.PUBLIC_URL + '/images/icons/mail-icon.png'}
                                                                    alt="mail-icon" style={{ width: '5%' }} />
                                                                <span className="ml-3">
                                                                    <strong>{t("resident:E-mail")}</strong>
                                                                </span>
                                                                <input type="text" className="form-control inputBox mt-1"
                                                                    name="owner_object.email"
                                                                    onChange={this.handleInputChange}
                                                                    value={this.state.owner_object?.email || ""}
                                                                    required={this.state.active}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row mt-4 d-flex justify-content-center">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <button type="submit" className="btn btn-primary" form="form-update-owner"
                                                            disabled={this.state.loading} >
                                                            {this.state.loading && <span
                                                                className="spinner-border spinner-border-sm align-middle mr-2" />}
                                                            {t("resident:Save")}
                                                        </button>
                                                        <Link
                                                            to={{
                                                                pathname: "/register/residential/detail/owner-detail/" + this.props.match.params.id
                                                            }}>
                                                            <button className="btn btn-secondary ml-3" >
                                                                {t("resident:Cancel")}
                                                            </button>
                                                        </Link>
                                                    </div>
                                                </div> */}
                                            </div>

                                        </div>
                                    </form>

                                </div>

                            </div>
                    }
                </Translation>
            </WrapperContent>
        </Wrapper>
        )
    }
}

export default ViewResidentialHistoryDetail;

