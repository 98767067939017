import React, { Component } from 'react'
import _ from "lodash"
import FileInput from '../../../project/projectInfo/componentProjectList/fileInput';
import { Link } from 'react-router-dom';
import "../style/manageRequest.scss"
import i18next from 'i18next';

class formCompanyComparative extends Component {
  render() {
    return (
      <div id="formCompanyComparative">
        <h4>{i18next.t("purchaseRequisition:Comparable company information")}</h4>
        <div className="lineBottom" />

        <div className="row">
          <div className="col-3">
            <h6>{i18next.t("purchaseRequisition:Comparable company amount")}</h6>
            <div className="input-group">
              <input type="number"
                disabled={this.props.disibleInput}
                min='1' max='99'
                className="form-control"
                name="purchaseRequisition.comparativeCompany" 
                value={this.props.purchaseRequisition.comparativeCompany}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>
          <div className="col-3">
            <h6>{i18next.t("purchaseRequisition:Lowest bid cost (Baht)")}</h6>
            <div className="input-group">
              <input type="number"
                disabled={this.props.disibleInput}
                className="form-control"
                name="purchaseRequisition.lowestOffer" 
                value={this.props.purchaseRequisition.lowestOffer}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>
          <div className="col-3">
            <h6>{i18next.t("purchaseRequisition:Maximum bid cost (Baht)")}</h6>
            <div className="input-group">
              <input type="number"
                disabled={this.props.disibleInput}
                className="form-control"
                name="purchaseRequisition.highestOffer" 
                value={this.props.purchaseRequisition.highestOffer}
                onChange={this.props.handleChangeInput}
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <h5>{i18next.t("purchaseRequisition:Comparable company document")}</h5>
          </div>

          <div className="col-12 mt-5 d-flex align-items-center">
            <div className="upload-file">
              <label className={(this.props.disibleInput && `${"bgOpacity"}`) + " btn btn-sm btnAttach"} htmlFor="FileFormCompanyComparytive">
                <img className="iconAttach" src={process.env.PUBLIC_URL + "/images/icons/attachIcon.png"} alt="attachIcon" />
                {i18next.t("purchaseRequisition:Attach file")}
              </label>
              <div className="upload-input">
                {!this.props.disibleInput &&
                  <FileInput
                    id="FileFormCompanyComparytive"
                    name="purchaseRequisition.procurementFormComparative"
                    appendFileInputRow={(fileInput) => this.props.appendFileInputRow("purchaseRequisition.procurementFormComparative", fileInput)}
                    />
                }
              </div>
            </div>
            <span className={(this.props.disibleInput && `${"bgOpacity"}`) + " ml-4 text-danger"}>{i18next.t("purchaseRequisition:Attach document .jpg or .pdf size not over 10 MB")}</span>
          </div>

          <div className="col-4 mt-5">
            <div
              className="slide"
            >
              {_.map(this.props.purchaseRequisition.procurementFormComparative, (item, index) => {
                let fileName = item.node.fileName
                let typeFileName = fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length)

                return (
                  <div className="lineBottomAttach" key={index}>
                    <div className="item">
                      <div className="detail">
                        {typeFileName === "pdf"
                          ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pdfIcon.png"} alt="pdf" />
                          : typeFileName === "png"
                            ? <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/pngIcon.png"} alt="png" />
                            : typeFileName === "jpeg" && <img className="typeIcon" src={process.env.PUBLIC_URL + "/images/typeFiles/jpgIcon.png"} alt="jpeg" />
                        }
                        <Link 
                          to="route"
                          onClick={(event) => { event.preventDefault(); 
                          window.open(
                            typeof item.node.fileUpload === "string"
                              ? item.node.fileUpload
                              : URL.createObjectURL(item.node.fileUpload)
                          ) }} 
                          target="blank">
                            {fileName.length >= 20 ? fileName.substr(0, 20) : fileName}
                        </Link>
                      </div>
                      {!this.props.disibleInput && 
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/cancel-icon.png'}
                          alt="cancel-icon"
                          style={{ width: "10px", height: "10px" }}
                          onClick={() => 
                            this.props.handleRemoveImgSelect(
                              "purchaseRequisition.procurementFormComparative",
                              index
                            )} 
                          />
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default formCompanyComparative;
