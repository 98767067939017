import React , {useState , useEffect} from 'react'
import {Translation} from "react-i18next"
import { Modal} from 'react-bootstrap';
import "../../debtFreeRequest/debtFreeRequest.scss";
import "../debtFreeCertification.scss"
import {commitMutation} from "react-relay";
import Swal from 'sweetalert2';
import environment from '../../../env/environment';
import {graphql} from "babel-plugin-relay/macro";
import i18next from 'i18next';

const mutation = graphql`
  mutation confirmModalMutation($input: UpdateDebsFreeCertificationInput!)  {
    updateDebsFreeCertification (input: $input) {
        ok
        message   
    }
  }
`;

function ConfirmModal(props) {
    const [modalConfirm,setModal] = useState(false)
    const [lifetime,setLifeTime] = useState(props.lifetime)
    const [dateStamp,setDateStamp] = useState(props.dateStamp)
    const [loading,setLoading] = useState(false)
    const [selectedContactID,setSelectedContactID] = useState(props.selectedContactID)

    useEffect(() => {
      if(props.lifetime !== lifetime){
        setLifeTime(props.lifetime)
      }
      if(props.dateStamp !== dateStamp){
        setDateStamp(props.dateStamp)
      }
      if(props.selectedContactID !== selectedContactID){
        setSelectedContactID(props.selectedContactID)
      }
      return () => {
      }
    }, [props.lifetime,props.dateStamp,props.selectedContactID])


    const onSubmit = () => {
      setLoading(true)
      let count = 0
      selectedContactID.forEach((id) => {
        let variables = {
          input : {
            debsFreeCertification : {
              debsFreeCertificationId : id,
              status: 'active',
              printDate : new Date(),
              voidDate : null,
              voidNote : '',
              dateStamp : dateStamp
            }
          }
        }
        commitMutation(
          environment,
          {
              mutation,
              variables,
              onCompleted: (response) => {
                  if (response.updateDebsFreeCertification && response.updateDebsFreeCertification?.ok) {
                      count += 1
                      if(count === selectedContactID.length){
                        Swal.fire(i18next.t("Allaction:Saved Successfully"), '', 'success').then(()=> {
                          setLoading(false)
                          setModal(false)
                          props.updateLoading()
                        })
                      }else{
                        Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(()=>{
                          setLoading(false)
                        })
                      }
                      
                  } else {
                      Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), i18next.t("Allaction:Please check again"), 'warning').then(() => {
                        setLoading(false)
                      })
                  }
              },
              onError: (err) => {
                  Swal.fire('Error!', i18next.t("Allaction:Please try again."), 'warning').then(()=> {
                    setLoading(false)
                  })
              },
          },
      )
      })
    }


    return (
      <Translation>
        {t =>
          <div>
            <React.Fragment>
                <button type="button"  id ="debt-free-certification" className="modal-confirm" onClick={() => setModal(true)}>
                    {/* {this.state.loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>} */}
                    <img src={process.env.PUBLIC_URL + '/images/icons/printer-white.png'} alt="plus" className="mr-1" style={{width:'13px' ,height:'13px'}}/> <span>พิมพ์</span>
                </button>
            </React.Fragment>
            
            <Modal show={modalConfirm} dialogClassName="modal-approve" id="modal">
              <Modal.Body style={{fontFamily:'Kanit' , borderBottom:'none'}}>
                  <div className="row mt-1 mb-1">
                      <div className="col-12 d-flex justify-content-center">
                        <img
                          src={process.env.PUBLIC_URL + '/images/icons/alert-blue.png'}
                          alt="alert" style={{width:'60px' , height:'60px'}}/>
                      </div>
                      <div className="col-12  mt-3 d-flex justify-content-center">
                          <span>การยืนยันการพิมพ์หนังสือ จะมีผลให้วันที่เริ่มพิมพ์เปลี่ยนแปลง และอายุของหนังสือรับรองนี้ จะมีผล {lifetime} วันนับจากวันที่เริ่มพิมพ์ 
                              และไม่สามารถเปลี่ยนแปลงหรือแก้ไขได้อีกในภายหลัง กรุณาตรวจสอบความถูกต้องก่อนพิมพ์ต้องการยินยันการพิมพ์?</span>
                      </div>
                  </div>
              </Modal.Body>
              <Modal.Footer style={{fontFamily:'Kanit' ,borderTop:'none'}}>
                <div className="col-12  mt-3 d-flex justify-content-center">
                    <button type="button" className="btn-confirm mr-2" onClick={() => onSubmit()}>
                        {loading && <span className="spinner-border spinner-border-sm spinner-border-white align-middle mr-2"/>}
                        <span>พิมพ์</span>
                    </button>
                    <button type="button" className="btn-close" onClick={() => setModal(false)}>
                        <span>ปิดหน้าต่าง</span>
                    </button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
          }
      </Translation>
    );
  }

export default ConfirmModal;