import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Translation } from 'react-i18next';
import Swal from 'sweetalert2';


class Navigation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            account: [{}],
            settingSecurity: [{}],
            role: [{}],
            settingCalu: [{}]
        }
        // this.setStates = this.setStates.bind(this)
    }
    componentWillMount() {
        this.getDataCompaynSecurity()
    }

    getDataCompaynSecurity() {
        // API.getCompanySetting().then((res) => {
        //     if (res.data.settingSecuritySystem) {
        //         let data = res.data.settingSecuritySystem
        //         this.setState({ settingSecurity: data, selectedId: data.settingSecuritySystemId })
        //     }else{
        //         this.setState({ settingSecurity: [] })
        //     }
        // })
    }




    alertError() {
        Swal.fire({
            icon: 'error', title: "กรุณาบันทึกบัญชีหลัก"
        })
    }

    render() {
        return (
            <Translation>
                {t =>
                    <div className="row" id="navigation-tab">
                        <div className="col">
                            <ul>
                                <li>
                                    <NavLink exact={true} to="/contact/fix-requests/fortenant/review">
                                        {t("PageList:Review")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/fortenant/pending">
                                        {t("PageList:Pending")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/fortenant/approve">
                                        {t("PageList:Approve")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/fortenant/finish">
                                        {t("PageList:Finish")}
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contact/fix-requests/fortenant/void">
                                        {t("PageList:Cancled")}
                                    </NavLink>
                                </li>
                            </ul>


                        </div>
                    </div>}
            </Translation>
        )
    }
}

export default Navigation;