import '../../document/styles/main.scss';
import React, {Component} from 'react';
import {format} from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../libs/numberWithComma";
import jwtDecode from 'jwt-decode'
import { getTypeProject } from "../../libs/getTypeOfProject"
import { Translation } from "react-i18next";
import i18n from "i18next"
import '../../document/collection_letter/collectionLetter.scss';
import _ from "lodash";
const ThaiBaht = require('thai-baht-text');
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us');

const example_letter = [
    {description : "ค่าส่วนกลาง" , price: 300},
    {description : "ค่าน้ำประปา" , price: 430},
    {description : "ค่าไฟฟ้า" , price: 2500},
    {description : "ค่ารักษามาตรน้ำ" , price: 500},
    {description : "ค่าเบี้ยประกันภัย" , price: 500},
]
class SettingCollectionLetterView extends Component {

    constructor(props){
        super(props);
        this.state = {
            setting_detail : this.props.setting_detail,
            token: jwtDecode(window.localStorage.getItem('token')),
        }

        this.getProductType = this.getProductType.bind(this);
        this.getProductTypeName = this.getProductTypeName.bind(this);
        this.getNumber = this.getNumber.bind(this);
    }

    componentDidUpdate(){
        if(!_.isEqual(this.state.setting_detail, this.props.setting_detail)){
            this.setState({
                setting_detail : _.cloneDeep(this.props.setting_detail)
            })
        }
    }


    getProductType(type) {
        let typeName = ""
        let getType = getTypeProject(type)
        if (getType === 'lowType') {
            this.props.selfProject?.establishmentStatus === 'ALLOCATED' ?
            typeName = i18n.t("PageDetailProjectInfo:Village"):typeName = i18n.t("collectionLetter:Village")
            // typeName = 'หมู่บ้านจัดสรรฯ':typeName = 'หมู่บ้านฯ'
        } else {
            typeName = i18n.t("PageDetailProjectInfo:Condominium")
        }
        return typeName;
    }

    getNumber(type){
        let typeNumer = ""
        if(type === 'first'){
            typeNumer = '1'
        }else if(type === 'second'){
            typeNumer = '2'
        }else if(type === 'third'){
            typeNumer = '3'
        }else{
            typeNumer = '4'
        }
        return typeNumer;
    }

    getProductTypeName(type){
        let typeName = ""
        if(type === 'VILLAGE'){
            typeName = 'เจ้าของบ้าน'
        }else{
            typeName = 'เจ้าของห้องชุด'
        }
        return typeName;
    }

    render() {

        let getType = getTypeProject(this.props.selfProject?.typeOfProject)

        return (
            <React.Fragment>
                <div className="print-page-a4" id="collection-letter" >
                    <div className="subpage" >
                        <div className="head">
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}> 
                                <img src={this.props.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>  
                                <strong>{this.props.selfProject.name}</strong>
                                <div className="address-header">{this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                <div>เลขประจำตัวเสียภาษี {this.props.selfProject.taxIdNumber || '-'}</div>
                            </div> 
                            </div>
                            {
                                this.props.status === "legal-process" ?
                                <div className="document-name">
                                    <div className="title">{i18n.t("collectionLetter:Notice")}</div>
                                </div>:
                                 <div className="document-name">
                                     <div className="title">{i18n.t("collectionLetter:Collection Letter")}</div>
                                </div>
                            }
                           
                        </div>
                        <>
                            <div className="head">
                                <div className="juristic-description" style={{ display: "grid", gridTemplateColumns: "0.80fr 5.5fr" }}>
                                    <div className="sub-title">
                                    <Translation>{t=><strong>{t("collectionLetter:Subject")}</strong>}</Translation> 
                                    </div>
                                    <div>
                                        <div style={{ minWidth: '115%' }}>
                                        <div className="description-letter" style={{fontSize:'12px'}}>
                                        {this.props.status === "legal-process" ? 
                                         i18n.t("collectionLetter:Please make the payment.") :  
                                         i18n.t("collectionLetter:Request for Payment of Maintenance and Public Utility Fees")}
                                        &nbsp;({i18n.t("collectionLetter:No.")}{this.getNumber(this.props.status)}) <br />
                                        {/* {i18n.t("collectionLetter:Office")} &nbsp;{getType && getType === 'lowType' ?                        
                                        this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                        &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}  */}
                                        {i18n.languages[0] === 'th' ? this.state.setting_detail.creatorName : this.state.setting_detail.creatorNameEng}
                                        &nbsp;{i18n.t("collectionLetter:Request to follow up on the outstanding balance of expenses that overdue.")}<br/>
                                        {i18n.t("collectionLetter:The details are as follows.")}
                                         </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="document-name" style={{fontSize:'12px'}}>
                                    <div><strong>{i18n.t("collectionLetter:Date")}:</strong> {format(new Date(), 'DD/MM/YYYY', {locale: thLocale})}</div>
                                    <div><strong>{i18n.t("collectionLetter:No.")}:</strong> CLL2022050001</div>
                                </div>
                            </div>

                            <div className="detail-main row" style={{fontSize:'12px'}}>
                                    <div className="dear">
                                    <Translation>{t=><strong>{t("collectionLetter:To")}</strong>}</Translation> 
                                    </div>
                                   <div className="customer-detail">
                                                            <React.Fragment>
                                                                {/* <strong className="mr-1"> {this.getType(this.props.allCollectionLetters.node.contact.residential.type.slug)}</strong> */}
                                                                <strong className="mr-1">{i18n.t("collectionLetter:lowType")}</strong>
                                                                XXXX
                                                                <br />
                                                                <React.Fragment >
                                                                    <strong className="mr-1">{i18n.t("collectionLetter:Customer")}:</strong>
                                                                    XXXXXXX
                                                                    <br />
                                                                </React.Fragment>
                                                            </React.Fragment>                                                        
                                                        <strong className="mr-1">{i18n.t("collectionLetter:Address")}:</strong>
                                                        
                                                        <br />
                                                    </div>
                            </div> 
                            {this.props.attactDocument?.length && 
                            <div className="sub-detail row">
                                <div className="sub-title">
                                <strong>{i18n.t("collectionLetter:Attached")}:</strong>
                                </div>   
                                <div className="description-letter attachDoc">
                                   1.{i18n.t("settingCollectionDashBoard:Detailed Outstanding Debts Report")}
                                    {/* {this.props.attactDocument?.map((attach, index)=>{
                                        return  <>{index+1}. {attach}<br/></>
                                    })} */}
                                </div>                                
                            </div>}
                        </> 

                        <div className="outstanding-table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={50} className="text-center">{i18n.t("collectionLetter:No.")} </th>
                                        <th  className="text-center">
                                            {i18n.t("collectionLetter:Description")} 
                                        </th>
                                        <th width={180} className="text-center">
                                            {i18n.t("collectionLetter:Amount")}  
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {example_letter.map((value , index) => {
                                        return (
                                            <tr key={index+value.description}>
                                                <td className="text-center">{index+1}</td>
                                                <td>
                                                    {value.description}
                                                </td>
                                                <td className="text-right">
                                                    {numberWithComma(value.price,0)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="row">
                                                <div className={this.props.example ? "col-2" : "col-1"}>
                                                    <strong>{i18n.t("collectionLetter:Total")}</strong>
                                                </div>
                                                <div className={this.props.example ? "col-8 text-center" :"col-10 text-center"}>
                                                  
                                                    {
                                                         i18n.languages[0] === 'th' ? <strong>({ThaiBaht(_.sumBy(example_letter,'price'))})</strong> : 
                                                          <strong>({ numberToText.convertToText(parseInt(_.sumBy(example_letter,'price')))})</strong>
                                                    }
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <strong>{numberWithComma(_.sumBy(example_letter,'price'),0)}</strong>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                        <div className="collection-description">
                            <strong>{i18n.t("collectionLetter:I hereby request to please pay the overdue expenses.")}</strong> <strong>{i18n.t("collectionLetter:Total Amount")}</strong> {numberWithComma(_.sumBy(example_letter,'price'),0)}&nbsp;{i18n.t("newBookingSingha_Setting:Baht")}&nbsp;{i18n.t("collectionLetter:Within")} {this.state.setting_detail.numberOfDays}{i18n.t("collectionLetter:days from the date of issue")}<br/>
                            <br/><div className="tab-description">{i18n.languages[0] === 'th' ? this.state.setting_detail.description :this.state.setting_detail.descriptionEng }</div>
                            <div className="tab-description">{i18n.languages[0] === 'th' ? this.state.setting_detail.descriptionSecond : this.state.setting_detail.descriptionSecondEng}</div>
                            {(this.state.setting_detail.payment_channel.cash.paymentTimeStatus || this.state.setting_detail.payment_channel.cheque.paymentCheck || this.state.setting_detail.payment_channel.bank.bankAccountStatus) &&
                                <React.Fragment>
                                    <div className="pay-channel" >
                                            <strong>{i18n.t("collectionLetter:Payment method")}</strong><br/>
                                    {this.state.setting_detail.payment_channel.cash.paymentTimeStatus && 
                                        <React.Fragment>
                                            - {i18n.t("collectionLetter:Payment can be made in cash at the legal office.")} ({i18n.t("collectionLetter:Business hours")} {format(this.state.setting_detail.payment_channel.cash.paymentTime1, "HH:mm")} น. - {format(this.state.setting_detail.payment_channel.cash.paymentTime2, "HH:mm")} น)<br/>
                                        </React.Fragment>
                                    }
                                    {this.state.setting_detail.payment_channel.cheque.paymentCheck && 
                                        <React.Fragment> 
                                            {/* - {i18n.t("collectionLetter:Pay by check made out to")} {this.props.selfProject.name}<br/> */}
                                            - {i18n.t("collectionLetter:Pay by check made out to")}   {i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}<br/>
                                        </React.Fragment>
                                    }
                                    {this.state.setting_detail.payment_channel.bank.bankAccountStatus &&
                                        <React.Fragment> 
                                            - {i18n.t("collectionLetter:Transfer via bank")} {this.state.setting_detail.payment_channel.bank.bank_payment.bankName} &nbsp;{i18n.t("payChannel:Branch")}&nbsp; {this.state.setting_detail.payment_channel.bank.bank_payment.branch} {i18n.t("payChannel:Account number")}&nbsp; {this.state.setting_detail.payment_channel.bank.bank_payment.accountNumber} {i18n.t("payChannel:Account name")}&nbsp; {i18n.languages[0] === 'th' ? this.state.setting_detail.payment_channel.bank.bank_payment.accountName : this.state.setting_detail.payment_channel.bank.bank_payment.accountNameEn}  <br/>
                                        </React.Fragment>
                                    }  
                                    </div>
                                    <br/>
                                </React.Fragment>
                            }
                               
                                <div className="tab-description">
                                    {i18n.t("collectionLetter:Bring a copy of the evidence of deposit(PAY IN SLIP) contact to receive a receipt at the office")}
                                    &nbsp;{getType && getType === 'lowType' ?                        
                                    this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                    &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} {this.state.setting_detail.payment_channel.channelDescription !== '' ? i18n.t("collectionLetter:Or send by") +" "+this.state.setting_detail.payment_channel.channelDescription : ''} {i18n.t("collectionLetter:In this regard,")}
                                    &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType && getType === 'lowType'?                            
                                    this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                     &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}.&nbsp;{i18n.t("collectionLetter:A receipt will be issued to you as evidence.")}

                                    {/* {this.state.token?.site?.includes('victorymanagement') || this.state.token?.site?.includes('svmsupport-admin.silverman') || this.state.token?.site?.includes('test.port80.me') ? 'ฝ่ายบริหาร':'นิติบุคคล'}
                                    {this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} {this.state.setting_detail.payment_channel.channelDescription !== '' ? 'หรือส่งทาง ' + this.state.setting_detail.payment_channel.channelDescription : ''}  
                                    ทั้งนี้{this.state.token?.site?.includes('victorymanagement') || this.state.token?.site?.includes('svmsupport-admin.silverman') || this.state.token?.site?.includes('test.port80.me')? 'ฝ่ายบริหาร':'นิติบุคคล'}{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)} จะได้ออกใบเสร็จรับเงินให้แก่ท่านเพื่อเป็นหลักฐานต่อไป */}
                                </div>
                            <div className="mt-4">
                                {i18n.t("collectionLetter:If you have any questions, please contact")} {this.state.setting_detail.contactName ? this.state.setting_detail.contactName : "..............................................................."} 
                                &nbsp;{i18n.t("collectionLetter:Phone")} {this.state.setting_detail.contactPhone ? this.state.setting_detail.contactPhone : "..............................................................."} &nbsp;{i18n.t("collectionLetter:In this regard,")}
                                &nbsp;{i18n.t("collectionLetter:Office")}&nbsp;{getType && getType === 'lowType' ?                        
                                " "+this.props.selfProject?.establishmentStatus === 'ALLOCATED' ? i18n.t("collectionLetter:Juristic Person"):i18n.t("collectionLetter:Management"):i18n.t("collectionLetter:Juristic Person")}
                                &nbsp;{this.props.selfProject?.typeOfProject && this.getProductType(this.props.selfProject?.typeOfProject)}
                                &nbsp;{i18n.t("collectionLetter:We apologize if you have already paid the fees before receiving this letter")} 
                            </div>  
                        </div>
                        <div className="footer">
                            <div className="signature">
                                {i18n.t("collectionLetter:Sincerely,")}
                                <br/>
                                <br/>
                                ............................................................... <br/>
                                ({this.state.setting_detail.signerName ? this.state.setting_detail.signerName : "..............................................................."})<br/>
                                {this.state.setting_detail.signerPosition ? this.state.setting_detail.signerPosition : "..............................................................."}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }                   
}

export default SettingCollectionLetterView;