/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QuickMassageTypeNote = "INVOICE" | "PAYMENT_QUOTATION" | "QUOTATION" | "RECEIVED" | "%future added value";
export type PurchaseSummaryQueryVariables = {|
  type?: ?string
|};
export type PurchaseSummaryQueryResponse = {|
  +quickMassageViewer: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +massageNote: ?string,
        +typeNote: QuickMassageTypeNote,
        +creator: ?string,
      |}
    |}>
  |}
|};
export type PurchaseSummaryQuery = {|
  variables: PurchaseSummaryQueryVariables,
  response: PurchaseSummaryQueryResponse,
|};
*/


/*
query PurchaseSummaryQuery(
  $type: String
) {
  quickMassageViewer(typeNote: $type) {
    edges {
      node {
        id
        massageNote
        typeNote
        creator
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "typeNote",
        "variableName": "type"
      }
    ],
    "concreteType": "QuickMassageNodeConnection",
    "kind": "LinkedField",
    "name": "quickMassageViewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuickMassageNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "QuickMassageNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "massageNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeNote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creator",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PurchaseSummaryQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PurchaseSummaryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "bdaacd2a422b8d9f6493a6341a22e36c",
    "id": null,
    "metadata": {},
    "name": "PurchaseSummaryQuery",
    "operationKind": "query",
    "text": "query PurchaseSummaryQuery(\n  $type: String\n) {\n  quickMassageViewer(typeNote: $type) {\n    edges {\n      node {\n        id\n        massageNote\n        typeNote\n        creator\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3655e0bff23b36105e42ed6c2caa6c76';

module.exports = node;
