import React, { Component } from "react";
import numberWithComma from "../../../libs/numberWithComma";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import LightBox from "../../../libs/lightBox";
import { Translation } from "react-i18next";
import '../styles/asset.scss'


class PayChannel extends Component {

    render() {
        
        return (
            <div className= "mt-3" id='payChannel'>
                <div className="card-body">
                    <div className="row">
                            <div className="col">
                            {
                                this.props.cashTransaction && this.props.cashTransaction.length !== 0 &&
                                <div className="row col-12 mb-2">
                                    <Translation>
                                        {t=>
                                        <span>เงินสด/Cash {numberWithComma(this.props.cashTransaction.reduce(function (total, obj) {
                                            return total + parseFloat(obj.node.price)
                                        }, 0))} {t("createReceive:Baht")}</span>}
                                    </Translation>
                                </div>
                            }
                            {
                                this.props.creditCardTransaction && this.props.creditCardTransaction.length !== 0 &&
                                <div className="row col-12 mb-2">
                                    <Translation>
                                        {t=>
                                        <span>บัตรเครดิต/Credit Card {numberWithComma(this.props.creditCardTransaction.reduce(function (total, obj) {
                                            return total + parseFloat(obj.node.amount)
                                        }, 0))} {t("createReceive:Baht")}</span>}
                                    </Translation>
                                </div>
                            }
                            {
                                this.props.bankTransaction && this.props.bankTransaction.length !== 0 &&
                                <div className="row col-12 mb-2">
                                    <span>เงินโอน/Bank Transfer</span>
                                    {
                                        this.props.bankTransaction.map((bank) => (
                                            <div className="row col-12" key={bank.node.id}>
                                                <Translation>
                                                    {t=>
                                                    <span>-&emsp;{t("createReceive:Bank")}&nbsp;&nbsp;{bank.node.bankAccount.bankName}
                                                        &emsp;{t("createReceive:Account No.")}&nbsp;&nbsp;{bank.node.bankAccount.accountNumber}
                                                        &emsp;{t("createReceive:date")}&nbsp;&nbsp;{format(bank.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                        &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(bank.node.price)} {t("createReceive:Baht")}</span>}
                                                </Translation>

                                                <div className="row col-12">
                                                    {
                                                        bank.node.imageSlip &&
                                                        <div className="p-2">
                                                            <LightBox image={bank.node.imageSlip} />
                                                        </div>
                                                    }
                                                    {
                                                        bank.node.imageSlipAdditional &&
                                                        <div className="p-2">
                                                            <LightBox image={bank.node.imageSlipAdditional} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {this.props.chequeTransaction && this.props.chequeTransaction.length > 0 &&
                                <div className="row col-12 mb-2">
                                    <span>เช็คธนาคาร/Cheque Bank</span>
                                    {
                                        this.props.chequeTransaction.map((cheque) => (
                                            <div className="row col-12" key={cheque.node.id}>
                                                <Translation>
                                                    {t=>
                                                    <span>-&emsp;{t("createReceive:Cheque No.")}&nbsp;&nbsp;{cheque.node.chequeNumber}
                                                    &emsp;{cheque.node.bankName}
                                                    &emsp;{t("createReceive:Branch")}&nbsp;&nbsp;{cheque.node.branchNumber}
                                                    &emsp;{t("createReceive:date")}&nbsp;&nbsp;{format(cheque.node.date, 'DD/MM/YYYY', { locale: thLocale })}
                                                    &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(cheque.node.price)} {t("createReceive:Baht")}</span>}
                                                </Translation>
                                                {cheque.node.chequeImage &&
                                                    <div className="row col-12">
                                                        <div className="p-2">
                                                            <LightBox image={cheque.node.chequeImage} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                this.props.unknownReceiveTransaction && this.props.unknownReceiveTransaction.length !== 0 &&
                                <div className="row col-12 mb-2">
                                    <span>เงินรับรอการตรวจสอบ/Unknown Receive </span>
                                    {
                                        this.props.unknownReceiveTransaction.map((unknown) => (
                                            <div className="row col-12" key={unknown.node.id}>
                                                <Translation>
                                                    {t=>
                                                    <span>-&emsp;{unknown.node.description}
                                                        &emsp;{t("createReceive:Amount")}&nbsp;&nbsp;{numberWithComma(unknown.node.amount)}{t("createReceive:Baht")}
                                                    </span>
                                                    }
                                                </Translation>
                                            </div>
                                        ))
                                    }
                                </div>
                            }

                        </div>
                    </div>
                    
                </div>  
            </div>
        )
    }
}

export default PayChannel