import React, { Component } from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import '../styles/register.scss'

import Navigation from "./navigation";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import _ from "lodash";
import { format } from 'date-fns';

const allResidential = graphql`
    query roomDetailQuery($id:ID!){
        residential(id: $id){
            id
            developer
            name
            floor
            size
            status
            otherStatus
            address
            ownershipNumber
            ownershipRatio
            homeNumber
            villageBuilding
            villageNumber
            lane
            soi
            plan
            postalCode
            province
            district
            subDistrict
            mailingVillageBuilding
            mailingVillageNumber
            mailingLane
            mailingPostalCode
            mailingProvince
            mailingDistrict
            mailingSubDistrict
            leaseCode
            phase
            building
            waterMeterCode
            electricMeterCode
            dateConveyance
            dateStay
            decorating
            dateBox
            address
            ownershipNumber
            unitNumber

            # ที่อยู๋ผู้เช่า
            renterHomeNumber
            renterVillageBuilding
            renterVillageNumber
            renterLane
            renterSoi
            renterProvince
            renterDistrict
            renterSubDistrict
            renterPostalCode

            # ที่อยู๋ตัวแทน
            agentHomeNumber
            agentVillageBuilding
            agentVillageNumber
            agentLane
            agentSoi
            agentProvince
            agentDistrict
            agentSubDistrict
            agentPostalCode

            residentialDocument{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            deed{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            residentialLeaseDocument{
                edges{
                    node{
                        id
                        fileName
                        fileUpload
                    }
                }
            }
            residentialHouseholder(type:"owner"){
                edges{
                    node{
                        id
                        tenant{
                            firstName
                            lastName
                            lease{ edges { node { id fileName fileUpload } } }
                        }
                    }
                }
            }

            residentialHouseholderRent : residentialHouseholder(type:"rent"){
                edges{
                    node{
                        id
                        tenant{
                            firstName
                            lastName
                            lease{ edges { node { id fileName fileUpload } } }
                        }
                    }
                }
            }
            type{
                id
                name
            }
            project{
                typeOfProject
            }
            conveyance
            owmerInsure{
                   edges{
                      node{
                          id
                          expDate
                          insure {
                            id
                            name
                            description
                        }
                      } 
                   } 
                }
            obligationDeveloper{
                   edges{
                      node{
                          id
                          dueDate
                          productAndService{
                              id
                              name
                          }
                      } 
                   } 
                }
            car{
                   edges{
                      node{
                          id
                          carBrand
                      } 
                   } 
                }
        }
        userConfig {
            id languages
        }

    }
`;

class RoomDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.match.params.id,
            edit: false,
            dateBox: '',
            waterMeterCode: '',
            electricMeterCode: '',

            status: "owner",
        };

        this.getPrefix = this.getPrefix.bind(this);
        this.isBangkok = this.isBangkok.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    isBangkok(code) {
        let bangkokCode = [10600, 10510, 10110, 10230, 10900, 10150, 10210, 10400, 10300,
            10170, 10170, 10140, 10600, 10700, 10600, 10240, 10150, 10120, 10800, 10260, 10150,
            10700, 10500, 10220, 10160, 10240, 10330, 10250, 10100, 10400, 10200, 10260, 10160, 10510,
            10120, 10400, 10140, 10520, 10230, 10310, 10110, 10250, 10240, 10100, 10120, 10220, 10530,
            10160, 10210, 10310];
        if (_.indexOf(bangkokCode, parseInt(code)) >= 0) {
            return true;
        }
        return false;
    }

    getPrefix(languages = "th", type, code) {
        if (type === "subdistrict" && this.isBangkok(code)) {
            return languages == "th" ? "แขวง" : "District "
        } else if (type === "district" && this.isBangkok(code)) {
            return languages == "th" ? "เขต" : "County "
        } else if (type === "province" && this.isBangkok(code)) {
            return ""
        } else if (type === "province") {
            return languages == "th" ? "จ." : "Province "
        } else if (type === "subdistrict") {
            return languages == "th" ? "ต." : "District "
        } else if (type === "district") {
            return languages == "th" ? "อ." : "County "
        } else if (type === "lane") {
            return languages == "th" ? "ถ." : "Road "
        } else if (type === "soi") {
            return languages == "th" ? "ซ." : "Soi "
        } else if (type === "villageNumber") {
            return languages == "th" ? "หมู่ที่" : "Moo "
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.closeMenu);
    }
    closeMenu() { }


    render() {
        let textData = JSON.parse(localStorage.getItem("dataTextExtraMenu"));
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <WrapperContent>
                    <Translation>
                        {
                            t =>
                                <QueryRenderer
                                    environment={environment}
                                    query={allResidential}
                                    cacheConfig={{ use_cache: false }}
                                    variables={{ "id": this.state.id }}
                                    render={({ error, props }) => {
                                        if (error) {
                                            return <div>{error.message}</div>;
                                        } else if (props) {
                                            let languages = props?.userConfig?.languages || "th"
                                            let owner_name = ''
                                            let unitNumber = props?.residential?.unitNumber
                                            props.residential.residentialHouseholder.edges.forEach((element, index) => {
                                                if (index + 1 === props.residential.residentialHouseholder.edges.length) owner_name = owner_name + element.node.tenant.firstName + ' ' + element.node.tenant.lastName
                                                else owner_name = owner_name + element.node.tenant.firstName + ' ' + element.node.tenant.lastName + ','
                                            })

                                            return (
                                                <div className="container-fluid" id="detail">
                                                    <br />
                                                    <div className="row justify-content-between">
                                                        <div className="col header-color">
                                                            <h4 className="mb-4">
                                                                <Link to="/register/residential/list/all">
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                                        alt="back" className="back" />
                                                                </Link>
                                                                {props.residential.name}
                                                            </h4>
                                                            <div className="content-inner">
                                                                <Navigation id={props.residential.id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="content-inner" key={props.residential.id}>
                                                        <React.Fragment>
                                                            <div className="col-3">
                                                                <h5 className="mb-4 ml-3 float-left">
                                                                    <span>{t('register:detail')}</span>
                                                                </h5>
                                                            </div>
                                                            <div className="col-3">
                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'tenant_tenant_edit' }) &&
                                                                    <span className="float-left mb-2 ml-3">
                                                                        <Link
                                                                            to={{ pathname: "/register/residential/detail/form-room-detail/" + this.state.id + "/" + props.residential.id }}>
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + '/images/icons/edit.png'}
                                                                                alt="project-info-icon" />
                                                                            <span className="grey-color  ml-1">{t("residential:Edit")}</span>
                                                                        </Link>
                                                                    </span>
                                                                    // <span className="float-right mb-2 ml-3 cursor" onClick={() => this.setState({edit : true})}>
                                                                    //     <img src={process.env.PUBLIC_URL + '/images/icons/edit-blue-color.png'} alt="edit"/>
                                                                    //     <span className="text-blue"> {t("residential:Edit")}</span>
                                                                    // </span>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                    <div className="row mb-4 mt-5" style={{ clear: "both" }}>
                                                        <div className="col-md-12 col-xl-10 mx-auto">
                                                            <div className="row">
                                                                <div className="col-md-7 col-xl-7 mx-auto">
                                                                    <div className="card show mt-2">
                                                                        <div className="card-body">
                                                                            <div className="col mt-5">
                                                                                <React.Fragment>
                                                                                    {
                                                                                        <div className="row  mb-3">
                                                                                            <div className="col-4">
                                                                                                <span>{t("residential:Unit No")}</span>
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                {unitNumber ? unitNumber : '-'}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </React.Fragment>

                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {props.residential.project.typeOfProject === "CONDOMINIUM" ?
                                                                                            <span>{t("residential:Room No.")}</span> : <span>{t("residential:House No.")}</span>}
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {props.residential.name}
                                                                                    </div>
                                                                                </div>
                                                                                {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'tenant_tenant_print' }) &&
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-4">{t("residential:Create member code")}</div>
                                                                                        <div className="col">
                                                                                            {/* <Link
                                                                                    to={"/register/residential/account/gen-code-a5/owner/" + props.residential.id}
                                                                                    className="mr-3">
                                                                                    เจ้าของบ้าน
                                                                                </Link> */}
                                                                                            {props.residential.developer ?
                                                                                            <>
                                                                                                <span className="mr-3">{t("residential:Resident")}</span>
                                                                                                <span className="mr-3">{t("residential:Tenant")}</span>
                                                                                                <span>{t("residential:Agent")}</span>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <Link
                                                                                                    to={"/register/residential/account/gen-code-a5/resident/" + props.residential.id}
                                                                                                    className="mr-3">
                                                                                                    {
                                                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                                    textData?.data?.roomDetail.resident    
                                                                                                        :
                                                                                                    t("residential:Resident")
                                                                                                    }
                                                                                                </Link>
                                                                                                <Link
                                                                                                    to={"/register/residential/account/gen-code-a5/renter/" + props.residential.id}
                                                                                                    className="mr-3">
                                                                                                    {
                                                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                                    textData?.data?.roomDetail.tenant    
                                                                                                        :                                                                                                    
                                                                                                    t("residential:Tenant")
                                                                                                    }
                                                                                                </Link>
                                                                                                <Link
                                                                                                    to={"/register/residential/account/gen-code-a5/agent/" + props.residential.id}>
                                                                                                    {t("residential:Agent")}
                                                                                                </Link>
                                                                                            </>}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className="row mb-3">
                                                                                    <div className="col-4">
                                                                                        {
                                                                                            props.residential.project.typeOfProject === "CONDOMINIUM" ?
                                                                                                <>{t("residential:Floor")}</> : <>{t("residential:Number of floor")}</>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {props.residential.floor}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Area_size")}
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {props.residential.size}
                                                                                        {
                                                                                            props.residential.project.typeOfProject === "CONDOMINIUM" ?
                                                                                                t("residential:Sq m") : t("residential:Sq wa")
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Ownership ratio")}
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {props.residential.ownershipRatio ? props.residential.ownershipRatio : '-'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Status")}
                                                                                    </div>
                                                                                    <div className="col">
                                                                                        {
                                                                                            props.residential.status === 'FOR_RENT' ?
                                                                                            _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                                textData?.data?.roomDetail.forRent    
                                                                                                :       
                                                                                                <>{t("residential:For rent")}</>
                                                                                                :
                                                                                                props.residential.status === 'LIVE' ?
                                                                                                    <>{t("residential:Live")}</>
                                                                                                    :
                                                                                                    props.residential.status === 'SEIZURE' ?
                                                                                                        <>{t("residential:Seizure")}</>
                                                                                                        :
                                                                                                        props.residential.status === 'AVAILABILITY' ?
                                                                                                            <>{t("residential:Availability")}</> 
                                                                                                            :
                                                                                                            props.residential.status === 'OTHER' ? props.residential.otherStatus ? props.residential.otherStatus && <> {props.residential.otherStatus} </>:
                                                                                                                <>{t("residential:Other")}</>: <>{t("residential:Other")}</>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Lease agreement no.")}
                                                                                    </div>
                                                                                    <div className="col ">
                                                                                        {props.residential.leaseCode ? props.residential.leaseCode : '-'}
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    props.residential.status === 'FOR_RENT' &&
                                                                                    <div className="row mb-3">
                                                                                        <div className="col-4">
                                                                                            {t("residential:Lease agreement")}
                                                                                            {/* Rental contract */}
                                                                                        </div>
                                                                                        <div className="col ">
                                                                                            {props.residential.residentialHouseholderRent?.edges.map((e, inx) => {
                                                                                                return (
                                                                                                    e.node.tenant?.lease?.edges.map((file, inx) => {
                                                                                                        let typeFile = file.node.fileName.split(".")
                                                                                                        return (
                                                                                                            file.node.status !== "delete" &&
                                                                                                            <p key={inx}>
                                                                                                                <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                                <Link
                                                                                                                    to="route"
                                                                                                                    onClick={(event) => {
                                                                                                                        event.preventDefault();
                                                                                                                        window.open(
                                                                                                                            typeof file.node.fileUpload === "string"
                                                                                                                                ? file.node.fileUpload
                                                                                                                                : URL.createObjectURL(file.node.fileUpload)
                                                                                                                        )
                                                                                                                    }}
                                                                                                                    target="blank">
                                                                                                                    {file.node.fileName}
                                                                                                                </Link>
                                                                                                            </p>
                                                                                                        )
                                                                                                    })
                                                                                                )
                                                                                            })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Type")}
                                                                                    </div>
                                                                                    <div className="col ">
                                                                                        {props.residential.type.name}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Phase")}
                                                                                    </div>
                                                                                    <div className="col ">
                                                                                        {props.residential.phase ? props.residential.phase : '-'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Plan")}
                                                                                    </div>
                                                                                    <div className="col ">
                                                                                        {props.residential.plan ? props.residential.plan : '-'}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row  mb-3">
                                                                                    <div className="col-4">
                                                                                        {t("residential:Building no.")}
                                                                                    </div>
                                                                                    <div className="col ">
                                                                                        {props.residential.building ? props.residential.building : '-'}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card show mt-3">
                                                                        <div className="card-body">
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Electric meter no.")}
                                                                                </div>
                                                                                <div className="col">
                                                                                    {props.residential.electricMeterCode ? props.residential.electricMeterCode : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Water meter no.")}
                                                                                </div>
                                                                                <div className="col">
                                                                                    {props.residential.waterMeterCode ? props.residential.waterMeterCode : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Transfer status by developer")}
                                                                                </div>
                                                                                <div className="row ml-1">                                                                                                                                                <div className="col">
                                                                                    <div
                                                                                        className="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio"
                                                                                            className="custom-control-input"
                                                                                            checked={props.residential.conveyance === 'ALREADY_TRANSFERRED'}
                                                                                            disabled />
                                                                                        <label
                                                                                            className="custom-control-label">{t("residential:Transferred  ownership")}</label>
                                                                                    </div>
                                                                                </div>
                                                                                    <div>
                                                                                        <div
                                                                                            className="custom-control custom-radio custom-control-inline">
                                                                                            <input type="radio"
                                                                                                className="custom-control-input"
                                                                                                checked={props.residential.conveyance === 'NOT_TRANSFERRED'}
                                                                                                disabled />
                                                                                            <label
                                                                                                className="custom-control-label">{t("residential:Not transfer ownership")}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Latest transfer date")}
                                                                                </div>
                                                                                <div className="col">
                                                                                    {props.residential.dateConveyance ? format(props.residential.dateConveyance, 'DD/MM/YYYY') : '-'}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {
                                                                                    _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                    textData?.data?.roomDetail.ownership    
                                                                                        :                                                                                               
                                                                                    t("residential:Ownership")
                                                                                    }
                                                                                </div>
                                                                                <div className="col">
                                                                                    {owner_name ?
                                                                                        <div>
                                                                                            {owner_name}
                                                                                        </div> :
                                                                                        <div>-</div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Stay date")}
                                                                                </div>
                                                                                <div className="col ">
                                                                                    {props.residential.dateStay ? props.residential.dateStay : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card show mt-3">
                                                                        <div className="card-body">
                                                                            <div className="row  mb-3">
                                                                                <div className="col-4">
                                                                                    {t("residential:Decorating")}
                                                                                </div>
                                                                                <div className="col ">
                                                                                    {props.residential.decorating ? props.residential.decorating : '-'}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-md-5 col-xl-5 mx-auto">
                                                                    {/* ที่อยู่ในการออกเอกสาร */}
                                                                    <div className="card show mt-2">
                                                                        <div className="card-body colorUse">
                                                                            < h6 className="mb-4 float-left" > {t('register:register_address')}</ h6>
                                                                            <div className="clearfix" />
                                                                            {/* <AddressDetail residential={props.residential} languages={languages} /> */}
                                                                            <div className="row" id="navigation-tab">
                                                                                <div className="col">
                                                                                    <ul>
                                                                                        <li className={`nav-item ${this.state.status === "owner" ? "active" : ""}`}>
                                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ status: "owner" })}> {t("AgentRole:Resident/Co-owner")}</a>
                                                                                        </li>
                                                                                        <li className={`nav-item ${this.state.status === "renter" ? "active" : ""}`}>
                                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ status: "renter" })}> {t("AgentRole:Rent")}</a>
                                                                                        </li>
                                                                                        <li className={`nav-item ${this.state.status === "agent" ? "active" : ""}`}>
                                                                                            <a className={`nav-link`} href="#" onClick={() => this.setState({ status: "agent" })}> {t("AgentRole:Agent")}</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>

                                                                            {
                                                                                this.state.status === "owner" ?
                                                                                    <React.Fragment>
                                                                                        <p>{(props.residential.homeNumber || "-") + " "}
                                                                                                {props.residential.villageBuilding && " " + props.residential.villageBuilding + " "}
                                                                                                {props.residential.villageNumber && this.getPrefix(languages, "villageNumber") + props.residential.villageNumber + " "}
                                                                                                {props.residential.soi && this.getPrefix(languages, "soi") + props.residential.soi + " "}
                                                                                                {props.residential.lane && this.getPrefix(languages, "lane") + props.residential.lane + " "}
                                                                                                {props.residential.subDistrict && this.getPrefix(languages, "subdistrict", props.residential.postalCode) + props.residential.subDistrict + " "}
                                                                                                {props.residential.district && this.getPrefix(languages, "district", props.residential.postalCode) + props.residential.district + " "}
                                                                                                {props.residential.province && this.getPrefix(languages, "province", props.residential.postalCode) + props.residential.province + " "}
                                                                                                {props.residential.postalCode}
                                                                                            </p>
                                                                                    </React.Fragment>
                                                                                    :
                                                                                    this.state.status === "renter" ?
                                                                                        <React.Fragment>
                                                                                            <p>{(props.residential.renterHomeNumber || "-") + " "}
                                                                                                {props.residential.renterVillageBuilding && " " + props.residential.renterVillageBuilding + " "}
                                                                                                {props.residential.renterVillageNumber && this.getPrefix(languages, "villageNumber") + props.residential.renterVillageNumber + " "}
                                                                                                {props.residential.renterSoi && this.getPrefix(languages, "soi") + props.residential.renterSoi + " "}
                                                                                                {props.residential.renterLane && this.getPrefix(languages, "lane") + props.residential.renterLane + " "}
                                                                                                {props.residential.renterSubDistrict && this.getPrefix(languages, "subdistrict", props.residential.renterPostalCode) + props.residential.renterSubDistrict + " "}
                                                                                                {props.residential.renterDistrict && this.getPrefix(languages, "district", props.residential.renterPostalCode) + props.residential.renterDistrict + " "}
                                                                                                {props.residential.renterProvince && this.getPrefix(languages, "province", props.residential.renterPostalCode) + props.residential.renterProvince + " "}
                                                                                                {props.residential.renterPostalCode}
                                                                                            </p>
                                                                                        </React.Fragment>
                                                                                        :
                                                                                        <React.Fragment>
                                                                                            <p>{(props.residential.agentHomeNumber || "-") + " "}
                                                                                                {props.residential.agentVillageBuilding && " " + props.residential.agentVillageBuilding + " "}
                                                                                                {props.residential.agentVillageNumber && this.getPrefix(languages, "villageNumber") + props.residential.agentVillageNumber + " "}
                                                                                                {props.residential.agentSoi && this.getPrefix(languages, "soi") + props.residential.agentSoi + " "}
                                                                                                {props.residential.agentLane && this.getPrefix(languages, "lane") + props.residential.agentLane + " "}
                                                                                                {props.residential.agentSubDistrict && this.getPrefix(languages, "subdistrict", props.residential.agentPostalCode) + props.residential.agentSubDistrict + " "}
                                                                                                {props.residential.agentDistrict && this.getPrefix(languages, "district", props.residential.agentPostalCode) + props.residential.agentDistrict + " "}
                                                                                                {props.residential.agentProvince && this.getPrefix(languages, "province", props.residential.agentPostalCode) + props.residential.agentProvince + " "}
                                                                                                {props.residential.agentPostalCode}
                                                                                            </p>
                                                                                        </React.Fragment>


                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className='card show mt-2'>
                                                                        <div className='card-body'>
                                                                            <div className='row'>
                                                                                <div className='col'>
                                                                                    <h6 className="mb-4 float-left">
                                                                                        {
                                                                                        _.find(JSON.parse(localStorage.getItem("allExtraMenu")), ['menu_text', 'projectMenu'])?.menu_on ?
                                                                                        textData?.data?.roomDetail.otherDocuments    
                                                                                            :                                                                                                   
                                                                                        t('residential:Deed and other documents')}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-4">
                                                                                    {t('residential:Deed')}
                                                                                </div>
                                                                                <div className="col-12 ">
                                                                                    {props.residential.deed?.edges.length > 0 ?
                                                                                        props.residential.deed?.edges.map((file, inx) => {
                                                                                            let typeFile = file.node.fileName.split(".")
                                                                                            return (
                                                                                                file.node.status !== "delete" &&
                                                                                                <p key={inx}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                typeof file.node.fileUpload === "string"
                                                                                                                    ? file.node.fileUpload
                                                                                                                    : URL.createObjectURL(file.node.fileUpload)
                                                                                                            )
                                                                                                        }}
                                                                                                        target="blank">
                                                                                                        {file.node.fileName}
                                                                                                    </Link>
                                                                                                </p>
                                                                                            )
                                                                                        })

                                                                                        : "-"}
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-4">
                                                                                    {t('register:Other documents')}
                                                                                </div>
                                                                                <div className="col-12 ">
                                                                                    {props.residential.residentialDocument?.edges.length > 0 ?
                                                                                        props.residential.residentialDocument?.edges.map((file, inx) => {
                                                                                            let typeFile = file.node.fileName.split(".")
                                                                                            return (
                                                                                                file.node.status !== "delete" &&
                                                                                                <p key={inx}>
                                                                                                    <img src={process.env.PUBLIC_URL + "/images/typeFiles/" + typeFile[1] + "Icon.png"} style={{ width: '30px' }} /> &nbsp;

                                                                                                    <Link
                                                                                                        to="route"
                                                                                                        onClick={(event) => {
                                                                                                            event.preventDefault();
                                                                                                            window.open(
                                                                                                                typeof file.node.fileUpload === "string"
                                                                                                                    ? file.node.fileUpload
                                                                                                                    : URL.createObjectURL(file.node.fileUpload)
                                                                                                            )
                                                                                                        }}
                                                                                                        target="blank">
                                                                                                        {file.node.fileName}
                                                                                                    </Link>
                                                                                                </p>
                                                                                            )
                                                                                        })

                                                                                        : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className="card show mt-3">
                                                                        <div className="card-body">
                                                                            <div className="row  mb-3">
                                                                                <div className="col-6">
                                                                                    <strong>
                                                                                        {t('register:Insured')}
                                                                                    </strong>
                                                                                </div>
                                                                                <div className="col ">
                                                                                    <strong>
                                                                                        {t('register:Due date')}
                                                                                    </strong>
                                                                                </div>
                                                                            </div>
                                                                            {props.residential.owmerInsure.edges.length > 0 ?
                                                                                props.residential.owmerInsure.edges.map((insure, index) => {
                                                                                    return (
                                                                                        <div className="row mb-3" key={insure.node.id}>
                                                                                            <div className="col-1">
                                                                                                <input type="checkbox" disabled={true} checked={true} />
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                {insure.node.insure.name}
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                {format(insure.node.expDate, 'DD/MM/YYYY')}
                                                                                            </div>
                                                                                        </div>

                                                                                    );
                                                                                })

                                                                                :

                                                                                <div className="row mb-3">
                                                                                    <div className="col">
                                                                                        -
                                                                                    </div>
                                                                                </div>

                                                                            }
                                                                            <div className="row mt-4 mb-3">
                                                                                <div className="col-6">
                                                                                    <strong>
                                                                                        {t('register:Marketing policy')}
                                                                                    </strong>
                                                                                </div>
                                                                                <div className="col ">
                                                                                    <strong>
                                                                                        {t('register:Due date')}
                                                                                    </strong>
                                                                                </div>
                                                                            </div>
                                                                            {props.residential.obligationDeveloper.edges.length > 0 ?
                                                                                props.residential.obligationDeveloper.edges.map((obligation, index) => {
                                                                                    return (
                                                                                        <div className="row mb-3" key={obligation.node.id}>
                                                                                            <div className="col-1">
                                                                                                <input type="checkbox" disabled={true} checked={true} />
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                {obligation.node.productAndService.name}
                                                                                            </div>
                                                                                            <div className="col">
                                                                                                {format(obligation.node.dueDate, 'DD/MM/YYYY')}
                                                                                            </div>
                                                                                        </div>

                                                                                    );
                                                                                })
                                                                                :
                                                                                <div className="row mb-3">
                                                                                    <div className="col">
                                                                                        -
                                                                                    </div>
                                                                                </div>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="card show mt-3">
                                                                        <div className="card-body">
                                                                            <div className="row  mb-3">
                                                                                <div className="col-6">
                                                                                    {t('register:Welcome box date')}
                                                                                </div>
                                                                                <div className="col ">
                                                                                    {props.residential.dateBox ? format(props.residential.dateBox, 'DD/MM/YYYY') : '-'}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            );
                                        }
                                        return (
                                            <div className="container-fluid" id="detail">
                                                <img src={process.env.PUBLIC_URL + "/images/icons/alert/loading.gif"} />
                                            </div>
                                        )
                                    }}
                                />
                        }
                    </Translation>

                </WrapperContent>
            </Wrapper >
        );
    }
}

export default RoomDetail;
