import React from 'react';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import Wrapper from '../../components/wrapper/index';
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import environment from "../../env/environment";
import { QueryRenderer } from "react-relay";
import Pagination from "../../libs/newPagination";
import ProjectTopMenuNavigation from "../projectTopMenuNavigation";
import ComponentPagination from "../../libs/componentPagination";
import { format } from "date-fns";
import Lightbox from "react-images";
import "./styles/staffList.scss"
import Swal from "sweetalert2";
import _ from "lodash"
import i18n from "i18next";
import Navigation from './navigation';
import CreateUpdateStaff from './mutation/changeStatusStaff';
import onSubmitSuccess from '../../contact/chatgroup/action/onSubmitSuccess';
import onSubmitError from '../../contact/chatgroup/action/onSubmitError';

import SeacrhDetail from '../../components/search';

const allProjectStaff = graphql`
    query staffListQuery($search: String, $first: Int, $last: Int , $status: Boolean ,$startDate: DateTime, $endDate: DateTime){
        projectStaffViewer{
            allProjectStaff(search: $search, first: $first, last: $last ,status: $status , startDate: $startDate ,endDate: $endDate ){
                totalCount
                edges {
                    
                    node {
                        id
                        image
                        nameTitle
                        nameStaff
                        lastNameStaff
                        role
                        department
                        dateWork
                        endWork
                        phone
                        email
                        note
                        status
                        projectStaffDocument {
                        edges {
                            node {
                                id
                                fileName
                                fileUpload
                            }
                        }
                    }
                 }   
                } 
            }
        }
    }
`;

class StaffList extends ComponentPagination {

    constructor(props) {
        super(props);
        this.state = {
            image_light_box: "",
            open_light_box: false,
            redirect: false,
            refetch: false,
            loading: false,
            start_date: "",
            end_date: "",
            search: "",
        }

        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.toggleLightBox = this.toggleLightBox.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.handlePropsChange = this.handlePropsChange.bind(this)
    }

    toggleLightBox(open_light_box, image = '') {
        this.setState({ open_light_box: open_light_box, image_light_box: image })
    }

    onChangeSearch(text) {
        this.setState({ search: text });
    }

    onDelete(staff_id) {
        Swal.fire({
            customClass: {
                title: 'swal2-title-custom',
                header: 'swal2-header-custom',
                container: 'swal2-container-custom',
                content: 'swal2-content-custom',
            },
            title: i18n.t("staff:Please specify the reason for withdrawing this staff"),
            html:
                '<div class="row  text-left">' +
                `<div class="col-1 text-left mt-3"><input type="radio" id="expair" name="statusDelete" checked /> </div><div class="col-11 mt-3">${i18n.t("staff:Expired contract")}</div>` +
                `<div class="col-1 text-left mt-3"><input type="radio" id="retire" name="statusDelete"  /> </div><div class="col-11 mt-3">${i18n.t("staff:Retire")}</div>` +
                `<div class="col-1 text-left mt-3"><input type="radio" id="death" name="statusDelete"  /> </div><div class="col-11 mt-3">${i18n.t("staff:Died")}</div>` +
                '<div class="col-1 mt-3"><input type="radio" id="otherStatusIs" name="statusDelete"  /></div>' +
                '<div class="col-11 mt-3"><input type="text" id="otherStatusName" class="form-control" /></div>' +
                '</div>',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: i18n.t("staff:void"),
            preConfirm: () => ({
                expair: document.getElementById("expair").checked,
                death: document.getElementById("death").checked,
                retire: document.getElementById("retire").checked,
                otherStatusIs: document.getElementById("otherStatusIs").checked,
                otherStatusName: document.getElementById("otherStatusName").value,
            }),
        }).then((c) => {
            if (c.value) {
                let data = {
                    id: staff_id,
                    endWork: format(new Date(), 'YYYY-MM-DD'),
                    note: c.value.death ? i18n.t("staff:Died") : c.value.expair ? i18n.t("staff:Expired contract") : c.value.retire ? i18n.t("staff:Retire") : c.value.otherStatusName
                }
                CreateUpdateStaff(data, onSubmitSuccess, onSubmitError)
                this.props.history.push("/project/staff/loading")
            }
        })
    }
    handlePropsChange(channel_list) {
        let search = ""
        let start_date = null
        let end_date = null
        channel_list.forEach((e, index) => {
            if (e.name === "search") {
                search = e.value
            }
            else if (e.name === "start_date" && e.value !== '') {
                start_date = e.value + "T00:00"
            }
            else if (e.name === "end_date" && e.value !== '') {
                end_date = e.value + "T23:59"
            }

        });
        this.setState({
            search: search,
            start_date: start_date,
            end_date: end_date,
        })
    }

    render() {
        if (this.props.match.params.status === "loading") {
            this.props.history.push("/project/staff/current")
        }
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <div id="wrapper-content">
                    <ProjectTopMenuNavigation mini={true} /> 
                        { 
                            this.state.loading == false ?
                                <div className="container-fluid box" id="staff-list">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                            <h3 className="mb-4">
                                                <Link to="/project">
                                                    <img src={process.env.PUBLIC_URL + '/images/wrapperContent/back.png'}
                                                        alt="back" className="back" />
                                                </Link>
                                                <span><Translation >{t=> t('staff:staff')}</Translation></span>

                                            </h3>
                                        </div>
                                        <div className="col text-right">

                                            {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff_create' }) &&
                                                <Link to="/project/staff/form-staff">
                                                    <button className="btn-primary-blue ml-3">+&nbsp; <Translation >{t=> t("staff:Add staff")}</Translation> </button>
                                                </Link>
                                            }
                                        </div>
                                    </div>

                                    <Navigation />
                                    <SeacrhDetail
                                        seleteDetail={false}
                                        selectIs={false}
                                        startDateIs={true}
                                        endDateIs={true}
                                        searchBoxId={true}
                                        search={this.state.search}
                                        start_date={this.state.start_date}
                                        end_date={this.state.end_date}
                                        handlePropsChange={this.handlePropsChange}
                                    />


                                    <div className="content-inner">
                                        <QueryRenderer
                                            environment={environment}
                                            query={allProjectStaff}
                                            cacheConfig={{ use_cache: false }}
                                            variables={{
                                                search: this.state.search,
                                                startDate: this.state.start_date || null,
                                                endDate: this.state.end_date || null,
                                                first: this.state.first,
                                                last: this.state.last,
                                                reQuery: this.state.reQuery,
                                                status: this.props.match.params.status === "current" ? true : false
                                            }}
                                            render={({ error, props }) => {
                                                if (error) {
                                                    return <div className="alert alert-danger"
                                                        role="alert">{error.message}</div>;
                                                } else if (props) {
                                                    return <React.Fragment>
                                                        <div className="card fade-up">
                                                            <Translation>
                                                                {t=> 
                                                                    <div className="table-responsive">
                                                                        <table className="table table-hover">
                                                                            <thead className="thead-light">
                                                                                <tr className="text-center">
                                                                                    <th />
                                                                                    <th>{t("staff:Name - Surname")}</th>
                                                                                    <th>{t("staff:Department")}</th>
                                                                                    <th>{t("staff:Position")}</th>
                                                                                    <th>{t("staff:Start date")}</th>
                                                                                    {
                                                                                        this.props.match.params.status === "current" ?
                                                                                            <th />
                                                                                            :
                                                                                            <React.Fragment>
                                                                                                <th>{t("staff:End date")}</th>
                                                                                                <th>{t("staff:Note")}</th>
                                                                                            </React.Fragment>
                                                                                    }

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    props.projectStaffViewer.allProjectStaff.edges.map((staff) =>
                                                                                        <tr key={staff.node.id} className="show text-center">
                                                                                            <td width="120" className="text-center">
                                                                                                {staff.node.image ?
                                                                                                    <div
                                                                                                        className="rounded-circle avatar-img juristic profile-img"
                                                                                                        style={{ backgroundImage: `url(${staff.node.image})` }}>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <img
                                                                                                        src={process.env.PUBLIC_URL + '/images/icons/profile-upload-icon.png'}
                                                                                                        className="rounded-circle avatar-img juristic profile-img"
                                                                                                        alt="staff-profile" />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <Link to={{ pathname: "/project/staff/form-staff/" + staff.node.id }}>
                                                                                                    {staff.node.nameStaff} {staff.node.lastNameStaff}
                                                                                                </Link>
                                                                                            </td>
                                                                                            <td>{staff.node.department}</td>
                                                                                            <td>{staff.node.role}</td>
                                                                                            <td width="200">{format(staff.node.dateWork, 'DD/MM/YYYY')}</td>
                                                                                            {
                                                                                                this.props.match.params.status === "current" ?
                                                                                                    <td width="300" className='text-right'>

                                                                                                        {((_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff_edit' }) || _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff_delete' }) || staff.node.document) && staff.node.status == true) &&
                                                                                                            <React.Fragment>
                                                                                                                <button className="btn"
                                                                                                                    id="dropdownMenuButton"
                                                                                                                    data-toggle="dropdown"
                                                                                                                    aria-haspopup="true"
                                                                                                                    aria-expanded="false">
                                                                                                                    <img
                                                                                                                        src={process.env.PUBLIC_URL + '/images/icons/choice.png'}
                                                                                                                        alt="choice" />
                                                                                                                </button>
                                                                                                                <div className="dropdown-menu"
                                                                                                                    aria-labelledby="dropdownMenuButton">
                                                                                                                    {
                                                                                                                        staff.node.document &&
                                                                                                                        <span
                                                                                                                            className="dropdown-item cursor"
                                                                                                                            onClick={() => this.toggleLightBox(true, staff.node.document)}>
                                                                                                                            {t("staff:Attach document")}
                                                                                                                        </span>
                                                                                                                    }
                                                                                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff_edit' }) &&
                                                                                                                        <Link
                                                                                                                            style={{ textDecoration: 'none' }}
                                                                                                                            to={{ pathname: "/project/staff/form-staff/" + staff.node.id }}>
                                                                                                                            <span
                                                                                                                                className="dropdown-item cursor">
                                                                                                                                {t("staff:Edit")}
                                                                                                                            </span>
                                                                                                                        </Link>
                                                                                                                    }

                                                                                                                    {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: 'project_projectstaff_delete' }) &&
                                                                                                                        <span className="dropdown-item cursor"
                                                                                                                            onClick={() => this.onDelete(staff.node.id)}>
                                                                                                                            {t("staff:Remove")}
                                                                                                                        </span>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    </td> 
                                                                                                    : 
                                                                                                    <React.Fragment>
                                                                                                        <td>{staff.node.endWork ? format(staff.node.endWork, 'DD/MM/YYYY') : "-"}</td>
                                                                                                        <td>{staff.node.note}</td>
                                                                                                    </React.Fragment>
                                                                                            }

                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </Translation>
                                                        </div>
                                                        <div className="row">
                                                            <Pagination changePage={this.changePage}
                                                                first={this.state.first}
                                                                last={this.state.last}
                                                                totalCount={props.projectStaffViewer.allProjectStaff.totalCount} />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                return <div className="container-fluid box fixRequestFast text-center" >
                                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                                </div>;
                                            }}
                                        />
                                    </div>
                                    <Lightbox
                                        images={[{ src: this.state.image_light_box }]}
                                        onClose={() => this.toggleLightBox(false)}
                                        isOpen={this.state.open_light_box}
                                        backdropClosesModal={true}
                                        showImageCount={false}
                                    />
                                </div>
                                :
                                <div className="container-fluid box fixRequestFast text-center" >
                                    <img src={process.env.PUBLIC_URL + '/images/iconAlerts/loading.gif'} />
                                </div>
                        }
                </div>
            </Wrapper>
        );
    }
}

export default StaffList;
