/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateClearGuaranteeMoneyReceivedAndTransactionInput = {|
  guaranteeMoneyReceived: string,
  detailTransaction: any,
  payChannel?: ?any,
  note?: ?string,
  forfeitRemark?: ?string,
  payGroup?: ?string,
  otherNameAddress?: ?any,
  clientMutationId?: ?string,
|};
export type createClearGuaranteeMoneyReceivedAndTransactionMutationVariables = {|
  input: CreateClearGuaranteeMoneyReceivedAndTransactionInput
|};
export type createClearGuaranteeMoneyReceivedAndTransactionMutationResponse = {|
  +createClearGuaranteeMoneyReceivedAndTransaction: ?{|
    +ok: ?boolean,
    +warningText: ?string,
  |}
|};
export type createClearGuaranteeMoneyReceivedAndTransactionMutation = {|
  variables: createClearGuaranteeMoneyReceivedAndTransactionMutationVariables,
  response: createClearGuaranteeMoneyReceivedAndTransactionMutationResponse,
|};
*/


/*
mutation createClearGuaranteeMoneyReceivedAndTransactionMutation(
  $input: CreateClearGuaranteeMoneyReceivedAndTransactionInput!
) {
  createClearGuaranteeMoneyReceivedAndTransaction(input: $input) {
    ok
    warningText
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateClearGuaranteeMoneyReceivedAndTransactionPayload",
    "kind": "LinkedField",
    "name": "createClearGuaranteeMoneyReceivedAndTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "warningText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createClearGuaranteeMoneyReceivedAndTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createClearGuaranteeMoneyReceivedAndTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6096a2ccc350dd7f3c9d96ab1833fc9b",
    "id": null,
    "metadata": {},
    "name": "createClearGuaranteeMoneyReceivedAndTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation createClearGuaranteeMoneyReceivedAndTransactionMutation(\n  $input: CreateClearGuaranteeMoneyReceivedAndTransactionInput!\n) {\n  createClearGuaranteeMoneyReceivedAndTransaction(input: $input) {\n    ok\n    warningText\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '92c0e3ffc740cc8b6d3533f758dd4f38';

module.exports = node;
