/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DebsFreeCertificationRequestStatus = "ACTIVE" | "APPROVE" | "DRAFT" | "EDIT" | "PAID" | "VOID" | "%future added value";
export type debtFreeFormQueryVariables = {|
  id?: ?string
|};
export type debtFreeFormQueryResponse = {|
  +allDebsFreeCertificationRequest: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +added: any,
        +updated: any,
        +nameTransfer: ?string,
        +phoneTransfer: ?string,
        +contact: {|
          +id: string,
          +name: string,
          +firstName: string,
          +lastName: string,
        |},
        +requestingDate: ?any,
        +waitingDate: ?any,
        +approvalDate: ?any,
        +cancellationDate: ?any,
        +price: ?number,
        +tenant: {|
          +id: string,
          +firstName: string,
          +lastName: ?string,
        |},
        +phone: ?string,
        +addressContact: ?string,
        +address: ?string,
        +copyIdCard: boolean,
        +copyHouse: boolean,
        +copyApartment: boolean,
        +copySurnameChange: boolean,
        +otherDocument: boolean,
        +otherDocumentDescription: ?string,
        +attorney: boolean,
        +attorneyBook: ?string,
        +waterAndElectric: boolean,
        +startSuspend: ?any,
        +endSuspend: ?any,
        +transactionDebtType: ?string,
        +creatorApprove: ?string,
        +creatorVoid: ?string,
        +noteVoid: ?string,
        +receive: ?{|
          +id: string,
          +docNumber: string,
        |},
        +otherReceive: ?{|
          +id: string,
          +docNumber: string,
        |},
        +status: DebsFreeCertificationRequestStatus,
        +fee: boolean,
        +charge: ?number,
        +debsFreeCertificationRequestDocument: ?{|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +fileUpload: string,
              +nameFile: ?string,
            |}
          |}>
        |},
        +payment: ?string,
      |}
    |}>
  |}
|};
export type debtFreeFormQuery = {|
  variables: debtFreeFormQueryVariables,
  response: debtFreeFormQueryResponse,
|};
*/


/*
query debtFreeFormQuery(
  $id: ID
) {
  allDebsFreeCertificationRequest(id: $id) {
    edges {
      node {
        id
        docNumber
        added
        updated
        nameTransfer
        phoneTransfer
        contact {
          id
          name
          firstName
          lastName
        }
        requestingDate
        waitingDate
        approvalDate
        cancellationDate
        price
        tenant {
          id
          firstName
          lastName
        }
        phone
        addressContact
        address
        copyIdCard
        copyHouse
        copyApartment
        copySurnameChange
        otherDocument
        otherDocumentDescription
        attorney
        attorneyBook
        waterAndElectric
        startSuspend
        endSuspend
        transactionDebtType
        creatorApprove
        creatorVoid
        noteVoid
        receive {
          id
          docNumber
        }
        otherReceive {
          id
          docNumber
        }
        status
        fee
        charge
        debsFreeCertificationRequestDocument {
          edges {
            node {
              id
              fileUpload
              nameFile
            }
          }
        }
        payment
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "docNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "DebsFreeCertificationRequestNodeConnection",
    "kind": "LinkedField",
    "name": "allDebsFreeCertificationRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DebsFreeCertificationRequestNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DebsFreeCertificationRequestNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "added",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameTransfer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneTransfer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactNode",
                "kind": "LinkedField",
                "name": "contact",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestingDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waitingDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cancellationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TenantNode",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "addressContact",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyIdCard",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyHouse",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copyApartment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "copySurnameChange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherDocument",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "otherDocumentDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attorney",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attorneyBook",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "waterAndElectric",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startSuspend",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endSuspend",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionDebtType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorApprove",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creatorVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noteVoid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ReceiveNode",
                "kind": "LinkedField",
                "name": "receive",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OtherReceiveNode",
                "kind": "LinkedField",
                "name": "otherReceive",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "charge",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DebsFreeCertificationRequestDocumentsNodeConnection",
                "kind": "LinkedField",
                "name": "debsFreeCertificationRequestDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DebsFreeCertificationRequestDocumentsNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DebsFreeCertificationRequestDocumentsNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileUpload",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameFile",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payment",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "debtFreeFormQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "debtFreeFormQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "bfd989e64333cf028678e4bbae0036a5",
    "id": null,
    "metadata": {},
    "name": "debtFreeFormQuery",
    "operationKind": "query",
    "text": "query debtFreeFormQuery(\n  $id: ID\n) {\n  allDebsFreeCertificationRequest(id: $id) {\n    edges {\n      node {\n        id\n        docNumber\n        added\n        updated\n        nameTransfer\n        phoneTransfer\n        contact {\n          id\n          name\n          firstName\n          lastName\n        }\n        requestingDate\n        waitingDate\n        approvalDate\n        cancellationDate\n        price\n        tenant {\n          id\n          firstName\n          lastName\n        }\n        phone\n        addressContact\n        address\n        copyIdCard\n        copyHouse\n        copyApartment\n        copySurnameChange\n        otherDocument\n        otherDocumentDescription\n        attorney\n        attorneyBook\n        waterAndElectric\n        startSuspend\n        endSuspend\n        transactionDebtType\n        creatorApprove\n        creatorVoid\n        noteVoid\n        receive {\n          id\n          docNumber\n        }\n        otherReceive {\n          id\n          docNumber\n        }\n        status\n        fee\n        charge\n        debsFreeCertificationRequestDocument {\n          edges {\n            node {\n              id\n              fileUpload\n              nameFile\n            }\n          }\n        }\n        payment\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58bb60359c517fd92909f3a28e8ffe8f';

module.exports = node;
