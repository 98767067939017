import React, { Component } from 'react'
import Wrapper from '../../components/wrapper';
import Header from '../../components/header/index';
import Sidebar from '../../components/sidebar/index';
import WrapperContent from '../../components/wrapper/wrapperContent';
import { Translation } from 'react-i18next';
import Navigation from './navigation';
import './settingCollection.scss'
import SettingAutomatic from './settingAutomatic';
import SettingForm from './settingForm';
import AccountingTopMenuNavigation from '../../accounting/accountingTopMenuNavigation';
import i18next from 'i18next';
import BackButtonIcon from "../../components/BackBtn/indexBack";

class SettingCollectionDashBoard extends Component {

    constructor(props){
        super(props)
        this.state ={
            status : this.props.match.params.status,
            formDebit : 1
        }
    }

    componentDidMount(){
        this.setState({
            formDebit : this.switchForm(this.props.match.params.status)
        })
    }

    componentDidUpdate(){
        if(this.state.status !== this.props.match.params.status){
            this.setState({
                status : this.props.match.params.status
            } ,() => {
                this.setState({
                    formDebit : this.switchForm(this.state.status)
                })
            })
        }
    }

    switchForm = (status) => {
        switch (status) {
            case 'first' :
                return 1
            case 'second' :
                return 2
            case 'third' :
                return 3
            case 'legal-process' :
                return 4
            default :
                return 1
        }
    }

    render(){
        return (
            <Wrapper>
                <Header/>
                <Sidebar/>
                <WrapperContent>
                    <AccountingTopMenuNavigation mini={true}/>
                    <Translation>
                        {
                            (t) => 
                            <div className="container-fluid box" id="setting-collection">
                                <BackButtonIcon
                                    LinkBack={"/accounting"}
                                    LinkText={i18next.t('setting:Collection Letter Setting')}
                                    isToolTrip={true}
                                    toolTripText={i18next.t('setting:Please complete all the forms.')}
                                />
                                <div className="content-inner">
                                    <Navigation />
                                    {this.props.match.params.status === 'settings' ? 
                                        <SettingAutomatic/>
                                        :
                                        <SettingForm  formDebit={this.state.formDebit} status={this.props.match.params.status}/>  
                                    }
                                </div>
                            </div>
                        }
                    </Translation>


                </WrapperContent>
            </Wrapper>
        );
    }




}
export default SettingCollectionDashBoard;