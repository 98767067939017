/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type settingInvoiceTemplateQueryVariables = {||};
export type settingInvoiceTemplateQueryResponse = {|
  +checkInvoiceTemplate: ?{|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +templateFirst: boolean,
        +descriptionFirst: ?string,
        +topicThFirst: ?string,
        +topicEnFirst: ?string,
        +templateSecond: boolean,
        +descriptionSecond: ?string,
        +topicThSecond: ?string,
        +topicEnSecond: ?string,
        +templateThird: boolean,
        +descriptionThird: ?string,
        +topicThThird: ?string,
        +topicEnThird: ?string,
        +templateFourth: boolean,
        +descriptionFourth: ?string,
        +topicThFourth: ?string,
        +topicEnFourth: ?string,
        +templateFifth: boolean,
        +descriptionFifth: ?string,
        +topicThFifth: ?string,
        +topicEnFifth: ?string,
      |}
    |}>
  |}
|};
export type settingInvoiceTemplateQuery = {|
  variables: settingInvoiceTemplateQueryVariables,
  response: settingInvoiceTemplateQueryResponse,
|};
*/


/*
query settingInvoiceTemplateQuery {
  checkInvoiceTemplate {
    edges {
      node {
        id
        templateFirst
        descriptionFirst
        topicThFirst
        topicEnFirst
        templateSecond
        descriptionSecond
        topicThSecond
        topicEnSecond
        templateThird
        descriptionThird
        topicThThird
        topicEnThird
        templateFourth
        descriptionFourth
        topicThFourth
        topicEnFourth
        templateFifth
        descriptionFifth
        topicThFifth
        topicEnFifth
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvoiceTemplateNodeConnection",
    "kind": "LinkedField",
    "name": "checkInvoiceTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceTemplateNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceTemplateNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicThFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicEnFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateSecond",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionSecond",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicThSecond",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicEnSecond",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateThird",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionThird",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicThThird",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicEnThird",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateFourth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionFourth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicThFourth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicEnFourth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "templateFifth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "descriptionFifth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicThFifth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "topicEnFifth",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingInvoiceTemplateQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingInvoiceTemplateQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4b9ae7c277a2db2e43c9a1bb0919afcf",
    "id": null,
    "metadata": {},
    "name": "settingInvoiceTemplateQuery",
    "operationKind": "query",
    "text": "query settingInvoiceTemplateQuery {\n  checkInvoiceTemplate {\n    edges {\n      node {\n        id\n        templateFirst\n        descriptionFirst\n        topicThFirst\n        topicEnFirst\n        templateSecond\n        descriptionSecond\n        topicThSecond\n        topicEnSecond\n        templateThird\n        descriptionThird\n        topicThThird\n        topicEnThird\n        templateFourth\n        descriptionFourth\n        topicThFourth\n        topicEnFourth\n        templateFifth\n        descriptionFifth\n        topicThFifth\n        topicEnFifth\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c320c900fac65960f1b7be0124c67ec9';

module.exports = node;
