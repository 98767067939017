/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type VoidDownloadJobInput = {|
  downloadJobId?: ?string,
  downloadJobList?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type VoidDownloadMutationVariables = {|
  input: VoidDownloadJobInput
|};
export type VoidDownloadMutationResponse = {|
  +voidDownloadJob: ?{|
    +ok: ?boolean,
    +massage: ?string,
  |}
|};
export type VoidDownloadMutation = {|
  variables: VoidDownloadMutationVariables,
  response: VoidDownloadMutationResponse,
|};
*/


/*
mutation VoidDownloadMutation(
  $input: VoidDownloadJobInput!
) {
  voidDownloadJob(input: $input) {
    ok
    massage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "VoidDownloadJobPayload",
    "kind": "LinkedField",
    "name": "voidDownloadJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "massage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VoidDownloadMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VoidDownloadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2b82671b6a17a40d03359a62f6501cf6",
    "id": null,
    "metadata": {},
    "name": "VoidDownloadMutation",
    "operationKind": "mutation",
    "text": "mutation VoidDownloadMutation(\n  $input: VoidDownloadJobInput!\n) {\n  voidDownloadJob(input: $input) {\n    ok\n    massage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '435995f24a88c883f29417e723d626b1';

module.exports = node;
