import '../styles/main.scss';
import React, {Component} from 'react';
import $ from "jquery";
import {format} from "date-fns";
import {getTypeProject, getProductTypeName} from "../../libs/getTypeOfProject"
import numberWithCommas from '../../libs/numberWithComma';
import getNameResidential from "../../libs/getNameResidential";
import { Translation } from "react-i18next";
import i18n from "i18next"
import _ from "lodash";
import './collectionLetter.scss';
const ThaiBaht = require('thai-baht-text');
const numberToText = require('number-to-text')
require('number-to-text/converters/en-us');

const example_letter = [
    {description : "ค่าส่วนกลาง" , price: 300},
    {description : "ค่าน้ำประปา" , price: 430},
    {description : "ค่าไฟฟ้า" , price: 2500},
    {description : "ค่ารักษาาตรน้ำ" , price: 500},
    {description : "ค่าเบี้ยประกันภัย" , price: 500},
]
class OutStandingCollectionLetter extends Component {

    constructor(props){
        super(props);
        this.state = {
            debtStatus : i18n.t("collectionLetter:as of the date of issuing the demand letter"),
            languages:"th"
        }
    }

    queryLanguages(){
        this.setState({ languages : this.props.languages })
        i18n.changeLanguage(this.props.languages);
    }

    componentDidMount(){
       this.queryLanguages();
       this.setPage()
       if(this.props.allCollectionLetters.node.creator !== 'Auto'){
           this.setState({
               debtStatus : i18n.t("collectionLetter:as of the date of issuing the demand letter")
           })
       }else{
           if(this.props.allCollectionLetters.node.debtStatus === 'CUSTOM'){
               this.setState({
                debtStatus : i18n.t("collectionLetter:as of the date of issuing the demand letter")
               })
           }else{
            this.setState({
                debtStatus : i18n.t("collectionLetter:as of the due date")
               })
           }
       }
    }

    setPage = () =>  {
        setTimeout(() => {
            let page = $('.' + 'outstanding-page' + this.props.indexRecord);
            let head = page.find('.head').height();
            let detail = page.find('.detail-main').height();
            let outstanding = page.find('.outstanding-table').height();
            let page_height = 900
            let diff = page_height - (head + detail + outstanding);
            $('.outstanding-page' +this.props.indexRecord +  ' .lastRow td').css({paddingBottom: diff});
        }, 200);
    }

    getType = (type_slug) => {
        const type_house = ["single_house","twin_house","townhome","penthouse","commercial-building","commercial-building","townhouse",
                            "office","store","mall"]
        if(type_house.includes(type_slug)){
            return i18n.t("collectionLetter:the owner of house number:")
        }else{
            return i18n.t("collectionLetter:the owner of condominium unit number:")
        }
    }

    getProductType(type){

        let typeName = ""
        if(type === 'VILLAGE'){
            typeName = i18n.t("collectionLetter:housing estate")
        }else{
            typeName = i18n.t("collectionLetter:condominium building")
        }
        return typeName;
    }


    getProductTypeName(type){
        let typeName = ""
        if(type === 'VILLAGE'){
            typeName = i18n.t("collectionLetter:Owner")
        }else{
            typeName = i18n.t("collectionLetter:highType")
        }
        return typeName;
    }

    render() {
        let invoiceDoc =  ''
        let getType = getTypeProject(this.props.selfProject.typeOfProject)
        return (
            <React.Fragment>
                <div className={"print-page-a4 outstanding-page"+this.props.indexRecord} id="collection-letter" key={this.props.indexRecord} style={{position: 'relative'}}>
                    <div className="subpage" style={{overflow: 'hidden' , height: "279mm", padding: '0mm'}}>
                        <div className="head">
                            <div className="juristic-description" style={{display:"grid",gridTemplateColumns:"2fr 5fr"}}>
                            <div style={{minHeight:100,minWidth:100}}> 
                                <img src={this.props.selfProject.logo} alt="silverman"/>
                            </div>
                            <div>  
                                <strong>{i18n.languages[0] === "en" ? (this.props.selfProject.nameEn || this.props.selfProject.name) : this.props.selfProject.name}</strong>
                                <div className="address-header">{i18n.languages[0] === "en" ? (this.props.selfProject.addressEn || this.props.selfProject.address) : this.props.selfProject.address} โทร {this.props.selfProject.juristicContactNumber || '-'}</div>
                                <div>{i18n.t("collectionLetter:Tax ID.")} {this.props.selfProject.taxIdNumber || '-'}</div>
                            </div> 
                            </div>
                            <div className="document-name">
                                 <div className="title">{i18n.t("PageDetailProjectInfo:Document")}</div>
                            </div>
                        </div>
                        <div className="row col-12 d-flex justify-content-center">
                            <strong className="header-description">{i18n.t("downloadFile:receivable_outstanding_report")}</strong><br/>
                        </div>
                        <div className="detail-main row">
                            <div className="row">
                                <div className="col-12">
                                    <strong className="mr-1">{i18n.t("collectionLetter:Debt balance")} {this.state.debtStatus} </strong>
                                </div>
                                <br/>
                                {this.props.allCollectionLetters.node.contact.typeOfContact === 'RESIDENTIAL' ?
                                    <React.Fragment>
                                        <div className="col-12">
                                            <strong className="mr-1">
                                                {/* {this.props.selfProject.typeOfProject && getProductTypeName(getType)} */}
                                                {this.props.selfProject.typeOfProject && <Translation>{t=><strong className="mr-1">{t(`collectionLetter:${getProductTypeName(getType)}`)}</strong>}</Translation>}
                                                {/* {this.getType(this.props.allCollectionLetters.node.contact.residential.type.slug)} */}
                                             </strong>
                                            {this.props.allCollectionLetters.node.contact.name}
                                        </div>
                                        <br/>
                                        <div className="col-12" >
                                            <strong className="mr-1">{i18n.t("collectionLetter:Customer")}:</strong>
                                            {/* {this.props.allCollectionLetters.node.contact.firstName} {this.props.allCollectionLetters.node.contact.lastName} */}
                                            {getNameResidential(this.props.allCollectionLetters.node.contact.firstName , this.props.allCollectionLetters.node.contact.lastName )}
                                            <br/>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="col-12">
                                        <strong className="mr-1">{i18n.t("collectionLetter:Customer")}:</strong>
                                        {this.props.allCollectionLetters.node.contact.name}
                                        <br/>
                                    </div>
                                }
                                
                                <div className="col-12">
                                    <strong className="mr-1">{i18n.t("collectionLetter:Address")}:</strong>
                                    {this.props.allCollectionLetters?.node.contact.registeredProvince.includes("กรุงเทพ") || this.props.allCollectionLetters.node.contact.registeredProvince.includes("กทม") ? (
                                    <>
                                        {this.props.allCollectionLetters.node.contact.registeredAddress}
                                        {this.props.allCollectionLetters.node.contact.registeredDistrict && ` แขวง${this.props.allCollectionLetters.node.contact.registeredDistrict}`}
                                        {this.props.allCollectionLetters.node.contact.registeredCity && ` เขต${this.props.allCollectionLetters.node.contact.registeredCity}`}
                                        {this.props.allCollectionLetters.node.contact.registeredProvince && ` ${this.props.allCollectionLetters.node.contact.registeredProvince} `}
                                        {this.props.allCollectionLetters.node.contact.registeredPostalCode}{" "}
                                        {this.props.allCollectionLetters.node.contact.registeredCountry}
                                    </>
                                    ) : (
                                    <>
                                        {this.props.allCollectionLetters.node.contact.registeredAddress}
                                        {this.props.allCollectionLetters.node.contact.registeredDistrict && ` ตำบล${this.props.allCollectionLetters.node.contact.registeredDistrict}`}
                                        {this.props.allCollectionLetters.node.contact.registeredCity && ` อำเภอ${this.props.allCollectionLetters.node.contact.registeredCity}`}
                                        {this.props.allCollectionLetters.node.contact.registeredProvince && ` จังหวัด${this.props.allCollectionLetters.node.contact.registeredProvince} `}
                                        {this.props.allCollectionLetters.node.contact.registeredPostalCode}{" "}
                                        {this.props.allCollectionLetters.node.contact.registeredCountry}
                                    </>
                                    ) }
                                </div>
                                <br/>
                                
                            </div>
                        </div>
                        

                        <div className="outstanding-table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width={50} className="text-center">
                                            {i18n.t("collectionLetter:No.")} 
                                        </th>
                                        <th width={110}  className="text-center">
                                            {i18n.t("developer:Invoice number")}  
                                        </th>
                                        <th  width={210} className="text-center">
                                            {i18n.t("collectionLetter:Description")}  
                                        </th>
                                        <th  width={80} className="text-center">
                                            {i18n.t("individualReceipt:issueDate")}  
                                        </th>
                                        <th width={80} className="text-center">
                                            {i18n.t("developer:Due date")}  
                                        </th>
                                        <th className="text-center">
                                            {i18n.t("collectionLetter:Overdue Day")}  
                                        </th>
                                        <th width={90} className="text-center">
                                            {i18n.t("receivableOutstandingSummaryReport:Net Outstanding")}  
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.recordCollectionLetters.length > 0 && 
                                    this.props.recordCollectionLetters.map((invoice , index) => {
                                        let dateDiff =  (new Date(this.props.allCollectionLetters.node.updated).getTime() - new Date(invoice.node.transaction.invoice.dueDate).getTime()) / (1000*60*60*24)

                                        let duplicate = true
                                        if(invoiceDoc !== invoice.node.transaction.invoice.docNumber){
                                            invoiceDoc = invoice.node.transaction.invoice.docNumber
                                            duplicate = false
                                        }
                                        return (
                                            <tr key={index} style={{lineHeight: 1}}>
                                                {
                                                    localStorage.getItem('site_id') !== '340' && localStorage.getItem('site_id') !== '468' && 
                                                    localStorage.getItem('site_id') !== '402' && localStorage.getItem('site_id') !== '490' && 
                                                    localStorage.getItem('site_id') !== '456' && localStorage.getItem('site_id') !== '340' ?
                                                        <td className="text-center">{invoice.no}</td>
                                                        : 
                                                         <td className="text-center">{(this.props.indexRecord * this.props.countList) + (index+1)}</td>
                                                }
                                                <td className="text-center"> {!duplicate && invoiceDoc }</td>
                                                <td style={{lineHeight: 1.2}}>{invoice.node.transaction.description}</td>
                                                <td className="text-center">{format(invoice.node.transaction.invoice.issuedDate,'DD/MM/YYYY')}</td>
                                                <td className="text-center">{format(invoice.node.transaction.invoice.dueDate,'DD/MM/YYYY')}</td>
                                                <td className="text-right">{Math.round(dateDiff)}</td>
                                                <td className="text-right"> {numberWithCommas(invoice.node.sumTotalRecord,0)}</td>
                                            </tr>
                                        )
                                    })}
                                    
                                    <tr style={{borderBottom:!this.props.lastPage ? '1px solid #212529' : ''}} className="lastRow"/>
                                    
                                </tbody>
                                {this.props.lastPage &&
                                <tfoot>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="row">
                                                <div className={this.props.example ? "col-2" : "col-1"}>
                                                    {i18n.t("collectionLetter:Total")}
                                                </div>
                                                <div className={this.props.example ? "col-8 text-center" :"col-10 text-center"}>
                                                   { i18n.languages[0] === 'th' ?  <strong>({ThaiBaht(this.props.sumRecord)})</strong> : 
                                                    <strong>({ numberToText.convertToText(parseInt(this.props.sumRecord))})</strong>}
                                                </div>
                                                <div className="col-1"/>
                                            </div>
                                        </td>
                                        <td className="text-right">
                                            <strong>{numberWithCommas(this.props.sumRecord)}</strong>
                                        </td>
                                    </tr>
                                </tfoot>}
                            </table>
                        </div>
                        
                    </div>
                </div>
            </React.Fragment>
        )
    }                   
}

export default OutStandingCollectionLetter;