import React, {Component} from "react";
import numberWithComma from "../../libs/numberWithComma";
import { format } from "date-fns";



class reportTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            printBy: "",
            start_date: "",
            end_date: "",
            contactName: "",
            customerType: "",
            status: "",
        }

        this.getRTStatus = this.getRTStatus.bind(this)
        this.getTotalPrice = this.getTotalPrice.bind(this)
        this.getMethodPayment = this.getMethodPayment.bind(this)
        this.getTime = this.getTime.bind(this)
        this.getPersonType = this.getPersonType.bind(this)
    }

    getMethodPayment(value){
        let result
        if(value === 'cash'){
            result = 'เงินสด'
        }else{
            result = 'QR Code'
        }
        return result
    }

    getRTStatus(value,carId){
        let result;
        if(value === 'request'){
            result = "ออกใบเสร็จ"
        }else if(value === 'finished'){
            result = "ออกใบเสร็จแล้ว"
        }else{
            result = 'ไม่มีการขอใบเสร็จ' 
        }
        return result
    }
    // componentWillMount() {
    //     fetchQuery(environment, query).then((res) => {
    //         if (res.selfProject) {
    //             let jwtDecodes = jwtDecode(window.localStorage.getItem('token'))
    //             this.setState({
    //                 projectName: res.selfProject.name,
    //                 printBy: `${jwtDecodes.first_name} ${jwtDecodes.last_name}`,
    //                 start_date: this.props.start_date,
    //                 end_date: this.props.end_date,
    //                 contactName: this.props.contactName,
    //                 customerType: this.props.customerType,
    //                 status: this.props.status,
    //             })
    //         }
    //     }
    //     )
    // }

    // removeTrailingZeros(description){
    //     if(description.includes("ค่าน้ำประปา")){
    //         description = description.replace(/ *\[[^)]*\] */g, " ")
    //     } else {
    //         description = description.replace(/ *\[[^)]*\] */g, " ")
    //     }
    //     return description
    // }
    getTotalPrice(paymentDate,startDate){

        const {estampStatus,etampTimer,etampTimerHH,etampTimerMM,etampTimerVistor,etampTimerVistorHH,etampTimerVistorMM} = this.props.settingCalu
        let A = (this.getTime('hours',paymentDate,startDate) * 3600) + (this.getTime('',paymentDate,startDate) * 60)
        let B = estampStatus ? etampTimer ? (etampTimer * 3600) : (etampTimerHH * 3600 + etampTimerMM * 60) : etampTimerVistor ? (etampTimerVistor * 3600) : (etampTimerVistorHH * 3600 + etampTimerVistorMM * 60)
        let hours;
        let minutes;
        let price;

        if (Math.sign(A - B) === 1) {
             hours = Math.floor((A - B) / 3600); // get hours
             minutes = Math.floor(((A - B) - (hours * 3600)) / 60); // get minutes
             price = (hours * parseFloat(this.props.settingCalu.toPayCal) + (minutes >= this.props.settingCalu.toCalOverTime ? parseFloat(this.props.settingCalu.toPayCal) : 0))
             return numberWithComma(price || 0, "0.00")
           // setOverTime({ hours, minutes, price: (hours * parseFloat(toPayCal) + (minutes >= toCalOverTime ? parseFloat(toPayCal) : 0)) })
        } else {
            //setOverTime(false)
        }
    }

    getTime (type,paymentDate,startDate) {
        if (type === 'hours') {
            return parseInt(Math.abs(new Date(paymentDate) - new Date(startDate)) / (1000 * 60 * 60))
        } else {
           return parseInt(Math.abs(new Date(paymentDate).getTime() - new Date(startDate).getTime()) / (1000 * 60) % 60)
        }
    }

    getPersonType(val){
        let result;
        switch (val) {
            case "tenant":
              result = "ผู้เช่า/ลูกบ้าน"
              break;
            case "contact":
              result = "ติดต่อสำนักงานขาย"
              break;
            case "appointment":
              result = "ผู้ติดต่อนัดหมายล่วงหน้า"
            case "person":
                result = "บุคคลทั่วไป"
              break;
          }
          return result;
    }


    render() {
        let dataChunked = [];
        let rowPerpage = !this.props.isUseAccount ? 10 : 20;
        for (let i=0;i<this.props.data.length ;i += rowPerpage) {
            let chunk = this.props.data.slice(i, i + rowPerpage);
            dataChunked.push(chunk);            
        }

        return (
            <React.Fragment>
                {dataChunked.map((data,page_index)=>(
                    <div className='print-page-a4 hoziA4' key={page_index}>
                                    {/* แสดงตาราง */}
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <table className="table tebleBorder">
                                                <thead className='tableHead'>
                                                            <tr>
                                                            <th>เลขที่</th>
                                                            <th>วันที่-เวลาที่ชำระค่าที่จอดรถ</th>
                                                            <th>เลขทะเบียนรถ</th>
                                                            <th>จำนวนเงิน (บาท)</th>
                                                            {
                                                                !this.props.isUseAccount ?  <th>ประเภทบุคคล</th> :''
                                                            }
                                                            <th>รูปแบบการชำระเงิน</th>
                                                            {
                                                                !this.props.isUseAccount ?  <th>หลักฐานการชำระเงิน</th> :''
                                                            }
                                                            <th>สถานะการขอใบเสร็จ</th>
                                                            {
                                                                this.props.isUseAccount ? 
                                                                <>
                                                                    <th>เลขที่รายรับอื่นๆ</th>
                                                                    <th>วันที่ออก</th>
                                                                    <th>ออกโดย</th>
                                                                </>
                                                                :''
                                                            }
                                                            </tr>
                                                </thead>  
                                                <tbody className='tableBody'>
                                                {data.map((n, index) => {
         
                                                            return (
                                                                <React.Fragment key={n.id}>
                                                                    <tr>
                                                                    <td>{ n.reportCode ? n.reportCode : "-"}</td>
                                                                    <td>{n.dateUsingSt}-{n.timeUsingSt}</td>
                                                                    <td>{n.carRegistration ? n.carRegistration : '-'}</td>
                                                                    <td>{this.getTotalPrice(n?.paymentDate,n?.startDate) || 0}</td>
                                                                    {
                                                                        !this.props.isUseAccount ? 
                                                                        <td>
                                                                            {
                                                                                n.typePerson ?  this.getPersonType(n.typePerson) :''
                                                                            }
                                                                        </td> :''
                                                                    }
                                                                    <td>{n.methodPayment ? this.getMethodPayment(n.methodPayment) : '-'}</td>
                                                                    {
                                                                        !this.props.isUseAccount ? 
                                                                        <td>
                                                                            {
                                                                                n.refPayment ?  <img src={n.refPayment} 
                                                                                style={{width:'50px'}}
                                                                                alt="receipt"/> :'-'
                                                                            }
                                                                        </td> :''
                                                                    }
                                                                    <td>
                                                                        {n.rtStatus ? this.getRTStatus(n.rtStatus,n.carTransactionId) : '-'}
                                                                    </td>
                                                                    {
                                                                        this.props.isUseAccount ? 
                                                                        <> 
                                                                        <td>  
                                                                            {n.orDocnumber ? n.orDocnumber : '-'}
                                                                        </td>
                                                                        <td>{n.orIssuedDate ? n.orIssuedDate : '-'}</td>
                                                                        <td>{n.orCreator ? n.orCreator : '-'}</td>
                                                                        </> :''
                                                                    }
                                                                    </tr>
                                                                </React.Fragment>
                                                                )
 
                                                })}                
                                                </tbody>
                                                
                                            </table>
                                        </div>    
                                    </div>    
                                    <div className="footer">
                                        <div className="row">
                                            <div className="col-5" />
                                            <div className="col-2 text-center">
                                                <p className="f10 pb-0 mb-0">{page_index+1}/{dataChunked.length}</p>
                                            </div>
                                            <div className="col-5 text-right">
                                                <p className="f10 pb-0 mb-0">
                                                    ผู้พิมพ์ {this.props.printBy} วันที่พิมพ์ {format(new Date(), "DD/MM/YYYY เวลา HH:mm น.")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>   
                    </div>
                ))}
            </React.Fragment>
        )
    }
}

export default reportTable;