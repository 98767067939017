import React, { Component } from 'react';
import Header from "../../../components/header";
import Sidebar from "../../../components/sidebar";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Wrapper from "../../../components/wrapper";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import environment from "../../../env/environment";
import Loading from '../../../libs/loading';
import BackButtonIcon from '../../../components/BackBtn/indexBack';
import i18next from 'i18next';

const query = graphql`
    query DashboardfixAssetDepreciateQuery {
        fixAssetDepreciate
    }
`;
class DashboardfixAssetDepreciate extends Component {

    render() {
        return (
            <Wrapper>
                <Header />
                <Sidebar />
                <QueryRenderer
                    environment={environment}
                    query={query}
                    render={({ error, props }) => {
                        if (error) {
                            return <div>{error.message}</div>;
                        } else if (props) {

                            return (
                                <React.Fragment>
                                    <div id="wrapper-content">
                                        <AccountingTopMenuNavigation mini={true} />
                                        <Translation>
                                            {t =>
                                                <div className="container-fluid box">
                                                    <BackButtonIcon 
                                                        LinkBack={"/accounting"}
                                                        LinkText={i18next.t("ReportAsset:Asset Report")}
                                                    />
                                                    <div className="content-inner">
                                                        <div className="row fade-up">
                                                            <div className="col-md-4 col-xl-4 mb-2">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">1. {i18next.t("ReportAsset:Asset Register Report")}</h5>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent">
                                                                        <Link to="/accounting/report/asset/register" className="btn btn-primary btn-sm">{i18next.t("ReportAsset:Views")}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xl-4 mb-2">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">2. {i18next.t("ReportAsset:Asset Disposal Register Report")}</h5>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent">
                                                                        <Link to="/accounting/report/asset/amortized" className="btn btn-primary btn-sm">{i18next.t("ReportAsset:Views")}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4 col-xl-4 mb-2">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">3. {i18next.t("ReportAsset:Depreciation Details Report")}</h5>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent">
                                                                        <Link to="/accounting/report/asset/depreciate" className="btn btn-primary btn-sm">{i18next.t("ReportAsset:Views")}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row fade-up mt-3">
                                                            <div className="col-md-4 col-xl-4 mb-2">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">4. {i18next.t("ReportAsset:Asset Verification Status Report")}</h5>
                                                                    </div>
                                                                    <div className="card-footer bg-transparent">
                                                                        <Link to="/accounting/report/asset/checking" className="btn btn-primary btn-sm">{i18next.t("ReportAsset:Views")}</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </Translation>


                                    </div>
                                </React.Fragment>
                            );
                        }
                        return <Loading />
                    }}
                />
            </Wrapper>
        );
    }
}

export default DashboardfixAssetDepreciate;
