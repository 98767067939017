import React from "react";
import Header from "../../components/header/index";
import Sidebar from "../../components/sidebar/index";
import Wrapper from "../../components/wrapper/index";
import ContactTopMenuNavigation from "../contactTopMenuNavigation";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import i18n from 'i18next'
import "../styles/index.scss";

import { graphql } from "babel-plugin-relay/macro";
import Navigation from "./navigation";
import DatePickerAdapter from "../../libs/datePickerAdapter";
import Search from "../SearchInput";

import { commitMutation, QueryRenderer } from "react-relay";
import environment from "../../env/environment";
import { format, subMonths } from "date-fns";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import thLocale from "date-fns/locale/th";
import Pagination from "../../libs/newPagination";
import Lightbox from "react-images";
import ComponentPagination from "../../libs/componentPagination";
import Swal from "sweetalert2";
import UpdateParcel from "./mutations/UpdateParcel";
import CreateParcelNoti from "./mutations/CreateParcelNoti";
import ExportParcel from "./server_export_parcel";
import _ from "lodash";
import i18next from "i18next";
import Loading from "../../libs/loading";
import "./parcel.scss";

let key = {
  "ห่อเล็ก": "Small Pack",
  "ห่อกลาง": "Medium Pack",
  "ห่อใหญ่": "Large Pack",
  "ซองจดหมาย/เอกสาร": "Envelope/Document",
  "ซองขนาดเล็ก": "Small Envelope",
  "ซองขนาดกลาง": "Medium Envelope",
  "ซองขนาดใหญ่": "Large Envelope",
  "กล่องขนาดเล็ก": "Small Box",
  "กล่องขนาดกลาง": "Medium Box",
  "กล่องขนาดใหญ่": "Large Box",
  "อื่นๆ": "Other",
}

const allParcel = graphql`
  query parcelListQuery(
    $status: String
    $first: Int
    $last: Int
    $search: String
    $start_date: DateTime
    $end_date: DateTime
    $order: String
  ) {
    parcelViewer {
      allParcel(
        status: $status
        first: $first
        last: $last
        search: $search
        startDate: $start_date
        endDate: $end_date
        order: $order
      ) {
        totalCount
        edges {
          node {
            id
            name
            trackingNumber
            serviceName
            description
            imageParcel
            added
            status
            docNumber
            updated
            userFirstName
            userLastName

            deleterName
            deleteRemark
            parcelNoti{
                edges {
                        node {
                                id
                                creator
                                added 
                                creator
                    }
                }
            }
            residential {
              id
              name
            }
            taker {
              id
              lastLogin
              username
              juristic {
                firstName
                lastName
              }
              tenant {
                nameTitle
                firstName
                lastName
              }
            }
            takerName
            receiverName
            creatorName
            pickupDate
            returnRemark
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation parcelListMutation($input: UpdateParcelInput!) {
    updateParcel(input: $input) {
      ok
    }
  }
`;

class ParcelList extends ComponentPagination {
  constructor(props) {
    super(props);

    this.state.search = "";
    this.state.start_date = subMonths(new Date(), 1);
    this.state.end_date = new Date();
    this.state.temp_start_date = subMonths(new Date(), 1);
    this.state.temp_end_date = new Date();
    this.state.image_light_box = "";
    this.state.open_light_box = false;
    this.state.loading = false;
    this.state.reQuery = false;
    this.state.order = "-added";
    this.state.status = props.match.params.status;
    this.state.send = false;
    this.state.receive = false;
    this.state.return = false;
    this.state.current_date = new Date().getFullYear().toString() >= "2021";

    this.toggleLightBox = this.toggleLightBox.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onDeleteParcel = this.onDeleteParcel.bind(this);
    this.onChangeStatueParcel = this.onChangeStatueParcel.bind(this);
    this.onChangeStatueParcelReceive = this.onChangeStatueParcelReceive.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onChangeStatusToReturn = this.onChangeStatusToReturn.bind(this);
    this.onSetOrder = this.onSetOrder.bind(this);
    this.getParcelStatus = this.getParcelStatus.bind(this);
  }

  componentDidUpdate() {
    if (this.state.status !== this.props.match.params.status) {
      this.setState({ status: this.props.match.params.status });
      this.onSetOrder();
    }
  }

  componentWillMount() {
    this.onSetOrder();
  }

  getParcelStatus(status) {
    if (status == "NOT_RECEIVE") return "Nor received"
    if (status == "RECEIVE") return "Received success"
    if (status == "DELETED") return "Deleted item"
    if (status == "RETURN") return "Return list"
  }

  onSetOrder() {
    if (this.props.match.params.status === "receive") {
      this.setState({ order: "-pickup_date", send: true, receive: true });
    } else if (
      this.props.match.params.status === "deleted" ||
      this.props.match.params.status === "return"
    ) {
      this.setState({ order: "-updated", send: true, return: true });
    } else if (this.props.match.params.status === "not_receive") {
      this.setState({ order: "-added", send: true });
    }
  }

  toggleLightBox(open_light_box, image = "") {
    this.setState({ open_light_box: open_light_box, image_light_box: image });
  }

  onChangeSearch(text) {
    this.setState({ search: text });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSearch(event) {
    this.setState({
      start_date: this.state.temp_start_date,
      end_date: this.state.temp_end_date,
      search: event.target.value,
    });
  }

  async onChangeStatueParcel(id_list, status) {

    if (id_list.length !== 0 && !this.state.loading) {
      if (status === "deleted") {
        await Swal.fire({
          title: i18n.t("parcel_list:Are you sure to remove the parcel list?"),
          text: i18n.t("parcel_list:All parcel") + " " + id_list.length + " " + i18n.t("parcel_list:item"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.t("parcel_list:Yes"),
          cancelButtonText: i18n.t("parcel_list:Cancel"),
        }).then(async (result) => {
          // }).then((result) => {

          if (result.value) {

            this.setState({ loading: true });

            let { value: void_remark } = await Swal.fire({
              title: i18n.t('invoiceDetail:Note'),
              input: "text",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: i18n.t('invoiceDetail:Cancel'),
              confirmButtonText: i18n.t('invoiceDetail:Confirm'),
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t('invoiceDetail:Please enter a cancellation note');
                }
              },
            });

            if (void_remark) {
              let variables = {
                input: {
                  idList: id_list,
                  status: status,
                  deleteRemark: void_remark,
                },
              };
              UpdateParcel(
                variables,
                () => this.onSuccess(i18n.t("parcel_list:Delete successfully!")),
                this.onError
              );
            }

          }
        });
      } else if (status === 'receive' || status === 'return') {
        let { value: return_remark } = await Swal.fire({
          title: i18n.t("parcel_list:Return note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18n.t("parcel_list:Cancel"),
          confirmButtonText: i18n.t("parcel_list:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18n.t("parcel_list:Please enter a return note!");
            }
          },
        });

        if (return_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              idList: id_list,
              status: status,
              returnRemark: return_remark,
            },
          };
          UpdateParcel(
            variables,
            () => this.onSuccess(i18n.t("parcel_list:Success")),
            this.onError
          );
        }
      } else {
        await Swal.fire({
          title: i18n.t("parcel_list:Are you sure to notify duplicate parcels?"),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.t("parcel_list:Yes"),
          cancelButtonText: i18n.t("parcel_list:Cancel"),
        }).then((result) => {
          if (result.value) {
            this.setState({ loading: true });

            var temp = [];
            id_list.forEach(key => {
              temp.push({
                parcelId: key
              })
            });

            let variables = {
              input: {
                parcelNoti: temp
              },
            };

            CreateParcelNoti(
              variables,
              () => this.onSuccess(i18n.t("parcel_list:Success")),
              this.onError
            );

          }
        });
      }
    } else if (this.state.loading) {
      Swal.fire({
        title: i18n.t("parcel_list:Please wait"),
        type: "warning",
      });
    } else {
      Swal.fire({
        title: i18n.t("parcel_list:Please select item"),
        type: "warning",
      });
    }
  }

  async onChangeStatueParcelReceive(id_list, status) {
    let receive_date = i18next.t('parcel_list:Received_date')
    let name_recipient = i18next.t('parcel_list:Recipient name')
    let max_date = format(new Date(), "YYYY-MM-DDT00:00", { locale: thLocale })
    const { value: formValues } = await Swal.fire({
      title: i18n.t("parcel_list:People receive"),
      html:
        '<div class="row mt-4">' +
        '<div class="col">' +
        `<h6 class="text-left">${name_recipient}</h6>` +
        '<input type="text" id="receiver_name" class="form-control"/>' +
        "</div>" +
        "</div>" +
        '<div class="row mt-4">' +
        '<div class="col">' +
        `<h6 class="text-left" >${receive_date}</h6>` +
        `<input type="datetime-local" id="pickup_datetime" class="form-control" max="${max_date}" />` +
        "</div>" +
        "<br/>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#6c757d",
      confirmButtonText: i18n.t("parcel_list:Confirm"),
      cancelButtonText: i18n.t("parcel_list:Cancel"),
      preConfirm: () => {
        let receiver_name = document.getElementById("receiver_name").value;
        let pickup_datetime = document.getElementById("pickup_datetime").value;

        if (receiver_name !== "" && pickup_datetime !== "") {
          return [receiver_name, pickup_datetime];
        }
        Swal.showValidationMessage(i18n.t("parcel_list:Please complete the information!"));
        return false;
      },
    });

    if (formValues) {
      let variables = {
        input: {
          idIn: id_list,
          status: "receive",
          receiverName: formValues[0],
          pickupDate: new Date(formValues[1]),
        },
      };

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          this.setState({ loading: false });
          if (response.updateParcel.ok) {
            Swal.fire(i18n.t("parcel_list:Save successfully"), "", "success").then(() => {
              this.setState({ reQuery: !this.state.reQuery });
            });
          } else {
            Swal.fire(i18n.t("parcel_list:Failed to save"), "", "warning");
          }
        },
        onError: (err) => {
          this.setState({ loading: false });
          Swal.fire(i18n.t("parcel_list:Failed to save"), i18n.t("parcel_list:Please try again"), "error");
        },
      });
    }
  }

  onSuccess(success_text) {
    this.setState({ loading: false });
    Swal.fire(success_text, "", "success").then(() => {
      this.clearCheckList();
      this.setState({ reQuery: !this.state.reQuery });
    });
  }

  onError() {
    this.setState({ loading: false });
    Swal.fire(i18n.t("parcel_list:Failed"), i18n.t("parcel_list:Please try again"), "error");
  }
  onD() {
    this.setState({ loading: false });
    Swal.fire(i18n.t("parcel_list:Failed"), i18n.t("คุณได้แจ้งเตือนไปแล้ว"), "error");
  }

  onDeleteParcel(id) {
    this.onChangeStatueParcel([id], "deleted");
  }

  onChangeStatusToReturn(id) {
    this.onChangeStatueParcel([id], "return");
  }

  entering = (e) => {
    e.children[0].style.borderTopColor = '#E6F2FE';
    e.children[1].style.backgroundColor = '#E6F2FE';
    e.children[1].style.padding = '10px';
    e.children[1].style.color = '#0243AE';

  };

  getLastTime(data) {


    if (data.length > 0) {

      let last = _.last(data)
      let round = 0;
      let roundTime = 0

      _.map(data, (value, index) => {
        round = index + 1
      })

      return (
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }} onEntering={this.entering}
          overlay={(props) => (
            <Tooltip {...props}>
              {
                _.map(data, (value, index) => {
                  roundTime = index + 1;

                  return (
                    <>
                      <div>
                        <p>
                          {
                            "รอบที่ : " + " " + roundTime + " " + format(
                              value.node?.added,
                              "DD/MM/YYYY เวลา HH:mm น.",
                              { locale: thLocale })
                          }
                          <br /> <span>โดย {value.node?.creator} </span>
                        </p>

                      </div>
                    </>
                  )
                })
              }
            </Tooltip>
          )} placement="top">
          <p>
            {
              "รอบที่ : " + round + " " +
              format(
                last?.node?.added,
                "DD/MM/YYYY",
                { locale: thLocale })
            }
          </p>
        </OverlayTrigger>

      )
    } else {
      return "-"
    }

  }

  async onClickReceive(id) {
    let receive_date = i18next.t('parcel_list:Received_date')
    let name_recipient = i18next.t('parcel_list:Recipient name')
    let max_date = format(new Date(), "YYYY-MM-DDT00:00", { locale: thLocale })
    const { value: formValues } = await Swal.fire({
      title: i18n.t("parcel_list:People receive"),
      html:
        '<div class="row mt-4">' +
        '<div class="col">' +
        `<h6 class="text-left">${name_recipient}</h6>` +
        '<input type="text" id="receiver_name" class="form-control"/>' +
        "</div>" +
        "</div>" +
        '<div class="row mt-4">' +
        '<div class="col">' +
        `<h6 class="text-left" >${receive_date}</h6>` +
        `<input type="datetime-local" id="pickup_datetime" class="form-control" max="${max_date}" />` +
        "</div>" +
        "<br/>",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#6c757d",
      confirmButtonText: i18n.t("parcel_list:Confirm"),
      cancelButtonText: i18n.t("parcel_list:Cancel"),
      preConfirm: () => {
        let receiver_name = document.getElementById("receiver_name").value;
        let pickup_datetime = document.getElementById("pickup_datetime").value;

        if (receiver_name !== "" && pickup_datetime !== "") {
          return [receiver_name, pickup_datetime];
        }
        Swal.showValidationMessage(i18n.t("parcel_list:Please complete the information!"));
        return false;
      },
    });

    if (formValues) {
      let variables = {
        input: {
          id: id,
          status: "receive",
          receiverName: formValues[0],
          pickupDate: new Date(formValues[1]),
        },
      };

      commitMutation(environment, {
        mutation,
        variables,
        onCompleted: (response) => {
          this.setState({ loading: false });
          if (response.updateParcel.ok) {
            Swal.fire(i18n.t("parcel_list:Save successfully"), "", "success").then(() => {
              this.setState({ reQuery: !this.state.reQuery });
            });
          } else {
            Swal.fire(i18n.t("parcel_list:Failed to save"), "", "warning");
          }
        },
        onError: (err) => {
          this.setState({ loading: false });
          Swal.fire(i18n.t("parcel_list:Failed to save"), i18n.t("parcel_list:Please try again"), "error");
        },
      });
    }
  }

  takeName(parcel) {
    if (parcel.node.taker) {
      if (parcel.node.taker.juristic) {
        let takerName = `(${parcel.node.taker.juristic.firstName} ${parcel.node.taker.juristic.lastName})`
        return takerName
      }
      else if (parcel.node.taker.tenant) {
        let takerName = `(${parcel.node.taker.tenant.nameTitle}${parcel.node.taker.tenant.firstName} ${parcel.node.taker.tenant.lastName})`
        return takerName
      } else {
        return ''
      }
    }
    else if (parcel.node.takerName) {
      return `(${parcel.node.takerName})`
    }
    else {
      return ''
    }
  }

  render() {

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <div id="wrapper-content">
          <ContactTopMenuNavigation mini={true} />
          <Translation>
            {t=>
              <div className="container-fluid box" id="parcel">
                <div className="row justify-content-between">
                  <div className="col">
                    <h3>
                      <Link to="/contact">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/wrapperContent/back.png"
                          }
                          alt="back"
                          className="back"
                        />
                      </Link>
                      <Translation>
                        {(t) => <span>{t("parcel:parcel_list")}</span>}
                      </Translation>
                    </h3>
                  </div>
                  <div className="col">
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "contact_parcel_create",
                    }) && (
                        <Link to="/contact/parcel/create/form-parcel">
                          <button
                            type="button"
                            className="btn btn-primary add float-right"
                          >
                            <img
                              src={process.env.PUBLIC_URL + "/images/icons/plus.png"}
                              alt="plus"
                            />
                            <Translation>
                              {(t) => <span>{t("parcel:create_parcel")}</span>}
                            </Translation>
                          </button>
                        </Link>
                      )}
                    {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                      codename: "contact_parcel_print",
                    }) && (
                        <ExportParcel
                          state={this.state}
                          status={this.props.match.params.status}
                        />
                      )}
                    {this.state.current_date && (
                      <span
                        className="float-right"
                        id="dropdownPrintButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <button
                          type="button"
                          className="add btn btn-outline-primary float-right mr-3"
                        >
                          <Translation>
                            {(t) => <span>{t("parcel_list:Print the parcel receipt")}</span>}
                          </Translation>
                        </button>
                      </span>
                    )}
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownPrintButton"
                    >
                      <React.Fragment>
                        <Link
                          className="dropdown-item cursor"
                          to={{
                            pathname:
                              "/register/residential/account/gen-parcel-a4/}",
                            state: {
                              check_list: this.state.checkList,
                              start_date: this.state.start_date,
                              end_date: this.state.end_date,
                              status: "all",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/correct-icon.png"
                            }
                            alt="correct-icon"
                          />
                          <Translation>
                            {t => <span className="ml-3">{t("parcel_list:All")}</span>}
                          </Translation>
                        </Link>

                        <div className="dropdown-divider" />

                        <Link
                          className="dropdown-item cursor"
                          to={{
                            pathname:
                              "/register/residential/account/gen-parcel-a4/}",
                            state: {
                              check_list: this.state.checkList,
                              start_date: this.state.start_date,
                              end_date: this.state.end_date,
                              status: "not_active",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/correct-icon.png"
                            }
                            alt="u-turn-icon"
                          />
                          <Translation>
                            {t => <span className="ml-3">{t("parcel_list:Not use application")}</span>}
                          </Translation>
                        </Link>
                        <div className="dropdown-divider" />
                      </React.Fragment>
                    </div>
                  </div>
                </div>

                <div className="content-inner">
                  <Navigation />

                  <div className="row mt-1">
                    <div className="col-2">
                      <div className="btn-group">
                        <Translation>
                          {t =>
                            <button
                              type="button"
                              className="btn btn-light dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {t("parcel_list:Manage multiple")}
                            </button>}
                        </Translation>
                        {
                          <div className="dropdown-menu">
                            <Translation>
                              {t =>
                                <React.Fragment>
                                  {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "contact_parcel_edit" }) &&
                                    <span
                                      className="dropdown-item approve-dropdown"
                                      onClick={() =>
                                        this.onChangeStatueParcelReceive(
                                          this.state.checkList,
                                          "receive"
                                        )
                                      }
                                    >
                                      {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                      )}
                                      {t("parcel_list:select_receive")}
                                    </span>
                                  }

                                  {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "contact_parcel_delete" }) &&
                                    <span
                                      className="dropdown-item approve-dropdown"
                                      onClick={() =>
                                        this.onChangeStatueParcel(
                                          this.state.checkList,
                                          "deleted"
                                        )
                                      }
                                    >
                                      {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                      )}
                                      {t("parcel_list:Remove list")}
                                    </span>
                                  }
                                  {_.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "contact_parcel_delete" }) &&
                                    <span
                                      className="dropdown-item approve-dropdown"
                                      onClick={() =>
                                        this.onChangeStatueParcel(
                                          this.state.checkList,
                                          "duplicated"
                                        )
                                      }
                                    >
                                      {this.state.loading && (
                                        <span className="spinner-border spinner-border-sm align-middle mr-2" />
                                      )}
                                      {t("parcel_list:duplicate_receive")}
                                    </span>
                                  }
                                </React.Fragment>


                              }
                            </Translation>
                          </div>
                        }

                      </div>
                    </div>
                    <div className="col-7">
                      <div className="form-inline float-right">
                        <div className="form-group mb-2">
                          <Translation>
                            {t =>
                              <label htmlFor="end_date" className="ml-3 mr-3">
                                {t("parcel_list:Sort by date")}
                              </label>}
                          </Translation>
                          <DatePickerAdapter
                            id="temp_start_date"
                            className="form-control"
                            name="temp_start_date"
                            selected={this.state.temp_start_date}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <Translation>
                            {t =>
                              <label htmlFor="end_date" className="ml-3 mr-3">
                                {t("parcel_list:to")}
                              </label>}
                          </Translation>
                          <DatePickerAdapter
                            id="temp_end_date"
                            name="temp_end_date"
                            className="form-control"
                            selected={this.state.temp_end_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <Search
                        callback_function={this.onChangeSearch}
                        search={this.state.search}
                        handleSearch={this.handleSearch}
                        temp_end_date={this.state.temp_end_date}
                        temp_start_date={this.state.temp_start_date}
                      />
                    </div>
                  </div>

                  <QueryRenderer
                    environment={environment}
                    query={allParcel}
                    cacheConfig={{ use_cache: false }}
                    variables={{
                      search: this.state.search,
                      first: this.state.first,
                      last: this.state.last,
                      start_date: this.state.start_date,
                      end_date: this.state.end_date,
                      status: this.props.match.params.status,
                      reQuery: this.state.reQuery,
                      order: this.state.order,
                    }}
                    render={({ error, props }) => {
                      if (error) {
                        return (
                          <div className="alert alert-danger" role="alert">
                            {error.message}
                          </div>
                        );
                      } else if (props) {
                        return (

                          <React.Fragment>
                            <div className="card fade-up" id="parcel">
                              <div className="table-responsive">
                                <table className="table table-hover">
                                  {this.props.match.params.status === "all" && (
                                    <React.Fragment>
                                      <thead className="thead-light">
                                        <tr>
                                          <th>
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id="checkAll"
                                                className="custom-control-input"
                                                onChange={() =>
                                                  this.getAllList(
                                                    props.parcelViewer.allParcel
                                                      .edges
                                                  )
                                                }
                                                checked={this.state.check_all}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="checkAll"
                                              />
                                            </div>
                                          </th>
                                          {this.state.current_date && (
                                            <Translation>{t => <th>{t("parcel_list:No.")}</th>}</Translation>
                                          )}
                                          <th>
                                            <div className="dropdown dropdown-status">
                                              <span
                                                className="cursor"
                                                onClick={() =>
                                                  this.setState({
                                                    order:
                                                      this.state.order === "added"
                                                        ? "-added"
                                                        : "added",
                                                    send: !this.state.send,
                                                    receive: true,
                                                    return: true,
                                                  })
                                                }
                                              >
                                                <Translation>{t => <>{t("parcel_list:Delivery date")}</>}</Translation>
                                                {this.state.send ? (
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/icons/sort-down.png"
                                                    }
                                                    alt="sort-down-icon"
                                                    className="ml-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/icons/sort-up.png"
                                                    }
                                                    alt="sort-up-icon"
                                                    className="ml-1"
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </th>

                                          <th>Tracking</th>
                                          <Translation>{t => <th>{t("parcel_list:Parcel status")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Room")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Name-Surname")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Recipient name")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Sent by")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Parcel feature")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Return note")}</th>}</Translation>
                                          <th />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {_.map(
                                          props.parcelViewer.allParcel.edges,
                                          (parcel, index) => (

                                            <React.Fragment>
                                              <tr key={index} className="show">
                                                <td>
                                                  <div className="custom-control custom-checkbox">
                                                    <input
                                                      type="checkbox"
                                                      id={parcel.node.id}
                                                      className="custom-control-input"
                                                      onChange={() =>
                                                        this.appendToCheckList(
                                                          parcel.node.id
                                                        )
                                                      }
                                                      checked={this.checkList(
                                                        parcel.node.id
                                                      )}
                                                    />
                                                    <label
                                                      className="custom-control-label"
                                                      htmlFor={parcel.node.id}
                                                    />
                                                  </div>
                                                </td>

                                                {this.state.current_date && (
                                                  <td>{parcel.node.docNumber}</td>
                                                )}
                                                <td>
                                                  {format(
                                                    parcel.node.added,
                                                    "DD/MM/YYYY - HH:mm น.",
                                                    { locale: thLocale }
                                                  )}
                                                </td>
                                                <td>
                                                  {parcel.node?.trackingNumber
                                                    ? parcel.node.trackingNumber
                                                    : ""}
                                                </td>
                                                <Translation>{t => <td>{parcel.node?.status && t(`parcel_list:${this.getParcelStatus(parcel.node.status)}`)}</td>}</Translation>
                                                <td>
                                                  {parcel.node.residential.name}
                                                </td>
                                                <td>{parcel.node.name}</td>
                                                <td>
                                                  {
                                                    parcel.node?.status == "RECEIVE"
                                                      ? `${parcel.node.receiverName} ${this.takeName(parcel)}`
                                                      : "-"
                                                  }
                                                </td>
                                                <td>{parcel.node.serviceName}</td>
                                                <td>
                                                  {parcel.node.imageParcel && (
                                                    <React.Fragment>
                                                      <img
                                                        src={
                                                          parcel.node.imageParcel
                                                        }
                                                        alt="parcel"
                                                        className="img-fluid cursor parcel"
                                                        onClick={() =>
                                                          this.toggleLightBox(
                                                            true,
                                                            parcel.node.imageParcel
                                                          )
                                                        }
                                                      />
                                                      &emsp;
                                                    </React.Fragment>
                                                  )}
                                                  {parcel.node.description}
                                                </td>
                                                <td>{(parcel.node?.status == "RETURN" && parcel.node.returnRemark) ? parcel.node.returnRemark : "-"}</td>
                                                {parcel.node.status === "NOT_RECEIVE" && (
                                                  <td width="150" height="60">
                                                    <span className="btn show-icon cursor" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                                      <img src={process.env.PUBLIC_URL + "/images/icons/choice.png"} alt="choice" />
                                                    </span>
                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" >
                                                      {_.some(JSON.parse(localStorage.getItem("permission_list")),
                                                        { codename: "contact_parcel_edit", }) && (
                                                          <React.Fragment>
                                                            <span className="dropdown-item cursor" onClick={() => this.onClickReceive(parcel.node.id)}>
                                                              <img src={process.env.PUBLIC_URL + "/images/icons/correct-icon.png"} alt="correct-icon" />
                                                              <Translation>
                                                                {t =>
                                                                  <span className="ml-3">
                                                                    {t("parcel_list:Received")}
                                                                  </span>}
                                                              </Translation>
                                                            </span>
                                                            <div className="dropdown-divider" />
                                                            <span className="dropdown-item cursor" onClick={() => this.onChangeStatusToReturn(parcel.node.id)}>
                                                              <img src={process.env.PUBLIC_URL + "/images/icons/u-turn-icon.png"} alt="u-turn-icon" />
                                                              <Translation>{t => <span className="ml-3"> {t("parcel_list:Return")} </span>}</Translation>
                                                            </span>
                                                            <div className="dropdown-divider" />
                                                          </React.Fragment>
                                                        )}
                                                      {_.some(JSON.parse(localStorage.getItem("permission_list")),
                                                        { codename: "contact_parcel_delete", }) && (
                                                          <span className="dropdown-item cursor" onClick={() => this.onDeleteParcel(parcel.node.id)}>
                                                            <img src={process.env.PUBLIC_URL + "/images/icons/bin.png"} alt="bin" />
                                                            <Translation>{t => <span className="ml-3">{t("parcel_list:Remove")}</span>}</Translation>
                                                          </span>
                                                        )}
                                                    </div>
                                                  </td>

                                                )}

                                                {parcel.node.status !== "NOT_RECEIVE" && <td />}



                                              </tr>
                                            </React.Fragment>
                                          )
                                        )}
                                      </tbody>
                                    </React.Fragment>
                                  )}
                                  {this.props.match.params.status !== "all" && (
                                    <React.Fragment>
                                      <thead className="thead-light">
                                        <tr>
                                          <th>
                                            <div className="custom-control custom-checkbox">
                                              <input
                                                type="checkbox"
                                                id="checkAll"
                                                className="custom-control-input"
                                                onChange={() =>
                                                  this.getAllList(
                                                    props.parcelViewer.allParcel
                                                      .edges
                                                  )
                                                }
                                                checked={this.state.check_all}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor="checkAll"
                                              />
                                            </div>
                                          </th>
                                          {this.state.current_date && (
                                            <Translation>{t => <th>{t("parcel_list:No.")}</th>}</Translation>
                                          )}
                                          {this.props.match.params.status !==
                                            "not_receive" && (
                                              <th>
                                                <div className="dropdown dropdown-status">
                                                  {this.props.match.params.status ===
                                                    "receive" ? (
                                                    <span
                                                      className="cursor"
                                                      onClick={() =>
                                                        this.setState({
                                                          order:
                                                            this.state.order ===
                                                              "pickup_date"
                                                              ? "-pickup_date"
                                                              : "pickup_date",
                                                          receive: !this.state
                                                            .receive,
                                                          send: true,
                                                        })
                                                      }
                                                    >
                                                      <Translation>{t => <>{t("parcel_list:Received date")}</>}</Translation>
                                                      {this.state.receive ? (
                                                        <img
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/sort-down.png"
                                                          }
                                                          alt="sort-down-icon"
                                                          className="ml-1"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/sort-up.png"
                                                          }
                                                          alt="sort-up-icon"
                                                          className="ml-1"
                                                        />
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="cursor"
                                                      onClick={() =>
                                                        this.setState({
                                                          order:
                                                            this.state.order ===
                                                              "updated"
                                                              ? "-updated"
                                                              : "updated",
                                                          return: !this.state.return,
                                                          send: true,
                                                        })
                                                      }
                                                    >
                                                      {this.props.match.params
                                                        .status === "deleted"
                                                        ? <Translation>{t => <>{t("parcel_list:Removal date")}</>}</Translation>
                                                        : <Translation>{t => <>{t("parcel_list:Return date")}</>}</Translation>}
                                                      {this.state.return ? (
                                                        <img
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/sort-down.png"
                                                          }
                                                          alt="sort-down-icon"
                                                          className="ml-1"
                                                        />
                                                      ) : (
                                                        <img
                                                          src={
                                                            process.env.PUBLIC_URL +
                                                            "/images/icons/sort-up.png"
                                                          }
                                                          alt="sort-up-icon"
                                                          className="ml-1"
                                                        />
                                                      )}
                                                    </span>
                                                  )}
                                                </div>
                                              </th>
                                            )}
                                          <th>
                                            <div className="dropdown dropdown-status">
                                              <span
                                                className="cursor"
                                                onClick={() =>
                                                  this.setState({
                                                    order:
                                                      this.state.order === "added"
                                                        ? "-added"
                                                        : "added",
                                                    send: !this.state.send,
                                                    receive: true,
                                                    return: true,
                                                  })
                                                }
                                              >
                                                <Translation>{t => <>{t("parcel_list:Delivery date")}</>}</Translation>
                                                {this.state.send ? (
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/icons/sort-down.png"
                                                    }
                                                    alt="sort-down-icon"
                                                    className="ml-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/images/icons/sort-up.png"
                                                    }
                                                    alt="sort-up-icon"
                                                    className="ml-1"
                                                  />
                                                )}
                                              </span>
                                            </div>
                                          </th>
                                          <th>Tracking</th>
                                          {this.props.match.params.status ===
                                            "receive" && <Translation>{t => <th>{t("parcel_list:Recipient name")}</th>}</Translation>}
                                          <Translation>{t => <th>{t("parcel_list:Room")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Name-Surname")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Sent by")}</th>}</Translation>
                                          <Translation>{t => <th>{t("parcel_list:Parcel feature")}</th>}</Translation>
                                          {/* <Translation>{t=><th>{t("parcel_list:Duplicate note")}</th>}</Translation> */}
                                          {this.props.match.params.status ===
                                            "not_receive" &&
                                            <>
                                              <Translation>{t => <th>{t("parcel_list:By")}</th>}</Translation>
                                            </>
                                          }
                                          {this.props.match.params.status ===
                                            "not_receive" &&
                                            <>
                                              <Translation>{t => <th>{t("parcel_list:Duplicate note")}</th>}</Translation><th />
                                            </>
                                          }

                                          {this.props.match.params.status ===
                                            "return" && <Translation>{t => <th>{t("parcel_list:Return note")}</th>}</Translation>}
                                          {this.props.match.params.status ===
                                            "deleted" &&
                                            <>
                                              <Translation>{t => <th>{t("parcel_list:Delete By")}</th>}</Translation>
                                            </>
                                          }
                                          {this.props.match.params.status ===
                                            "deleted" &&
                                            <>
                                              <Translation>{t => <th>{t("parcel_list:Reason")}</th>}</Translation>
                                            </>
                                          }

                                        </tr>
                                      </thead>
                                      <tbody>
                                        {props.parcelViewer.allParcel.edges.map(
                                          (parcel) =>
                                          (
                                            <tr
                                              key={parcel.node.id}
                                              className="show"
                                            >
                                              <td>
                                                <div className="custom-control custom-checkbox">
                                                  <input
                                                    type="checkbox"
                                                    id={parcel.node.id}
                                                    className="custom-control-input"
                                                    onChange={() =>
                                                      this.appendToCheckList(
                                                        parcel.node.id
                                                      )
                                                    }
                                                    checked={this.checkList(
                                                      parcel.node.id
                                                    )}
                                                  />
                                                  <label
                                                    className="custom-control-label"
                                                    htmlFor={parcel.node.id}
                                                  />
                                                </div>
                                              </td>
                                              {this.state.current_date && (
                                                <td>{parcel.node.docNumber}</td>
                                              )}
                                              {this.props.match.params.status ===
                                                "receive" && (
                                                  <td>
                                                    {parcel.node.pickupDate
                                                      ? format(
                                                        parcel.node.pickupDate,
                                                        "DD/MM/YYYY - HH:mm น.",
                                                        { locale: thLocale }
                                                      )
                                                      : "-"}
                                                  </td>
                                                )}
                                              {(this.props.match.params.status ===
                                                "deleted" ||
                                                this.props.match.params.status ===
                                                "return") && (
                                                  <td>
                                                    {format(
                                                      parcel.node.updated,
                                                      "DD/MM/YYYY ",
                                                      { locale: thLocale }
                                                    )}
                                                  </td>
                                                )}
                                              <td>
                                                {format(
                                                  parcel.node.added,
                                                  "DD/MM/YYYY - HH:mm น.",
                                                  { locale: thLocale }
                                                )}
                                              </td>
                                              <td>
                                                {parcel.node?.trackingNumber
                                                  ? parcel.node.trackingNumber
                                                  : ""}
                                              </td>
                                              {this.props.match.params.status ===
                                                "receive" && (
                                                  <td>
                                                    {`${parcel.node.receiverName} ${this.takeName(parcel)}`}
                                                  </td>

                                                )}

                                              <td>
                                                {parcel.node.residential.name}
                                              </td>
                                              <td>{parcel.node.name}</td>
                                              <td>{parcel.node.serviceName}</td>
                                              <td>
                                                {parcel.node.imageParcel && (
                                                  <React.Fragment>
                                                    <img
                                                      src={parcel.node.imageParcel}
                                                      alt="parcel"
                                                      className="img-fluid cursor parcel"
                                                      onClick={() =>
                                                        this.toggleLightBox(
                                                          true,
                                                          parcel.node.imageParcel
                                                        )
                                                      }
                                                    />
                                                    &emsp;
                                                  </React.Fragment>
                                                )}
                                                {
                                                  t("pdpaPrivacyPolicy:PrivacyPolicy") === "th" ?
                                                    parcel.node.description : key[parcel.node.description]
                                                }
                                              </td>
                                              {(parcel.node.userFirstName === "") && (parcel.node.userLastName === "")
                                                ? (this.props.match.params.status === "not_receive") && <td>-</td>
                                                : (this.props.match.params.status === "not_receive") && <td>{parcel.node.userFirstName} {parcel.node.userLastName}</td>
                                              }

                                              {this.props.match.params.status ===
                                                "not_receive" && (
                                                  <td>
                                                    {
                                                      parcel.node?.parcelNoti?.edges && (this.getLastTime(parcel.node?.parcelNoti?.edges))
                                                    }

                                                  </td>

                                                )}

                                              {this.props.match.params.status ===
                                                "not_receive" && (
                                                  <td width="100" height="60">
                                                    <span
                                                      className="btn show-icon cursor"
                                                      id="dropdownMenuButton"
                                                      data-toggle="dropdown"
                                                      aria-haspopup="true"
                                                      aria-expanded="false"
                                                    >
                                                      <img
                                                        src={
                                                          process.env.PUBLIC_URL +
                                                          "/images/icons/choice.png"
                                                        }
                                                        alt="choice"
                                                      />
                                                    </span>
                                                    <div
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuButton"
                                                    >
                                                      {_.some(
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "permission_list"
                                                          )
                                                        ),
                                                        {
                                                          codename:
                                                            "contact_parcel_edit",
                                                        }
                                                      ) && (
                                                          <React.Fragment>
                                                            <span
                                                              className="dropdown-item cursor"
                                                              onClick={() =>
                                                                this.onClickReceive(
                                                                  parcel.node.id
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  process.env
                                                                    .PUBLIC_URL +
                                                                  "/images/icons/correct-icon.png"
                                                                }
                                                                alt="correct-icon"
                                                              />
                                                              <Translation>
                                                                {t =>
                                                                  <span className="ml-3">
                                                                    {t("parcel_list:Received")}
                                                                  </span>}
                                                              </Translation>
                                                            </span>

                                                            <div className="dropdown-divider" />

                                                            <span
                                                              className="dropdown-item cursor"
                                                              onClick={() =>
                                                                this.onChangeStatusToReturn(
                                                                  parcel.node.id
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  process.env
                                                                    .PUBLIC_URL +
                                                                  "/images/icons/u-turn-icon.png"
                                                                }
                                                                alt="u-turn-icon"
                                                              />
                                                              <Translation>
                                                                {t =>
                                                                  <span className="ml-3">
                                                                    {t('parcel_list:Return')}
                                                                  </span>}
                                                              </Translation>
                                                            </span>
                                                            <div className="dropdown-divider" />
                                                          </React.Fragment>
                                                        )}

                                                      {_.some(
                                                        JSON.parse(
                                                          localStorage.getItem(
                                                            "permission_list"
                                                          )
                                                        ),
                                                        {
                                                          codename:
                                                            "contact_parcel_delete",
                                                        }
                                                      ) && (
                                                          <span
                                                            className="dropdown-item cursor"
                                                            onClick={() =>
                                                              this.onDeleteParcel(
                                                                parcel.node.id
                                                              )
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                process.env.PUBLIC_URL +
                                                                "/images/icons/bin.png"
                                                              }
                                                              alt="bin"
                                                            />
                                                            <Translation>
                                                              {t =>
                                                                <span className="ml-3">
                                                                  {t("parcel_list:Remove")}
                                                                </span>}
                                                            </Translation>
                                                          </span>
                                                        )}
                                                    </div>
                                                  </td>

                                                )}
                                              {this.props.match.params.status ===
                                                "return" && (
                                                  <td>
                                                    {parcel.node.returnRemark || "-"}
                                                  </td>
                                                )}

                                              {this.props.match.params.status ===
                                                "deleted" && <td>
                                                  {parcel.node.deleterName || "-"}
                                                </td>
                                              }
                                              {this.props.match.params.status ===
                                                "deleted" &&
                                                <td>
                                                  {parcel.node.deleteRemark || "-"}
                                                </td>
                                              }

                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </React.Fragment>
                                  )}
                                </table>
                              </div>
                            </div>
                            <div className="row">
                              <Pagination
                                changePage={this.changePage}
                                first={this.state.first}
                                last={this.state.last}
                                totalCount={props.parcelViewer.allParcel.totalCount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      }
                      return <Loading />
                    }}
                  />
                </div>

                <Lightbox
                  images={[{ src: this.state.image_light_box }]}
                  onClose={() => this.toggleLightBox(false)}
                  isOpen={this.state.open_light_box}
                  backdropClosesModal={true}
                  showImageCount={false}
                />
              </div>
            }
          </Translation>
        </div>
      </Wrapper>
    );
  }
}

export default ParcelList;
