import React, {Component} from 'react';
import PrivateRoute from '../../libs/privateRoute';
import {Route, Switch} from "react-router-dom";
import NoMatch from "../../components/noMatch";
import UnitReportList from "./unitReportList";
import WrapperUnitReport from './wrapperUnitReport';

class UnitReport extends Component {
    render() {
        return (
            <Switch>
                <PrivateRoute exact path={`${this.props.match.url}/list`} component={UnitReportList}/>
                <PrivateRoute path={`${this.props.match.url}/unit-report-pdf/:startDate?/:endDate?/:search?`} component={WrapperUnitReport}/>
                <Route component={NoMatch}/>
            </Switch>
        );
    }
}

export default UnitReport;