/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssetCalSchedule = "MONTHLY" | "YEARLY" | "%future added value";
export type AssetSaleStatus = "ACTIVE" | "DETERIORATE" | "DISAPPEAR" | "SOLD" | "%future added value";
export type AssetType = "BUILDING" | "BUILDING_RENOVATION" | "COMMON_PROPERTY" | "INTANGIBLE_ASSET" | "LAND" | "OFFICE_DECORATION" | "OFFICE_EQUIPMENT" | "OTHER_ASSET" | "TOOLS" | "TRAINING_EQUIPMENT" | "VEHICLE" | "%future added value";
export type assetDepreciateReportQueryVariables = {|
  search?: ?string,
  start_date?: ?any,
  end_date?: ?any,
  type?: ?string,
  last_date?: ?any,
|};
export type assetDepreciateReportQueryResponse = {|
  +allAssetReport: ?{|
    +totalCount: ?number,
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +docNumber: string,
        +name: string,
        +purchaseDate: ?any,
        +costPrice: ?number,
        +calDepreciation: boolean,
        +depreciationRatePercent: ?number,
        +carcassPrice: ?number,
        +saleStatus: AssetSaleStatus,
        +saleDate: ?any,
        +historyPreviousAmount: ?number,
        +depreciationAmount: ?number,
        +historyNextAmount: ?number,
        +calDepreciationDate: ?any,
        +calSchedule: AssetCalSchedule,
        +type: AssetType,
        +historicalAmount: number,
      |}
    |}>,
  |}
|};
export type assetDepreciateReportQuery = {|
  variables: assetDepreciateReportQueryVariables,
  response: assetDepreciateReportQueryResponse,
|};
*/


/*
query assetDepreciateReportQuery(
  $search: String
  $start_date: DateTime
  $end_date: DateTime
  $type: String
  $last_date: DateTime
) {
  allAssetReport(search: $search, startDate: $start_date, endDate: $end_date, type: $type, lastDate: $last_date) {
    totalCount
    edges {
      node {
        id
        docNumber
        name
        purchaseDate
        costPrice
        calDepreciation
        depreciationRatePercent
        carcassPrice
        saleStatus
        saleDate
        historyPreviousAmount
        depreciationAmount
        historyNextAmount
        calDepreciationDate
        calSchedule
        type
        historicalAmount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "endDate",
        "variableName": "end_date"
      },
      {
        "kind": "Variable",
        "name": "lastDate",
        "variableName": "last_date"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "start_date"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "AssetNodeConnection",
    "kind": "LinkedField",
    "name": "allAssetReport",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetNodeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetNode",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "docNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchaseDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "costPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calDepreciation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationRatePercent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "carcassPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saleStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "saleDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "historyPreviousAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "depreciationAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "historyNextAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calDepreciationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "calSchedule",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "historicalAmount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetDepreciateReportQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "assetDepreciateReportQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b0ab97bceae08cb6fc30c55852dde2f0",
    "id": null,
    "metadata": {},
    "name": "assetDepreciateReportQuery",
    "operationKind": "query",
    "text": "query assetDepreciateReportQuery(\n  $search: String\n  $start_date: DateTime\n  $end_date: DateTime\n  $type: String\n  $last_date: DateTime\n) {\n  allAssetReport(search: $search, startDate: $start_date, endDate: $end_date, type: $type, lastDate: $last_date) {\n    totalCount\n    edges {\n      node {\n        id\n        docNumber\n        name\n        purchaseDate\n        costPrice\n        calDepreciation\n        depreciationRatePercent\n        carcassPrice\n        saleStatus\n        saleDate\n        historyPreviousAmount\n        depreciationAmount\n        historyNextAmount\n        calDepreciationDate\n        calSchedule\n        type\n        historicalAmount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2dd6fd818b28b23136eb7c6fb0ee2f66';

module.exports = node;
