import React, { Component } from "react";
import Header from "../../../components/header/index";
import Sidebar from "../../../components/sidebar/index";
import Wrapper from "../../../components/wrapper/index";
import WrapperContent from "../../../components/wrapper/wrapperContent";
import { graphql } from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../../env/environment";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import { format } from "date-fns";
import thLocale from "date-fns/locale/th";
import numberWithComma from "../../../libs/numberWithComma";
import getNameResidential from "../../../libs/getNameResidential";
import Swal from "sweetalert2";
// import InvoiceSummary from "../../income/invoice/invoiceSummary";
import OtherExpenseSummary from "./core/otherExpenseSummary"
import { fetchQuery } from "relay-runtime";
import AccountingTopMenuNavigation from "../../accountingTopMenuNavigation";
import Redirect from "react-router-dom/es/Redirect";
import OtherExpensePayChannel from "./otherExpensePayChannel";
import _ from "lodash";
import i18next from "i18next";
import BackButtonIcon from "../../../components/BackBtn/indexBack";

const query = graphql`
  query otherExpenseViewQuery($id: ID!, $ref_transaction: String) {
    otherExpense(id: $id) {
      id
      issuedDate
      docNumber
      remark
      status
      total
      taxType
      refNumber
      taxInvoiceNumber
      taxInvoiceDate
      taxInvoicePeriod
      contact {
        name
        refNumber
        firstName
        lastName
        registeredName
        registeredAddress
        registeredCountry
        registeredProvince
        registeredCity
        registeredDistrict
        registeredPostalCode
        typeOfContact
      }

      firstName 
      lastName
      name
      phone
      email
      taxIdNumber
      address
      district
      city
      province
      postalCode
      country

      unknownContact
      # otherExpensePaymentChannel(status:"paid",slug:"petty_cash"){
      otherExpensePaymentChannel(status:"paid"){
        edges{
          node{
            id
            price
            date
            setPettyCash{
              docNumber
              description
              withdrawer
            }
            rdCash{
                docNumber 
            }
          }
        }
      }
      otherExpenseTransaction {
        edges {
          node {
            id
            productAndService {
              productCode
              name
            }
            chartOfAccount {
              chartOfAccountCode
              name
            }
            description
            unitItems
            price
            discount
            vat
            vatAmount
            preTaxAmount
            whtRate
            whtRatePercent
          }
        }
      }
    }
    cashTransactionViewer {
      allCashTransaction(refNumber: $ref_transaction) {
        edges {
          node {
            id
            description
            price
          }
        }
      }
    }
    chequeTransactionViewer {
      allChequeTransaction(refNumber: $ref_transaction , status_In:"wait, clearing, void, bide, delete") {
        edges {
          node {
            id
            date
            bankAccount {
              id
              accountName
              branch
            }
            chequeNumber
            price
          }
        }
      }
    }

    allBankAccountTransaction(refTransaction: $ref_transaction) {
      edges {
        node {
          id
          price
          date
          imageSlip
          bankAccount {
            bankName
            accountNumber
          }
        }
      }
    }

    allAccountRecordGroup(refTransaction: $ref_transaction) {
      edges {
        node {
          id
          refNumber
          name
          added
          issuedDate
          category
          totalDebit
          totalCredit
          refTransaction
          creator
          contact {
            id
            name
            firstName
            lastName
          }
          accountRecord {
            edges {
              node {
                id
                name
                debit
                credit
                chartOfAccountCode {
                  id
                  name
                  chartOfAccountCode
                }
              }
            }
          }
        }
      }
    }
  }
`;

const mutation = graphql`
  mutation otherExpenseViewMutation(
    $input: UpdateOtherExpenseAndOtherExpenseTransactionStatusInput!
  ) {
    updateOtherExpenseAndOtherExpenseTransactionStatus(input: $input) {
      ok
      warningText
    }
  }
`;

const other_expense = {
  id: "",
  issuedDate: "",
  docNumber: "",
  remark: "",
  status: "",
  contact: {},
  unknownContact: "",


  otherExpenseTransaction: {
    edges: {
      node: {
        id: "",
        productAndService: {
          productCode: "",
          name: "",
        },
        description: "",
        unitItems: "",
        price: "",
        discount: "",
        vat: "",
        vatAmount: "",
        preTaxAmount: "",
        whtRate: "",
      },
    },
  },
};

class OtherExpenseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sum_total: 0,
      loading: false,
      transactions: [],
      other_expense: other_expense,
      redirectTo: false,

      allBankAccountTransaction: [],
      allCashTransaction: [],
      allChequeTransaction: [],
      allAccountRecordGroup: [],
      allOtherExpensePaymentChannel: [],
      oe_5400_07: false,
    };

    this.onChangeStatusToVoid = this.onChangeStatusToVoid.bind(this);
  }

  componentWillMount() {
    fetchQuery(environment, query, {
      id: this.props.match.params.id,
      ref_transaction: this.props.match.params.ref_transaction,
    }).then((data) => {

      let Oe_5400_07 = _.findIndex(data.otherExpense.otherExpenseTransaction.edges, { 'node': { 'chartOfAccount': { 'chartOfAccountCode': '5400-07' } } }) // -1 คือไม่มี > 0 คือมีในรายการจ่าย
      this.setState({
        allCashTransaction: data.cashTransactionViewer.allCashTransaction.edges,
        allChequeTransaction: data.chequeTransactionViewer.allChequeTransaction.edges,
        allBankAccountTransaction: data.allBankAccountTransaction.edges,
        allOtherExpensePaymentChannel: data.otherExpense.otherExpensePaymentChannel.edges,
        allAccountRecordGroup: data.allAccountRecordGroup.edges,
        oe_5400_07: Oe_5400_07 >= 0 ? true : false,
      });
      if (data.otherExpense) {
        this.setState({ other_expense: data.otherExpense });
      }
      if (data.otherExpense.otherExpenseTransaction) {
        let transactions = [];
        data.otherExpense.otherExpenseTransaction.edges.forEach((trans) => { transactions.push(trans.node); });
        this.setState({ transactions: transactions });
      }
    });
  }

  onChangeStatusToVoid() {
    Swal.fire({
      title: `${i18next.t("otherExpenseView:Are you sure you want to cancel the record of other expenses")}?`,
      text: "",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: i18next.t("Allaction:Yes"),
      cancelButtonText: i18next.t("Allaction:cancel"),
    }).then(async (result) => {
      if (result.value) {
        let { value: void_remark } = await Swal.fire({
          title: i18next.t("Allaction:Note"),
          input: "text",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: i18next.t("Allaction:cancel"),
          confirmButtonText: i18next.t("Allaction:Confirm"),
          inputValidator: (value) => {
            if (!value) {
              return i18next.t("Allaction:Please enter a cancellation note.");
            }
          },
        });

        if (void_remark) {
          this.setState({ loading: true });
          let variables = {
            input: {
              status: "void",
              otherExpenseId: this.props.match.params.id,
              voidRemark: void_remark,
            },
          };

          commitMutation(environment, {
            mutation,
            variables,
            onCompleted: (response) => {
              this.setState({ loading: false });
              if (
                response.updateOtherExpenseAndOtherExpenseTransactionStatus.ok
              ) {
                Swal.fire(i18next.t("Allaction:successcompleate"), "", "success").then(() =>
                  this.setState({ redirectTo: true })
                );
              } else {
                Swal.fire(
                  i18next.t("Allaction:Saved Unsuccessful"),
                  response.updateOtherExpenseAndOtherExpenseTransactionStatus
                    .warningText,
                  "warning"
                );
                this.setState({ loading: false });
              }
            },
            onError: (err) => {
              this.setState({ loading: false });
              Swal.fire(i18next.t("Allaction:Saved Unsuccessful"), "", "warning");
            },
          });
        }
      } else {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to="/accounting/expense/other_expense/list/all" />;
    }

    return (
      <Wrapper>
        <Header />
        <Sidebar />
        <WrapperContent>
          <AccountingTopMenuNavigation mini={true} />

          <Translation>
            {t =>
              <div className="container-fluid box" id="invoice-detail">

                <BackButtonIcon
                  LinkBack={"/accounting/expense/other_expense/list/all"}
                  LinkText={i18next.t("expense:other_expense")+" "+"(OE)"}
                  boxHtmlText={
                    <div className="col text-right">
                      {this.state.other_expense.status !== "VOID" &&
                        _.some(JSON.parse(localStorage.getItem("permission_list")), { codename: "accounting_other_expense_delete", }) && (
                          <button
                            className="btn btn-danger add mr-3"
                            disabled={this.state.loading}
                            onClick={this.onChangeStatusToVoid}
                          >
                            {this.state.loading && (<span className="spinner-border spinner-border-sm align-middle mr-2" />)}
                            {i18next.t("otherExpenseView:Cancel Other Expenses Record")}
                          </button>
                        )}
                      {_.some(JSON.parse(localStorage.getItem("permission_list")), {
                        codename: "accounting_other_expense_print",
                      }) && (
                          <Link
                            to={
                              "/document/other-expense/" + this.props.match.params.id + "/" + this.props.match.params.ref_transaction
                            }
                            target="_blank"
                          >
                            <button
                              className="btn btn-primary add"
                              disabled={this.state.loading}
                            >
                              {i18next.t("otherExpenseView:Print Other Expenses Record")}
                            </button>
                          </Link>
                        )}
                    </div>
                  }
                />


                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Creditor")}</strong>
                        </p>
                        <p>
                          {this.state.other_expense.contact
                            ? this.state.other_expense.contact.typeOfContact ===
                              "RESIDENTIAL"
                              ? this.state.other_expense.contact.refNumber +
                              " " +
                              getNameResidential(
                                this.state.other_expense.contact.firstName,
                                this.state.other_expense.contact.lastName
                              )
                              : this.state.other_expense.contact.refNumber +
                              " " +
                              this.state.other_expense.contact.name
                            : this.state.other_expense.unknownContact || "-"}
                        </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Number")}</strong>
                        </p>
                        <p>{this.state.other_expense.docNumber} </p>
                      </div>
                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Issued Date")}</strong>
                        </p>
                        <p>
                          {format(this.state.other_expense.issuedDate, "DD/MM/YYYY", { locale: thLocale })}
                        </p>
                      </div>

                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Reference Document Number")}</strong>
                        </p>
                        <p>{this.state.other_expense.refNumber} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Invoice/Receipt Number")}</strong>
                        </p>
                        <p>{this.state.other_expense.taxInvoiceNumber || "-"}</p>
                      </div>


                      {this.state.other_expense.taxInvoiceNumber ? <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Dated")}</strong>
                        </p>
                        <p>
                          {format(this.state.other_expense.taxInvoiceDate, "DD/MM/YYYY", { locale: thLocale })}
                        </p>
                      </div>
                        :
                        <div className="col"></div>
                      }

                    </div>

                    <hr />
                    <div className="row">
                      <div className="col">
                        <p>
                          <strong>{i18next.t("otherExpenseView:Address")}</strong>
                        </p>
                        {/* <p>
                      {this.state.other_expense.contact ? (
                        <>
                          {this.state.other_expense.contact.registeredProvince?.includes("กรุงเทพ") || this.state.other_expense.contact.registeredProvince?.includes("กทม")? (
                            <>
                              {this.state.other_expense.contact.registeredAddress}
                              {this.state.other_expense.contact.registeredDistrict && ` แขวง${this.state.other_expense.contact.registeredDistrict}`}
                              {this.state.other_expense.contact.registeredCity && ` เขต${this.state.other_expense.contact.registeredCity}`}
                              {this.state.other_expense.contact.registeredProvince && ` ${this.state.other_expense.contact.registeredProvince} `}
                              {this.state.other_expense.contact.registeredPostalCode}{" "}
                              {this.state.other_expense.contact.registeredCountry}
                            </>
                          ) : (
                            <>
                              {this.state.other_expense.contact.registeredAddress}
                              {this.state.other_expense.contact.registeredDistrict && ` ตำบล${this.state.other_expense.contact.registeredDistrict}`}
                              {this.state.other_expense.contact.registeredCity && ` อำเภอ${this.state.other_expense.contact.registeredCity}`}
                              {this.state.other_expense.contact.registeredProvince && ` จังหวัด${this.state.other_expense.contact.registeredProvince} `}
                              {this.state.other_expense.contact.registeredPostalCode}{" "}
                              {this.state.other_expense.contact.registeredCountry}
                            </>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </p> */}


                        {/* BTA685 new rq */}
                        <p>
                          {this.state.other_expense?.province === "กรุงเทพ" || this.state.other_expense?.province === "กรุงเทพมหานคร" || this.state.other_expense?.province === "กทม" ? (
                            <>
                              {this.state.other_expense?.address + " "}
                              {this.state.other_expense?.district && ` แขวง${this.state.other_expense?.district}`}
                              {this.state.other_expense?.city && ` เขต${this.state.other_expense?.city}`}
                              {this.state.other_expense?.province && ` ${this.state.other_expense?.province} `}
                              {this.state.other_expense?.postalCode} {" "}
                              {this.state.other_expense?.country}
                            </>
                          ) : (
                            <>
                              {this.state.other_expense.address}
                              {this.state.other_expense.district && ` ตำบล${this.state.other_expense.district}`}
                              {this.state.other_expense.city && ` อำเภอ${this.state.other_expense.city}`}
                              {this.state.other_expense.province && ` จังหวัด${this.state.other_expense.province} `}
                              {this.state.other_expense.postalCode}{" "}
                              {this.state.other_expense.country}
                            </>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="table-responsive fade-up">
                      <table className="table table-hover mt-2">
                        <thead className="thead-light">
                          <tr>
                            <th>{i18next.t("otherExpenseView:Item")}</th>
                            <th>{i18next.t("otherExpenseView:Description")}</th>
                            <th className="text-center">{i18next.t("otherExpenseView:Quantity")}</th>
                            <th className="text-right">{i18next.t("otherExpenseView:Price/Unit")}</th>
                            <th className="text-right">{i18next.t("otherExpenseView:Discount")}</th>
                            <th className="text-right">{i18next.t("otherExpenseView:VAT")}</th>
                            <th className="text-right">{i18next.t("otherExpenseView:VAT Amount")}</th>
                            <th className="text-right">{i18next.t("otherExpenseView:Withholding Tax")}</th>
                          </tr>
                        </thead>
                        {this.state.transactions.map((oe_transaction) => {
                          let code = "";
                          let name = "";
                          if (oe_transaction.productAndService) {
                            code = oe_transaction.productAndService.productCode;
                            name = oe_transaction.productAndService.name;
                          } else {
                            code = oe_transaction.chartOfAccount.chartOfAccountCode;
                            name = oe_transaction.chartOfAccount.name;
                          }
                          return (
                            <tbody key={oe_transaction.id}>
                              <tr>
                                <td>
                                  {code} {name}
                                </td>
                                <td>{oe_transaction.description}</td>
                                <td className="text-center">
                                  {oe_transaction.unitItems}
                                </td>
                                <td className="text-right">
                                  {numberWithComma(oe_transaction.price)}
                                </td>
                                <td className="text-right">
                                  {numberWithComma(oe_transaction.discount)}
                                </td>

                                {oe_transaction.vat === 0 ? (
                                  <td className="text-right">0%</td>
                                ) : oe_transaction.vat === 7 ? (
                                  <td className="text-right">7%</td>
                                ) : (
                                  <td className="text-right">{i18next.t("preFixName:None")}</td>
                                )}

                                <td className="text-right">
                                  {numberWithComma(oe_transaction.vatAmount)}
                                </td>
                                <td className="text-right">
                                  {oe_transaction.whtRate || "-"}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </table>
                    </div>
                    <OtherExpensePayChannel
                      total={this.state.other_expense.total}
                      bankTransaction={this.state.allBankAccountTransaction}
                      cashTransaction={this.state.allCashTransaction}
                      chequeTransaction={this.state.allChequeTransaction}
                      paymentChannel={this.state.allOtherExpensePaymentChannel}
                      allAccountRecordGroup={this.state.allAccountRecordGroup}
                      account_type="pay"
                      oe540007={this.state.oe_5400_07}
                    />

                    <OtherExpenseSummary
                      remark={this.state.other_expense.remark}
                      transaction_list={this.state.transactions}
                      remark_edit={true}
                      other_expense={true}
                      taxType={this.state.other_expense.taxType}
                      refNumber_oe={this.state.other_expense.docNumber}
                    />
                  </div>
                </div>
              </div>
            }
          </Translation>
        </WrapperContent>
      </Wrapper>
    );
  }
}

export default OtherExpenseView;
